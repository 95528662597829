import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button, Form, Input, DatePicker, Collapse, Row, Col, Select, Table, Spin } from "antd";

import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunction";
import { getProducts } from "../../redux/actions/product.action";
import { ReadCookie } from "../../utils/readCookie";
import { getInterLog } from "../../redux/actions/interlog.action";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import Tables from "../../components/Table/Tables";
import { InterLogColumns } from "../../utils/cols";
const InterLog = () => {
    TabTitle("Inter Log")
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const { product, interLog } = useSelector((state) => state);
    const { Panel } = Collapse;
    const [lines, setLines] = useState([]);
    const [loading, setLoading] = useState(false);
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const [from_date, setFromDate] = useState(moment());
    const [to_date, setToDate] = useState(moment());

    useEffect(() => {
        const values = {
            "item_code": null,
            "actual_size_qty": null,
            "from_date": null,
            "to_date": null,
            "status": null,
            "description": null
        }
        dispatch(getInterLog(token, values)).then((res) => {
            if (res?.type === "GET_INTERLOG_SUCCESS") {
                setLines(res?.payload?.data);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Some Thing Went wrong",
                });
            }
        })

    }, [dispatch, token]);

    console.log("form.getFieldValue().from_date", form.getFieldValue().from_date)

    const onFinish = async (e) => {

        await form.validateFields().then((values) => {
            setLoading(true);
            const data = {
                "item_code": values.item,
                "actual_size_qty": values.actual_size_qty === undefined ? null : values.actual_size_qty,
                "from_date": moment(from_date).format("YYYY-MM-DD"),
                "to_date": moment(to_date).format("YYYY-MM-DD"),
                "description": values.description === undefined ? null : values.description
            }
            dispatch(getInterLog(token, data)).then((res) => {
                if (res?.type === "GET_INTERLOG_SUCCESS") {
                    setLines(res?.payload?.data);
                    setLoading(false);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Some Thing Went wrong",
                    });
                }
            })
        })
    }
    
  

    console.log("lines", lines)

    // const columns = [
    //     {
    //         title: "Item Code",
    //         dataIndex: "item_code",
    //         width: 100,
    //         fixed: 'left',

    //     },
    //     {
    //         title: "Actual Size Qty",
    //         dataIndex: "actual_size_qty",
    //         width: 120,
    //     },
    //     {
    //         title: "Date",
    //         dataIndex: "date_time",
    //         width: 140,
    //     },
    //     {
    //         title: "Status",
    //         dataIndex: "status",
    //         width: 140,
    //     },
    //     {
    //         title: "Descripton",
    //         dataIndex: "description",
    //         width: 140,
    //     },
    //     // {
    //     //     title: "is Active",
    //     //     dataIndex: "emailsent",
    //     //     width: 140,
    //     // },
    // ]


    function debounce(cb, delay = 1000) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }

    const handleChanges = (val) => {
        if (val === "" || val === "All") return;
        dispatch(getProducts(token, menuId, val));
    };

    const optimizedFn = useCallback(debounce(handleChanges), []);


    const handleItems = (value) => {
        console.log("handleItems", value)
    }

    console.log("INTER", interLog)
    function downloadExcelFileData(data) {
        if (data) {
          return data.map((v) => {
            return {
              "Item Code": v.item_code,
              "Actual Size Qty": v.actual_size_qty,
              "Date": v.date_time,
              "Description": v.description,
            };
          });
        }
      }

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader title={"Inter Log"} />
                <div className="bodyStyle">
                    <Collapse
                        style={{ width: "99%", margin: "10px" }}
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    >
                        <Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<Button
                            onClick={() => {
                                history.push("/saleperson");
                            }}
                            className="iconBtn crudBTN"
                            shape="circle"
                        />}>
                            <Form
                                form={form}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                // initialValues={worker}
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            name="item"
                                            label="Item"
                                            // labelAlign="left"
                                            labelCol={{ span: 5 }}

                                        >
                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children
                                                        ?.toLowerCase()
                                                        .indexOf(input?.toLowerCase()) >= 0
                                                }
                                                onSearch={(value) => {
                                                    optimizedFn(value);
                                                }}
                                                onChange={(e) => handleItems(e)}
                                                // onChange={(e) => {
                                                //     setItemId(e);
                                                //     // handleItems(e);
                                                // }}
                                                loading={product.loading}
                                            >
                                                {
                                                    product && product?.payload?.data.map((val, index) => {
                                                        return (
                                                            <Select.Option key={index} value={val.item_id}>
                                                                {val.item_code}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }


                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Item label="Actual Size" name="actual_size_qty" labelCol={{ span: 5 }}
                                        >
                                            <Input placeholder="Enter Actual Size" size="small" />
                                            {/* <DatePicker
                                                size="small"
                                                id="toDate"
                                                format="MM-DD-YYYY"
                                                allowClear
                                                className="dateInput customInputs"
                                                defaultValue={moment()}
                                                value={moment()}
                                            /> */}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            name="fromDate"
                                            label="From Date"
                                            labelCol={{ span: 5 }}
                                        >
                                            <DatePicker
                                                size="small"
                                                id="fromDate"
                                                format="MM-DD-YYYY"
                                                allowClear
                                                className="dateInput customInputs"
                                                defaultValue={moment()}
                                                value={moment()}
                                                onChange={(e) => setFromDate(moment(e))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            name="toDate"
                                            label="To Date"
                                            labelCol={{ span: 5 }}
                                        >
                                            <DatePicker
                                                size="small"
                                                id="date"
                                                format="MM-DD-YYYY"
                                                allowClear
                                                className="dateInput customInputs"
                                                defaultValue={moment()}
                                                value={moment()}
                                                onChange={(e) => setToDate(moment(e))}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/* <Col xs={24} xl={8}>
                                        <Form.Item
                                            label="Active"
                                            name="isActive"
                                        >
                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                allowClear

                                            //   disabled={!(formState === "add" || formState === "edit")}
                                            //   defaultValue={form.getFieldValue().orderStatus}
                                            //   onMouseDownCapture={onMouseDownSelectableHack}
                                            >
                                                <Select.Option

                                                    value={true}
                                                >
                                                    True
                                                </Select.Option>
                                                <Select.Option

                                                    value={false}
                                                >
                                                    False
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col> */}
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>

                            </Form>
                        </Panel>
                    </Collapse>

                    {interLog?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                {interLog?.payload?.data.length > 0 && (
                  <CSVLink
                    filename={"InterLog.csv"}
                    data={
                      downloadExcelFileData(interLog?.payload?.data) || []
                    }
                    onClick={() => {}}
                  >
                    Download Excel
                  </CSVLink>
                )}
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={InterLogColumns}
                  source={interLog?.payload?.data}
                  load={interLog?.loading}
                  paginate
                />
              </div>
            </div>
          )}


                    {/* <div className="mainProductContainer">
                        <div style={{ padding: 10 }}>

                            {
                                loading ? (
                                    <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                                        <Spin size='large' />
                                    </div>
                                ) : (
                                    lines.length > 0 ? (
                                        <Table
                                            // title={() =>
                                            //     <CSVLink
                                            //         filename={"invoice-email.csv"}
                                            //         data={excelData}
                                            //         onClick={() => {
                                            //         }}
                                            //     >
                                            //         Download Excel
                                            //     </CSVLink>
                                            // }
                                            // rowSelection={{
                                            //     ...rowSelection,
                                            // }}
                                            // rowKey="invoice_header_id"
                                            scroll={450}
                                            perPage={100}
                                            columns={columns}
                                            dataSource={lines.length > 0 ? lines : []}
                                            loading={interLog?.loading}
                                        // paginate
                                        />
                                    ) :

                                        interLog?.loading ? (
                                            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                                                <Spin size='large' />
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    backgroundColor: "#CCCCCC",
                                                    borderWidth: "1pt",
                                                    borderStyle: "Dashed",
                                                    fontWeight: "bold",
                                                    height: "25px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                No record(s)
                                            </div>
                                        )


                                )
                            }
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}


export default InterLog;