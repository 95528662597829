import React, { useEffect, useCallback, useRef, useState } from "react";
import "./CustomerPaymentLedger.css";
import { Select, DatePicker, Form, Table, Spin, Button, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAllScmBrand } from "../../redux/actions/scmBrand.action";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { loadCustomers } from "../../redux/actions/customerForm";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Label } from "reactstrap";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { getBillFormData } from "../../redux/actions/bill.action";
import { getVendorLedger, getVendorLedger2 } from "../../redux/actions/vendorLedger.action";

const { Option } = Select;
const dateFormatList = ["MM/DD/YYYY"];

const VenderLedger = ({ token }) => {
  TabTitle("Vendor Ledger")
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const tableRef = useRef(null);
  const [select, setSelect] = useState("All");
  const [loader, setLoader] = useState(false);
  const [customerList, setCutomerList] = useState([]);
  const [customerList2, setCutomerList2] = useState([]);
  const [customerId, setCutomerId] = useState([]);
  const [excelData, setExcelData] = useState([])
  const [excel, setExcel] = useState([])
  const [loading, setLoading] = useState(false);
  const {
    billFormData,
    vendorLedger,
    vendorLedger2
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");
  const { submited } = useParams();



  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);
  let rbalance = 0;

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      dispatch(getVendorLedger(token, values)).then((res) => {
        console.log(res?.payload?.data, 'res?.payload?.data')
        let filterData = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id != "0";
        })
        console.log(filterData, 'res?.payload?.data123')
        let filterZeroId = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id == "0";
        })
        console.log(filterZeroId, 'res?.payload?.data123456')

        setCutomerId(filterZeroId)
        setCutomerList(filterData)
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      })
      dispatch(getVendorLedger2(token, values)).then((res) => {
        console.log(res?.payload?.data, 'res?.payload?.data')
        let filterData = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id != "0";
        })
        console.log(filterData, 'res?.payload?.data123')
        let filterZeroId = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id == "0";
        })
        console.log(filterZeroId, 'res?.payload?.data123456')

        setCutomerId(filterZeroId)
        setCutomerList2(filterData)
        setLoading(false);
        downloadExcelFileData2(res?.payload?.data);
      })
    });
  };

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getAllScmBrand(token));
    dispatch(getCustomerType(token));
    dispatch(getBillFormData(token));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Transaction Type": v.transtype,
          "Transaction Date": moment(v.bill_date).format("MM-DD-YYYY"),
          "Transaction #": v.bill_header_no,
          "Transaction Amount": numeral(v.bill_amount).format("0,00.00")
        };
      })
    )
  }


  function downloadExcelFileData2(data) {
    setExcel(
      data.map((v) => {
        return {
          "Transaction Type": v.transtype,
          "Transaction Date": moment(v.payment_date).format("MM-DD-YYYY"),
          "Transaction #": v.direct_payment_header_no,
          "Transaction Amount": numeral(v.payment_amount).format("0,00.00")
        };
      })
    )
  }


  const column = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "transtype",
      key: "transtype",
      width: 150
    },
    {
      title: "Transaction Date",
      dataIndex: "bill_date",
      key: "bill_date",
      render: (text, record) => {
        return moment(record.bill_date).format("MM-DD-YYYY")
      }
    },
    {
      title: "Transaction #",
      dataIndex: "bill_header_no",
      key: "bill_header_no",
    },
    {
      title: "Transaction Amount",
      dataIndex: "bill_amount",
      key: "bill_amount",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "center"
    },
  ];

  const column2 = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "transtype",
      key: "transtype",
      width: 150
    },
    {
      title: "Transaction Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (text, record) => {
        return moment(record.payment_date).format("MM-DD-YYYY")
      }
    },
    {
      title: "Transaction #",
      dataIndex: "direct_payment_header_no",
      key: "direct_payment_header_no",
    },
    {
      title: "Transaction Amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
      render: (text, record) => {
        return <>$ {numeral(record.payment_amount).format("0,00.00")}</>
      },
      align: "center"
    },
  ];

  const billAmount = customerList.reduce((acc, item, index) => acc + parseFloat(item.bill_amount), 0)
  const billAmount2 = customerList2.reduce((acc, item, index) => acc + parseFloat(item.payment_amount), 0)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="report-wrapper-main header_class business_class ledger_print">
            <div className="row business_header justify-content-between pb-2 ledger_print">
              <div className="col-3 d-flex align-items-start  box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>

              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        onClick={handlePrint}
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print"
                      ></i>
                    </div>
                    <div className="print_icon printing_class" >
                      <CSVLink
                        filename={"Vendor-Ledger.csv"}
                        data={excelData}
                        onClick={() => {
                        }}
                      >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={xlsx}
                          style={{ borderWidth: "0px", height: "14px" }}
                        />
                      </CSVLink>
                      <CSVLink
                        filename={"Vendor-Ledger2.csv"}
                        data={excel}
                        onClick={() => {
                        }}
                      >
                      </CSVLink>
                    </div>
                  </div>
                  <span style={{ fontWeight: "bolder" }}>
                    Vendor Ledger
                    <br />

                    <div>
                      Rpt # 489
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {/* Filters Table */}
            <div className="row  business_header ledger_print">
              <div className="col business_header">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <div className="main_form row">

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          From:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="from_date"
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>

                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          To:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="to_date"
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main_form row">

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label className="labels width30">Vendor:</label>
                        <div className=" w-100">
                          <Form.Item
                            name="vendor_id"
                            rules={[
                              {
                                required: true,
                                message: "Vendor is Required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              className={"select_Inputs"}
                              loading={billFormData.loading}
                              // placeholder="Select Customers"
                              size="small"
                              ref={focusInput}
                            >
                              {billFormData?.payload?.vendor.map((item) => {
                                return (
                                  <Option key={item.vendor_id} value={item.vendor_id}>
                                    {item.vendor_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className=" col-6">
                      <div>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="submit-btn-btm "
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Render Data Table */}
          </div>
          <div className="table_content customer_ledger_table_content ledger_print" style={{ maxWidth: '900px' }} >
            <div className="">
              {vendorLedger?.payload?.data !== undefined ?
                (
                  vendorLedger?.payload?.data.length > 0 ? (
                    <Table
                      className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit ledger_print"
                      ref={tableRef}
                      size="small"
                      bordered={false}
                      type="company"
                      headerClassName='abc'
                      dataSource={customerList && customerList}
                      columns={column}
                      loading={vendorLedger.loading}
                      pagination={{
                        defaultCurrent: 1,
                        defaultPageSize: 100,
                        pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                      }}
                      summary={(pageData) => {
                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ textAlign: "center", margin: "0" }} className="totalquantity">
                                  ${billAmount.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>

                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
                ) : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
              }
              {vendorLedger2?.payload?.data !== undefined ?
                (
                  vendorLedger2?.payload?.data.length > 0 ? (
                    <>
                      <div className="print_icon printing_class" >
                        <CSVLink
                          filename={"Vendor-Ledger2.csv"}
                          data={excel}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "12px" }}
                          />
                        </CSVLink>
                      </div>
                      <Table
                        className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit ledger_print"
                        ref={tableRef}
                        size="small"
                        bordered={false}
                        type="company"
                        headerClassName='abc'
                        dataSource={customerList2 && customerList2}
                        columns={column2}
                        loading={vendorLedger2.loading}
                        pagination={{
                          defaultCurrent: 1,
                          defaultPageSize: 100,
                          pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                        }}
                        summary={(pageData) => {
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <p style={{ textAlign: "center", margin: "0" }} className="totalquantity">
                                    ${billAmount2.toLocaleString("en-US")}
                                  </p>
                                </Table.Summary.Cell>

                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    </>
                  ) : (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
                ) : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenderLedger;
