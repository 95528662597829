import './user.css'
import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Tabs, Divider } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie } from '../../utils/readCookie';
import Tables from "../../components/Table/Tables"
import { logColumns, userColumns } from "../../utils/cols";
// import { getLog } from "../../redux/actions/log.action"
import notification from '../../utils/notification'
import { Row as Brow, Col as Bcol } from "reactstrap";
import { addAdminUser, deleteAdminUser, loadAdminUser, updateAdminUser } from '../../redux/actions/adminUser.actions';
import { TabTitle } from '../../utils/GeneralFunction';

const { TabPane } = Tabs;

const AdminUser = ({ token }) => {
    TabTitle("User")
    const { users, userRights, log, } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [password, setPassword] = useState("")
    const [mobile, setMobile] = useState(null)
    const [email, setEmail] = useState("")
    const [loginId, setLoginId] = useState("")
    const [form] = Form.useForm();
    const firstNameInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [adminUserObj, setAdminUserObj] = useState("");
    // const [check, setCheck] = useState(true);
    const dispatch = useDispatch();
    const id = ReadCookie('menuId');


    useEffect(() => {
        dispatch(loadAdminUser(token))
    }, [dispatch, token])




    const handleDelete = () => {
        if (adminUserObj) {

            dispatch(deleteAdminUser(token, adminUserObj.user_id)).then((res) => {
                if (res.type === "DELETE_ADMIN_USER_SUCCESS") {
                    notification("success", "Deleted", "Record deleted successfully!");
                }
                if (res.type === "DELETE_ADMIN_USER_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    first_name: "",
                    last_name: "",
                    email: "",
                    mobile_no: null,
                    login_id: ''

                })
                setFirstName("")
                setLastName("")
                setEmail("")
                setLoginId("")
                setMobile(null)
                setAdminUserObj(null)
                setFormState('view')
                dispatch(loadAdminUser(token, id))

            })
        }
        setFormState("view")
    }

    useEffect(() => {

        if (adminUserObj) {
            setFormState('view')
            form.setFieldsValue({
                id: adminUserObj.user_id,
                first_name: adminUserObj.first_name,
                last_name: adminUserObj.last_name,
                email: adminUserObj.email,
                // password:adminUserObj.user_password,
                mobile_no: adminUserObj.mobile_no,
                login_id: adminUserObj.login_id,


            });
            setFirstName(adminUserObj.first_name)
            // setPassword(adminUserObj.user_password)
            setLastName(adminUserObj.last_name)
            setEmail(adminUserObj.email)
            setLoginId(adminUserObj.login_id)
            setMobile(adminUserObj.mobile_no)

        }


    }, [adminUserObj]);

    useEffect(() => {
        if (loginId === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [loginId])




    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    // useEffect(() => {

    //     dispatch(getLog(token))
    // }, [dispatch, token])


    const formSave = async () => {

        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addAdminUser(token, { first_name: firstName, last_name: lastName, email: email, user_password: password, mobile_no: mobile, login_id: loginId.toLowerCase() }, id)).then((res) => {

                    if (res.type === "ADD_ADMIN_USER_ERROR") {

                        notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                    }
                    if (res.type === "ADD_ADMIN_USER_SUCCESS") {
                        notification("success", "Created", "User created successfully!");

                        form.setFieldsValue({
                            first_name: "",
                            last_name: "",
                            email: "",
                            password: "",
                            mobile_no: "",
                            login_id: ''


                        })
                        setFormState("view")
                        setFirstName("")
                        setLastName("")
                        setEmail("")
                        setLoginId("")
                        setPassword("")
                        setMobile("")
                        setAdminUserObj(null)
                    }
                    dispatch(loadAdminUser(token, id))
                })
            }
            if (formState === "edit") {
                dispatch(updateAdminUser(token, { first_name: firstName, last_name: lastName, email: email, user_password: password, mobile_no: mobile, login_id: loginId }, adminUserObj.user_id)).then((res) => {
                    if (res.type === "UPDATE_ADMIN_USER_ERROR") {
                        notification("error", "Not updated", "Something went wrong")
                    }
                    if (res.type === "UPDATE_ADMIN_USER_SUCCESS") {
                        notification("success", "Updated", "User updated successfully")
                    }
                    dispatch(loadAdminUser(token, id))
                })
            }

        }).catch((err) => {

        })
    }

    useEffect(() => {
        if (formState === "add") {
            firstNameInput.current.focus()
            form.setFieldsValue({
                id: null,
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                mobile_no: "+",
                login_id: null
            })
            setEmail("")
            setFirstName("")
            setLastName("")
            setMobile("+")
            setLoginId("")
            setPassword("")
        }
    }, [formState]);
    const filterReset = () => {
        setFormState("view")
        setAdminUserObj(null)
        // setCheck(true)
        form.setFieldsValue({
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            mobile_no: null,
            login_id: null
        })
        setEmail("")
        setFirstName("")
        setLastName("")
        setMobile(null)
        setLoginId("")
        setPassword("")

    }


    return (
        <div className='styleContainer'>
            <div className='styleBody h-100' >
                <PageHeader title={"Admin Users"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} disable={disable} reset={filterReset} save={formSave} onDelete={handleDelete} /></div>
                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={14} lg={{ span: 16, order: 2 }} xl={{ span: 18, order: 2 }} >
                                    {/*** */}
                                    <div className='styleForm'  >
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"
                                            // labelCol={{ span: 8 }}

                                            >
                                                <div className='customRow styleFormUpper ' ></div>






                                                <div className='userForm'>
                                                    <div className="container-fluid pbpx-10 ptpx-15">
                                                        <Brow>

                                                            {adminUserObj !== null && !(formState === "add" || mobile === null) &&
                                                                <Bcol lg={6} xl={6} xxl={4} className="slc">

                                                                    <Form.Item
                                                                        label="ID"
                                                                        name="id"
                                                                        initialValue={form.getFieldValue().id}
                                                                        labelCol={{ span: 10 }}
                                                                        wrapperCol={{ span: 14 }}

                                                                    >
                                                                        <Input size='small' className='styleInput id-field-style' readOnly />

                                                                    </Form.Item>
                                                                </Bcol>
                                                            }

                                                            <Bcol lg={6} xl={6} xxl={4} className="slc">
                                                                <Form.Item
                                                                    label="Login ID"
                                                                    name="login_id"
                                                                    initialValue={form.getFieldValue().desc}
                                                                    labelCol={{ span: 10 }}

                                                                    wrapperCol={{ span: 14 }}
                                                                    rules={formState === "add" ? [
                                                                        {
                                                                            required: true,
                                                                            message: "Login id is required",
                                                                        },
                                                                    ] : null
                                                                    }

                                                                >

                                                                    <Input size='small' placeholder='Login ID' disabled={!(formState === "add")} readOnly={formState === "edit"} ref={firstNameInput} onChange={(e) => setLoginId(e.target.value)} className='styleInput' />
                                                                </Form.Item>
                                                            </Bcol>
                                                            <Bcol lg={6} xl={6} xxl={4} className="slc">
                                                                <Form.Item
                                                                    label="First Name"
                                                                    name="first_name"
                                                                    initialValue={form.getFieldValue().desc}
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 14 }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "First Name is required",
                                                                        }
                                                                    ]}
                                                                >

                                                                    <Input size='small' placeholder='First Name' disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setFirstName(e.target.value)} className='styleInput' />
                                                                </Form.Item>
                                                            </Bcol>
                                                            <Bcol lg={6} xl={6} xxl={4} className="slc">
                                                                <Form.Item
                                                                    label="Last Name"
                                                                    name="last_name"
                                                                    initialValue={form.getFieldValue().desc}
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 14 }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Last Name is required",
                                                                        }
                                                                    ]}
                                                                >

                                                                    <Input size='small' placeholder='Last Name' disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setLastName(e.target.value)} className='styleInput' />
                                                                </Form.Item>
                                                            </Bcol>
                                                            <Bcol lg={6} xl={6} xxl={4} className="slc">
                                                                <Form.Item
                                                                    label="Email"
                                                                    name="email"
                                                                    initialValue={form.getFieldValue().desc}

                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 14 }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Email is required",
                                                                        },
                                                                        {
                                                                            type: "email",
                                                                            message: "Only email allowed"
                                                                        }
                                                                    ]}
                                                                >

                                                                    <Input size='small' placeholder='Email' type="email" disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setEmail(e.target.value)} className='styleInput' />
                                                                </Form.Item>
                                                            </Bcol>
                                                            <Bcol lg={6} xl={6} xxl={4} className="slc">
                                                                <Form.Item
                                                                    label="Password"
                                                                    name="password"
                                                                    initialValue={form.getFieldValue().desc}
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 14 }}
                                                                    rules={formState === "add" ? [
                                                                        {
                                                                            required: true,
                                                                            message: "Password is required",
                                                                        }
                                                                    ] : null}
                                                                >

                                                                    <Input size='small' placeholder='Password' type="password" disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setPassword(e.target.value)} className='styleInput' />
                                                                </Form.Item>
                                                            </Bcol>
                                                            <Bcol lg={6} xl={6} xxl={4} className="slc">
                                                                <Form.Item
                                                                    label="Mobile"
                                                                    name="mobile_no"
                                                                    initialValue={formState === "add" ? "+" : ""}
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 14 }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Mobile number is required",
                                                                        },
                                                                        {
                                                                            pattern: new RegExp(/^([+]\d{2})\d{8,13}$/),
                                                                            message: "Invalid Mobile Number"
                                                                        }
                                                                    ]}

                                                                >

                                                                    <Input size='small' placeholder='Mobile' disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setMobile(e.target.value)} className='styleInput' />
                                                                </Form.Item>
                                                            </Bcol>
                                                        </Brow>
                                                    </div>




                                                    {/* <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Active"
                                                            name="active"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                        </Form.Item>
                                                    </div> */}
                                                </div>
                                            </Form>
                                        </div>

                                        {/* <div className='modalStyle' >


                                        </div> */}
                                        <Divider />
                                        {/* <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered  >
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div> */}
                                    </div>

                                </Col>
                                <Col xs={24} sm={24} md={10} lg={{ span: 8, order: 1 }} xl={{ span: 6, order: 1 }} >
                                    <div className='styleTable' >
                                        <Tables type="adminUser" formState={formState} adminUserObj={setAdminUserObj} source={users?.payload?.data} col={userColumns} paginate scroll={500} perPage={25} load={users.loading} rowKey="user_id" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>





    )
}


export default AdminUser