import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Input } from "antd";
import Loader from "react-loader-spinner";
import { getOneBGradeStock } from "../../redux/actions/bGradeStock";
import { DownloadTableExcel } from "react-export-table-to-excel";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { ReadCookie } from "../../utils/readCookie";
import { Excel } from "antd-table-saveas-excel";
import logo from "../../assets/images/logo.png";
import { header } from "../OMSBooking";
import "../OMSBooking/styleSheet.css";
import printJS from "print-js";
import moment from 'moment/moment';
import { Link } from "react-router-dom";
import numeral from 'numeral';
import { TabTitle } from '../../utils/GeneralFunction';
const B_Grade_Stock = ({ token }) => {
  TabTitle("B Grade")
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const { bGradeStock } = useSelector((state) => state);
  const [excelToogle, setExcelToogle] = useState(false);
  const tableRef = useRef(null);

 let date=bGradeStock?.payload?.data[0]?.date;
 let format=moment(date).format("MM-DD-YYYY")
 
  useEffect(() => dispatch(getOneBGradeStock(token, menuId)), [dispatch, menuId, token])
  const totalSum = () => {
    let sumAmount = bGradeStock?.payload?.data.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.total_quantity);
    }, 0);
    return sumAmount;
  }
  
  const handlePrint = () => {
    if (bGradeStock?.payload?.data.length > 3000) {
      printJS({
        printable: bGradeStock?.payload?.data,
        type: "json",
        honorColor: true,
        repeatTableHeader: true,
        documentTitle: "Report",
        properties: ["location", "product", "size","actualsizeqty", "parentsku", "reference"],
        gridHeaderStyle: " border: 1px solid grey; text-align:left; padding-left:10px",
        gridStyle: "border: 1px solid grey; padding-left:10px",
        header: header,
      });
    }
    else {
      window.print()
    }
  };

  const handleExcelExport = () => {
    setExcelToogle(false);
    const excel = new Excel();
    excel.addSheet("test")
      .addColumns(columns)
      .addDataSource(bGradeStock?.payload?.data, { str2Percent: true })
      .setTHeadStyle()
      .saveAs("Excel.xlsx");
  };

  const columns = [

    {
      title: "Parent Sku",
      dataIndex: "parentsku",
      key: "parentsku",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.parentsku.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.parentsku - b.parentsku;
      },
    },
      
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.sku.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.sku - b.sku;
      },
    },
    {
      title: "Gender Category Name",
      dataIndex: "gender_category_name",
      key: "gender_category_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.gender_category_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.gender_category_name - b.gender_category_name;
      },
    },
    {
      title: "Gender Name",
      dataIndex: "gender_name",
      key: "gender_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.gender_name - b.gender_name;
      },
    }, 
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.location.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.location - b.location;
      },
    }, 
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.product.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.product - b.product;
      },
    },
    {
      title: "Style Name",
      dataIndex: "style_name",
      key: "style_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.style_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.style_name - b.style_name;
      },
    },
    {
      title: "Wash Name",
      dataIndex: "wash_name",
      key: "wash_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.wash_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.wash_name - b.wash_name;
      },
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.reference.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.reference - b.reference;
      },
    },
    {
      title: "Total Quantity",
      dataIndex: "total_quantity",
      key: "total_quantity",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.total_quantity.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.total_quantity - b.total_quantity;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.date - b.date;
      },
    },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>

                <div className="col-3 box-space right-content-header text-right">
                  <span style={{ fontWeight: "bolder" }}>
                    <div className=" d-flex justify-content-between">
                      <div className=" d-flex printing_class">
                        <div className="print_icon printing_class">
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            onClick={handlePrint}
                            className="fa-solid fa-print"
                          ></i>
                        </div>
                        <div className="print_icon printing_class">

                          {excelToogle ? (
                            <DownloadTableExcel
                              filename="users table"
                              sheet="users"
                              currentTableRef={tableRef.current}
                            >
                              <input
                                type="image"
                                name="ImgExcel"
                                alt="Export to excel"
                                id="ImgExcel"
                                title="Export to excel"
                                src={xlsx}
                                style={{ borderWidth: "0px", height: "20px" }}
                              />
                            </DownloadTableExcel>
                          ) : (
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              onClick={handleExcelExport}
                              style={{ borderWidth: "0px", height: "20px" }}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        Login as:
                        <span
                          id="lblSalesPerson"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {userName}
                        </span>
                        
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
                  Date:    {format}
            <div >
              <div >
{
  bGradeStock?.payload?.data?.length>0?
  <Table
  scrollX={"20rem"}
  dataSource={bGradeStock?.payload?.data}
  columns={columns}
  perPage={250}
  load={bGradeStock.loading}
  summary={(pageData) => {
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Quantity : {numeral(totalSum()).format("0,0.00")}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }}
/>:
<Loader type="Grid" color="#212121" height={40} width={40} />
}

              
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default B_Grade_Stock;
