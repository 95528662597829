import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  Table, Spin
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { getinventory } from "../../redux/actions/inventoryReport.action";
import { TabTitle } from "../../utils/GeneralFunction";
const { Option } = Select;

const InventoryReport = () => {
  TabTitle("D2C Inventory")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [inventoryList, setInventoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = ReadCookie("defaultCompany");
  const {
    inventory,
    adminLocation
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])


  useEffect(() => {
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      // width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "Parent",
      dataIndex: "parent",
      key: "parent",
      // width: 70,
    },
    {
      title: "UPC Code",
      dataIndex: "upc_code",
      key: "upc_code",
      // width: 100,
    },
    {
      title: "Sum of quantity_available",
      dataIndex: "on_hand_quantity",
      key: "on_hand_quantity",
      // width: 100,
      align: "right"
    },
    {
      title: "Cost",
      dataIndex: "cost_price",
      key: "cost_price",
      // width: 130,
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Title",
      dataIndex: "style_name",
      key: "style_name",
      // width: 70,
    },
    {
      title: "Item Name",
      dataIndex: "sku",
      key: "sku",
      // width: 70,
    },
    {
      title: "Wash Name",
      dataIndex: "wash_name",
      key: "wash_name",
      // width: 70,
    },
    {
      title: "Gender Name",
      dataIndex: "gender_name",
      key: "gender_name",
      // width: 120,
    },
    {
      title: "New Season",
      dataIndex: "newseason",
      key: "newseason",
      // width: 150,
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getinventory(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setInventoryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Parent": v.parent,
          "UPC Code": v.upc_code,
          "Sum of quantity_available": v.on_hand_quantity,
          "Cost": v.cost_price,
          "Title": v.style_name,
          "Item Name": v.sku,
          "Wash Name": v.wash_name,
          "Gender Name": v.gender_name,
          "New Season": v.newseason,
        };
      })
    )
  }

  const totalQuantity = () => {
    let sumQty = inventoryList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.total_qty);
    }, 0);
    return sumQty;
  }

  const totalSum = () => {
    let sumAmount = inventoryList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.cost_price);
    }, 0);
    return sumAmount;
  }


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"D2C Inventory Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item
                          name="location"
                          label="Location"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size="small"
                            showSearch
                            optionFilterProp="children"
                            autoComplete={"nope"}
                            loading={adminLocation.loading}
                          >
                            <Option value={null}>All</Option>
                            {
                              adminLocation?.payload?.data.map((val, index) => {
                                return (
                                  <Option key={index} value={val.location_id}>
                                    {val.location_name}
                                  </Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            inventoryList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"D2C-Inventory-Report.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(200px + 90%)',
                  y: 450,
                }}
                //   scrollX={"50rem"}
                dataSource={inventoryList}
                columns={columns}
                loading={inventory.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  let Qty = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.on_hand_quantity
                    ));
                  },
                    0);
                  let Amount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.cost_price
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Qty.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default InventoryReport;
