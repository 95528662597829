import React from "react";
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function CrudButtons({ invoiceId, edit, save, disable, formState, formType, onDelete, reset, name, flag, rights, saleOrderID, id, customerCode, formData, reportTittle, reportName, reportIP, orderID, customer_id, order_header_id, invoiceHeaderID, invoice_header_id, userRights, user_type }) {
  const history = useHistory();



  // ************ CONFIRM DELETE FUNCTION **************
  const confirmDelete = () => {
    if (formData?.ra_no) {
      Swal.fire({
        icon: "error",
        text: "Return is Already Created, You need to Delete Return First."
      })
    } else {
      onDelete();
    }
  };


  // function disableBtns() {
  //   let result = false;
  //   if (!userRights?.can_edit && user_type !== "PK" || formState === "add") {
  //     result = true;
  //   }
  //   else {
  //     result = false;
  //   }
  //   return result;
  // }

  function disableBtns() {
    let result = false;
    // if (!userRights?.can_edit && user_type !== "PK" || formState === "add") {
    //   result = true;
    // }
    if (!userRights?.can_edit || formState === "add") {
      result = true;
    }
    if (user_type !== "PK") {
      result = true;
    }
    else {
      result = false;
    }
    return result;
  }

  return (
    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null :
            <div className='buttons-div' >
              <Tag color={"green"} key={1}>{formType.toUpperCase()}</Tag>
              {formType !== "view" &&
                <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className='fspx-12' />} />
              }
            </div>
          }
        </div>
        <div>

          <Tooltip placement="bottom" title="Accept Payment" color="#fbfbfb">
            <Button
              type="primary"
              // disabled={((formState === "add" || formState === "view")) || userRights.can_delete === true ? false : true}
              // disabled={userRights.can_edit === true ? false : true || formState === "add"}
              disabled={disableBtns()}
              onClick={() => history.push(`/customer-payment/add?mode=add`, { state: formData })}
              className="modalButton"
            >
              Accept Payment
            </Button>
          </Tooltip>
          {
            invoice_header_id && (
              <Tooltip placement="bottom" title="Return " color="#fbfbfb">
                <Button
                  disabled={disableBtns()}
                  // disabled={userRights.can_edit === true ? false : true || formState === "add"}
                  // disabled={((formState === "add" || formState === "view")) || userRights.can_delete === true ? false : true}
                  type="primary"
                  onClick={() => history.push(`/return-authorization?mode=return&invoice_id=${invoice_header_id}&customer_id=${customer_id}`)}
                  className="modalButton"
                >
                  Return
                </Button>
              </Tooltip>
            )
          }

          {(formType === "formView" || formType === "view") && (

            userRights.can_print ? (
              <Link
                style={{ paddingLeft: "10px", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"

                to={{
                  pathname: `/sales-inovice-report`,
                  search: `?order_header_id=${order_header_id}&&invoice_header_id=${invoice_header_id}`,
                  orderID
                }}
              >
                View Report
              </Link>
            ) : ""

          )}

          {/*Edit Button*/}
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
                <Button
                  // disabled={
                  //   disable ||
                  //   formType === "view" ||
                  //   formType === "add" ||
                  //   (userRights?.can_edit === true ? false : true)
                  // }
                  disabled={(userRights.can_edit === true ? false : true) || (disable || formType === "view" || formType === "add")}
                  onClick={() => formState("edit")}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )
          }

          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => {
                history.push("/invoice-list");
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>


          {/*Delete Button*/}
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Delete" color="#fbfbfb">
                {rights?.can_delete === false ? (
                  <Button
                    className="iconBtn crudBTN"
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                ) : (
                  <Popconfirm
                    placement="topLeft"
                    disabled={userRights.can_delete === true ? false : true}
                    title={`Are you sure to delete this ${invoice_header_id}?`}
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      disabled={userRights.can_delete === true ? false : true}
                      className="iconBtn crudBTN"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                )}
              </Tooltip>
            )
          }
          {/*Save Button*/}
          {
            (formType === "add" || formType === "edit") && (
              <Tooltip placement="bottom" title="Save" color="#fbfbfb">
                <Button
                  onClick={() => save()}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<FileDoneOutlined />}
                />
              </Tooltip>
            )
          }
        </div>
      </div>
      <Divider />
    </>
  );
}
