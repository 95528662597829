import {
	GET_ONE_BILL_PAYMENT_REQUEST,
	GET_ONE_BILL_PAYMENT_SUCCESS,
	GET_ONE_BILL_PAYMENT_ERROR,
	GET_BILL_PAYMENT_REQUEST,
	GET_BILL_PAYMENT_SUCCESS,
	GET_BILL_PAYMENT_ERROR,
	ADD_BILL_PAYMENT_REQUEST,
	ADD_BILL_PAYMENT_SUCCESS,
	ADD_BILL_PAYMENT_ERROR,
	UPDATE_BILL_PAYMENT_REQUEST,
	UPDATE_BILL_PAYMENT_SUCCESS,
	UPDATE_BILL_PAYMENT_ERROR,
	DELETE_BILL_PAYMENT_REQUEST,
	DELETE_BILL_PAYMENT_SUCCESS,
	DELETE_BILL_PAYMENT_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getAllBillPayment = (token, data) => (dispatch) => {
	// 
	dispatch({ type: GET_BILL_PAYMENT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/payBillPaymentHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			payment_header_no: data.referenceNo === "" ? null : data.referenceNo,
			company_name: data.subsidiary === "" ? null : data.subsidiary,
			from_amount: data.from_amount === "" ? null : data.from_amount,
			to_amount: data.to_amount === "" ? null : data.to_amount,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			due_date: moment(data.due_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			account_name: data.account === "" ? null : data.account,///
			vendor_name: data.payee === "" ? null : data.payee,////
			gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,///
			ap_account_name: data.apAccount === "" ? null : data.apAccount,
			cheaque_no: data.cheaque_no === "" ? null : data.cheaque_no,////
			company_id: 2
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BILL_PAYMENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BILL_PAYMENT_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillPayment = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_PAYMENT_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payBillPaymentHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_PAYMENT_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_PAYMENT_ERROR,
				payload: error.response,
			});
		});
};

export const addBillPayment = (token, formData, sumVal, companyId) => (dispatch) => {

	dispatch({ type: ADD_BILL_PAYMENT_REQUEST });
	let newData = {
		payment_date: moment(formData.paymentdate).format("MM-DD-YYYY"),
		due_date: moment(formData.dueDate).format("MM-DD-YYYY"),
		account_id: formData.account,
		vendor_id: formData.payee,
		ap_account_id: null,
		company_id: formData.subsidiary,
		payment_amount: sumVal,
		gl_period_id: formData.postingPeriod,
		memo: formData.memo,
		check_no: formData.checkNo,


		//		reference_no: formData.referenceNo
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/payBillPaymentHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_PAYMENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_PAYMENT_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillPayment = (token, formData, id) => (dispatch) => {

	// 
	let newData = {
		payment_date: moment(formData.paymentdate).format("MM-DD-YYYY"),
		account_id: formData.account,
		vendor_id: formData.payee,
		ap_account_id: formData.apAccount,
		company_id: formData.subsidiary,
		payment_amount: formData.amount,
		// payment_amount: formData.paymentAmount,
		gl_period_id: formData.postingPeriod,
		memo: formData.memo,
	};
	dispatch({ type: UPDATE_BILL_PAYMENT_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/payBillPaymentHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			// 
			return dispatch({
				type: UPDATE_BILL_PAYMENT_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_PAYMENT_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const deleteBillPaymentHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_BILL_PAYMENT_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/payBillPaymentHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_PAYMENT_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_PAYMENT_ERROR,
				payload: error.response,
			});
		});
};
