import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { deleteCreditMemoHeader, getOneCreditMemoDetail, getCreditMemoFormData } from "../../redux/actions/creditMemo.action";
import CrudButtons from "./components/CrudButtons";
import CreditMemoForm from "../CreditMemo/components/Form/CreditMemoForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";

export default function CreditMemo({ token }) {
	TabTitle("Credit Memo")
	const dispatch = useDispatch();
	const companyId = ReadCookie("defaultCompany");
	// const menuId = ReadCookie("menuId");
	const { log, creditMemo, userType } = useSelector((state) => state);
	const history = useHistory();
	const { id } = useParams();
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [flag, setFlag] = useState(false);
	const [submit, setSubmit] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const [loading, setLoading] = useState(false);
	const startDate = "01-1-2022";
	const endDate = "06-01-2022";
	const [userRights, setUserRights] = useState({})

	const [menuId, setMenuId] = useState(1261);


	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		setLoading(true);
		dispatch(deleteCreditMemoHeader(token, id)).then((res) => {
			if (res.type === "DELETE_CREDIT_MEMO_HEADER_SUCCESS") {
				// dispatch(getAllBillHeader(token, startDate, endDate));
				setLoading(false);
				notification("success", "Credit Memo Deleted", res.payload);
				setFlag(true);
				history.push("/credit-memo/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
		// dispatch(getCreditMemoFormData(token));
	}, []);

	useEffect(() => {
		if (id) {
			setFormState("formView");
			dispatch(getOneCreditMemoDetail(token, id));
		}
	}, [id]);

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Credit Memo"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								user_type={userType?.payload?.data[0]?.coalesce}
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								userRights={userRights}
								formType={formState}
								save={formSave}
								formState={setFormState}
								onDelete={handleDelete}
								saleOrderID={id}
								formData={creditMemo?.payload}
							/>
							<div className="mainProductContainer">
								<CreditMemoForm
									loading={loading}
									companyId={companyId}
									submit={setSubmit}
									val={submit}
									formData={formState === "formView" || formState === "edit" ? creditMemo?.payload : null}
									formState={formState}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									saleOrderID={id}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Bill{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
