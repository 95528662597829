import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  DatePicker,
  Input,
  Tabs,
  Button,
  Form,
  Select,
  Card,
  Row,
  Col,
  Table,
  InputNumber,
  Collapse,
  Divider,
  Skeleton,
  Tooltip,
  Popconfirm,
  Spin,
  Checkbox,
} from "antd";
import Swal from "sweetalert2";
import {
  CaretRightOutlined,
  DeleteOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  addOrderShipmentHeader,
  updateOrderShipmentHeader,
  getOrderShipmentFormData,
  deleteOrderShipmentLine,
  getOneOrderShipmentHeaderDetail,
} from "../../../../redux/actions/orderShipment.action";
import {
  getOneOrderShipmentLine,
  addOrderShipmentLine,
  updateOrderShipmentLine,
  getOneOrderShipmentLineDetail,
} from "../../../../redux/actions/orderShipmentLine.action";
import { getLog } from "../../../../redux/actions/log.action";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { logColumns } from "../../../../utils/cols";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import numeral from "numeral";
import { getOneCustomerAddress } from "../../../../redux/actions/customerAddress.action";
import { sendEmail } from "../../../../redux/actions/email.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getTracking } from "../../../../redux/actions/tracking.action";
import { sendToBergen } from "../../../../redux/actions/sendToBergen.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { sendBergenEmail } from "../../../../redux/actions/sendBergenEmail.action";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;

const OrderShipmentForm = ({
  userRights,
  formData,
  val,
  token,
  formState,
  submit,
  dCompany,
  saleOrderID,
  checkFlag,
  setFlag,
  shipHeaderId,
  edit,
  loading: deleteLoading,
  changeState,
  mode,
  setDisable,
}) => {
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { orderShipmentFormData, customerAddress, glImpact, tracking, systemLog } =
    useSelector((state) => state);
  const [visibleItems, setVisibleItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isShipComplete, setIsShipComplete] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const history = useHistory();
  const [deletedLineItems, setDeletedLineItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shipingCost, setShippingCost] = useState(0);

  // ********** SUMMARY DISCOUNT SUM **********

  const discountSum = visibleItems.reduce((accum, val) => {
    return accum + 0;
  }, 0);

  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0])
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      }
    })
  }
  // ********** MODE USEEFFECT **********
  useEffect(() => {
    dispatch(getOrderShipmentFormData(token, false));
    if (mode === "edit") {
      dispatch(getOneOrderShipmentLineDetail(token, saleOrderID)).then(
        (res) => {

          if (res.type === "GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_SUCCESS") {

            // const initialValue = 0;
            // const sumWithInitial = res?.payload?.data?.reduce(
            //   (total, item) => item.quantity + total, 0);
            // console.log(sumWithInitial);
            const totalAmt = res?.payload?.data?.reduce((sum, i) => {
              return sum + (i.rate * i.quantity)
            }, 0)
            console.log("🚀 ~ file: OrderShipmentForm.js:127 ~ totalAmt ~ totalAmt:", totalAmt)

            let defKeys = [];
            res?.payload?.data.filter((item) => item.fulfill === true).map((o) => defKeys.push(o.item_code));
            setSelectedRowKey(defKeys);
            setVisibleItems(res.payload.data);
          } else if (res.type === "GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_ERROR") {
            notification("warning", "No Items Found", res?.payload?.data.data);
          }
        }
      );
    } else if (mode === "add") {
      dispatch(getOneOrderShipmentLine(token, saleOrderID)).then((res) => {
        if (res.type === "GET_ONE_ORDER_SHIPMENT_LINE_SUCCESS") {

          let defKeys = [];
          res?.payload
            ?.filter((item) => item.fulfill === true)
            .map((o) => defKeys.push(o.item_code));
          setSelectedRowKey(defKeys);
          setVisibleItems(res.payload);
          let items = res?.payload.map((item) => {
            let quantity = 0;
            if (parseInt(item.available_quantity) <= parseInt(item.balance)) {
              quantity = parseInt(item.available_quantity);
            } else if (
              parseInt(item.balance) <= parseInt(item.available_quantity)
            ) {
              quantity = parseInt(item.balance);
            }
            return {
              ...item,
              quantity: quantity,
            };
          });
          setVisibleItems(items);
        } else if (res.type === "GET_ONE_ORDER_SHIPMENT_LINE_ERROR") {
          notification("warning", "No Items Found", res?.payload?.data.data);
        }
      });
    }
  }, [mode]);

  // ********** FORMDATA USEEFFECT **********
  useEffect(() => {
    if (formData) {
      if (formData.order_header_id) {
        dispatch(
          getGLImpact(token, {
            transaction_header_id: saleOrderID,
            transaction_type: "PT",
          })
        );
        dispatch(getOneInvoiceGlPeriod(token));
        dispatch(
          getOneCustomerAddress(
            token,
            formData.customer_id,
            formData.order_header_id
          )
        );
        dispatch(
          getTracking(token, {
            tracking_id: formData.ship_header_no,
          })
        );
      }
      form.setFieldsValue({
        shipHeaderId: formData.ship_header_no,
        customer: formData.customer_name,
        company: formData.company_id,
        startDate: moment(formData.start_date),
        orderDate: moment(formData.order_date),
        cancelDate: moment(formData.cancel_date),
        orderNumber: formData.order_header_no,
        category: formData.sp_region_category_id,
        gender: formData.gender_name,
        genderId: formData.gender_id,
        orderStatus: formData.order_status_id,
        location: formData.location_name,
        store: formData.customer_store_no,
        salesPerson: formData.salesperson_name,
        region: formData.region_name,
        shipHeaderId: formData.ship_header_no,
        memo: formData.memo,
        ptMemo: formData.pt_memo,
        resaleCertificateNo: formData.release_certificate_no,
        externalRefID: formData.reference_no,
        shippingMethodId: formData.shipping_method_id,
        shippingCost: formData.shipping_cost,
        shippingTaxCode:
          formData.shipping_tax_code == null
            ? formData.shipping_tax_code
            : Number(formData.shipping_tax_code),
        shippingTaxRate: formData.shipping_tax_rate,
        shippingAddressID: formData.shipping_address_id,
        shipViaId: formData.ship_via_id,
        termForPrint: formData.term_for_print,
        paymentTermId: formData.payment_term_id,
        freightTermId: formData.freight_term_id,
        factor_decision: formData.factor_decision,
        freightAmount: formData.freight_amount,
        freightCharges: formData.freight_charges,
        isShipComplete: formData.is_ship_complete,
        isClosed: formData.is_closed,
        isSendToBergen: formData.sent_to_bergen,
        deptDescription: formData.department_description,
        divisionCode: formData.division_code,
        dc: formData.dc_no,
        po: formData.reference_no ? formData.reference_no : formData?.po_no,
        divisionDescription: formData.division_description,
        markForCode: formData.mark_for_code,
        markForCompanyName: formData.mark_for_company_name,
        markForAddress1: formData.mark_for_address_1,
        markForAddress2: formData.mark_for_address_2,
        markForCity: formData.mark_for_city,
        markForState: formData.mark_for_state,
        markForZip: formData.mark_for_zip,
        markForCountry: formData.mark_for_country,
        markForEmail: formData.mark_email,
        markForPhone: formData.mark_for_phone,
        factor: formData.factor_id,
        shippingAccountNo: formData.ship_account_no,
        berganBillingId: formData.bergan_billing_id,
        berganPaymentTermId: formData.bergan_payment_term_id,
        sourceType: formData.source_type,
        status: formData.status_id,
        ptStatusId: formData.pt_status_id,
        shipDate: moment(formData.ship_date),
        specialInstructions: formData.special_instructions,

        bill_to_non_us: formData.bill_to_non_us,
        ship_to_non_us: formData.ship_to_non_us,
        order_type: formData.order_type_id,
        tax_duties: formData.tax_duties,
        cs_comment: formData.cs_comment,
        billingCustomer: formData.billing_address_id,
        billing_firstname: formData.bill_to_first_name,
        billing_lastname: formData.bill_to_last_name,
        addressOneBilling: formData.bill_to_address_1,
        addressTwoBilling: formData.bill_to_address_2,
        cityBilling: formData.bill_to_city_name,
        stateBilling: formData.bill_to_state_name,
        zipBilling: formData.bill_to_zip_code,
        countryBilling: formData.bill_to_country_name,
        telBilling: formData.bill_to_phone,
        billingEmail: formData.bill_to_email,
        shippingCustomer: formData.shipping_address_id,
        shipping_firstname: formData.ship_to_first_name,
        shipping_lastname: formData.ship_to_last_name,
        addressOneShipping: formData.ship_to_address_1,
        addressTwoShipping: formData.ship_to_address_2,
        shippingEmail: formData.ship_to_email,
        cityShipping: formData.ship_to_city_name,
        telShipping: formData.ship_to_phon,
        stateShipping: formData.ship_to_state_name,
        zipShipping: formData.ship_to_zip_code,
        countryShipping: formData.ship_to_country_name,
      });
      setShippingCost(formData.shipping_cost);
    }

  }, [formData]);

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  // ********** UPDATE QUANTITY FUNCTION **********
  const updateQuantity = (type, id, value, index) => {
    let arr = [...visibleItems];
    value = parseInt(value);
    if (formState === "add") {
      const filter = arr.map((item) => {
        if (item.item_code === id) {
          let maxQuantity = 0;
          if (parseInt(value) === 0) {
            setSelectedRowKey(
              selectedRowKey.filter((items) => items !== item.item_code)
            );
          } else {
            setSelectedRowKey([...selectedRowKey, item.item_code]);
          }

          if (parseInt(value) <= parseInt(item?.available_quantity)) {
            maxQuantity = parseInt(value);
          } else if (parseInt(item.available_quantity) <= parseInt(value)) {
            maxQuantity = parseInt(item.available_quantity);
          }

          if (value > maxQuantity) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Maxiumum Quantity Exceed",
            });
          }

          if (type === "up") {
            item.quantity = value > maxQuantity ? maxQuantity : value;
            item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
            item.discount =
              (item.quantity * item.rate * item.discount_percent) / 100;
            item.tax_amount =
              (item.quantity * item.rate * item.tax_percent) / 100;
            item.net_amount =
              item.quantity * item.rate - item.discount + item.tax_amount;
            return item;
          }

          if (type === "down") {
            item.quantity = value > maxQuantity ? maxQuantity : value;
            item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
            item.discount =
              (item.quantity * item.rate * item.discount_percent) / 100;
            item.tax_amount =
              (item.quantity * item.rate * item.tax_percent) / 100;
            item.net_amount =
              item.quantity * item.rate - item.discount + item.tax_amount;
            return item;
          }
        } else {
          return item;
        }
      });
      visibleItems[index].gross_amount = value * visibleItems[index].rate;
      let selectItem = filter.filter((f) => f.fulfill);
      setFilteredItems(selectItem);
      setVisibleItems(filter);
    } else if (formState === "edit") {
      const filter = arr.map((item) => {
        if (item.item_code === id) {
          let invoice_qty = parseInt(item.invqty);
          if (value >= invoice_qty) {
            if (value <= item.available_quantity) {
              item.quantity = value;
              item.discount =
                (item.quantity * item.rate * item.discount_percent) / 100;
              item.tax_amount =
                (item.quantity * item.rate * item.tax_percent) / 100;
              item.net_amount = item.rate * item.quantity - parseFloat(item.discount || 0) + parseFloat(item.tax_amount || 0);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Qty can't be Greater Than Available Qty.",
              });
            }

          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Value Cannot be Less Than Invoice Qty.",
            });
          }
          return item;
        } else {
          return item;
        }
      });
      visibleItems[index].gross_amount = value * visibleItems[index].rate;
      let selectItem = filter.filter((f) => f.fulfill);
      setFilteredItems(selectItem);
      setVisibleItems(filter);
    }
  };


  const updateDiscountPercent = (type, id, value, index) => {
    let arr = [...visibleItems];
    value = value;
    // Rate and QTY;
    let rate_and_qty = arr[index].rate * arr[index].quantity
    arr[index].discount_percent = value;
    arr[index].discount = (rate_and_qty * value) / 100;
    arr[index].net_amount = rate_and_qty - arr[index].discount || 0 + arr[index].tax_amount || 0;
    setVisibleItems(arr);
  };

  const updateTaxPercent = (type, id, value, index) => {
    let arr = [...visibleItems];
    value = value;
    // Rate and QTY;
    let rate_and_qty = arr[index].rate * arr[index].quantity
    arr[index].tax_percent = value;
    arr[index].tax_amount = (rate_and_qty * value) / 100;
    arr[index].net_amount = rate_and_qty - arr[index].discount + arr[index].tax_amount;
    setVisibleItems(arr);
  };

  // ******* UPDATE RATE FUNCTION *********

  const updateRate = (type, id, value, index) => {
    let arr = [...visibleItems];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        item.rate = value;

        item.discount = ((value * item.quantity) * item.discount_percent) / 100;
        item.tax_amount = ((value * item.quantity) * item.tax_percent) / 100;
        item.net_amount = item.rate * item.quantity - parseFloat(item.discount || 0) + parseFloat(item.tax_amount || 0);
        return item;
      } else {
        return item;
      }
    });
    setVisibleItems(filter);
  };

  // ********** DELETE ORDER ITEMS FUNCTION **********
  const handleDeleteOrder = (record) => {
    let dataSource = [...visibleItems];
    let deleteItem = dataSource.filter((val) => val.item_id !== record.item_id);
    setVisibleItems(deleteItem);

    if (mode === "edit") {
      let deleteDataSource = [...deletedLineItems];
      deleteDataSource.push(record);
      setDeletedLineItems(deleteDataSource);
    }
  };

  // ********** ROWS COLUMNS **********
  const columns = [
    {
      title: "SKU",
      dataIndex: "item_code",
      width: 100,
      fixed: "left",
    },
    {
      title: "Style",
      dataIndex: "style_name",
      width: 120,
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
    },
    {
      title: "Gender Category",
      dataIndex: "gender_category_name",
      width: 150,
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Cost Price",
      dataIndex: "rate",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            <InputNumber
              disabled={!(formState === "add" || formState === "edit")}
              size="small"
              min="0"
              onStep={(value, info) =>
                updateRate(info.type, record.item_code, value, index)
              }
              value={record.rate}
              onChange={(value) =>
                updateRate("up", record.item_code, value, index)
              }
              defaultValue={0}
            />
            {/* {numeral(record.rate).format("0.00")} */}
          </>
        );
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      editable: true,
    },
    {
      title: "Available Quantity",
      dataIndex: "available_quantity",
      editable: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      render: (text, record, index) => {
        return (
          <InputNumber
            disabled={!(formState === "add" || formState === "edit")}
            size="small"
            min="0"
            onStep={(value, info) =>
              updateQuantity(info.type, record.item_code, value, index)
            }
            value={record.quantity}
            onChange={(value) =>
              updateQuantity("up", record.item_code, value, index)
            }
          // defaultValue={0}
          />
        );
      },
    },
    {
      title: "Amount",
      render: (_, record) =>
        parseFloat(record.quantity * record.rate || 0).toFixed(2),
    },
    {
      title: "Discount Percent",
      render: (_, record, index) => {
        return (
          <>
            <InputNumber
              disabled={!(formState === "add" || formState === "edit")}
              size="small"
              min="0"
              onStep={(value, info) =>
                updateDiscountPercent("up", record.item_code, value, index)
              }
              value={parseFloat(
                record.discount_percent === null ? 0 : record.discount_percent
              ).toFixed(2)}
              onChange={(value) =>
                updateDiscountPercent("up", record.item_code, value, index)
              }
            // defaultValue={0}
            />
            {/* {parseFloat(
              record.discount_percent === null ? 0 : record.discount_percent
            ).toFixed(2)}{" "} */}

          </>
        );
      },
    },
    {
      title: "Discount Amount",

      render: (_, record) =>
        parseFloat(record.discount === null ? 0 : record.discount || 0).toFixed(
          2
        ),
    },
    {
      title: "Tax Percent",
      render: (_, record, index) => {
        return (
          <>
            <InputNumber
              disabled={!(formState === "add" || formState === "edit")}
              size="small"
              min="0"
              onStep={(value, info) =>
                updateTaxPercent("up", record.item_code, value, index)
              }
              value={parseFloat(
                record.tax_percent === null ? 0 : `${record.tax_percent}`
              ).toFixed(2)}
              onChange={(value) =>
                updateTaxPercent("up", record.item_code, value, index)
              }
            // defaultValue={0}
            />
            {/* {parseFloat(
              record.tax_percent === null ? 0 : `${record.tax_percent} %`
            ).toFixed(2)}{" "}
            % */}
          </>
        );
      },
      width: 70,
    },
    {
      title: "Tax Amount",
      render: (_, record) => {
        return parseFloat(
          record.tax_amount === null ? 0 : record.tax_amount || 0
        ).toFixed(2);
      },
      width: 70,
    },

    {
      title: "Net Amount",
      render: (_, record) => parseFloat(record.net_amount || 0).toFixed(2),
    },
    {
      title: "Operation",
      width: 80,
      dataIndex: "operation",
      render: (_, record, index) => {
        return (
          <>
            {/* {
              formState === "edit" ? (
                <Tooltip title="Delete" color={"white"}>
                  <Popconfirm
                    title={`Are you sure to delete the item ${record.item_id}?`}
                    onConfirm={() => handleDeleteOrder(record)}
                  >
                    <DeleteOutlined className={`delete-Button`} />
                  </Popconfirm>
                </Tooltip>
              ) : null
            } */}
            {
              formState === "edit" || formState === "add" ? (
                <Tooltip title="Delete" color={"white"}>
                  <Popconfirm
                    title={`Are you sure to delete the item ${record.item_code}?`}
                    onConfirm={() => handleDeleteOrder(record)}
                  >
                    <DeleteOutlined className={`delete-Button`} />
                  </Popconfirm>
                </Tooltip>
              ) : ""
            }

          </>
        );
      },
    },
  ];

  // ********** ROWS PT COLOUMN **********
  const PTcolumns = [
    {
      title: "Ticket ID",
      dataIndex: "tracking_id",
      width: 200
    },
    {
      title: "Tracking Status",
      dataIndex: "tracking_status",
      width: 250
    },
    {
      title: "Tracking Number",
      dataIndex: "tracking_no",
    },
    {
      title: "Status Date",
      dataIndex: "track_date",
      width: 150,
      render: (text, record) => (
        <>
          {moment(text).format("MM-DD-YYYY")}
        </>
      ),
    },
  ];

  // ********** ROWS GL IMPACT COLOUMN **********
  const glImpcatCols = [
    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text, record, index) => {
        return <>$ {numeral(record.debit).format("0,0.00")}</>;
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) => {
        return <>$ {numeral(record.credit).format("0,0.00")}</>;
      },
    },

    {
      title: "created_date",
      dataIndex: "created_date",
    },
  ];

  // ********** HANDLE TAB FUNCTION **********
  const handleTab = (key) => {
    if (key === "header") {
      dispatch(getSystemLog(token, { table_name: "scm_order_ship_header", record_id: saleOrderID }));
    }
    else if (key === "line") {
      dispatch(getSystemLog(token, { table_name: "scm_order_ship_lines", record_id: saleOrderID }));
    }
    else if (key === "2") {
      // dispatch(getAllCustomerPaymentHeaders(token, "2022-01-01"," 2022-01-31"));
    }
  };

  // ********** HANDLE SEND EMAIL FUNCTION **********
  function sendingEmail() {

    console.log({ formData: formData })
    const data = {
      customerEmail: formData.bill_to_email,
      spEmail: formData.salesperson_email,
      orderShipID: saleOrderID,
      shipHeaderNumber: formData.ship_header_no,
      shippingCost: formData.shipping_cost,
      totalAmount: summary().amount
    }

    dispatch(sendEmail(token, data)).then((res) => {
      if (res.type === "SEND_EMAIL_SUCCESS") {
        notification("success", res?.payload?.data);
      }
    });
  }

  // const onCustomerSelect = (e, valid) => {
  //     if (e) {
  //         dispatch(getBilling(token, e)).then((res) => {

  //             if (valid) {
  //                 if (res.type === "GET_BILLING_SUCCESS") {
  //                     res.payload?.data.filter((item) => {
  //                         if (item.default_bill_to) {
  //                             form.setFieldsValue({
  //                                 addressOneBilling: `${item?.address_1_bill_to ? item?.address_1_bill_to : ""
  //                                     } ${item?.address_2_bill_to ? item?.address_2_bill_to : ""} ${item?.address_3 ? item?.address_3 : ""
  //                                     } `,
  //                                 billingEmail: item.email_bill_to,
  //                                 zipBilling: item.zip_code_bill_to,
  //                                 stateBilling: item.bill_to_state_name,
  //                                 telBilling: item.phone_bill_to,
  //                                 cityBilling: item.bill_to_city_name,
  //                                 countryBilling: item.bill_to_country_name,
  //                                 billingCustomer: item.customer_bill_to_id,
  //                                 billing_firstname: item.first_name,
  //                                 billing_lastname: item.last_name,
  //                             });

  //                             if (formState === "add") {
  //                                 form.setFieldsValue({
  //                                     location: 1,
  //                                     orderStatus: 1,
  //                                 });
  //                             }
  //                         }

  //                         if (item.default_ship_to) {
  //                             form.setFieldsValue({
  //                                 addressOneShipping: `${item?.address_1_bill_to ? item?.address_1_bill_to : ""
  //                                     } ${item?.address_2_bill_to ? item?.address_2_bill_to : ""} ${item?.address_3 ? item?.address_3 : ""
  //                                     } `,
  //                                 shippingEmail: item.email_bill_to,
  //                                 zipShipping: item.zip_code_bill_to,
  //                                 stateShipping: item.bill_to_state_name,
  //                                 telShipping: item.phone_bill_to,
  //                                 cityShipping: item.bill_to_city_name,
  //                                 countryShipping: item.bill_to_country_name,
  //                                 shippingCustomer: item.customer_bill_to_id,
  //                                 shipping_firstname: item.first_name,
  //                                 shipping_firstname: item.last_name,
  //                             });

  //                             if (formState === "add") {
  //                                 form.setFieldsValue({
  //                                     location: 1,
  //                                     orderStatus: 1,
  //                                 });
  //                             }
  //                         }
  //                     });
  //                 }
  //             }
  //         });
  //         dispatch(getShipping(token, e)).then((res) => {
  //             if (res.type === "GET_SHIPPING_SUCCESS") {
  //                 res.payload?.data.find((item) => {
  //                     if (item.is_default) {
  //                         form.setFieldsValue({
  //                             addressOneShipping: `${item.address_1 ? item.address_1 + "," : ""
  //                                 }${item.address_2 ? item.address_2 : ""}, ${item.address_3 ? item.address_3 : ""
  //                                 } `,
  //                             billingEmail: item.email,
  //                             zipShipping: item.zip_code,
  //                             stateShipping: item.state_name,
  //                             telShipping: item.phone,
  //                             cityShipping: item.city_name,
  //                             countryShipping: item.country_name,
  //                             shippingCustomer: item.customer_ship_to_id,
  //                         });
  //                     }
  //                 });
  //             }
  //         });

  //         dispatch(getOneCustomer(token, e)).then((customer) => {
  //             if (customer.type === "GET_ONE_CUSTOMER_SUCCESS") {
  //                 if (valid) {
  //                     form.setFieldsValue({
  //                         shippingMethodId: customer.payload.shipping_method_id,
  //                         freightTermId: customer.payload.freight_term_id,
  //                         shippingAccountNo: customer.payload.shipping_account_no,
  //                         shipViaId: customer.payload.courier_service_id,
  //                         isShipComplete: customer.payload.is_ship_complete,
  //                         berganBillingId: customer.payload.bergan_billing_id,
  //                         berganPaymentTermId: customer.payload.bergan_payment_term_id,
  //                     });
  //                     setIsShipComplete(customer.payload.is_ship_complete);
  //                 }
  //             } else if (customer.type === "GET_ONE_CUSTOMER_ERROR") {
  //                 notification("error", "Customer", customer?.payload?.data);
  //             }
  //         });
  //     }
  // };

  // ********** CHECKFLAG USEEFFECT **********
  useEffect(() => {
    if (checkFlag === true) {
      form.setFieldsValue({
        addressOneBilling: null,
        berganBillingId: null,
        berganPaymentTermId: null,
        billingAddressID: null,
        billingCustomer: null,
        billingEmail: null,
        cancelDate: null,
        category: null,
        cityBilling: null,
        cityShipping: null,
        company: null,
        countryBilling: null,
        countryShipping: null,
        customer: null,
        dc: null,
        deptDescription: null,
        divisionCode: null,
        divisionDescription: null,
        // endDate: null,
        externalRefID: null,
        freightAmount: null,
        freightCharges: null,
        freightTermId: null,
        factor_decision: null,
        gender: null,
        isClosed: false,
        isSendToBergen: false,
        isShipComplete: false,
        location: null,
        memo: null,
        ptMemo: null,
        orderDate: null,
        orderStatus: null,
        paymentTermId: null,
        po: null,
        region: null,
        resaleCertificateNo: null,
        salesPerson: null,
        shipDate: null,
        shippingAccountNo: null,
        shippingAddressID: null,
        shippingCost: null,
        shippingCustomer: null,
        shippingEmail: null,
        shippingMethodId: null,
        shippingTaxCode: null,
        shippingTaxRate: null,
        shipViaId: null,
        sourceType: null,
        startDate: null,
        stateBilling: null,
        stateShipping: null,
        telShipping: null,
        telBilling: null,
        store: null,
        termForPrint: null,
        zipBilling: null,
        zipShipping: null,
        specialInstructions: null,
        billing_firstname: null,
        billing_lastname: null,
        shipping_firstname: null,
        shipping_lastname: null,
        cs_comment: null,
      });
      setVisibleItems([]);
      setFlag(false);
    }
  }, [checkFlag]);

  // ********** ON FINISH FAILED FUNCTION **********
  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({ icon: "error", title: "Oops...", text: errors[0]?.errors[0] });
  };

  // ********** SUMMARY FUNCTION **********
  const summary = () => {
    let orderSummary = visibleItems;
    let amount = 0;
    let quantity = 0;
    let discount = 0;
    let tax = 0;
    let shippingCost = shipingCost;

    if (orderSummary.length > 0) {
      orderSummary?.forEach(function (item) {
        if (item.hasOwnProperty("rate")) {
          amount += parseFloat(item.rate) * parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("quantity")) {
          quantity += parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("discount")) {
          discount += parseFloat(item.discount);
        }
        if (item.hasOwnProperty("tax_amount")) {
          tax += parseFloat(item.tax_amount);
          // tax = taxSum || 0;
        }
      });
    }

    return {
      quantity: quantity,
      amount: numeral(amount || 0).format("0,0.00"),
      discount: numeral(discount || 0).format("0,0.00"),
      tax: numeral(tax || 0).format("0,0.00"),
      shippingCost: numeral(shipingCost || 0).format("0,0.00"),
      net: numeral(
        numeral(amount).value() -
        numeral(discount).value() +
        numeral(tax).value() +
        numeral(shippingCost).value()
      ).format("0,0.00"),
    };
  };


  // ********** ON FINISH FUNCTION **********
  const onFinish = async (e) => {
    submit(false);
    setDisable(true);



    if (visibleItems.length <= 0) {
      Swal.fire({
        icon: "error",
        title: "Add Line Items",
        text: "The line item shouldn't be empty.",
      });
      setLoading(false);
      setDisable(false);
    } else {
      await form.validateFields().then((values) => {
        setLoading(true);
        setDisable(true);
        let check = false;
        visibleItems.forEach((val) => {
          if (val.quantity <= 0) {
            Swal.fire({
              icon: "error",
              title: "OOPS",
              text: `The line item Quantity shouldn't be 0.`,
            });
            check = true;
            setLoading(false);
            setDisable(false);
          }
        });
        if (!check) {
          if (formState === "add") {
            setLoading(true);
            setDisable(true);
            dispatch(addOrderShipmentHeader(token, values, formData)).then(
              (res) => {
                if (res.type === "ADD_ORDER_SHIPMENT_HEADER_SUCCESS") {
                  let shipHeaderHead = res?.payload;
                  form.setFieldsValue({
                    shipHeaderId: res?.payload?.ship_header_no,
                  });
                  let finalItems = visibleItems.map((v) => {
                    delete v.balance;
                    delete v.available_quantity;
                    delete v.amount;
                    delete v.order_quantity;
                    // delete v.style_name;
                    delete v.wash_name;
                    delete v.gender_category_name;
                    delete v.inseam;
                    delete v.size;
                    return {
                      ...v,
                      ship_header_id: res.payload.ship_header_id,
                      fulfill: v.fulfill,
                      gross_amount: parseFloat(v.rate * v.quantity - v.discount + v.tax_amount)
                      // net_amount: v.net_amount.toString().replace(/,/g, "")
                    };
                  });
                  dispatch(addOrderShipmentLine(token, finalItems)).then(
                    (res) => {
                      if (res.type === "ADD_ORDER_SHIPMENT_LINE_SUCCESS") {
                        setLoading(false);
                        setDisable(false);
                        notification(
                          "success",
                          "Order Shipment Created",
                          res?.payload?.data
                        );
                        history.push(
                          `/order-shipment?mode=edit&order_id=${shipHeaderHead.ship_header_id}&customer_id=${formData.customer_id}`
                        );
                      } else if (res.type === "ADD_ORDER_SHIPMENT_LINE_ERROR") {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: res?.payload?.data?.data,
                        });
                      }
                    }
                  );
                } else if (res.type === "ADD_ORDER_SHIPMENT_HEADER_ERROR") {
                  setLoading(false);
                  setDisable(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res?.payload?.data?.error.errors[0]?.message,
                  });
                }
              }
            );
          } else if (formState === "edit") {
            setLoading(true);
            setDisable(true);
            formData.sent_to_bergen = form.getFieldValue().isSendToBergen;
            dispatch(
              updateOrderShipmentHeader(token, values, formData, saleOrderID)
            ).then((res) => {
              if (res.type === "UPDATE_ORDER_SHIPMENT_HEADER_SUCCESS") {
                let finalItems = visibleItems.map((v) => {
                  return {
                    fulfill: v.fulfill,
                    ship_line_id: v.ship_line_id,
                    rate: numeral(v.rate).format("0.00"),
                    // gross_amount: v.gross_amount,
                    quantity: v.quantity,
                    discount_percent: numeral(v.discount_percent).format("0.00"),
                    discount: numeral(v.discount).format("0.00"),
                    tax_percent: numeral(v.tax_percent).format("0.00"),
                    tax_amount: numeral(v.tax_amount).format("0.00"),
                    gross_amount: parseFloat(v.rate * v.quantity - v.discount + v.tax_amount)
                    // net_amount: v.net_amount.toString().replace(/,/g, "")
                  };
                });
                if (deletedLineItems.length > 0) {
                  dispatch(
                    deleteOrderShipmentLine(
                      token,
                      deletedLineItems,
                      formData?.ship_header_id
                    )
                  );
                }
                dispatch(
                  updateOrderShipmentLine(token, saleOrderID, finalItems)
                ).then((res) => {
                  if (res.type === "UPDATE_ORDER_SHIPMENT_LINE_SUCCESS") {
                    notification(
                      "success",
                      "Order Shipment Updated",
                      res?.payload?.data
                    );
                    changeState("formView");
                    setLoading(false);
                    setDisable(false);
                    formData.pt_status_id = form.getFieldValue().ptStatusId;
                    formData.status_id = form.getFieldValue().status;
                    console.log(
                      formData.pt_status_id,
                      formData.status_id,
                      formData.sent_to_bergen,
                      "sent to bergen done-"
                    );
                    if (
                      formData.pt_status_id === 1 &&
                      formData.status_id === 2 &&
                      (!formData.sent_to_bergen ||
                        formData.sent_to_bergen === false)
                    ) {
                      form.setFieldsValue({
                        isSendToBergen: true,
                      });
                      sendToBergenMethod();
                    } else {
                      dispatch(
                        getOneOrderShipmentHeaderDetail(token, saleOrderID)
                      );
                    }
                  } else if (res.type === "UPDATE_ORDER_SHIPMENT_LINE_ERROR") {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: res?.payload?.data?.data,
                    });
                    setLoading(false);
                    setDisable(false);
                  }
                });
              } else if (res.type === "UPDATE_ORDER_SHIPMENT_HEADER_ERROR") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.payload?.data?.data,
                });
                setLoading(false);
                setDisable(false);
              }
            });
          }
        }
      });
    }
  };

  // ********** SEND TO BERGEN METHOD FUNCTION **********
  const sendToBergenMethod = () => {
    setLoading(true);
    dispatch(sendToBergen(token, saleOrderID)).then((res) => {
      if (res.type === "SEND_TO_BERGEN_SUCCESS") {
        dispatch(getOneOrderShipmentHeaderDetail(token, saleOrderID));
        setLoading(false);
        if (!res?.payload?.data?.error) {
          notification("success", "Send To Bergen");
        } else {
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: res?.payload?.data?.error,
          });
        }
      }
    });
  };

  // ********** SEND TO EMAIL TO CUSTOMER WHEN RELEASE TO BERGEN FUNCTION **********
  const sendBergenEmailMethod = () => {
    setLoading(true);
    dispatch(sendBergenEmail(token, saleOrderID)).then((res) => {
      setLoading(false);
      notification("success", "Email Sent Successfully!");
      console.log(res, 'res');
      // if (res.type === "SEND_BERGEN_EMAIL_SUCCESS") {
      //   dispatch(getOneOrderShipmentHeaderDetail(token, saleOrderID));
      //   setLoading(false);
      //   if (!res?.payload?.data?.error) {
      //     notification("success", "Send To Bergen");
      //   } else {
      //     Swal.fire({
      //       icon: "error",
      //       title: "OOPS",
      //       text: res?.payload?.data?.error,
      //     });
      //   }
      // }
    });
  };


  let sortedOrderItems = visibleItems.length > 0 && visibleItems.sort(function (a, b) {
    return a.item_code.localeCompare(b.item_code, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });


  // const sendToBergen = () => {
  // }

  return (
    <>
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        name="basic"
      >
        {orderShipmentFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-15">
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="shipHeaderId"
                        label="Pick Ticket #"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <Input
                          size="small"
                          tabIndex={10}
                          bordered={false}
                          readOnly
                          className={`customInputs without_bg fw-400 `}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Pick Ticket Date"
                        name="shipDate"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <DatePicker
                          disabled
                          // disabled={formState === "formView"}
                          size="small"
                          format="MM-DD-YYYY"
                          allowClear
                          className="dateInput customInputs"
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="po"
                        label="P.O #"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <Input
                          size="small"
                          tabIndex={1}
                          bordered={false}
                          readOnly
                          className={`customInputs without_bg fw-400 `}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="ptStatusId"
                        label="Pt Status"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: true,
                            message: "Please select Pt status",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          allowClear
                          disabled={formState === "formView"}
                        >
                          {orderShipmentFormData?.payload?.ptStatus.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.pt_status_id}
                                  disabled={!item.is_active}
                                  value={item.pt_status_id}
                                >
                                  {item.pt_status_name}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xl={8} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="customer"
                        label="Customer"
                        labelAlign="left"
                        labelCol={{
                          xs: { span: 52 },
                          lg: { span: 52 },
                          xxl: { span: 4 },
                        }}
                      >
                        <Input
                          size="small"
                          tabIndex={1}
                          disabled={formState === "formView"}
                          readOnly
                          className={`customInputs without_bg fw-400 `}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="orderNumber"
                        label="Sale Order #"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        {/* Order Num <br /> */}
                        <a target="_blank" href={`/sale-order?mode=edit&order_id=${formData?.order_header_id}&customer_id=${formData?.customer_id}`}>
                          {formData?.order_header_no}
                        </a>
                        {/* <Input
                          size="small"
                          bordered={false}
                          readOnly
                          className={`customInputs without_bg fw-400 `}
                        /> */}
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={8} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="SO Memo"
                        labelAlign="left"
                        labelCol={{
                          lg: { span: 52 },
                          xxl: { span: 10 },
                        }}
                      >

                        <Input
                          size="small"
                          disabled={formState === "formView" || formState === "edit"}
                          readOnly
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="status"
                        label="Status"

                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          { required: true, message: "Please select status" },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={16}
                          autoComplete={"nope"}
                          allowClear
                          disabled
                        // disabled={formState === "formView"}
                        >
                          {orderShipmentFormData?.payload?.shipStatus?.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.ship_status_id}
                                  disabled={!item.is_active}
                                  value={item.ship_status_id}
                                >
                                  {item.ship_status_name}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={8} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="ptMemo"
                        label="PT Memo"
                        labelAlign="left"
                        labelCol={{
                          lg: { span: 52 },
                          xxl: { span: 10 },
                        }}
                      >
                        <Input
                          size="small"
                          disabled={formState === "formView"}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={8} xxl={3}>
                    <div className="firstSectionRo">
                      <Button
                        disabled={((formState === "add" || formState === "view")) || userRights.can_delete === true ? false : true}
                        // disabled={formState === "add" || formState === "edit" }
                        onClick={() => {
                          sendBergenEmailMethod()
                        }

                        }>Send Email</Button>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <div className="firstSectionRo">
                  <div className="order-summary">
                    <div className="summary-title">Pick Ticket Summary</div>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Quantity:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {summary().quantity}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Sub Total:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${summary().amount}
                        </p>
                      </Bcol>
                    </Brow>

                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Less Discount:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${summary().discount || 0}
                        </p>
                      </Bcol>
                    </Brow>

                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Tax:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${summary().tax || 0}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Shipping Cost:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${numeral(shipingCost).format("00.00")}
                        </p>
                      </Bcol>
                    </Brow>

                    <Divider className="my-1" />
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">${summary().net}</p>
                      </Bcol>
                    </Brow>
                  </div>
                </div>
              </Bcol>
            </Brow>
            <Brow className="secondSectio">
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Billing Information"
                >
                  <div className="secondSectionRow">
                    {customerAddress?.loading ? (
                      <Skeleton paragraph={{ rows: 6 }} />
                    ) : (
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="billingCustomer"
                            label="Name"
                            className="name-field-form selectNamee"
                            labelAlign="left"
                            labelCol={{
                              xs: { span: 24 },
                              sm: { span: 4 },
                              md: { span: 4 },
                              g: { span: 2 },
                            }}
                          >
                            <Select
                              size="small"
                              tabIndex={4}
                              autoComplete={"nope"}
                              showSearch={true}
                              className="customInputs fw-400"
                              loading={customerAddress?.loading}
                              disabled={formState === "formView"}
                            >
                              {customerAddress.payload?.data?.map(
                                (item, index) => {
                                  return (
                                    <Option
                                      key={index}
                                      value={item.customer_address_id}
                                    >
                                      {" "}
                                      {`${item.address_addressee}(${item.address_zip_code})`}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_firstname"
                            label={`First Name`}
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneBilling"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoBilling"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityBilling"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateBilling"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipBilling"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryBilling"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telBilling"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Card>
              </Bcol>
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Shipping Information"
                >
                  {customerAddress?.loading ? (
                    <Skeleton paragraph={{ rows: 6 }} />
                  ) : (
                    <div className="secondSectionRow">
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="shippingCustomer"
                            label="Name"
                            labelAlign="left"
                            className="name-field-form"
                            labelCol={{
                              xs: { span: 24 },
                              sm: { span: 4 },
                              md: { span: 4 },
                              lg: { span: 2 },
                            }}
                          >
                            <Select
                              size="small"
                              tabIndex={5}
                              autoComplete={"nope"}
                              showSearch={true}
                              className="customInputs fw-400"
                              loading={customerAddress?.loading}
                              disabled={formState === "formView"}
                            >
                              {customerAddress.payload?.data?.map(
                                (item, index) => {
                                  return (
                                    <Option
                                      key={index}
                                      value={item.customer_address_id}
                                    >
                                      {" "}
                                      {`${item.address_addressee}(${item.address_zip_code})`}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_firstname"
                            label="First Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneShipping"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoShipping"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityShipping"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateShipping"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipShipping"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryShipping"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telShipping"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shippingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Card>
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>

          <SubTitles name={"Date"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Start Date is required" },
                    ]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={6}
                      format="MM-DD-YYYY"
                      disabled={true}
                      allowClear
                      className="dateInput customInputs without_border"
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="cancelDate"
                    label="Cancel Date"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Cancel Date is required" },
                    ]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={8}
                      format="MM-DD-YYYY"
                      allowClear
                      className="dateInput customInputs without_border"
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              {/* {formState === "formView" || formState === "edit" ? (
                <Bcol lg={6} xxl={3}>
                  <div className="firstSectionRo">
                    Send Email
                    <Button
                      onClick={(e) => sendingEmail()}
                      className="iconBtn crudBTN"
                      shape="circle"
                      icon={<MailOutlined />}
                    />
                  </div>
                </Bcol>
              ) : null} */}
            </Brow>
          </div>
          <SubTitles name={"Sales Information"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Gender is required" }]}
                  >
                    <Input
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="region"
                    label="Region"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Region is required" }]}
                  >
                    <Input
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="category"
                    label="Category"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <Input
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="location"
                    label="Location"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Location is required" },
                    ]}
                  >
                    <Input
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="salesPerson"
                    label="Sales Person"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Sales Person is required" },
                    ]}
                  >
                    <Input
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>

          <Collapse
            bordered={false}
            defaultActiveKey={1}
            className="pbpx-10 bg-white"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header="Classification" key="1">
              <Tabs
                type="card"
                size="small"
                defaultActiveKey="shipping"
                centered
                onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Shipping" key="shipping">
                  <div className="container-fluid pbpx-0 ptpx-0">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingAccountNo"
                            label="Shipping Account No"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              tabIndex={14}
                              className="customInputs"
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shipViaId"
                            label="Ship Via"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Ship Via is required",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              tabIndex={15}
                              autoComplete={"nope"}
                              allowClear
                              disabled={formState === "formView"}
                            >
                              {orderShipmentFormData?.payload?.shipVia.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.courier_service_id}
                                      disabled={!item.is_active}
                                      value={item.courier_service_id}
                                    >
                                      {item.service_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingMethodId"
                            label="Shipping Method"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please select Shipping Method",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              tabIndex={16}
                              autoComplete={"nope"}
                              allowClear
                              disabled={formState === "formView"}
                            >
                              {orderShipmentFormData?.payload?.adminShipMethod.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.shipping_method_id}
                                      disabled={!item.is_active}
                                      value={item.shipping_method_id}
                                    >
                                      {item.shipping_method_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingCost"
                            label="Shipping Cost"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          // rules={[{ required: true, message: "Shipping Cost is required" }]}
                          >
                            <InputNumber
                              size="small"
                              onInput={(val) => {
                                setShippingCost(val);
                                // form.setFieldsValue({ shippingCost: val });
                              }}
                              onChange={(val) => {
                                setShippingCost(val);
                                // form.setFieldsValue({ shippingCost: val });
                              }}
                              tabIndex={17}
                              className="customInputs w-100"
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxCode"
                            label="Shipping Tax Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Select
                              size="small"
                              tabIndex={18}
                              autoComplete={"nope"}
                              allowClear
                              disabled={formState === "formView"}
                            >
                              {orderShipmentFormData?.payload?.adminShipTaxCode.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.ship_tax_id}
                                      disabled={!item.is_active}
                                      value={item.ship_tax_id}
                                    >
                                      {item.ship_tax_description}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxRate"
                            label="Shipping Tax Rate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <InputNumber
                              size="small"
                              tabIndex={19}
                              className="customInputs w-100"
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="freightTermId"
                            label="Freight Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Freight Term",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              tabIndex={20}
                              autoComplete={"nope"}
                              allowClear
                              disabled={formState === "formView"}
                            >
                              {orderShipmentFormData?.payload?.freightTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.freight_term_id}
                                      disabled={!item.is_active}
                                      value={item.freight_term_id}
                                    >
                                      {item.freight_term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="berganBillingId"
                            label="Bergen Billing Option"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Bergen Billing is required",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              tabIndex={24}
                              autoComplete={"nope"}
                              allowClear
                              disabled={formState === "formView"}
                            >
                              {orderShipmentFormData?.payload?.berganBilling.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.bergan_billing_id}
                                      disabled={!item.is_active}
                                      value={item.bergan_billing_id}
                                    >
                                      {item.bergan_billing_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="berganPaymentTermId"
                            label="Bergen Payment Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Bergen Payment Term is required",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              tabIndex={24}
                              autoComplete={"nope"}
                              allowClear
                              disabled={formState === "formView"}
                            >
                              {orderShipmentFormData?.payload?.berganPaymentTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.bergen_payment_term_id}
                                      disabled={!item.is_active}
                                      value={item.bergen_payment_term_id}
                                    >
                                      {item.term_description}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="paymentTermId"
                            label="Payment Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Payment Term",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              name="paymentTermId"
                              autoComplete={"nope"}
                              allowClear
                              filterOption={(input, option) =>
                                option?.children
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) >= 0
                              }
                              showSearch
                              loading={orderShipmentFormData.loading}
                              defaultValue={form.getFieldValue().paymentTermId}
                              onChange={(value) =>
                                form.setFieldsValue({ paymentTermId: value })
                              }
                              disabled={formState === "formView"}
                            >
                              {orderShipmentFormData?.payload?.paymentTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.term_id}
                                      disabled={!item.is_active}
                                      value={item.term_id}
                                    >
                                      {item.term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="factor_decision"
                            label="Factor Decision"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              readOnly
                              size="small"
                              className={`customInputs fw-400 `}
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="specialInstructions"
                            label="Special Instructions"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={formState === "formView"}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="bill_to_non_us"
                            label="Bill To Non-US"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              className={`customInputs`}
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="ship_to_non_us"
                            label="Ship To Non-US"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              className={`customInputs`}
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="order_type"
                            label="Order Type"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          // rules={[{ required: true, message: "Please Select Order Type" }]}
                          >
                            <Select
                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              loading={orderShipmentFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().order_type}
                            // onMouseDownCapture={onMouseDownSelectableHack}
                            >

                              {orderShipmentFormData?.payload?.scmOrderType?.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.order_type_id}
                                      value={item.order_type_id}
                                    >
                                      {item.order_type_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="tax_duties"
                            label="Tax & Duties"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              className={`customInputs`}
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="cs_comment"
                            label="Cs comment"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              style={{ width: 600 }}
                              // className={`customInputs`}
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                    </Brow>
                  </div>
                </TabPane>

                <TabPane tab="PT Status" key="ptStatus">
                  <div className="container-fluid pbpx-0 ptpx-0">
                    <Brow>
                      <Bcol lg={6} xl={12} xxl={12}>
                        <div
                          className="firstSectionRo"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Form.Item
                            name="isSendToBergen"
                            valuePropName="checked"
                            label="Is Pick Ticket Added"
                            labelAlign="left"
                            labelCol={{ span: 22 }}
                          >
                            <Checkbox
                              disabled={formState === "formView"}
                              name="isSendToBergen"
                            ></Checkbox>
                          </Form.Item>
                          {/* <Button type="submit" className="btn btn-success" style={{ display: "flex", justifyContent: "end" }} onClick={sendToBergenMethod}>Send to Bergen</Button> */}
                        </div>
                      </Bcol>
                    </Brow>
                  </div>
                  <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    source={tracking?.payload?.data}
                    col={PTcolumns}
                    load={tracking.loading}
                  />
                </TabPane>
              </Tabs>
            </Panel>
          </Collapse>

          <Tabs
            type="card"
            size="small"
            defaultActiveKey="1"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Product Information" key="1">
              {sortedOrderItems.length > 0 ? (
                <Table
                  disabled={!(formState === "edit" || formState === "add")}
                  type={"company"}
                  scroll={{ x: 1680, y: 400 }}
                  size="small"
                  bordered
                  dataSource={sortedOrderItems.map((it) => ({
                    ...it,
                    key: it.item_code,
                  }))}
                  columns={columns}
                  pagination={false}
                  rowClassName="editable-row"
                />
              ) : (
                <Spin />
              )}
            </TabPane>

            {(formState === "edit" || formState === "formView") &&
              <TabPane tab="System Information" key="logs" >
                <Tabs
                  type="card"
                  size="small"
                  defaultActiveKey="1"
                  centered
                  onChange={(key) => handleTab(key)}
                >
                  <TabPane tab="Header" key="header">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                  <TabPane tab="Line" key="line">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                </Tabs>

              </TabPane>}
            <TabPane tab="Gl Impact" key="gl">
              <div className="tabs">
                <Tables
                  type="company"
                  scrollX={"50rem"}
                  scroll={360}
                  perPage={15}
                  source={glImpact?.payload?.data}
                  col={glImpcatCols}
                  load={glImpact.loading}
                  paginate
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

export default OrderShipmentForm;
