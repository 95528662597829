import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getAllCompany } from "../../redux/actions/companyAccess"
import { addItem, updateItem } from '../../redux/actions/item.action';
import { getOneStyleCombination } from '../../redux/actions/styleCombination.action';
import { getOneItem } from '../../redux/actions/item.action';
import notification from '../../utils/notification';
import { UndoOutlined } from "@ant-design/icons";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Select, Input, Tooltip, message } from 'antd';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const { Option } = Select;

export default function ProductPrimaryForm({ genderCategory, setGenderCategory, setSize, submit, formState, companyId, val, token, formData, data, sku, changeState, randomData, dCompany, flagSet, itemId, valSKU, combinationData, setNewID, newID, newSize, newInseam, inseams, productID, setArrSizes, setArr,
    setInseams, sizes, setSizes, seasonYear, itemType }) {
    const [form] = Form.useForm();
    const history = useHistory();
    const submitButton = useRef(null);
    const focusInput = useRef(null);
    const [companyChange, setCompanyChange] = useState(false);
    const [gender, setGender] = useState(null);
    const [reset, setReset] = useState(false);
    const [productImage, setProductImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);

    const dispatch = useDispatch();
    const { companyAll, productType, fabricClass } = useSelector(state => state)

    let getgender = data?.payload?.genderCategoryDetail?.filter((item) => {
        return item.id === gender
    })

    let getGenderCategory = getgender && getgender[0]?.data?.filter((item) => {
        return item.gender_category_id === genderCategory
    })

    let getGenderResult = getGenderCategory && getGenderCategory[0]?.size_chart;


    const onFinish = async (e) => {
        submit(false)
        form.setFieldsValue({ [e.target.name]: e.target.value });

        await form.validateFields().then((values) => {

            if (formState === "add") {

                if (productImage) {
                    const formData = new FormData();
                    formData.append('image', productImage);
                    fetch('http://localhost:8000/v1/create/ItemImage',
                        {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            body: formData
                        })
                        .then(function (res) { return res.json(); })
                        .then(function (data) {
                            setImageURL(data?.imageUrl)

                        })
                }

                dispatch(addItem(token, values, randomData, inseams, sizes, imageURL)).then((res) => {
                    if (res.type === "ADD_ITEM_SUCCESS") {
                        // setAddSuccess(true)
                        // setItemSuccess(res?.payload)
                        history.push(`/product-info?mode=edit&product_id=${res?.payload}`);
                        setNewID(res?.payload)
                        notification('success', 'Item', 'Item added Successfully!')
                        changeState("formView")
                    }
                    if (res.type === "ADD_ITEM_ERROR") {
                        notification('error', res?.payload?.request?.statusText, res?.payload?.data?.data)
                    }
                })

            }
            if (formState === "edit") {
                dispatch(updateItem(token, values, productID, "", randomData, inseams, sizes)).then((res) => {
                    if (res.type === "UPDATE_ITEM_SUCCESS") {
                        changeState("formView")
                        dispatch(getOneItem(token, productID))
                        notification('success', 'Item', 'Item updated Successfully!')
                        flagSet(false)
                        window.location.reload();
                    }
                    if (res.type === "UPDATE_ITEM_ERROR") {
                        notification('error', res?.payload?.request?.statusText, res?.payload?.data?.data)
                        flagSet(false)
                    }
                    dispatch(getOneItem(token, productID)).then((res) => {
                        if (res.type === "GET_ONE_ITEM_SUCCESS") {
                            sku(res?.payload.item_code)

                        }
                    })
                })
            }
        });
    };

    function search(value) {
        const newValue = value;
        if (/^[0-9\._]+$/.test(newValue)) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "can not type integer value"
            });
        }
    }


    function handleChange(value, type) {
        if (type === "style") {
            dispatch(getOneStyleCombination(token, value)).then((res) => {
                if (res.type === "GET_ONE_STYLE_COMBINATION_SUCCESS") {
                    res?.payload?.data.find(f => {
                        setGender(f.gender_id)
                        if (f.is_default) {
                            form.setFieldsValue({
                                gender: f.gender_id,
                                genderCategory: f.gender_category_id,
                                cut: f.cut_id,
                                rise: f.rise_id,
                                riseLabel: f.rise_label_id,
                                fitCategory: f.fit_category_id,
                            })
                        }
                    })
                }
                if (res.type === "GET_ONE_STYLE_COMBINATION_ERROR") {
                    // notification('warning', "Style Combination", "Please create combination for this style")
                    message.warning('Please create combination for this style');
                    form.setFieldsValue({
                        rise: null,
                        riseLabel: null,
                        gender: null,
                        genderCategory: null,
                        cut: null,
                        fitCategory: null
                    })
                }
            })
        }

        if (type === "company") {
            setCompanyChange(true);
            companyId(value)
            form.setFieldsValue({
                company: value
            })
        }
        if (type === "gender") {
            form.setFieldsValue({
                genderCategory: null
            })
            if (value) {
                setGender(value)
            } else {
                setGender(null)
            }
        }
        if (type === "genderCategory") {
            if (value) {
                setGenderCategory(value)
            } else {
                setGenderCategory(null)
            }
        }
    }

    useEffect(() => {
        if (val) {
            submitButton.current.click()
        }
    }, [val])

    useEffect(() => {
        if (formState === "add") {
            focusInput.current.focus();
        }

        if (formState === "view") {
            dispatch(getAllCompany(token)).then(() => {
                form.setFieldsValue({
                    company: parseInt(dCompany)
                })
            });
        }
        if (formState === "add") {
            setGender(null)
            form.setFieldsValue({
                sku: null,
                productName: null,
                seasonYear: null,
                cost: `0.00`,
                newSeasonYear: null,
                ldpRate: `0.00`,
                fabricComposition: null,
                productType: null,
                inseamLabel: null,
                season: null,
                style: null,
                wash: null,
                washType: null,
                cut: null,
                fabric: null,
                fitCategory: null,
                gender: null,
                genderCategory: null,
                rise: null,
                riseLabel: null,
                status: null,
                productClass: null,
                newSeason: null,
                retailPrice: `0.00`,
                active: null,
                description: null,
                itemType: 5
            })
        }
    }, [formState])

    useEffect(() => {
        form.setFieldsValue({
            productType: null,
            style: null,
            wash: null,
            washType: null,
            cut: null,
            fitCategory: null,
            genderCategory: null,
            rise: null,
            riseLabel: null,
            itemType: 5,
            productClass: null,
        })
        setCompanyChange(false);
    }, [companyChange])


    useEffect(() => {
        setSize(getGenderResult)
    }, [gender, genderCategory])

    useEffect(() => {
        if (formData) {
            setGender(formData.gender_id)
            companyId(formData.company_id)
            sku(formData.item_code)
            form.setFieldsValue({
                company: formData.company_id,
                sku: formData.item_code,
                productName: formData.item_name,
                productType: formData.product_type_id,
                inseamLabel: formData.inseam_label_id,
                season: formData.season_id,
                seasonYear: parseInt(formData.season_year),
                style: formData.style_id,
                wash: formData.wash_id,
                washType: formData.wash_type_id,
                cut: formData.cut_id,
                fabric: formData.fabric_id,
                fitCategory: formData.fit_category_id,
                gender: formData.gender_id,
                genderCategory: formData.gender_category_id,
                rise: formData.rise_id,
                riseLabel: formData.rise_label_id,
                status: formData.product_status_id,
                productClass: formData.product_class_id,
                newSeason: formData.new_season_id,
                newSeasonYear: parseInt(formData.new_season_year),
                cost: formData.cost_price,
                ldpRate: formData.ldp_rate,
                fabricComposition: formData.fabric_composition,
                retailPrice: formData.rate,
                active: formData.is_active,
                description: formData.description,
                itemType: formData.item_type_id
            })
            setReset(false);
        }
    }, [formData, reset])


    const handleSKU = (val) => {
        if (valSKU !== val) {
            combinationData([])
        }
        sku(val)
    }

    const handleReset = () => {
        setReset(true)
    }

    console.log({ itemType })

    return (
        <div className='formProduct p-0' >
            {formState === "edit" &&
                <div className='undoDiv'  >
                    <Tooltip placement="left" title="Form Reset" color="#fbfbfb"  >
                        <Button className="iconBtn undoBtn " icon={<UndoOutlined />} shape='circle' onClick={() => handleReset()} />
                    </Tooltip>
                </div>
            }

            <Form onFinish={onFinish} form={form} name="basic">
                <div className="container-fluid pbpx-5 ptpx-5">
                    <Brow>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <Form.Item name="company"
                                    label="Company"
                                    labelAlign='left'
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        size="small"
                                        loading={companyAll.loading}
                                        disabled={formState === "edit" ? true : formState !== "add"}
                                        defaultValue={form.getFieldValue().company}
                                        className="w-100"
                                        onChange={(value) => handleChange(value, 'company')}
                                    >
                                        {
                                            companyAll?.payload?.data?.map((item) => {
                                                return <Option key={item.company_id} value={item.company_id}>{item.company_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol'>
                                <Form.Item name="sku"
                                    label="SKU"
                                    labelAlign='left'
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    initialValue={formState === "add" ? null : form.getFieldValue().sku}
                                    rules={[
                                        {
                                            required: true,
                                            message: "SKU is required"
                                        },
                                        {
                                            pattern: new RegExp(/[0-9]/),
                                            message: "Characters not allowed"
                                        }
                                    ]}
                                >
                                    <Input
                                        size="small"
                                        className='styleInput'
                                        // placeholder={"SKU"}
                                        ref={focusInput}
                                        // readOnly={formState === "edit"} disabled={!(formState === "add")}
                                        disabled={!(formState === "add" || formState === "edit")}
                                        onBlur={(e) => {
                                            if (formState === "edit" || formState == "add") {
                                                handleSKU(e.target.value)
                                            }
                                        }}
                                        onPressEnter={(e) => {
                                            if (formState === "edit" || formState == "add") {
                                                handleSKU(e.target.value)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </Bcol>

                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Style is required" }]} name="style"
                                        label="Style"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch

                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().style : null}
                                            className="w-100"
                                            // placeholder={"Style"}
                                            onSearch={(value) => search(value)}
                                        >
                                            {
                                                data?.payload?.styleDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.style_id} value={item.style_id}>{item.style_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Gender is required" }]} name="gender"
                                        label="Gender"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().gender : null}
                                            className="w-100"
                                            // placeholder={"Gender"}
                                            onChange={(value) => handleChange(value, 'gender')}

                                        >
                                            {
                                                data?.payload?.genderDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.gender_id} value={item.gender_id}>{item.gender_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc' style={{ height: "36px" }}>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Gender Category is required" }]} name="genderCategory"
                                        label="Gender Category"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            disabled={gender === null || !(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().genderCategory : null}
                                            className="w-100"
                                            // placeholder={"Gender Category"}
                                            onChange={(value) => handleChange(value, 'genderCategory')}
                                        >



                                            {
                                                data?.payload?.genderCategoryDetail?.map((item) => {
                                                    if (gender === item.id) {
                                                        return (
                                                            item?.data?.map(item => {
                                                                return (
                                                                    <Option disabled={!item.is_active} key={item.gender_category_id} value={item.gender_category_id}>
                                                                        {item.gender_category_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        )
                                                    }
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                    <p>
                                    </p>
                                </div>
                            </div>
                        </Bcol>

                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Cut is required" }]} name="cut"
                                        label="Fit/Cut"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }} >

                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().cut : null}
                                            className="w-100"
                                        // placeholder={"Cut"}
                                        >
                                            {
                                                data?.payload?.cutsDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.cut_id} value={item.cut_id}>{item.cut_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Rise is required" }]} name="rise"
                                        label="Rise"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().rise : null}
                                            className="w-100"
                                        // placeholder={"Rise"}
                                        >
                                            {
                                                data?.payload?.riseDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.rise_id} value={item.rise_id}>{item.rise_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Rise Label is required" }]} name="riseLabel"
                                        label="Rise Label"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().riseLabel : null}
                                            className="w-100"
                                        // placeholder={"Rise Label"}
                                        >
                                            {
                                                data?.payload?.riseLabelDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.rise_label_id} value={item.rise_label_id}>{item.rise_label_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>

                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Fit Category is required" }]} name="fitCategory"
                                        label="Fit Category"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().fitCategory : null}
                                            className="w-100"
                                        // placeholder={"Fit Category"}
                                        >
                                            {
                                                data?.payload?.fitCategoryDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.fit_category_id} value={item.fit_category_id}>{item.fit_category_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Wash is required" }]} name="wash"
                                        label="Wash"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}

                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().wash : null}
                                            className="w-100"
                                        // placeholder={"Wash"}
                                        >
                                            {
                                                data?.payload?.washNameDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.wash_id} value={item.wash_id}>{item.wash_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Wash Type is required" }]} name="washType"
                                        label="Wash Type"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }} >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().washType : null}
                                            className="w-100"
                                        // placeholder={"Wash Type"}
                                        >
                                            {
                                                data?.payload?.washTypeDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.wash_type_id} value={item.wash_type_id}>{item.wash_type_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Fabric is required" }]} name="fabric"
                                        label="Fabric"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().fabric : null}
                                            className="w-100"
                                        // placeholder={"Fabric"}
                                        >
                                            {
                                                data?.payload?.fabricDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.fabric_id} value={item.fabric_id}>{item.fabric_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol'>
                                <div className="relative" >
                                    <Form.Item name="fabricComposition"
                                        label="Fabric Composition"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        initialValue={formState === "add" ? null : form.getFieldValue().fabricComposition}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Fabric Composition is required"
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            className='styleInput'
                                        // placeholder={"Fabric Composition"}
                                        // disabled={!(formState === "add" || formState === "edit")}
                                        />

                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Season is required" }]} name="season"
                                        label="Season"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().season : null}
                                            className="w-100"
                                        // placeholder={"Season"}
                                        >
                                            {
                                                data?.payload?.seasonDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.season_id} value={item.season_id}>{item.season_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Season Year is required" }]} name="seasonYear"
                                        label="Season Year"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().seasonYear : null}
                                            className="w-100"
                                        // placeholder={"Season Year"}
                                        > {seasonYear?.payload?.data?.map((res) => <Option key={res.season_year_id} value={res.season_year_id}>{res.season_year}</Option>
                                        )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "New Season is required" }]} name="newSeason"
                                        label="New Season"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().newSeason : null}
                                            className="w-100"
                                        // placeholder={"New Season"}
                                        >
                                            {
                                                data?.payload?.seasonDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.season_id} value={item.season_id}>{item.season_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "New Season Year is required" }]} name="newSeasonYear"
                                        label="New Season Year"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}

                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().newSeasonYear : null}
                                            className="w-100"
                                        // placeholder={"New Season Year"}
                                        >
                                            {seasonYear?.payload?.data?.map((res) => <Option key={res.season_year_id} value={res.season_year_id}>{res.season_year}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Inseam Label is required" }]} name="inseamLabel"
                                        label="Inseam Label"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().inseamLabel : null}
                                            className="w-100"
                                        // placeholder={"Inseam Label"}
                                        >
                                            {
                                                data?.payload?.inseamLabelDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.inseam_label_id} value={item.inseam_label_id}>{item.inseam_label_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Product Class is required" }]} name="productClass"
                                        label="Product Class"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().productClass : null}
                                            className="w-100"
                                        // placeholder={"Product Class"}
                                        >
                                            {
                                                data?.payload?.productClassDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.product_class_id} value={item.product_class_id}>{item.product_class_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item name="productType" rules={[{ required: true, message: "Product Type is required" }]}

                                        label="Product Type"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().productType : null}
                                            className="w-100"
                                        // placeholder={"Product Type"}
                                        >
                                            {
                                                productType?.payload?.data?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.product_type_id} value={item.product_type_id}>{item.product_type_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>

                            <div className='customCol'>
                                <div className="relative" >
                                    <Form.Item name="cost"
                                        label="Cost"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        initialValue={formState === "add" ? null : form.getFieldValue().cost}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Cost is required"
                                            },
                                            {
                                                pattern: new RegExp(/^(?![.]$|[.]$|$)\d+[.]?\d{0,6}$/),
                                                message: "Please enter correct amount.",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            className='styleInput'
                                            defaultValue={`0.00`}
                                        />

                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol'>
                                <div className="relative" >
                                    <Form.Item name="retailPrice"
                                        label="Retail Price"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        initialValue={formState === "add" ? null : form.getFieldValue().retailPrice}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Retail Price is required"
                                            },
                                            {
                                                pattern: new RegExp(/^(?![.,]$|[.,]$|$)\d+[,.]?\d{0,6}$/),
                                                message: "Please enter correct amount.",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            className='styleInput'
                                            defaultValue={`0.00`}
                                        // placeholder={"Retail Price"}
                                        // disabled={!(formState === "add" || formState === "edit")}
                                        />

                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol'>
                                <div className="relative" >
                                    <Form.Item name="ldpRate"
                                        label="LDP Rate"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        initialValue={formState === "add" ? null : form.getFieldValue().ldpRate}
                                        rules={[
                                            {
                                                required: true,
                                                message: "LDP Rate is required"
                                            },
                                            {
                                                pattern: new RegExp(/^(?![.,]$|[.,]$|$)\d+[,.]?\d{0,6}$/),
                                                message: "Please enter correct amount.",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            className='styleInput'
                                            defaultValue={`0.00`}
                                        // placeholder={"LDP Rate"}
                                        // disabled={!(formState === "add" || formState === "edit")}
                                        />

                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>

                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Status is required" }]} name="status"
                                        label="Status"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            loading={data.loading}
                                            // disabled={!(formState === "add" || formState === "edit")}
                                            defaultValue={formState === "edit" ? form.getFieldValue().status : null}
                                            className="w-100"
                                        // placeholder={"Status"}
                                        >
                                            {
                                                data?.payload?.productStatusDetail?.map((item) => {
                                                    return <Option disabled={!item.is_active} key={item.product_status_id} value={item.product_status_id}>{item.product_status_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        {(formState === "edit" || formState === "formView") &&
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className='customCol slc'>
                                    <div className="relative" >
                                        <Form.Item name="active"
                                            label="Active"
                                            labelAlign='left'
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                        >
                                            <Select
                                                size="small"

                                                loading={data.loading}
                                                defaultValue={formState === "edit" ? form.getFieldValue().active : null}
                                                disabled={formState !== "edit"}
                                                className="w-100"
                                            // placeholder="Active"
                                            >
                                                <Option key={1} value={true}>True</Option>
                                                <Option key={2} value={false}>False</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Bcol>
                        }
                        <Bcol lg={6} xl={8} xxl={6}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item
                                        name="description"
                                        label="Description"
                                        labelAlign='left'
                                        labelCol={{
                                            xs: { span: 8 },
                                            lg: { span: 162 },
                                            xxl: { span: 162 }
                                        }}
                                    >
                                        <Input
                                            size="small"
                                            className='styleInput'
                                            placeholder={"Enter Description"}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <Form.Item rules={[{ required: true, message: "Item Type is required" }]} name="itemType"
                                        label="itemType"
                                        labelAlign='left'
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Select
                                            size="small"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            loading={itemType.loading}
                                            defaultValue={formState === "add" ? 5 : form.getFieldValue().itemType}
                                            className="w-100"
                                        > {itemType?.payload?.data?.map((res) =>
                                            <Option key={res.item_type_id} value={res.item_type_id}>{res.item_type_name}</Option>
                                        )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Bcol>

                        <Bcol lg={6} xl={8} xxl={6}>
                            <div className='customCol slc'>
                                <div className="relative" >
                                    <input type='file' onChange={(e) => setProductImage(e.target.files[0])} />
                                </div>
                            </div>
                        </Bcol>
                        {(formState === "add" || formState === "view") &&
                            <>
                                <Bcol lg={6} xl={4} xxl={3}>
                                    <div className='customCol slc'>
                                        <div className="relative" >
                                        </div>
                                    </div>
                                </Bcol>
                                <Bcol lg={6} xl={4} xxl={3}>
                                    <div className='customCol slc'>
                                        <div className="relative" >
                                        </div>
                                    </div>
                                </Bcol>
                                <Bcol lg={6} xl={4} xxl={3}>
                                    <div className='customCol slc'>
                                        <div className="relative" >
                                        </div>
                                    </div>
                                </Bcol>
                            </>
                        }
                        {itemId &&
                            <>
                                <Bcol lg={6} xl={4} xxl={3}>
                                    <div className='customCol slc invisible'>
                                        <div className="relative" >
                                        </div>
                                    </div>
                                </Bcol>
                                <Bcol lg={6} xl={4} xxl={3}>
                                    <div className='customCol slc invisible'>
                                        <div className="relative" >
                                        </div>
                                    </div>
                                </Bcol>
                            </>

                        }
                        <Button style={{ display: "none" }} onClick={onFinish} ref={submitButton} />
                        {/* </div> */}
                    </Brow>
                </div>
            </Form >
        </div >
    )
}
