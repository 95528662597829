import React, { useState, useEffect, useRef, useCallback } from "react";
import { Select, Form, Table, Spin, Button, Input } from "antd";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { useDownloadExcel } from "react-export-table-to-excel";
import printJS from "print-js";
import { Excel } from "antd-table-saveas-excel";
import "./styleSheet.css";
import { header } from "./index";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import Loader from "react-loader-spinner";
import { getCustomerInfoReport } from "../../redux/actions/customerInfoReport.action";
import moment from "moment";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";

const { Option } = Select;
const CustomerInfo = ({ token }) => {
  TabTitle("Customer")
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { saleOrderFormData, customerTypes, paymentterm, customerInfoReport } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [excelToogle, setExcelToogle] = useState(true);
  const [excelData, setExcelData] = useState([])
  const tableRef = useRef(null);
  const company_id = ReadCookie("defaultCompany");
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  useEffect(() => {
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getCustomerType(token));
    dispatch(getAllCompany(token));
  }, [dispatch, menuId, token]);

  const handleChanges = (val) => {
    dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""));
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const optimizedFn = useCallback(debounce(handleChanges), []);

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      const data = {
        customer_id: values.customer === "All" ? null : values.customer,
        customer_type_id: values.customer_type_id === "All" ? null : values.customer_type_id,
        term_id: values.term_id === "All" ? null : values.term_id,
      };
      dispatch(getCustomerInfoReport(token, data)).then((res) => {
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);

      });
    });
  };


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Customer": v.customer_name,
          "Men Sale Person": v.men_salesperson,
          "Men Region": v.men_region,
          "Woman Sale Person": v.women_salesperson,
          "Woman Region": v.women_region,
          "Kids Sale Person": v.kids_salesperson,
          "Kids Region": v.kids_region,
          "Billing Address 1": v.billing_address1,
          "Billing Address 2": v.billing_address2,
          "Billing Country": v.billing_country_name,
          "Billing City": v.billing_city_name,
          "Billing State": v.billing_state_name,
          "Billing Email": v.billing_email,
          "Billing Phone": v.billing_phone_no,
          "Shipping Address 1": v.shipping_address1,
          "Shipping Address 2": v.shipping_address2,
          "Shipping Country": v.shipping_country_name,
          "Shipping City": v.shipping_city_name,
          "Shipping State": v.shipping_state_name,
          "Shipping Email": v.shipping_email,
          "Shipping Phone": v.shipping_phone_no,
          "Payment Term": v.term_name,
          "Customer Type": v.customer_type_name,
          "Opening Date": moment(v.opening_date).format("MM-DD-YY"),
        };
      })
    )
  }


  const columns = [
    {
      title: "#",
      // align: "left",
      // width: 100,
      render: (text, record, index) => {
        return index + 1
      }
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_name !== null) {
          return record.customer_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.customer_name !== null) & (b.customer_name !== null)) {
          return a.customer_name.localeCompare(b.customer_name);
        }
      },
    },
    {
      title: "Men Sale Person",
      dataIndex: "men_salesperson",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.men_salesperson !== null) {
          return record.men_salesperson
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.men_salesperson !== null) & (b.men_salesperson !== null)) {
          return a.men_salesperson.localeCompare(b.men_salesperson);
        }
      },
    },
    {
      title: "Men Region",
      dataIndex: "men_region",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.men_region !== null) {
          return record.men_region
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.men_region !== null) & (b.men_region !== null)) {
          return a.men_region.localeCompare(b.men_region);
        }
      },
    },
    {
      title: "Woman Sale Person",
      dataIndex: "women_salesperson",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.women_salesperson !== null) {
          return record.women_salesperson
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.women_salesperson !== null) & (b.women_salesperson !== null)) {
          return a.women_salesperson.localeCompare(b.women_salesperson);
        }
      },
    },
    {
      title: "Woman Region",
      dataIndex: "women_region",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.women_region !== null) {
          return record.women_region
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.women_region !== null) & (b.women_region !== null)) {
          return a.women_region.localeCompare(b.women_region);
        }
      },
    },
    {
      title: "Kids Sale Person",
      dataIndex: "kids_salesperson",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.kids_salesperson !== null) {
          return record.kids_salesperson
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.kids_salesperson !== null) & (b.kids_salesperson !== null)) {
          return a.kids_salesperson.localeCompare(b.kids_salesperson);
        }
      },
    },
    {
      title: "Kids Region",
      dataIndex: "kids_region",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.kids_region !== null) {
          return record.kids_region
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.kids_region !== null) & (b.kids_region !== null)) {
          return a.kids_region.localeCompare(b.kids_region);
        }
      },
    },
    {
      title: "Billing Address 1",
      dataIndex: "billing_address1",
      key: "billing_address1",
      align: "left",
      // width: 300,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.billing_address1 !== null) {
          return record.billing_address1
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.billing_address1 !== null) & (b.billing_address1 !== null)) {
          return a.billing_address1.localeCompare(b.billing_address1);
        }
      },

    },
    {
      title: "Billing Address 2",
      dataIndex: "billing_address2",
      key: "billing_address2",
      align: "left",
      // width: 300,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.billing_address2 !== null) {
          return record.billing_address2
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.billing_address2 !== null) & (b.billing_address2 !== null)) {
          return a.billing_address2.localeCompare(b.billing_address2);
        }
      },

    },
    {
      title: "Billing Country",
      dataIndex: "billing_country_name",
      key: "billing_country_name",
      align: "left",
      // width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.billing_country_name !== null) {
          return record.billing_country_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.billing_country_name !== null) & (b.billing_country_name !== null)) {
          return a.billing_country_name.localeCompare(b.billing_country_name);
        }
      },

    },
    {
      title: "Billing City",
      dataIndex: "billing_city_name",
      key: "billing_city_name",
      align: "left",
      // width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.billing_city_name !== null) {
          return record.billing_city_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.billing_city_name !== null) & (b.billing_city_name !== null)) {
          return a.billing_city_name.localeCompare(b.billing_city_name);
        }
      },

    },
    {
      title: "Billing State",
      dataIndex: "billing_state_name",
      key: "billing_state_name",
      align: "left",
      // width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.billing_state_name !== null) {
          return record.billing_state_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.billing_state_name !== null) & (b.billing_state_name !== null)) {
          return a.billing_state_name.localeCompare(b.billing_state_name);
        }
      },

    },
    {
      title: "Billing Email",
      dataIndex: "billing_email",
      key: "billing_email",
      align: "left",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.billing_email !== null) {
          return record.billing_email
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.billing_email !== null) & (b.billing_email !== null)) {
          return a.billing_email.localeCompare(b.billing_email);
        }
      },
    },
    {
      title: "Billing Phone",
      dataIndex: "billing_phone_no",
      key: "billing_phone_no",
      align: "left",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.billing_phone_no !== null) {
          return record.billing_phone_no
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.billing_phone_no !== null) & (b.billing_phone_no !== null)) {
          return a.billing_phone_no.localeCompare(b.billing_phone_no);
        }
      },
    },
    {
      title: "Shipping Address 1",
      dataIndex: "shipping_address1",
      key: "shipping_address1",
      align: "left",
      // width: 300,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_address1 !== null) {
          return record.shipping_address1
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.shipping_address1 !== null) & (b.shipping_address1 !== null)) {
          return a.shipping_address1.localeCompare(b.shipping_address1);
        }
      },

    },
    {
      title: "Shipping Address 2",
      dataIndex: "shipping_address2",
      key: "shipping_address2",
      align: "left",
      // width: 300,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_address2 !== null) {
          return record.shipping_address2
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.shipping_address2 !== null) & (b.shipping_address2 !== null)) {
          return a.shipping_address2.localeCompare(b.shipping_address2);
        }
      },

    },
    {
      title: "Shipping Country",
      dataIndex: "shipping_country_name",
      key: "shipping_country_name",
      align: "left",
      // width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_country_name !== null) {
          return record.shipping_country_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.shipping_country_name !== null) & (b.shipping_country_name !== null)) {
          return a.shipping_country_name.localeCompare(b.shipping_country_name);
        }
      },

    },
    {
      title: "Shipping City",
      dataIndex: "shipping_city_name",
      key: "shipping_city_name",
      align: "left",
      // width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_city_name !== null) {
          return record.shipping_city_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.shipping_city_name !== null) & (b.shipping_city_name !== null)) {
          return a.shipping_city_name.localeCompare(b.shipping_city_name);
        }
      },

    },
    {
      title: "Shipping State",
      dataIndex: "shipping_state_name",
      key: "shipping_state_name",
      align: "left",
      // width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_state_name !== null) {
          return record.shipping_state_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.shipping_state_name !== null) & (b.shipping_state_name !== null)) {
          return a.shipping_state_name.localeCompare(b.shipping_state_name);
        }
      },

    },
    {
      title: "Shipping Email",
      dataIndex: "shipping_email",
      key: "shipping_email",
      align: "left",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_email !== null) {
          return record.shipping_email
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.shipping_email !== null) & (b.shipping_email !== null)) {
          return a.shipping_email.localeCompare(b.shipping_email);
        }
      },
    },
    {
      title: "Shipping Phone",
      dataIndex: "shipping_phone_no",
      key: "shipping_phone_no",
      align: "left",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_phone_no !== null) {
          return record.shipping_phone_no
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.shipping_phone_no !== null) & (b.shipping_phone_no !== null)) {
          return a.shipping_phone_no.localeCompare(b.shipping_phone_no);
        }
      },
    },
    {
      title: "Payment Term",
      dataIndex: "term_name",
      key: "term_name",
      align: "left",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.term_name !== null) {
          return record.term_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.term_name !== null) & (b.term_name !== null)) {
          return a.term_name.localeCompare(b.term_name);
        }
      },
    },
    {
      title: "Customer Type",
      dataIndex: "customer_type_name",
      key: "customer_type_name",
      align: "left",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_type_name !== null) {
          return record.customer_type_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.customer_type_name !== null) & (b.customer_type_name !== null)) {
          return a.customer_type_name.localeCompare(b.customer_type_name);
        }
      },
    },
    {
      title: "Opening Date",
      dataIndex: "opening_date",
      key: "opening_date",
      align: "left",
      render: (text, record) => {
        return moment(record.opening_date).format("MM-DD-YY")
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.opening_date !== null) {
          return record.opening_date
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.opening_date !== null) & (b.opening_date !== null)) {
          return a.opening_date.localeCompare(b.opening_date);
        }
      },
    },
  ];

  const handlePrint = () => {
    if (customerInfoReport?.payload?.data.length > 3000) {
      printJS({
        printable: customerInfoReport?.payload?.data,
        // printable: report===``,
        type: "json",
        honorColor: true,
        repeatTableHeader: true,
        documentTitle: "Report",
        properties: [
          "serial_no",
          "region_name",
          "quantity",
          "net_amount",
          "percentage",
        ],
        gridHeaderStyle:
          " border: 1px solid grey; text-align:left; padding-left:10px",
        gridStyle: "border: 1px solid grey; padding-left:10px",
        header: header,
        type: "json",
      });
    } else {
      window.print();
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-4 box-space right-content-header text-right pb-0">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex printing_class mr-8">
                      <div className="print_icon printing_class" >
                        {customerInfoReport?.payload?.data.length ? (
                          <i
                            // onClick={handlePrint}
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            onClick={handlePrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        ) : (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            // src={btnPrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        )}
                      </div>
                      <div className="print_icon printing_class">
                        <CSVLink
                          filename={"Customer Info.csv"}
                          data={excelData}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        </CSVLink>
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Customer Information
                      {/* <br /> */}

                      <div>
                        Rpt # 006
                        <br />
                        Login as:
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <>
                <div className="row">
                  <div className="col">
                    <Form
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className='labels width30'>Customer</label>
                            <div className=" w-100">
                              <Form.Item name="customer" initialValue={"All"}>
                                <Select
                                  notFoundContent={
                                    saleOrderFormData.loading ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Loader
                                          type="Oval"
                                          color="#3c4b58"
                                          height={20}
                                          width={20}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Data
                                      </div>
                                    )
                                  }
                                  className={"select_Inputs"}
                                  autoComplete={"nope"}
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  loading={saleOrderFormData.loading}
                                  // onSelect={(data) => onCustomerSelect(data, true)}
                                  defaultValue={form.getFieldValue().customer}
                                  placeholder="Select Customer"
                                  onSearch={(value) => {
                                    optimizedFn(value);
                                  }}
                                >
                                  <Option value="All">All</Option>
                                  {saleOrderFormData?.payload?.customerDetail.map(
                                    (item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.customer_id}
                                          value={item.customer_id}
                                        >
                                          {" "}
                                          {`${item.customer_code} - ${item.customer_name}`}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className='labels width30'>Payment Term:</label>
                            <div className=" w-100">
                              <Form.Item
                                name="term_id"
                                initialValue={"All"}
                              >
                                <Select
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  showSearch
                                  defaultValue={form.getFieldValue().term_id}
                                  size="small"
                                  className={"input-width"}
                                // placeholder="Select Pament Term"
                                >
                                  <Option value="All">All</Option>
                                  {paymentterm?.payload?.data.map(
                                    (item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.term_id}
                                          value={item.term_id}
                                        >
                                          {item.term_name}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width30">
                              Customer Type:
                            </label>
                            <div className=" w-100">
                              <Form.Item name="customer_type_id" initialValue={"All"} >
                                <Select
                                  size="small"
                                  showSearch
                                  defaultValue={"All"}
                                  optionFilterProp="children"
                                  loading={customerTypes.loading}
                                >
                                  <Option value="All">All</Option>
                                  {customerTypes?.payload?.data.map((item) => {
                                    return <Option key={item.customer_type_id} value={item?.customer_type_id}>{item?.customer_type_name}</Option>
                                  })
                                  }
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div>
                            <Button
                              htmlType="submit"
                              type="primary"
                              className="submit-btn-btm "
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            </div>
            <div className="row" ref={tableRef}>
              <div className="col">
                <div className="maxWidth mt-3">
                  <>
                    {loading ? (
                      <div className=" w-100 d-flex align-items-center justify-content-center spin-style mt-3">
                        <Spin size='large' />
                      </div>
                    ) : (customerInfoReport?.payload?.data.length ? (
                      <>
                        <Table
                        scroll={{ x: 'max-content' }}
                          showSorterTooltip={false}
                          // scroll={{ x: 3000 }}
                          className="w-100 test04 "
                          size="small"
                          pagination={{ pageSize: 100 }}
                          bordered
                          columns={columns}
                          dataSource={
                            customerInfoReport?.payload?.data
                              ? customerInfoReport?.payload?.data
                              : []
                          }
                        />
                      </>) : <div
                        className="report-wrapper-main"
                        style={{
                          backgroundColor: "#CCCCCC",
                          borderWidth: "1pt",
                          borderStyle: "Dashed",
                          fontWeight: "bold",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                      No record(s)
                    </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default CustomerInfo;
