import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getItemTransferFormData } from "../../redux/actions/itemTransfer.action";
import CrudButtons from "./components/CrudButtons";
import CustomerPaymentForm from "./components/Form/customerPaymentForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { deleteCustomerPaymentHeader, getOneCustomerPaymentHeader } from "../../redux/actions/customerPaymnetHeader.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getRights } from "../../redux/actions/userRights.action";
import { useLayoutEffect } from "react";
export default function CustomerPayment({ token }) {
	TabTitle("Customer Payment")
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const { log, payCustomerPayment } = useSelector((state) => state);
	const queryParams = new URLSearchParams(window.location.search);
	const [formState, setFormState] = useState("view");
	const dCompanyId = ReadCookie("defaultCompany");
	const [minimize, setMinimize] = useState(false);
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [flag, setFlag] = useState(false);
	const mode = queryParams.get("mode");
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const [userRights, setUserRights] = useState({})
	const[menuId, setMenuId] = useState(408);

	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		dispatch(deleteCustomerPaymentHeader(token, id)).then((res) => {
			if (res.type === "DELETE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS") {
				notification("success", "Customer Payment deleted", res.payload);
				setFlag(true);
				history.push("/Payment-Header/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, [mode]);

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
		  console.log(res?.payload)
		  if (res.type === "GET_USER_RIGHTS_SUCCESS") {
			setUserRights(res?.payload)
		  }
		});
	  }, [token])
	  
	useEffect(() => {
		dispatch(getItemTransferFormData(token));
	}, []);

	useEffect(() => {
		if (id) {
			setFormState("formView");
			dispatch(getOneCustomerPaymentHeader(token, id));
		}
	}, [id]);


	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Customer-Payment"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								saleOrderID={id}
								formData={payCustomerPayment?.payload?.data}
								userRights={userRights}
							/>
							<div className="mainProductContainer">
								<CustomerPaymentForm
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={formState === "formView" || formState === "edit" ? payCustomerPayment?.payload?.data[0] : null}
									formState={formState}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									saleOrderID={id}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Item Transfer{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
