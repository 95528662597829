// import { useSelector, useDispatch } from "react-redux";
// import { getWashName } from "../../redux/actions/wash.action";
// import { Button, Form, Select, DatePicker, Radio, Input } from "antd";

// import { CloseCircleOutlined } from "@ant-design/icons";
// import { getSeason } from "../../redux/actions/season.action";
// import { useHistory } from "react-router-dom";
// import "../ProducList/productList.css";
// import moment from "moment";
// import { Row as Brow, Col as Bcol } from "reactstrap";
// import { loadCustomers } from "../../redux/actions/customerForm";
// import { ReadCookie } from "../../utils/readCookie";
// // import { getAllSaleperson } from "../../redux/actions/salesPerson";
// // import { getWashType } from "../../redux/actions/washType.action";
// import { getReportIP } from "../../redux/actions/reportIP.action";
// import { getGender } from "../../redux/actions/gender.action";
// // import { getStyle } from "../../redux/actions/style";
// // import { getGenderCategory } from "../../redux/actions/genderCategory.action";
// import { getRegion } from "../../redux/actions/region.action";

// const { RangePicker } = DatePicker;
// const BusinessDashboard = ({ token }) => {
// 	const { wash, style, genderCategory, season, gender, region } = useSelector((state) => state);
// 	const [date, setDate] = useState([moment().startOf("year").format("MM/DD/YYYY"), moment().format("MM/DD/YYYY")]);
// 	const [genderCategoryID, setGenderCategoryID] = useState("All");
// 	const [genderID, setGenderID] = useState("All");
// 	const [regionID, setRegionID] = useState("All");
// 	// const focusInput = useRef(null);
// 	const [selectedParameters, setSelectedParameters] = useState({
// 		region: "",
// 		gender: "",
// 	});

// 	const menuId = ReadCookie("menuId");
// 	const userName = ReadCookie("name");
// 	const dispatch = useDispatch();
// 	const [form] = Form.useForm();

// 	const [reportIP, setReportIP] = useState("");

// 	const { Option } = Select;
// 	let history = useHistory();

// 	useEffect(() => {
// 		dispatch(getReportIP(token)).then((res) => {
// 			setReportIP(res.payload.data[0].report_server_ip);
// 		});
// 		dispatch(getGender(token, menuId));
// 		dispatch(getRegion(token, menuId));
// 	}, []);

// 	const getReportName = () => {
// 		return "ReturnGroupReport.rptdesign";
// 	};
// 	const onGenderChange = (e, f) => {
// 		setGenderID(e);
// 		setSelectedParameters({ ...selectedParameters, gender: f.children });
// 	};
// 	const onRegionChange = (e, f) => {
// 		setRegion(e);
// 		setSelectedParameters({ ...selectedParameters, region: f.children });
// 	};

// 	const onFinish = (values) => {
// 		window.open(
// 			`${reportIP}birt/frameset?__report=${getReportName()}&__format=html&__svg=true&__locale=en_US&__timezone=PLT&__masterpage=true&__rtl=false&__cubememsize=10&__resourceFolder=C%3A%5CUsers%5Cadm%5Ceclipse%5Crcp-2021-09%5Ceclipse%5Cworkspace%5CERP-Reports&__parameterpage=false&-632080003&${`p_from_date=${date[0]}`}&${`p_to_date=${date[1]}`}&${washID === "All" ? "__isnull=p_wash_name_id" : `p_wash_name_id=${washID}`
// 			}&${styleID === "All" ? "__isnull=p_style_id" : `p_style_id=${styleID}`}&${sku === "" ? "__isnull=p_sku" : `p_sku=${sku}`}&${washTypeID === "All" ? "__isnull=p_wash_type_id" : `p_wash_type_id=${washTypeID}`}&
// 			${genderID === "All" ? "__isnull=p_season_id" : `p_season_id=${genderID}`}}${showGroup && `p_group_name=${groupName}`}&user_name=${userName}&selectedParameters=${`Selected Filters : Start Date : ${date[0]}, End Date : ${date[1]
// 			}, Customer : ${selectedParameters.customer ? selectedParameters.customer : "All"}, Sales Person : ${selectedParameters.spName ? selectedParameters.spName : "All"} Fit/Cut : ${selectedParameters.cut ? selectedParameters.cut : "All"
// 			}, Region : ${selectedParameters.region ? selectedParameters.region : "All"}, Wash Name : ${selectedParameters.washName ? selectedParameters.washName : "All"}, Wash Type : ${selectedParameters.washType ? selectedParameters.washType : "All"
// 			}, Fabric : ${selectedParameters.fabric ? selectedParameters.fabric : "All"},Gender : ${selectedParameters.gender ? selectedParameters.gender : "All"}, SKU : ${sku ? sku : "All"},`}`
// 		);
// 	};
// 	const clearFields = (event, type) => {
// 		event.preventDefault();
// 		if (type === "gender") {
// 			setGenderID("All");
// 			setSelectedParameters({ ...selectedParameters, gender: "" });
// 		} else if (type === "region") {
// 			setGenderCategoryID("All");
// 			setSelectedParameters({ ...selectedParameters, region: "" });
// 		}
// 	};
// 	return (
// 		<>
// 			<div
// 				className="product-detail-main-wrapper"
// 				style={{
// 					display: "flex",
// 					alignItems: "center",
// 					justifyContent: "center",
// 				}}
// 			>
// 				<div className="product-detail-main" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
// 					<div className="pageHead ">
// 						<h4 className="prod-det-title-hd mb-0">Business Dashboard</h4>
// 						<div>
// 							<Button className="pos-right-10 iconBtn crudBTN" shape="circle" icon={<CloseCircleOutlined className="color-red" />} onClick={() => history.push("/")} />
// 						</div>
// 					</div>
// 					<div className="product-detail-content">
// 						<Form labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} layout="horizontal" form={form} name="control-hooks" onFinish={onFinish}>
// 							<Brow>
// 								<Bcol lg={12}>
// 									<div className="date-filter">
// 										<h3 className="filter-title">Filters</h3>
// 										<Form.Item name="Gender" label="Gender">
// 											<div style={{ display: "flex" }}>
// 												<Select
// 													showSearch
// 													optionFilterProp="children"
// 													className="filter-fields"
// 													onChange={(e, f) => onGenderChange(e, f)}
// 													loading={gender?.loading}
// 													placeholder="Select Gender"
// 													defaultValue="All"
// 													value={genderID}
// 												>
// 													{gender?.payload?.data.map((item) => {
// 														return (
// 															<Option defaultValue="All" key={item.gender_id} value={item.gender_id}>
// 																{item.gender_name}
// 															</Option>
// 														);
// 													})}
// 												</Select>
// 												<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "gender")}>
// 													CLEAR
// 												</button>
// 											</div>
// 										</Form.Item>
// 										<Form.Item name="Region" label="Region">
// 											<div style={{ display: "flex" }}>
// 												<Select
// 													showSearch
// 													optionFilterProp="children"
// 													className="filter-fields"
// 													onChange={(e, f) => onRegionChange(e, f)}
// 													loading={region?.loading}
// 													placeholder="Select Religion"
// 													defaultValue="All"
// 													value={regionID}
// 												>
// 													{region?.payload?.data.map((item) => {
// 														return (
// 															<Option defaultValue="All" key={item.region_id} value={item.region_id}>
// 																{item.region_name}
// 															</Option>
// 														);
// 													})}
// 												</Select>
// 												<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "region")}>
// 													CLEAR
// 												</button>
// 											</div>
// 										</Form.Item>
// 									</div>
// 									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', }}>
// 										<Form.Item>
// 											<Button type="primary" htmlType="submit" className="w-30">
// 												Search
// 											</Button>
// 										</Form.Item>
// 									</div>
// 								</Bcol>

// 								{/* <Bcol lg={4} xl={4} xxl={4}>
// 									<div className="date-filter">
// 										<h3 className="filter-title">Active</h3>
// 										<Form.Item name="group">
// 											<Radio.Group onChange={(e) => setShowGroup(e.target.value)} defaultValue={showGroup} style={{ width: 500 }}>
// 												<Radio value={true}>Yes</Radio>
// 												<Radio value={false}>No</Radio>
// 											</Radio.Group>
// 										</Form.Item>
// 									</div>
// 									<div>
// 										<Form.Item>
// 											<Button type="primary" htmlType="submit" className="w-100">
// 												Search
// 											</Button>
// 										</Form.Item>
// 									</div>
// 								</Bcol> */}
// 							</Brow>
// 						</Form>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// export default BusinessDashboard;

import React, { useEffect, useCallback, useRef, useState } from "react";
import "./BusinessDashboard.css";
import { Select, DatePicker, Form, Table, Spin, Button, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getOmsReport } from "../../redux/actions/omsReport.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAllScmBrand } from "../../redux/actions/scmBrand.action";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { loadCustomers } from "../../redux/actions/customerForm";
import { getBussinessDashboard } from "../../redux/actions/bussinessDashboard.action";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { TabTitle } from "../../utils/GeneralFunction";

const { Option } = Select;

const BusinessDashboard = ({ token }) => {
  TabTitle("Business Dashboard")
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const [loader, setLoader] = useState(false);
  const {
    gender,
    region,
    companyAll,
    scmBrand,
    customerForms,
    getBussinessDashboardReducer,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");

  let result =
    getBussinessDashboardReducer &&
    getBussinessDashboardReducer?.payload?.data?.reduce(function (r, a) {
      r[a.tran_type] = r[a.tran_type] || [];
      r[a.tran_type].push(a);
      return r;
    }, Object.create(null));

  const bussinessdashboard = result && Object.values(result);

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handlePrint = () => {
    window.print();
  };

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(
      loadCustomers(
        token,
        null,
        null,
        null,
        null,
        null,
        val,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    ).then((res) => {
      if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
        setCustomer(res?.payload?.data);
      }
    });
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);

  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      //  **** get Last Index of the current Year ****
      let lastIndex = null;
      let lastYear = null;
      if (values.current_year !== null) {
        //  **** get the Current Year ****
        lastIndex = values.current_year;

        //  **** get the Current Year and - 1 from it****
        lastYear = lastIndex - 1;
      }
      //  **** Append to the values object ****
      values.last_year = lastYear;
      //
      dispatch(getBussinessDashboard(token, values));
      setLoader(true);
    });
  };

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getAllScmBrand(token));
    dispatch(getCustomerType(token));
  }, [dispatch, menuId, token]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="header_class business_class">
            {/* inputs Container */}

            <div className="row business_header justify-content-between pb-0 marginAuto">
              {/**** / Logo *****/}
              <div className="col-3 d-flex align-items-start box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>
              {/* <div className="logo_Container">
            <img src="http://oms2.dl1961.com/OMS/Reports/Images/logo5.png" />
          </div> */}
              {/* Items */}
              {/* <div className="col-sm-6 box-space pl-sm-0">
                <div className="brand-para">
                  <div>DL1961 Premium Denim Inc.</div>
                  <div>121 Varick Street</div>
                  <div>4th Floor</div>
                  <div>New York, NY 10013</div>
                  <div>(P) 646-514-9736</div>
                  <div>(F) 212-991-9989</div>
                  <div>(E) sales@DL1961.com</div>
                </div>
              </div> */}
              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print printing_class"
                        onClick={handlePrint}
                      ></i>
                    </div>
                    <div
                      className="print_icon printing_class"
                      name="ImgExcel"
                      alt="Export to excel"
                      id="ImgExcel"
                      title="Export to excel"
                      src={xlsx}
                    >
                      <i className="fa-solid fa-file-excel"></i>
                    </div>
                  </div>
                  <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                    Buisness Dashboard
                    <br />
                    Rpt # 489
                    <br />
                    Login as:
                    <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                      {" "}
                      {userName}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {/* Filters Table */}
            <div className="col business_header marginAuto">
              <Form form={form} name="control-hooks" onFinish={onFinish}>
                <div className="main_form row">
                  <div className="col-6">
                    <div className=" d-flex flex-row align-items-baseline">
                      <label htmlFor="gender_id" className="labels width30">
                        Gender
                      </label>
                      <div className=" w-100">
                        <Form.Item initialValue={"All"} name="gender_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            // placeholder="Select Prduct Type"
                            size="small"
                            className={"select_Inputs"}
                            defaultValue={form.getFieldValue().productType}
                          >
                            <Option defaultValue={null}>All</Option>
                            {gender &&
                              gender?.payload?.data.map((gender, i) => {
                                return (
                                  <Option key={i} value={gender?.gender_id}>
                                    {gender?.gender_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className=" d-flex flex-row align-items-baseline">
                      <label className="labels width30">Customer:</label>
                      <div className=" w-100">
                        <Form.Item name="customer_id" initialValue={"All"}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            className={"select_Inputs"}
                            loading={customerForms.loading}
                            // placeholder="Select Customers"
                            size="small"
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}
                            ref={focusInput}
                            defaultValue="All"
                            value={customer}
                          >
                            <Option defaultValue={null}>All</Option>
                            {customerForms?.payload?.data.map((item) => {
                              return (
                                <Option
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} ${item.customer_name}`}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className=" d-flex flex-row align-items-baseline">
                      <label className="labels width30">Brand:</label>
                      <div className=" w-100">
                        <Form.Item name="company_id" initialValue={2}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            // placeholder="Select Prduct Type"
                            defaultValue={form.getFieldValue().Brand}
                            size="small"
                            className={"select_Inputs"}
                          >
                            <Option defaultValue={null}>All</Option>
                            {companyAll &&
                              companyAll?.payload?.data.map((company, i) => {
                                return (
                                  <Option key={i} value={company.company_id}>
                                    {company.company_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className=" d-flex flex-row align-items-baseline">
                      <label htmlFor="gender_id" className="labels width30">
                        Region:
                      </label>
                      <div className=" w-100">
                        <Form.Item name="region_id" initialValue={"All"}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            // placeholder="Select Prduct Type"
                            size="small"
                            className={"select_Inputs"}
                            defaultValue={form.getFieldValue().Region}
                          >
                            <Option defaultValue={null}>All</Option>
                            {region &&
                              region?.payload?.data.map((region, i) => {
                                return (
                                  <Option key={i} value={region.region_id}>
                                    {region.region_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className=" d-flex flex-row align-items-baseline">
                      <label className="labels width30">Year:</label>
                      <div className=" w-100">
                        <Form.Item name="current_year" initialValue={"2023"}>
                          <Select
                            style={{ width: "70px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            // placeholder="Select Year"
                            defaultValue={form.getFieldValue().Year}
                            size="small"
                          >
                            <Option defaultValue={null}>All</Option>
                            <Option value="2020">2020</Option>
                            <Option value="2021">2021</Option>
                            <Option value="2022">2022</Option>
                            <Option value="2023">2023</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className=" d-flex flex-row align-items-baseline justify-content-end">
                      <label className=" no_visibility">none</label>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submit-btn-btm m-0"
                      >
                        Generate
                      </Button>
                    </div>
                  </div>
                </div>

                {/* <table
                  id="Table1"
                  cellPadding="3"
                  cellSpacing="0"
                  align="center"
                  className="tablestyleControls"
                >
                  <tbody>
                    <tr>
                      <td width="103">ProductType:</td>
                      <td>
                        <Form.Item initialValue={"All"} name="gender_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Prduct Type"
                            size="small"
                            className={"select_Inputs"}
                            defaultValue={form.getFieldValue().productType}
                          >
                            <Option defaultValue={null}>All</Option>
                            {gender &&
                              gender?.payload?.data.map((gender, i) => {
                                return (
                                  <Option key={i} value={gender?.gender_id}>
                                    {gender?.gender_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td width="103">Region:</td>
                      <td>
                        <Form.Item name="region_id" initialValue={"All"}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Prduct Type"
                            size="small"
                            className={"select_Inputs"}
                            defaultValue={form.getFieldValue().Region}
                          >
                            <Option defaultValue={null}>All</Option>
                            {region &&
                              region?.payload?.data.map((region, i) => {
                                return (
                                  <Option key={i} value={region.region_id}>
                                    {region.region_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td width="103">Customer:</td>
                      <td>
                        <Form.Item name="customer_id" initialValue={"All"}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            className={"select_Inputs"}
                            loading={customerForms.loading}
                            placeholder="Select Customers"
                            size="small"
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}
                            ref={focusInput}
                            defaultValue="All"
                            value={customer}
                          >
                            <Option defaultValue={null}>All</Option>
                            {customerForms?.payload?.data.map((item) => {
                              return (
                                <Option
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} ${item.customer_name}`}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td width="103">
                        <Form.Item name="current_year" initialValue={"2021"}>
                          <Select
                            style={{ width: "70px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Year"
                            defaultValue={form.getFieldValue().Year}
                            size="small"
                          >
                            <Option defaultValue={null}>All</Option>
                            <Option value="2020">2020</Option>
                            <Option value="2021">2021</Option>
                            <Option value="2022">2022</Option>
                            <Option value="2023">2023</Option>
                          </Select>
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td width="103">Brand:</td>
                      <td>
                        <Form.Item name="company_id" initialValue={2}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Prduct Type"
                            defaultValue={form.getFieldValue().Brand}
                            size="small"
                            className={"select_Inputs"}
                          >
                            <Option defaultValue={null}>All</Option>
                            {companyAll &&
                              companyAll?.payload?.data.map((company, i) => {
                                return (
                                  <Option key={i} value={company.company_id}>
                                    {company.company_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td width="103"></td>
                      <td>
                        <div className="generateButton">
                          <Button type="primary" htmlType="submit">
                            Generate
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </Form>
            </div>

            {/* Render Data Table */}
            <div className="table_content">
              <div className="">
                {bussinessdashboard
                  ? bussinessdashboard.map((bussiness, index) => {
                      return (
                        <div className="data_Table">
                          <div key={index}>
                            <div className="table_Heading business_class"
                             style={{width: "1400px"}}
                            >
                              {bussiness[0].tran_type}
                            </div>
                            <table className="table business_class" 
                            >
                              <tbody className="stripedTable">
                                <tr 
                                // className="months"
                                >
                                  <td
                                    className="serial_No"
                                    style={{ width: "26px" }}
                                  >
                                    #
                                  </td>
                                  <td style={{ width: "82px" }}></td>
                                  <td style={{ width: "82px" }}>JAN</td>
                                  <td style={{ width: "82px" }}>FEB</td>
                                  <td style={{ width: "82px" }}>MAR</td>
                                  <td style={{ width: "82px" }}>APR</td>
                                  <td style={{ width: "82px" }}>MAY</td>
                                  <td style={{ width: "82px" }}>JUN</td>
                                  <td
                                    style={{ width: "82px" }}
                                    className="spring"
                                  >
                                    SPRING
                                  </td>
                                  <td style={{ width: "82px" }}>JUL</td>
                                  <td style={{ width: "82px" }}>AUG</td>
                                  <td style={{ width: "82px" }}>SEP</td>
                                  <td style={{ width: "82px" }}>OCT</td>
                                  <td style={{ width: "82px" }}>NOV</td>
                                  <td style={{ width: "82px" }}>DEC</td>
                                  <td
                                    style={{ width: "100px" }}
                                    className="fall"
                                  >
                                    FALL
                                  </td>
                                  <td
                                    style={{ width: "100px" }}
                                    className="total"
                                  >
                                    TOTAL
                                  </td>
                                </tr>
                                {/* Table data */}
                                {bussiness.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="serial_No color_black">
                                        {index + 1}</td>
                                      <td className="color_black">{data && data.yearr}</td>             
                                      <td className={`${(data.jan < 0) ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`}>
                                        {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.jan}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td> 
                                      <td className={`${data.feb < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.feb}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>             
                                      <td className={`${data.mar < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.mar}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.apr < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.apr}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.may < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.may}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.jun < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.jun}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.spring < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""} spring`}>
                                      {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.spring}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                      </td>
                                      <td className={`${data.jul < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.jul}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.aug < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.aug}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.sep < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.sep}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.oct < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.oct}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.nov < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.nov}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.decc < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""}`}>{data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.decc}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}</td>
                                      <td className={`${data.fall < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""} fall`}>
                                      {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.fall}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                      </td>
                                      <td className={`${data.total < 0 ? "color_red"  : (data.yearr == '% VS LY') ? "color_green" : ""} total`}>
                                      {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.total}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })
                  : loader && (
                      <div className="loader">
                        <Space size="middle">
                          <Spin />
                        </Space>
                      </div>
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDashboard;
