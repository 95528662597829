import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, DatePicker, Spin, Table } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { getIncomeStatement } from "../../redux/actions/incomeStatement.action.js";
import { useDownloadExcel } from "react-export-table-to-excel";
const IncomeStatement = ({ token }) => {
  TabTitle("Income Statement")
  const { saleTargetReport, incomeStatement } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  var date = moment();

  const [dateTypeId, setDateTypeId] = useState("mte");
  const [monthId, setMonthId] = useState(date.format("M"));
  const [yearId, setYearId] = useState(date.format("Y"));
  const [monthToId, setMonthToId] = useState(date.format("M"));
  const [yearToId, setYearToId] = useState(date.format("Y"));
  // const [currentYearStartDate, setCurrentYearStartDate] = useState(moment().startOf("year").format("MM/DD/YYYY"));
  // const [currentYearEndDate, setCurrentYearEndDate] = useState(moment().format("MM/DD/YYYY"));
  // const [lastYearStartDate, setLastYearStartDate] = useState(moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY"));
  // const [lastYearEndDate, setLastYearEndDate] = useState(moment().subtract(1, "year").format("MM/DD/YYYY"));
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const { submited } = useParams();

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Income Statement",
    sheet: "Income Statement",
  });

  // Use reduce to group the data by "accttype"
  const groupedData = incomeStatement &&
    incomeStatement?.payload?.data?.reduce((result, item) => {
      const accttype = item[0]?.accttype;
      if (!result[accttype]) {
        result[accttype] = [];
      }
      result[accttype].push(item);
      return result;
    }, {});

  console.log("groupedData", groupedData);




  const customSortOrder = ["Income", "Cost of Goods Sold", "Expense", "Other Expense", "Other Income"];

  // Convert the grouped data into an array of objects
  const groupedArray = groupedData && Object.entries(groupedData).map(([accttype, items]) => ({
    accttype,
    items
  }));

  // Sort the grouped array using the custom sorting order
  groupedArray && groupedArray.sort((a, b) => {
    const indexA = customSortOrder.indexOf(a.accttype);
    const indexB = customSortOrder.indexOf(b.accttype);
    // Compare based on the custom sorting order indices
    return indexA - indexB;
  });


  console.log(groupedArray, "groupedArray");

  const sums = groupedArray?.map((v) => {
    return v.items.reduce((acc, item) => {
      console.log("item", item[0])
      return item[0].finalamount.map((itemValue, index) => {
        return (acc[index] || 0) + itemValue;
      });
    }, []);
  });

  console.log("sums", sums)

  const result = sums && sums.reduce((acc, curr) => acc.map((value, index) => value - curr[index]));

  console.log("result", result);


  useEffect(() => {
    let year = yearId;
    let month = monthId;
    let yearto = yearToId;
    let monthto = monthToId;
    let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
    let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
    let lastYearStartDate = moment()
      .startOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");
    let lastYearEndDate = moment()
      .endOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");

    if (dateTypeId === "mte") {
      currentYearStartDate = month + "/" + "01" + "/" + year;
      currentYearEndDate = monthto + "/" + moment(`${yearto} / ${monthto}`, "YYYY/MM").daysInMonth() + "/" + yearto;
    }

    // setCurrentYearStartDate(currentYearStartDate);
    // setCurrentYearEndDate(currentYearEndDate);
    // setLastYearStartDate(lastYearStartDate);
    // setLastYearEndDate(lastYearEndDate);

    const newState = {
      cy_start_date: currentYearStartDate,
      cy_end_date: currentYearEndDate,
    };
    setFormState(newState);
  }, [dateTypeId, monthId, yearId, monthToId, yearToId]);


  const onFinish = () => {
    setLoading(true);
    dispatch(getIncomeStatement(token, formState)).then((res) => {
      console.log(formState, "formState")
      if (res?.type === "GET_SALE_TARGET_REPORT_SUCCESS") {
        setLoading(false);
      }
    });
  };

  console.log(incomeStatement?.payload?.data)

  const handlePrint = () => {
    window.print();
  };




  const total = []
  for (let i = 0; i < sums?.length; i++) {
    let sum = 0;
    for (let j = 0; j < sums[i].length; j++) {
      sum += sums[i][j];
    }
    total.push(sum);
  }
  console.log({ total })


  const data = result && result.reduce(function (previousValue, currentValue) {
    return (previousValue + parseFloat(currentValue));
  }, 0);
  console.log("data123", data)

  const grossProfit = sums && sums[0].reduce((result, currentVal, index) => {
    result.push(currentVal - sums[1][index]);
    return result;
  }, []);

  console.log({ grossProfit });

  const totalGrossProfit = grossProfit && grossProfit.reduce(function (previousValue, currentValue) {
    return (previousValue + parseFloat(currentValue));
  }, 0);
  console.log({ totalGrossProfit });


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-0">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-3 box-space right-content-header text-right">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class">
                      <div className="print_icon printing_class">
                        <i
                          id="Image1"
                          title="Print"
                          alt="Print"
                          className="fa-solid fa-print printing_class"
                          onClick={handlePrint}
                        ></i>
                      </div>
                      <div className="print_icon printing_class">
                        {groupedArray ? (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={onDownload}
                            style={{
                              borderWidth: "0px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Income Statement
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {
                <div className="row pb-2">
                  <div className="col">
                    <Form
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      layout="horizontal"
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Year From:
                            </label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">

                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(yearId)}
                                defaultValue={moment(yearId)}
                                onChange={(e) =>
                                  setYearId(moment(e).format("YYYY"))
                                }
                                picker="year"
                              />
                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Year To:
                            </label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">

                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(yearToId)}
                                // defaultValue={moment(yearToId)}
                                onChange={(e) =>
                                  setYearToId(moment(e).format("YYYY"))
                                }
                                picker="year"
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">

                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width30">Month From:</label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">
                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(monthId)}
                                // disabled={dateTypeId === "ytd"}
                                onChange={(e) =>
                                  setMonthId(moment(e).format("MM"))
                                }
                                picker="month"
                                format={"MM"}
                                mode="month"
                              />
                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width30">Month To:</label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">
                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(monthToId)}
                                // disabled={dateTypeId === "ytd"}
                                onChange={(e) =>
                                  setMonthToId(moment(e).format("MM"))
                                }
                                picker="month"
                                format={"MM"}
                                mode="month"
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: "0px", display: "flex", justifyContent: "end" }} >
                            {/* <label className=" no_visibility">none</label> */}
                            <Button type="primary" htmlType="submit" className='submit-btn-btm m-0'
                            // disabled={loading}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
      {
        <div>
          <div className="report-box-main" ref={tableRef}>
            {
              groupedArray ? (
                groupedArray.length > 0 ? (
                  <>
                    {
                      groupedArray && (
                        <table className="report-table-main" >
                          <tr className="report-row-head">
                            <td className="report-column-head first-head">Finincial New</td>
                            {
                              groupedArray && groupedArray[0].items?.map((v, index) => {
                                return v[0]?.allmonths?.map((val) => {
                                  return (
                                    index == 0 ? <>
                                      <td className="report-column-head second-head text-right">{val}</td> </> : null
                                  )
                                })
                              })
                            }
                            <td className="report-column-head second-head text-right">Total Amount</td>
                          </tr>
                          {
                            groupedArray && groupedArray.map((val, index) => {
                              return (
                                <>
                                  <tr className="report-hd">
                                    <th className="report-hd">{val.accttype}</th>
                                  </tr>
                                  {val?.items?.map((v) => {
                                    return (
                                      <>
                                        <tr>
                                          <td style={{ padding: "0 15px" }}>
                                            <Link
                                              style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              to={{ pathname: `/income-detail`, search: `?account_name=${v[0]?.account_name}&date_from=${formState?.cy_start_date}&date_to=${formState?.cy_end_date}` }}
                                            >
                                              {/* <Link
                                              style={{
                                                color: "blue",
                                                textDecoration: "underline",
                                              }}
                                              to={{
                                                pathname: '/income-detail',
                                                state: { account_name: v[0]?.account_name}
                                              }}
                                            > */}
                                              {v[0]?.account_name}
                                            </Link>
                                          </td>

                                          {
                                            v[0]?.finalamount?.map((item) => {
                                              return (
                                                <>
                                                  <td className="report-value text-right">${numeral(item).format("0,00.00")}</td>
                                                </>
                                              )
                                            })
                                          }
                                          <td className="report-value text-right">${numeral(eval(v[0]?.finalamount.join('+'))).format("0,00.00")}</td>
                                        </tr>
                                      </>
                                    )
                                  })}
                                  <tr className="bottom-row">
                                    <td className="total-amount-left">Total-{val?.accttype}</td>
                                    {sums[index]?.map(it => (
                                      <>
                                        <td className="total-amount">${numeral(it).format("0,00.00")}</td>
                                      </>
                                    ))}
                                    <td className="total-amount">${numeral(total[index]).format("0,00.00")}</td>
                                  </tr>
                                  <tr className="bottom-row">
                                    <td className="total-amount-left">{val?.accttype == "Cost of Goods Sold" ? "Gross Profit" : null}</td>
                                    {grossProfit && grossProfit.map((v) => {
                                      return <td className="total-amount">{val?.accttype == "Cost of Goods Sold" ? "$" + numeral(v).format("0,00.00") : null}</td>
                                    })}
                                    <td className="total-amount">{val?.accttype == "Cost of Goods Sold" ? "$" + numeral(totalGrossProfit).format("0,00.00") : null}</td>
                                  </tr>
                                </>
                              )
                            })
                          }
                          <tr >
                            <td className="total-amount-left">
                              Net Profit / Loss
                            </td>
                            {result && result.map((v) => {
                              return <td className="total-amount">${numeral(v).format("0,00.00")}</td>
                            }
                            )}
                            <td className="total-amount">${numeral(data).format("0,00.00")}</td>
                          </tr>
                        </table>
                      )
                    }
                  </>) :
                  (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
              )
                : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
            }
          </div>
        </div>}
    </>
  );
};

export default IncomeStatement;
