import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, Checkbox, Form, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { getOmsStockSummary } from "../../redux/actions/omsStockSummary.action";
import {
  getGenderCategory,
  // getGenderCategoryChange,
} from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action";
import { ReadCookie } from "../../utils/readCookie";
import { getWashName } from "../../redux/actions/wash.action";
import {
  getStyle,
  // getStyleChange
} from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Link, useHistory } from "react-router-dom";
// import { Excel } from "antd-table-saveas-excel";
import Loader from "react-loader-spinner";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeasonYear } from "../../redux/actions/seasonYear.action";
import { imediateOtsBySize } from "../../redux/actions/omsStockSummary2.action";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { getskuloopUpData } from "../../redux/actions/skuLookupData.action";
import { useRef } from "react";
// const dateFormatList = ["MM-DD-YYYY"];

const TotalOts = ({ token }) => {
  TabTitle("Total OTS");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    // getWiseStockReport,
    // productType,
    gender,
    genderCategory,
    season,
    productStatus,
    // productClass,
    // size,
    wash,
    // fitCategory,
    // omsStockSummary2,
    skuLookUpData,
    genderCategoryChange,
    styleChange,
    Sku,
    // seasonYear,
    adminLocation,
  } = useSelector((state) => state);
  // const history = useHistory();
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  // const [productClassID, setProductClassID] = useState([]);
  // const [seasonID, setSeasonID] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isChecked2, setIsChecked2] = useState([]);
  const [allSesion, setAllSesion] = useState(true);
  const [allStatus, setAllStatus] = useState(true);
  const [isPCChecked, setIsPCChecked] = useState([]);
  const [allPC, setAllPC] = useState(true);
  const [loading, setLoading] = useState(false);
  const [BgradeCheck, setBgradeCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [steps, setSteps] = useState([]);
  const [allFilters, setAllFilters] = useState([
    "BERGEN ECOM",
    "NORD D2C",
    // "NRHL D2C",
    // "VERISHOP D2C",
    "Saks D2C",
    "Neiman D2C",
  ]);
  const [filters, setFilters] = useState([]);
  const stepsArr = [1, 17, 18, 25, 2, 29, 20, 21, 22, 28, 33];
  const [pendingChecked, setPendingChecked] = useState(true);
  const location = useLocation();
  const tableRef = useRef(null);
  const [stepsChecked, setStepChecked] = useState([]);

  console.log("token", { token, menuId });
  useEffect(() => {
    if (adminLocation?.payload?.data?.length > 0) {
      setSteps(
        adminLocation?.payload?.data
          .filter((val) => stepsArr.includes(val.location_id))
          .map((it) => ({ ...it, selected: true }))
      );
    }
  }, [adminLocation]);

  const stepschecked =
    steps && steps?.filter((it) => it.selected)?.map((it) => it.location_id);


  const values = {
    item_id: null,
    location_id: null,
  };

  const { Option } = Select;
  let pendingDate = "";

  const onChangeDate = (date, dateString) => {
    pendingDate = dateString;
  };

  // const sesionSelectAll = (e) => {
  //   setAllSesion(e.target.checked);
  // };
  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };
  // const handlePendingOrder = (e) => {
  //   setPendingChecked(e.target.checked);
  // };

  // const PCSelectAll = (e) => {
  //   setAllPC(e.target.checked);
  // }
  // // else if (!allPC) {
  // //   setAllPC();
  // // }
  // // };

  // const isCheckboxChecked = (id, value) => {
  //   const filterCheckBox =
  //     season && season?.payload?.data.find((val) => val.season_id === id);
  //   const checkId = isChecked.includes(id);
  //   if (!checkId) {
  //     setIsChecked((current) => [...current, filterCheckBox.season_id]);
  //   }
  //   if (!value) {
  //     const removeUnCheck = isChecked.filter(
  //       (item) => item !== filterCheckBox.season_id
  //     );
  //     setIsChecked(removeUnCheck);
  //   }
  //   setAllSesion(false);
  // };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus &&
      productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [
        ...current,
        filterCheckBox.product_status_id,
      ]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  const isFilter = (id, value) => {
    console.log(id, value);

    let checkId = filters.includes(id);
    if (!checkId) {
      // setFilters((current) => [...current, id])
      filters.push(id);
      setFilters(filters);
    }
    if (!value) {
      let removeUnCheck = filters.filter((item) => item !== id);
      console.log(removeUnCheck, "filters removeUnCheck");
      filters.length = 0;
      filters.push(...removeUnCheck);
    }
    console.log(filters, "filters");
  };

  // const isPCBoxChecked = (id, value) => {
  //   const filterCheckBox =
  //     productClass &&
  //     productClass?.payload?.data.find((val) => val.product_class_id === id);
  //   const checkId = isPCChecked.includes(id);
  //   if (!checkId) {
  //     setIsPCChecked((current) => [
  //       ...current,
  //       filterCheckBox.product_class_id,
  //     ]);
  //   }
  //   if (!value) {
  //     const removeUnCheck = isPCChecked.filter(
  //       (item) => item !== filterCheckBox.product_class_id
  //     );
  //     setAllPC(true);
  //     setIsPCChecked(removeUnCheck);
  //   }

  //   setAllPC(false);
  // };

  // console.log("location?.state",location?.state && location?.state?.values?.current_season)

  useEffect(() => {
    if (location?.state && location?.state?.values?.new_season_id) {
      const values = {
        as_on_date: moment("12-31-2023"),
        sku: "",
        season_id: location?.state?.values?.new_season_id,
        gender_id: location?.state?.values?.gender_id,
        gender_category_id: null,
        fit_category_id: null,
        style_id: null,
        product_status_id: null,
        product_class_id: null,
        sort_on: null,
        include_bgrade: "abc",
        wash_id: null,
      };
      form.setFieldsValue({
        season_id: values.season_id,
        gender_id: values.gender_id,
      });
      setLoading(true);
      dispatch(
        imediateOtsBySize(
          token,
          values,
          isChecked,
          isChecked2,
          isPCChecked,
          BgradeCheck
        )
      ).then((res) => {
        setLoading(false);
        setData(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }
  }, [location?.state && location?.state?.values?.new_season_id]);

  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      console.log(values, "values-----");
      //  **** get Last Index of the current Year ****
      // values.pendingDate = pendingDate;
      // values.BGradeStock = BGradechecked;
      values.stepsChecked = stepsChecked;
      values.isChecked = isChecked;
      values.isChecked2 = isChecked2;
      // if (allSesion) {
      //   values.allSesion = allSesion;
      // };

      if (allStatus) {
        values.allStatus = allStatus;
      }

      if (pendingChecked) {
        stepschecked.push(-1);
      }
      // get the current style Id
      // const styleId =
      //   style &&
      //   style?.payload?.data.find((val) => val.style_name === values.style);
      // get the product Status Id
      const productStatusId =
        productStatus &&
        productStatus?.payload?.data.find(
          (val) => val.product_status_id === values.product_Status
        );

      console.log("productStatusId", productStatusId);
      console.log("values", values);
      //
      const distributorParam = {
        // item_id: values.sku,
        location_id: stepschecked.length === 0 ? null : stepschecked.toString(),
        season_id: values.season_id === "All" ? null : values.season_id,
        sku: values.sku === "All" ? null : values.sku,
        gender_category_id: values.type === "All" ? null : values.type,
        //  style_id: styleId ? styleId.style_id : null, //null
        style_id: values.style_id === "All" ? null : values.style_id,
        product_status_id: isChecked2.length > 0 ? isChecked2.toString() : null,
        pending_date: moment(values.as_on_date).format("YYYY-MM-DD"),
        as_on_date: moment(values.as_on_date).format("YYYY-MM-DD"),
        category_id:
          values.gender_category_id === "All"
            ? null
            : values.gender_category_id,
        fit_category_id: null,
        gender_id: values.gender_id === "All" ? null : values.gender_id,
        product_class_id: null,
        sort_on: null,
        include_bgrade: "abc",
        wash_id: values.wash_id === "All" ? null : values.wash_id,
        new_sesaon_year_id:
          values.new_season_year_id === "All"
            ? null
            : values.new_season_year_id,
      };
      setLoading(true);
      dispatch(getskuloopUpData(token, distributorParam)).then((res) => {
        setLoading(false);
        setData(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  console.log("DATA", data);

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Sku": v.sku,
          "Inseam": v.inseam,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Current Season": v.current_season,
          "Gender": v.gender_name,
          "Fit Category": v.fit_category_name,
          "WS": v.ws,
          "RTL": v.rtl,
          "BERGEN": v.all_locations.bergen,
          "TRNS": v.all_locations.transit,
          "PK": v.all_locations.pakistan,
          "PROD": v.all_locations.production,
          "PEND": v.all_locations.pending,
          "INT": v.all_locations.international,
          "OTS": v.all_locations.final_ots,
          "=\"\"2/3\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": Number(v.all_locations.ots_qty_1) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_1)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_1)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_1)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_1)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_1)),
          "=\"\"4/5\"\"&CHAR(10)&\"\"S\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"28\"\"&CHAR(10)&\"\"24\"\"": Number(v.all_locations.ots_qty_2) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_2)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_2)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_2)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_2)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_2)),
          "=\"\"6/6X\"\"&CHAR(10)&\"\"M\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"29\"\"&CHAR(10)&\"\"25\"\"": Number(v.all_locations.ots_qty_3) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_3)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_3)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_3)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_3)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_3)),
          "=\"\"-\"\"&CHAR(10)&\"\"L\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"30\"\"&CHAR(10)&\"\"26\"\"": Number(v.all_locations.ots_qty_4) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_4)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_4)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_4)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_4)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_4)),
          "=\"\"-\"\"&CHAR(10)&\"\"XL\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"31\"\"&CHAR(10)&\"\"27\"\"": Number(v.all_locations.ots_qty_5) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_5)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_5)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_5)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_5)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_5)),
          "=\"\"-\"\"&CHAR(10)&\"\"1XL\"\"&CHAR(10)&\"\"6x\"\"&CHAR(10)&\"\"32\"\"&CHAR(10)&\"\"28\"\"": Number(v.all_locations.ots_qty_6) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_6)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_6)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_6)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_6)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_6)),
          "=\"\"-\"\"&CHAR(10)&\"\"2XL\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"33\"\"&CHAR(10)&\"\"29\"\"": Number(v.all_locations.ots_qty_7) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_7)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_7)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_7)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_7)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_7)),
          "=\"\"-\"\"&CHAR(10)&\"\"3XL\"\"&CHAR(10)&\"\"7x\"\"&CHAR(10)&\"\"34\"\"&CHAR(10)&\"\"30\"\"": Number(v.all_locations.ots_qty_8) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_8)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_8)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_8)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_8)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_8)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"35\"\"&CHAR(10)&\"\"31\"\"": Number(v.all_locations.ots_qty_9) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_9)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_9)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_9)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_9)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_9)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"36\"\"&CHAR(10)&\"\"32\"\"": Number(v.all_locations.ots_qty_10) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_10)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_10)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_10)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_10)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_10)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"12\"\"&CHAR(10)&\"\"38\"\"&CHAR(10)&\"\"33\"\"": Number(v.all_locations.ots_qty_11) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_11)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_11)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_11)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_11)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_11)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"14\"\"&CHAR(10)&\"\"40\"\"&CHAR(10)&\"\"34\"\"": Number(v.all_locations.ots_qty_12) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_12)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_12)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_12)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_12)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_12)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"16\"\"&CHAR(10)&\"\"42\"\"&CHAR(10)&\"\"35\"\"": Number(v.all_locations.ots_qty_13) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_13)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_13)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_13)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_13)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_13)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"18\"\"&CHAR(10)&\"\"44\"\"&CHAR(10)&\"\"36\"\"": Number(v.all_locations.ots_qty_14) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_14)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_14)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_14)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_14)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_14)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"20\"\"&CHAR(10)&\"\"46\"\"&CHAR(10)&\"\"37\"\"": Number(v.all_locations.ots_qty_15) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_15)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_15)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_15)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_15)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_15)),
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"48\"\"&CHAR(10)&\"\"38\"\"": Number(v.all_locations.ots_qty_16) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce_qty_16)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord_qty_16)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop_qty_16)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman_qty_16)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_16)),
          "Total": Number(v.all_locations.final_ots) +
            (filters.includes("BERGEN ECOM") &&
              Number(v.all_locations.ecommerce)) +
            (filters.includes("NORD D2C") &&
              Number(v.all_locations.nord)) +
            (filters.includes("VERISHOP D2C") &&
              Number(v.all_locations.verishop)) +
            (filters.includes("Neiman D2C") &&
              Number(v.all_locations.neiman)) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks)),
        };
      })
    )
  }

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getProductType(token, values));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getProductClass(token, menuId));
    dispatch(getSize(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getFitCategory(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getSeasonYear(token, menuId));
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (allSesion) {
      setIsChecked([]);
    }
  }, [allSesion]);

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus]);

  useEffect(() => {
    if (allPC) {
      setIsPCChecked([]);
    }
  }, [allPC]);

  const handlePrint = () => {
    window.print();
  };


  const handleGender = (gender_id) => {
    // dispatch(getGenderCategoryChange(token, gender_id));
    // dispatch(getStyleChange(token, gender_id, null, null));
    form.setFieldsValue({
      gender_category_id: "All",
      style_id: "All",
      sku: "All",
    });
  };

  const handleStyle = (gender_category_id) => {
    // dispatch(getStyleChange(token, form.getFieldValue().gender_id, gender_category_id, null))
    form.setFieldsValue({
      style_id: "All",
      sku: "All",
    });
  };

  const handleSku = (sku_id) => {
    if (sku_id === null) {
      dispatch(getSku(token, null, null, null));
    } else {
      // dispatch(getSku(token, form.getFieldValue().gender_id, form.getFieldValue().gender_category_id, sku_id));
    }
    form.setFieldsValue({
      sku: "All",
    });
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  };

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);


  const bergotal = data.reduce((acc, item, index) => acc + parseFloat(item.all_locations.bergen), 0)
  const trnsTotal = data.reduce((acc, item, index) => acc + parseFloat(item.all_locations.transit), 0)
  const pkTotal = data.reduce((acc, item, index) => acc + parseFloat(item.all_locations.pakistan), 0)
  const prodTotal = data.reduce((acc, item, index) => acc + parseFloat(item.all_locations.production), 0)
  const pendTotal = data.reduce((acc, item, index) => acc + parseFloat(item.all_locations.pending), 0)
  const intTotal = data.reduce((acc, item, index) => acc + parseFloat(item.all_locations.international), 0)
  const otsTotal = data.reduce((acc, item, index) => acc + parseFloat(item.all_locations.final_ots), 0)


  const colums = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text, record) => {
        return <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          to={{
            pathname: `/sku-lookup?sku=${record.sku}`,
            state: data,
          }}
          target="_blank"
        >
          {record.sku}
        </Link>
      },
      sorter: (a, b) => {
        return a.sku - b.sku
      },

    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      sorter: (a, b) => {
        return a.inseam - b.inseam
      },
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
      sorter: (a, b) => {
        if ((a.style_name !== null) & (b.style_name !== null)) {
          return a.style_name.localeCompare(b.style_name);
        }
      }
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
      sorter: (a, b) => {
        if ((a.wash_name !== null) & (b.wash_name !== null)) {
          return a.wash_name.localeCompare(b.wash_name);
        }
      }
    },
    {
      title: "Current Season",
      dataIndex: "current_season",
      key: "current_season",
      sorter: (a, b) => {
        if ((a.current_season !== null) & (b.current_season !== null)) {
          return a.current_season.localeCompare(b.current_season);
        }
      }
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      sorter: (a, b) => {
        if ((a.gender_name !== null) & (b.gender_name !== null)) {
          return a.gender_name.localeCompare(b.gender_name);
        }
      }
    },
    {
      title: "Fit Category",
      dataIndex: "fit_category_name",
      key: "fit_category_name",
      sorter: (a, b) => {
        if ((a.fit_category_name !== null) & (b.fit_category_name !== null)) {
          return a.fit_category_name.localeCompare(b.fit_category_name);
        }
      }
    },
    {
      title: "WS",
      dataIndex: "ws",
      key: "ws",
      align: 'right',
      sorter: (a, b) => {
        return a.sku - b.sku
      },
    },
    {
      title: "RTL",
      dataIndex: "rtl",
      key: "rtl",
      align: 'right',
      sorter: (a, b) => {
        return a.sku - b.sku
      },
    },
    {
      title: "BERGEN",
      dataIndex: "bergen",
      key: "bergen",
      align: 'right',
      render: (text, record) => {
        return record.all_locations.bergen
      },
      sorter: (a, b) => {
        return a.all_locations.bergen - b.all_locations.bergen
      },
    },
    {
      title: "TRNS",
      dataIndex: "transit",
      key: "transit",
      align: 'right',
      render: (text, record) => {
        return record.all_locations.transit
      },
      sorter: (a, b) => {
        return a.all_locations.transit - b.all_locations.transit
      },
    },
    {
      title: "PK",
      dataIndex: "pakistan",
      key: "pakistan",
      align: 'right',
      render: (text, record) => {
        return record.all_locations.pakistan
      },
      sorter: (a, b) => {
        return a.all_locations.pakistan - b.all_locations.pakistan
      },
    },
    {
      title: "PROD",
      dataIndex: "production",
      key: "production",
      align: 'right',
      render: (text, record) => {
        return record.all_locations.production
      },
      sorter: (a, b) => {
        return a.all_locations.production - b.all_locations.production
      },
    },
    {
      title: "PEND",
      dataIndex: "pending",
      key: "pending",
      align: 'right',
      render: (text, record) => {
        return <Link
          style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: `/pending-detail-summary-report`, search: `?sku=${record.sku}` }}
        >
          {record.all_locations.pending}
        </Link>
      },
      sorter: (a, b) => {
        return a.all_locations.pending - b.all_locations.pending
      },
    },
    {
      title: "INT",
      dataIndex: "international",
      key: "international",
      align: 'right',
      render: (text, record) => {
        return record.all_locations.international
      },
      sorter: (a, b) => {
        return a.all_locations.international - b.all_locations.international
      },
    },
    {
      title: "OTS",
      dataIndex: "final_ots",
      key: "final_ots",
      align: 'right',
      render: (text, record) => {
        return record.all_locations.final_ots
      },
      sorter: (a, b) => {
        return a.all_locations.final_ots - b.all_locations.final_ots
      },
    },
    {
      children: [
        {
          title: 'T-Jacket',
          dataIndex: 'building',
          key: 'building',
          width: 60,
          children: [
            {
              title: 'Shirt',
              dataIndex: 'building',
              key: 'building',
              width: 40,
              children: [
                {
                  title: 'W-Plus',
                  dataIndex: 'building',
                  key: 'building',
                  width: 40,
                  children: [
                    {
                      title: 'Kids',
                      dataIndex: 'building',
                      key: 'building',
                      width: 40,
                      children: [
                        {
                          title: 'Men',
                          dataIndex: 'building',
                          key: 'building',
                          width: 40,
                          children: [
                            {
                              title: 'Women',
                              dataIndex: 'building',
                              key: 'building',
                              width: 55,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '"2/3"',
          align: 'right',
          dataIndex: 'qty_1',
          key: 'qty_1',
          width: 55,
          children: [
            {
              title: 'XS',
              dataIndex: 'qty_1',
              key: 'qty_1',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '10W',
                  dataIndex: 'qty_1',
                  key: 'qty_1',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '2',
                      dataIndex: 'qty_1',
                      key: 'qty_1',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '27',
                          dataIndex: 'qty_1',
                          key: 'qty_1',
                          align: 'right',
                          width: 50,
                          children: [
                            {
                              title: '23',
                              dataIndex: 'qty_1',
                              key: 'qty_1',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  // this work is done for size 27 if 27 does not exists in Mens
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ? 0 : 
                                  Number(data.all_locations.ots_qty_1) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_1)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_1)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_1)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_1)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_1))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '"4/5"',
          align: 'right',
          dataIndex: 'qty_2',
          key: 'qty_2',
          width: 50,
          children: [
            {
              title: 'S',
              align: 'right',
              dataIndex: 'qty_2',
              key: 'qty_2',
              width: 55,
              children: [
                {
                  title: '12W',
                  align: 'right',
                  dataIndex: 'qty_2',
                  key: 'qty_2',
                  width: 55,
                  children: [
                    {
                      title: '3',
                      align: 'right',
                      dataIndex: 'qty_2',
                      key: 'qty_2',
                      width: 50,
                      children: [
                        {
                          title: '28',
                          align: 'right',
                          dataIndex: 'qty_2',
                          key: 'qty_2',
                          width: 50,
                          children: [
                            {
                              title: '24',
                              align: 'right',
                              dataIndex: 'qty_2',
                              key: 'qty_2',
                              width: 40,
                              render: (text, data) => {
                                return <>
                                
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ? 
                                  Number(data.all_locations.ots_qty_1) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_1)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_1)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_1)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_1)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_1))
                                  :
                                  
                                  Number(data.all_locations.ots_qty_2) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_2)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_2)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_2)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_2)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_2))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '"6/6X"',
          dataIndex: 'qty_3',
          key: 'qty_3',
          align: 'right',
          width: 60,
          children: [
            {
              title: 'M',
              dataIndex: 'qty_3',
              key: 'qty_3',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '14W',
                  dataIndex: 'qty_3',
                  key: 'qty_3',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '4',
                      dataIndex: 'qty_3',
                      key: 'qty_3',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '29',
                          dataIndex: 'qty_3',
                          key: 'qty_3',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '25',
                              dataIndex: 'qty_3',
                              key: 'qty_3',
                              width: 45,
                              align: 'right',
                              render: (text, data) => {
                                return <>
{
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ? Number(data.all_locations.ots_qty_2) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(data.all_locations.ecommerce_qty_2)) +
                                  (filters.includes("NORD D2C") &&
                                    Number(data.all_locations.nord_qty_2)) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(data.all_locations.verishop_qty_2)) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(data.all_locations.neiman_qty_2)) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_2))
                                  :
                                  
                                  Number(data.all_locations.ots_qty_3) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_3)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_3)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_3)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_3)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_3))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_4',
          key: 'qty_4',
          width: 50,
          align: 'right',
          children: [
            {
              title: 'L',
              dataIndex: 'qty_4',
              key: 'qty_4',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '16W',
                  dataIndex: 'qty_4',
                  key: 'qty_4',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '5',
                      dataIndex: 'qty_4',
                      key: 'qty_4',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '30',
                          dataIndex: 'qty_4',
                          key: 'qty_4',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '26',
                              dataIndex: 'qty_4',
                              key: 'qty_4',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
{
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_3) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_3)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_3)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_3)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_3)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_3))
                                  :
                                  Number(data.all_locations.ots_qty_4) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_4)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_4)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_4)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_4)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_4))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_5',
          key: 'qty_5',
          width: 50,
          align: 'right',
          children: [
            {
              title: 'XL',
              dataIndex: 'qty_5',
              key: 'qty_5',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '18W',
                  dataIndex: 'qty_5',
                  key: 'qty_5',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '6',
                      dataIndex: 'qty_5',
                      key: 'qty_5',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '31',
                          dataIndex: 'qty_5',
                          key: 'qty_5',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '27',
                              dataIndex: 'qty_5',
                              key: 'qty_5',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_4) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_4)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_4)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_4)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_4)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_4))
                                  :
                                  Number(data.all_locations.ots_qty_5) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_5)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_5)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_5)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_5)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_5))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_6',
          key: 'qty_6',
          width: 50,
          align: 'right',

          children: [
            {
              title: '1XL',
              dataIndex: 'qty_6',
              key: 'qty_6',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '20W',
                  dataIndex: 'qty_6',
                  key: 'qty_6',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '6x',
                      dataIndex: 'qty_6',
                      key: 'qty_6',
                      width: 50,
                      align: 'right',

                      children: [
                        {
                          title: '32',
                          dataIndex: 'qty_6',
                          key: 'qty_6',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '28',
                              dataIndex: 'qty_6',
                              key: 'qty_6',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_5) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_5)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_5)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_5)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_5)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_5))
                                  :
                                  Number(data.all_locations.ots_qty_6) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_6)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_6)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_6)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_6)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_6))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_7',
          key: 'qty_7',
          width: 50,
          align: 'right',

          children: [
            {
              title: '2XL',
              dataIndex: 'qty_7',
              key: 'qty_7',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '22W',
                  dataIndex: 'qty_7',
                  key: 'qty_7',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '7',
                      dataIndex: 'qty_7',
                      key: 'qty_7',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '33',
                          dataIndex: 'qty_7',
                          key: 'qty_7',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '29',
                              dataIndex: 'qty_7',
                              key: 'qty_7',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_6) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_6)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_6)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_6)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_6)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_6))
                                  :
                                  Number(data.all_locations.ots_qty_7) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_7)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_7)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_7)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_7)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_7))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_8',
          key: 'qty_8',
          width: 50,
          align: 'right',

          children: [
            {
              title: '3XL',
              dataIndex: 'qty_8',
              key: 'qty_8',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '24W',
                  dataIndex: 'qty_8',
                  key: 'qty_8',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '7x',
                      dataIndex: 'qty_8',
                      key: 'qty_8',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '34',
                          dataIndex: 'qty_8',
                          key: 'qty_8',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '30',
                              dataIndex: 'qty_8',
                              key: 'qty_8',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_7) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_7)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_7)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_7)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_7)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_7))
                                  :
                                  
                                  Number(data.all_locations.ots_qty_8) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_8)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_8)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_8)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_8)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_8))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_9',
          key: 'qty_9',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_9',
              key: 'qty_9',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '26W',
                  dataIndex: 'qty_9',
                  key: 'qty_9',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '8',
                      dataIndex: 'qty_9',
                      key: 'qty_9',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '35',
                          dataIndex: 'qty_9',
                          key: 'qty_9',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '31',
                              dataIndex: 'qty_9',
                              key: 'qty_9',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_8) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_8)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_8)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_8)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_8)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_8))
                                  :
                                  Number(data.all_locations.ots_qty_9) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_9)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_9)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_9)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_9)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_9))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_10',
          key: 'qty_10',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_10',
              key: 'qty_10',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '28W',
                  dataIndex: 'qty_10',
                  key: 'qty_10',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '10',
                      dataIndex: 'qty_10',
                      key: 'qty_10',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '36',
                          dataIndex: 'qty_10',
                          key: 'qty_10',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '32',
                              dataIndex: 'qty_10',
                              key: 'qty_10',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_9) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_9)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_9)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_9)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_9)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_9))
                                  :
                                  Number(data.all_locations.ots_qty_10) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_10)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_10)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_10)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_10)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_10))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_11',
          key: 'qty_11',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_11',
              key: 'qty_11',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '30W',
                  dataIndex: 'qty_11',
                  key: 'qty_11',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '12',
                      dataIndex: 'qty_11',
                      key: 'qty_11',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '38',
                          dataIndex: 'qty_11',
                          key: 'qty_11',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '33',
                              dataIndex: 'qty_11',
                              key: 'qty_11',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_10) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_10)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_10)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_10)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_10)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_10))
                                  :
                                  Number(data.all_locations.ots_qty_11) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_11)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_11)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_11)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_11)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_11))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_12',
          key: 'qty_12',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_12',
              key: 'qty_12',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '32W',
                  dataIndex: 'qty_12',
                  key: 'qty_12',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '14',
                      dataIndex: 'qty_12',
                      key: 'qty_12',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '40',
                          dataIndex: 'qty_12',
                          key: 'qty_12',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '34',
                              dataIndex: 'qty_12',
                              key: 'qty_12',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_11) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_11)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_11)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_11)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_11)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_11))
                                  :
                                  Number(data.all_locations.ots_qty_12) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_12)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_12)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_12)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_12)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_12))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_13',
          key: 'qty_13',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_13',
              key: 'qty_13',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_13',
                  key: 'qty_13',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '16',
                      dataIndex: 'qty_13',
                      key: 'qty_13',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '42',
                          dataIndex: 'qty_13',
                          key: 'qty_13',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '35',
                              dataIndex: 'qty_13',
                              key: 'qty_13',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                   {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ? 
                                  Number(data.all_locations.ots_qty_12) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_12)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_12)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_12)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_12)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_12))
                                  :
                                  Number(data.all_locations.ots_qty_13) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_13)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_13)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_13)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_13)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_13))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_14',
          key: 'qty_14',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_14',
              key: 'qty_14',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_14',
                  key: 'qty_14',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '18',
                      dataIndex: 'qty_14',
                      key: 'qty_14',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '44',
                          dataIndex: 'qty_14',
                          key: 'qty_14',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '36',
                              dataIndex: 'qty_14',
                              key: 'qty_14',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                 {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_13) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_13)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_13)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_13)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_13)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_13))
                                  :
                                  Number(data.all_locations.ots_qty_14) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_14)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_14)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_14)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_14)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_14))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_15',
          key: 'qty_15',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_15',
              key: 'qty_15',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_15',
                  key: 'qty_15',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '20',
                      dataIndex: 'qty_15',
                      key: 'qty_15',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '46',
                          dataIndex: 'qty_15',
                          key: 'qty_15',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '37',
                              dataIndex: 'qty_15',
                              key: 'qty_15',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_14) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_14)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_14)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_14)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_14)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_14))
                                  :
                                  Number(data.all_locations.ots_qty_15) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_15)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_15)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_15)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_15)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_15))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_16',
          key: 'qty_16',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_16',
              key: 'qty_16',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_16',
                  key: 'qty_16',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '-',
                      dataIndex: 'qty_16',
                      key: 'qty_16',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '48',
                          dataIndex: 'qty_16',
                          key: 'qty_16',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '38',
                              dataIndex: 'qty_16',
                              key: 'qty_16',
                              width: 40,
                              align: 'right',
                              render: (text, data) => {
                                return <>
                                  {
                                  (data.gender_name == "Men" && data.all_locations.size_1 == "28") ?
                                  Number(data.all_locations.ots_qty_15) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_15)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_15)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_15)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_15)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_15))
                                  :
                                  Number(data.all_locations.ots_qty_16) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(data.all_locations.ecommerce_qty_16)) +
                                    (filters.includes("NORD D2C") &&
                                      Number(data.all_locations.nord_qty_16)) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(data.all_locations.verishop_qty_16)) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(data.all_locations.neiman_qty_16)) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_16))}
                                </>
                              }
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
      ],
    },
    {
      title: "Total",
      align: 'right',
      render: (text, record) => {
        return <> {Number(record.all_locations.final_ots) +
        // return <> {Number(record.all_locations.ots) +
          (filters.includes("BERGEN ECOM") &&
            Number(record.all_locations.ecommerce)) +
          (filters.includes("NORD D2C") &&
            Number(record.all_locations.nord)) +
          (filters.includes("VERISHOP D2C") &&
            Number(record.all_locations.verishop)) +
          (filters.includes("Neiman D2C") &&
            Number(record.all_locations.neiman)) +
          (filters.includes("Saks D2C") &&
            Number(record.all_locations.saks))}
        </>
      },
      sorter: (a, b) => {
        // const sumA = Number(a.all_locations.ots) +
        const sumA = Number(a.all_locations.final_ots) +
          (filters.includes("BERGEN ECOM") &&
            Number(a.all_locations.ecommerce)) +
          (filters.includes("NORD D2C") &&
            Number(a.all_locations.nord)) +
          (filters.includes("VERISHOP D2C") &&
            Number(a.all_locations.verishop)) +
          (filters.includes("Neiman D2C") &&
            Number(a.all_locations.neiman)) +
          (filters.includes("Saks D2C") &&
            Number(a.all_locations.saks))

        // const sumB = Number(b.all_locations.ots) +
        const sumB = Number(b.all_locations.final_ots) +
          (filters.includes("BERGEN ECOM") &&
            Number(b.all_locations.ecommerce)) +
          (filters.includes("NORD D2C") &&
            Number(b.all_locations.nord)) +
          (filters.includes("VERISHOP D2C") &&
            Number(b.all_locations.verishop)) +
          (filters.includes("Neiman D2C") &&
            Number(b.all_locations.neiman)) +
          (filters.includes("Saks D2C") &&
            Number(b.all_locations.saks));
        return sumA - sumB
      },
    },
  ];

  return (
    <div className="header_class business_header_oms">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        {/* <div className="col-sm-6 box-space pl-sm-0">
                    <div className="brand-para">
                        <div>DL1961 Premium Denim Inc.</div>
                        <div>121 Varick Street</div>
                        <div>4th Floor</div>
                        <div>New York, NY 10013</div>
                        <div>(P) 646-514-9736</div>
                        <div>(F) 212-991-9989</div>
                        <div>(E) sales@DL1961.com</div>
                    </div>
                </div> */}
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Total-OTS.csv"}
                  data={excelData}
                  onClick={() => { }}
                  style={{ display: "flex" }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    height={14}
                  // onClick={handleExcelExport}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Total OTS
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              {/* <hr className="mt-0 mb-2" />
              <div>
                <label htmlFor="">Classification</label>
                <div className="checkboxes">
                  <div className="PC_Chekbox">
                    <Checkbox
                      size="large"
                      checked={allPC}
                      onChange={PCSelectAll}
                    >
                      All
                    </Checkbox>
                  </div>
                  {productClass &&
                    productClass?.payload?.data.map((pcls, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="product_class_id">
                            <Checkbox
                              size="large"
                              checked={allPC ? false : isPCChecked.includes(pcls.product_class_id)}
                              onChange={(e) =>
                                isPCBoxChecked(
                                  pcls.product_class_id,
                                  e.target.checked
                                )
                              }
                            >
                              {pcls.product_class_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={
                                allStatus
                                  ? false
                                  : isChecked2.includes(it.product_status_id)
                              }
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr className="my-1" />
              <div>
                <label htmlFor="">Locations</label>
                <div className="checkboxes">
                  {/* <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div> */}
                  {allFilters &&
                    allFilters.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              // checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                              onChange={(e) => isFilter(it, e.target.checked)}
                            >
                              {it}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <hr className="my-1" />
              <div>
                <label>Steps</label>
                <div className="checkboxes">
                  {getStepData &&
                    getStepData.map((step, i) => {
                      return (
                        <div className="checkbox_content">
                          <Checkbox
                            checked={steps[i]?.selected}
                            value={12}
                            onChange={(e) => {
                              setSteps(
                                steps.map((it) => {
                                  if (it.location_id == step.location_id) {
                                    return {
                                      ...it,
                                      selected: e.target.checked,
                                    };
                                  } else {
                                    return it;
                                  }
                                })
                              );
                            }}
                          >
                            {step.location_name}
                          </Checkbox>
                        </div>
                      );
                    })}
                  <Checkbox
                    checked={pendingChecked}
                    onChange={handlePendingOrder}
                  >
                    Pending Order
                  </Checkbox>
                </div>
              </div> */}
              {/* <hr className="my-1" />
              <div>
                <label htmlFor="">Season</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_season_id" className=" mb-0">
                      <Checkbox checked={allSesion} onChange={sesionSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {season &&
                    season?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_season_id">
                            <Checkbox
                              checked={allSesion ? false : isChecked.includes(it.season_id)}
                              onChange={(e) =>
                                isCheckboxChecked(
                                  it.season_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.season_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Gender
                    </label>
                    <div className="w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Gender"
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleGender(e)}
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_name}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Season
                    </label>
                    <div className=" w-100">
                      <Form.Item name="season_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().season_name}
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Product Status"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map((status, index) => {
                              return (
                                <Option key={index} value={status.season_name}>
                                  {status.season_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  {/* <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season Year</label>
                    <div className="w-100">
                      <Form.Item name="new_season_year_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          defaultValue={form.getFieldValue().new_season_year_id}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={"All"}>All</Option>
                          {seasonYear?.payload?.data?.map((res) => <Option key={res.season_year_id} value={res.season_year_id}>{res.season_year}</Option>
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div> */}
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Wash
                    </label>
                    <div className="w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_name}
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Product Status"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map((status, index) => {
                              return (
                                <Option key={index} value={status.wash_name}>
                                  {status.wash_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Category
                    </label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="gender_category_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Product Category"
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleStyle(e)}
                        // filterOption={(input, option) =>
                        //   option.children
                        //     .toLowerCase()
                        //     .includes(input.toLowerCase())
                        // }
                        >
                          <Option value={null}>All</Option>
                          {genderCategoryChange &&
                            genderCategoryChange?.payload?.data.length >= 0
                            ? genderCategoryChange &&
                            genderCategoryChange?.payload?.data.map(
                              (it, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={it.gender_category_name}
                                  >
                                    {it.gender_category_name}
                                  </Option>
                                );
                              }
                            )
                            : genderCategory &&
                            genderCategory?.payload?.data.map((it, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={it.gender_category_name}
                                >
                                  {it.gender_category_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Sku
                    </label>
                    <div className="w-100">
                      <Form.Item name="sku" initialValue={"All"}>
                        <Select
                          notFoundContent={
                            Sku.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loader
                                  type="Oval"
                                  color="#3c4b58"
                                  height={20}
                                  width={20}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                No Data
                              </div>
                            )
                          }
                          className={"select_Inputs"}
                          showSearch
                          onSearch={(value) => {
                            optimizedFn(value);
                          }}
                          // placeholder="Select Gender"
                          optionFilterProp="children"
                          size="small"
                        >
                          <Option value={null}>All</Option>
                          {Sku &&
                            Sku?.payload?.data.map((it, index) => {
                              return (
                                // <Option value="ABC">ABC</Option>
                                <Option key={index} value={it.sku}>
                                  {it.sku}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Style
                    </label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="style_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select a person"
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleSku(e)}
                        // filterOption={(input, option) =>
                        //   option.children
                        //     .toLowerCase()
                        //     .includes(input.toLowerCase())
                        // }
                        >
                          <Option value={null}>All</Option>
                          {styleChange && styleChange?.payload?.data.length >= 0
                            ? styleChange &&
                            styleChange?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_name}>
                                  {style.style_name}
                                </Option>
                              );
                            })
                            : style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_name}>
                                  {style.style_name}
                                </Option>
                              );
                            })}
                          {/* {style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_id}>
                                  {style.style_name}
                                </Option>
                              );
                            })} */}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  {/* <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Wash</label>
                    <div className="w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_name}
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Product Status"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={status.wash_name}
                                  >
                                    {status.wash_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div> */}
                </div>
                <div className="col-12 col-md-3">
                  {/* <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Pending Till</label>
                    <div className="w-100">
                      <Form.Item
                        name="as_on_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          onChange={onChangeDate}
                          format={dateFormatList}

                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "3px",
                      float: "right",
                    }}
                  >
                    {/* <label className=" no_visibility">none</label> */}
                    <Button
                      style={{ width: "fit-content" }}
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div>
        <div
          className="overflow"
          style={{
            overflowY: "scroll",
            height: "57vh",
            // borderBottom: "1px solid black"
          }}
        >
          {skuLookUpData?.payload?.data !== undefined ? (
            data.length > 0 ? (
              <Table
              className="// maxWidth test04 mt-3 sales_report_res_table_intransit immedidate_ots_3 immedidate_ots_baseline"
              style={{ maxWidth: "1680px", fontSize: "9px" }}
              ref={tableRef}
              size="small"
              bordered={false}
              type="company"
              headerClassName='abc'
              dataSource={data}
              columns={colums}
              loading={skuLookUpData.loading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 500,
                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
              }}
              scroll={{ x: 'max-content' }}
              summary={(pageData) => {
                let Quantity = pageData.reduce(function (
                  previousValue,
                  currentValue
                ) {
                  return (previousValue += parseFloat(
                    currentValue.sum_quantity
                  ));
                },
                  0);
                return (
                  <>
                    <Table.Summary.Row>
                      {/* <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                    </Table.Summary.Cell> */}
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell><p style={{ textAlign: "right" }} className="totalquantity">
                        Total :
                      </p>  </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          {bergotal.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          {trnsTotal.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          {pkTotal.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          {prodTotal.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          {pendTotal.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          {intTotal.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          {otsTotal.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
            ) : (
              <div className="no-Data">
                {/* {console.log(omsStockSummary2?.payload?.data.length, 'omsStockSummary?.payload?.data.length')} */}
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalOts;
