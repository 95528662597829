import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { getItemTransferFormData } from "../../redux/actions/itemTransfer.action";
import CrudButtons from "./components/CrudButtons";
import DepositForm from "./components/Form/DepositForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import {
  deleteDepositHeader,
  getOneDepositHeader,
} from "../../redux/actions/depositHeader.action";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";

export default function Deposit({ token }) {
  TabTitle("Deposit")
  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const { log, depositHeader } = useSelector((state) => state);
  const queryParams = new URLSearchParams(window.location.search);
  const [formState, setFormState] = useState("view");
  const dCompanyId = ReadCookie("defaultCompany");
  const [minimize, setMinimize] = useState(false);
  const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [flag, setFlag] = useState(false);
  const mode = queryParams.get("mode");
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [userRights, setUserRights] = useState({})


  useLayoutEffect(() => {
    dispatch(getRights(token, 1103)).then((res) => {
      console.log(res?.payload)
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        setUserRights(res?.payload)
      }
    });
  }, [token])

  const filterReset = () => {
    setFormState("view");
  };

  const handleDelete = () => {
    dispatch(deleteDepositHeader(token, id)).then((res) => {
      if (res.type === "DELETE_DEPOSIT_HEADER_SUCCESS") {
        notification("success", "Deposit Deleted", res.payload);
        setFlag(true);
        history.push("/deposit/list");
      }
    });
  };

  const changeState = () => {
    // 
    setFormState("formView");
  };

  const formSave = () => {
    setSubmit(true);
  };

  useEffect(() => {
    if (mode === "add") {
      setFormState("add");
    }
  }, [mode]);

  // useEffect(() => {
  // 	dispatch(getItemTransferFormData(token));
  // }, []);

  useEffect(() => {
    if (id) {
      setFormState("formView");
      dispatch(getOneDepositHeader(token, id));
    }
  }, [id]);
  // 

  return (
    <>
      <div className="styleContainer">
        <div className="styleBody h-100">
          <PageHeader
            title={"Deposit"}
            minimize={setMinimize}
            check={minimize}
          />
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                id={id}
                name={"saleOrder"}
                reset={filterReset}
                userRights={userRights}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
                depositId={id}
                formData={depositHeader?.payload?.data}
              />

              <div className="mainProductContainer">
                <DepositForm
                  dCompany={dCompanyId}
                  defaultCompany={companyId}
                  companyId={setCompanyId}
                  submit={setSubmit}
                  val={submit}
                  //formData={formState === "formView" || formState === "edit" ? depositHeader?.payload?.data[0] : null}
                  formState={formState}
                  changeState={changeState}
                  token={token}
                  checkFlag={flag}
                  setFlag={setFlag}
                  logs={log}
                  depositId={id}
                  disable={setDisable}
                  formData={depositHeader?.payload?.data}
                />
              </div>
            </div>
          </div>
        </div>
        {minimize && (
          <div className="styleFooter">
            {" "}
            <Button
              className="minimize-button-style"
              onClick={() => setMinimize(false)}
            >
              {" "}
              Customer Refund{" "}
            </Button>{" "}
          </div>
        )}
      </div>
    </>
  );
}
