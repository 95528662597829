import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import { getProductForm, getRelatedTransactions } from "../../redux/actions/productFrom.action"
import { getAllItem, getOneItem, deleteItem } from "../../redux/actions/item.action"
import { getCombination } from '../../redux/actions/generateCombination.action';
import { updateItem } from '../../redux/actions/item.action';
import { getLog } from "../../redux/actions/log.action"
import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "./CrudButtons";
import SubTitle from "../../components/SubTitle/SubTitle";
import Tables from '../../components/Table/Tables';
import notification from "../../utils/notification"
import { logColumns } from "../../utils/cols"
import { ReadCookie } from "../../utils/readCookie"
import Loader from "react-loader-spinner";
import ProductPrimaryForm from '../../components/Form/ProductPrimaryForm';
import GenerateForm from '../../components/Form/GenerateForm';
import { Button, Tabs, Modal, Input, Form, Row, Col, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import "../Style/style.css";
import "./product.css";
import { getProductType } from '../../redux/actions/productType.action';
import { TabTitle } from '../../utils/GeneralFunction';
import { getSeasonYear } from '../../redux/actions/seasonYear.action';
import { getItemType } from '../../redux/actions/itemType.action';

const { TabPane } = Tabs;

export default function ProductInfoNew({ token }) {
    TabTitle("Product-Information")
    const history = useHistory();
    const menuId = ReadCookie('menuId');
    const dCompanyId = ReadCookie('defaultCompany')
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { productForm, itemType, item, log, userRights, styleCombination, seasonYear, inseam } = useSelector(state => state);
    const [companyId, setCompanyId] = useState(ReadCookie('defaultCompany'));
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [disable, setDisable] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [col, setCol] = useState([]);
    const [itemId, setItemId] = useState(null);
    const [dCol, setDCol] = useState([]);

    const [visible, setVisible] = useState(false);
    const [inseamIds, setInseamIds] = useState([])
    const [sizeIds, setSizeIds] = useState([])
    const [sku, setSku] = useState(null)
    const [randomData, setRandomData] = useState([])
    const [demoData, setDemoData] = useState(false);
    const [flag, setFlag] = useState(true);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [hasSize, setHasSize] = useState([])
    const [hasInseam, setHasInseam] = useState([])
    const [newSize, setNewSize] = useState([])
    const [newInseam, setNewInseam] = useState([])
    const [relatedTrans, setRelatedTrans] = useState([]);
    const [size, setSize] = useState();
    const [genderCategory, setGenderCategory] = useState(null);
    const [newID, setNewID] = useState(null);
    const [inseams, setInseams] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [arrSizes, setArrSizes] = useState([])
    const [arr, setArr] = useState([])
    const [sizePayload, setSizePayload] = useState([])

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let productID = query.get("product_id");

    useEffect(() => {
        dispatch(getProductForm(token, menuId, companyId))
        dispatch(getProductType(token, menuId))
        dispatch(getSeasonYear(token, menuId))
        dispatch(getSeasonYear(token, menuId))
        dispatch(getItemType(token, menuId));

    }, [])

    const productCombination = [
        { title: "Item Code", dataIndex: "item_code", key: "item_code" },
        { title: "Inseam", dataIndex: "inseam", key: "inseam", sorter: { compare: (a, b) => a.inseam - b.inseam, multiple: 1, } },
        { title: "Size", dataIndex: "size", key: "size", sorter: { compare: (a, b) => a.size - b.size, multiple: 1, } },
        { title: "UPC Code", dataIndex: "upc_code", key: "upc_code" },
        { title: "NRF Color Code", dataIndex: "nrf_color_code", key: "nrf_color_code" },
        { title: "NRF Size Code", dataIndex: "nrf_size_code", key: "nrf_size_code", },
        { title: "Status", dataIndex: "is_active", key: "is_active", render: (text, record) => (record.is_active ? "Active" : "Inactive") },
        {
            title: 'Operation', key: 'operation',
            render: (text, record) => (
                <>
                    {userRights?.payload?.can_edit !== false &&
                        <Tooltip title="Edit" color="#fbfbfb">
                            <EditOutlined className='edit-Button' onClick={() => hanldeSubItems(record)} />
                        </Tooltip>}
                    {userRights?.payload?.can_delete !== false &&
                        <Popconfirm placement="top" title={"Are you sure to delete this?"} onConfirm={() => handleDeleteSubitem(record)} okText="Yes" cancelText="No">
                            <Tooltip placement='bottom' title="Delete" color="#fbfbfb">
                                <DeleteOutlined className='delete-Button' />
                            </Tooltip>
                        </Popconfirm>}
                </>
            ),
        },
    ];

    const relatedTransactions = [
        { title: "Transaction No", dataIndex: "transaction_no", key: "transaction_no" },
        { title: "Transaction Type", dataIndex: "transaction_type", key: "transaction_type" },
        { title: "Transaction Date", dataIndex: "transaction_date", key: "transaction_date" },
        { title: "Customer", dataIndex: "customer_name", key: "customer_name" },
        { title: "Term ", dataIndex: "term_name", key: "term_name", },
    ];

    const hanldeSubItems = (record) => {
        setDemoData(record)
        form.setFieldsValue({
            itemCode: record.item_id,
            upcCode: record.upc_code,
            nrfColorCode: record.nrf_color_code,
            nrfSizeCode: record.nrf_size_code,
        })
        setVisible(true)
    }

    const handleDeleteSubitem = (record) => {
        let array = [...randomData];
        let arrayInseam = [...inseamIds];
        let arraySize = [...sizeIds]

        let index = array.findIndex(i => {
            return i.item_code === record.item_code
        })
        array.splice(index, 1);

        let indexInseam = arrayInseam.findIndex(i => {
            return i === record.inseam
        })
        let indexSize = arraySize.findIndex(i => {
            return i === record.size
        })

        let deleteNowInseam = array.find(f => {
            return f.inseam === arrayInseam[indexInseam]
        })
        let deleteNowSize = array.find(f => {
            return f.size === arraySize[indexSize]
        })

        if (deleteNowInseam === undefined) {
            arrayInseam.splice(indexInseam, 1);
            setInseamIds(arrayInseam)
        }
        if (deleteNowSize === undefined) {
            arraySize.splice(indexSize, 1);
            setSizeIds(arraySize)
        }
        setRandomData(array)
        if (!flag) {
            dispatch(deleteItem(token, record.item_id)).then((res) => {
                dispatch(getCombination(token, itemId))
                setUpdateFlag(!updateFlag)
            })
        }
    }

    const handleDelete = () => {
        dispatch(deleteItem(token, itemId)).then((res) => {
            if (res.type === "DELETE_ITEM_SUCCESS") {
                notification('success', 'Item', res?.payload?.data)
                filterReset()
                dispatch(getAllItem(token, "Y")).then(res => {
                    if (res.type === "GET_ALL_ITEM_SUCCESS") {
                        setCol(Object.keys(res.payload?.data?.[0]))
                    }
                })
                // dispatch(getProductForm(token, menuId, dCompanyId))
            }
        })
    }

    const filterReset = () => {
        history.push({ pathname: '/product-info' })
        setFormState("view")
        setRandomData([]);
        setSizeIds([])
        setFlag(false);
        setItemId(null);
    }

    const formSave = () => {
        setSubmit(true);
    }

    useEffect(() => {
        if (productID) {
            setFormState("formView")
            dispatch(getOneItem(token, productID)).then((res) => {
                setNewSize(res.payload.size)
                setNewInseam(res.payload.inseam)
            })
            dispatch(getCombination(token, productID)).then((res) => {
                if (res.type === "GET_COMBINATION_SUCCESS") {
                    console.log(res?.payload?.data, 'res?.payload?.data');
                    res?.payload?.data.sort(function (a, b) {
                        return a.inseam - b.inseam;
                    });
                    res?.payload?.data.sort((a, b) => a.item_code.localeCompare(b.item_code));
                    setRandomData(res?.payload?.data)
                    let inseamArr = [];
                    let sizeArr = [];
                    let unique = res?.payload?.data.filter((item, i, ar) => {
                        inseamArr.push(item.inseam_id)
                        sizeArr.push(item.size_id)
                    });

                    setHasInseam([... new Set(inseamArr)])
                    setHasSize([... new Set(sizeArr)])
                }
            })
            setDCol([])
            setDisable(false)
        } else {
            setDisable(true);
            setDCol([])
        }
    }, [productID])

    const handleTab = (key) => {
        if (key === "logs") {
            dispatch(getLog(token, { inv_product_type: "inv_product_type" }))
        }
        else if (key === "relatedItems") {
            dispatch(getRelatedTransactions(token, itemId)).then((res) => {
                setRelatedTrans(res?.payload?.data)
            })
        }
    }

    const handleOk = async (e) => {
        form.setFieldsValue({ [e.target.name]: e.target.value });
        await form.validateFields().then((values) => {
            let demoArray = [...randomData]
            demoArray.find(f => {
                if (f.item_code === demoData.item_code) {
                    f.upc_code = values.upcCode;
                    f.nrf_color_code = values.nrfColorCode;
                    f.nrf_size_code = values.nrfSizeCode;
                }
            })
            if (!flag) {
                dispatch(updateItem(token, values, demoData.item_id, "subItem")).then((res) => {
                    dispatch(getCombination(token, itemId))
                    setUpdateFlag(!updateFlag)
                })
            }


        });
        setVisible(false);
        form.setFieldsValue({
            // itemCode: null,
            upcCode: null,
            nrfColorCode: null,
            nrfSizeCode: null
        })
    };

    const handleCancel = () => {
        setVisible(false);
        form.setFieldsValue({
            itemCode: null,
            upcCode: null,
            nrfColorCode: null,
            nrfSizeCode: null
        })
    };

    useEffect(() => {
        // if (formState === "add") {
        let inseamPayload = inseam?.payload?.data
        console.log(inseamIds, 'test12');
        let combination = inseamIds?.map(inseam => {
            let inseamName = inseam.split(/[- ]+/).pop();
            let inseamID = inseam.split("-");
            sizeIds?.map(size => {
                let sizeName = size.split(/[- ]+/).pop();
                let sizeID = size.split("-");
                if (sku !== undefined) {
                    let findData = randomData?.filter(rD => {
                        return rD.item_code === `${sku}-${inseamName}-${sizeName}`
                    });
                    // if(sku ==! )
                    if (findData.length <= 0) {
                        console.log('hello hello', sizeID[0], sizePayload, sizePayload?.payload?.data.find(items => items.size_name === sizeID[0]).size_id);
                        setRandomData((item) => [{
                            item_code: `${sku}-${inseamName}-${sizeName}`,
                            // inseam_id: parseInt(inseamID[0]),
                            inseam_id: (inseamPayload.find(items => items.inseam_name === inseamID[0]) || {}).inseam_id,
                            size_id: (sizePayload?.payload?.data.find(items => items.size_name === sizeID[0]) || {}).size_id,
                            // size_id: parseInt(sizeID[0]),
                            inseam: inseamName,
                            size: sizeName,
                            upc_code: null,
                            nrf_color_code: null,
                            nrf_size_code: null
                        }, ...item])
                    }
                }
            })
        })

    }, [inseamIds, sizeIds, formState, sku])

    // useEffect(() => {
    //     if (!flag) {
    //         setInseamIds([])
    //         setSizeIds([])
    //     }
    // }, [flag])

    useEffect(() => {
        if (formState === "add") {
            setRandomData([])
            setInseamIds([])
            setSizeIds([])
            setArrSizes([])
            setArr([])
        }
    }, [formState])
    console.log("🚀 ~ file: ProductInfoNew.js:347 ~ useEffect ~ INseamI:", inseamIds)
    console.log("🚀 ~ file: ProductInfoNew.js:347 ~ useEffect ~ SizeIDS:", sizeIds)
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100' >
                <PageHeader userRights={userRights?.payload} title={"Product Information"} minimize={setMinimize} check={minimize} type={formState} />
                {!minimize &&
                    <div className='bodyStyle'>
                        {formState !== "table" ?
                            <>
                                {(productForm.loading) || (item && item?.loading) || (styleCombination && styleCombination.loading) ?
                                    <div className='loaderDiv' >
                                        <Loader
                                            type="Grid"
                                            color="#212121"
                                            height={40}
                                            width={40}
                                        />
                                    </div> :
                                    null}
                            </> : null}
                        <div className='crudDiv' >
                            <CrudButtons
                                name={"product"}
                                userRights={userRights?.payload}
                                reset={filterReset}
                                flag={flag}
                                formType={formState}
                                save={formSave}
                                disable={disable}
                                formState={setFormState}
                                onDelete={handleDelete} />
                            <div className='mainProductContainer' style={formState === "table" ? { height: "83vh", overflowY: "hidden" } : {}}  >
                                <>
                                    <SubTitle name={"Primary Information"} />
                                    <ProductPrimaryForm
                                        setNewID={setNewID}
                                        newID={newID}
                                        dCompany={dCompanyId}
                                        formState={formState}
                                        changeState={setFormState}
                                        defaultCompany={companyId}
                                        companyId={setCompanyId}
                                        submit={setSubmit}
                                        val={submit}
                                        token={token}
                                        formData={(formState === "formView" || formState === "edit" || formState === "view") ? item?.payload : null}
                                        data={productForm}
                                        itemType={itemType}
                                        sku={setSku}
                                        valSKU={sku}
                                        randomData={randomData}
                                        combinationData={setRandomData}
                                        flagSet={setFlag}
                                        itemId={productID}
                                        size={size}
                                        setSize={setSize}
                                        genderCategory={genderCategory}
                                        setGenderCategory={setGenderCategory}
                                        newSize={newSize}
                                        newInseam={newInseam}
                                        inseams={inseams}
                                        setInseams={setInseams}
                                        sizes={sizes}
                                        setSizes={setSizes}
                                        seasonYear={seasonYear}
                                        productID={productID}
                                        //
                                        arrSizes={arrSizes}
                                        setArrSizes={setArrSizes}
                                        arr={arr}
                                        setArr={setArr}

                                    />
                                    <SubTitle name={"Inseam & Size"} />

                                    <GenerateForm
                                        size={size}
                                        genderCategory={genderCategory}
                                        setGenderCategory={setGenderCategory}
                                        setSize={setSize}
                                        userRights={userRights?.payload}
                                        flag={setFlag}
                                        hasInseam={hasInseam}
                                        hasSize={hasSize}
                                        sku={sku}
                                        type={formState}
                                        token={token}
                                        sizeIds={setSizeIds}
                                        inseamIds={setInseamIds}
                                        itemId={itemId}
                                        newSize={newSize}
                                        newInseam={newInseam}
                                        inseams={inseams}
                                        setInseams={setInseams}
                                        sizes={sizes}
                                        setSizes={setSizes}
                                        arrSizes={arrSizes}
                                        setArrSizes={setArrSizes}
                                        arr={arr}
                                        setArr={setArr}
                                        setSizePayload={setSizePayload}
                                    />
                                    <SubTitle name={"Combination & Logs"} />
                                    <div className='productTabs p-10-all'>
                                        <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                            <TabPane tab="Combination" key="1">
                                                <div className='tabs' >
                                                    <Tables
                                                        scrollX={"50rem"}
                                                        type="combination"
                                                        scroll={250}
                                                        perPage={10}
                                                        source={randomData.sort()}
                                                        col={productCombination}
                                                        paginate />
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Related Transactions" key="relatedItems" disabled={formState === "add"}>
                                                <div className='tabs' >
                                                    <Tables scrollX={"50rem"} type="relatedItems" scroll={250} perPage={10} source={relatedTrans} col={relatedTransactions} paginate />
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Log Info" key="logs"  >
                                                <div className='tabs' >
                                                    <Tables scrollX={"50rem"} type="logs" scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>

                                </>
                            </div>

                        </div>
                    </div>
                }
            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Product</Button>
                </div>
            }
            <div className='modalForm' >
                <Modal
                    title="Update"
                    visible={visible}
                    onCancel={handleCancel}
                    footer={[
                        <Button onClick={() => handleCancel()} >Cancel</Button>,
                        <Button type='primary' onClick={(e) => handleOk(e)}
                        // disabled={((demoData.item_id && flag) ? true : false)} 

                        >UPDATE</Button>,
                    ]}
                >
                    <div className='' >
                        <Form onFinish={handleOk} form={form} name="basic">
                            <Row justify="space-between">
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item
                                        labelCol={{ span: 6 }}
                                        label="UPC Code"
                                        name="upcCode"
                                        labelAlign="left"
                                        rules={[
                                            {
                                                pattern: new RegExp(/^[0-9]*$/),
                                                message: "Characters not allowed",
                                            },
                                        ]}
                                    >
                                        <Input className='radius-5' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item
                                        labelCol={{ span: 6 }}
                                        label="NRF Color Code"
                                        name="nrfColorCode"
                                        labelAlign="left"
                                        rules={[
                                            {
                                                pattern: new RegExp(/^[0-9]*$/),
                                                message: "Characters not allowed",
                                            },
                                        ]}
                                    >
                                        <Input className='radius-5' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item
                                        labelCol={{ span: 6 }}
                                        label="NRF Size Code"
                                        name="nrfSizeCode"
                                        labelAlign="left"
                                        rules={[
                                            {
                                                pattern: new RegExp(/^[0-9]*$/),
                                                message: "Characters not allowed",
                                            },
                                        ]}
                                    >
                                        <Input className='radius-5' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </div>
        </div >
    )
}