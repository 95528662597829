import React from "react";

import Logo from "../../../assets/images/logo.png";
import { useSelector } from "react-redux";
import "../SalesReport.css";
import moment from "moment";
import { Button } from "antd";

const NewSalesReportHeader = () => {
  const { newSalesReport } = useSelector((state) => state);
  const headerData = newSalesReport && newSalesReport?.payload?.data[0];


  const printHandler = () => {
    window.print();
  }

  return (
    <div className='new-sales-report'>
      <div className="new-sales-report-header">
        {/* header Left */}
        <div className="header-left-side ">
          {/* Logo & Text */}
          <div className="new-sales-report-Logo-Container">
            <div>
              <img width={250} height={72} src={Logo} />
            </div>
            {/* Logo Items */}
            <div className="new-sales-report-Logo_items">
              <div>DL1961 Premium Denim Inc.</div>
              <div>121 Varick Street,4th Floor</div>
              <div>NEW YORK ,NY 10013, USA</div>
              <div>TEL : (646) 514-9736</div>
              <div>FAX : (212) 991-9989</div>
              <div>EMAIL : sales@DL1961.com</div>
            </div>
            <Button style={{ marginBottom: '10px', width: "100px", height: 30, marginTop: 30 }} onClick={printHandler}>
              Print
            </Button>
            {/* Logo Items */}
          </div>
          {/* Items details */}
          <div className="items-detail-container">
            {/* Sold To */}
            <div className="sold-to-Container">
              <div className="sold-to sold-to-second">
                <div className="font_weight w-22">Sold To :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_customer}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight w-22">Name :</div>
                <div className="sold-to-data">{headerData?.billing_firstname ? `${headerData.billing_firstname + ' ' + headerData.billing_lastname}` : `${headerData?.customer_name}`}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight w-22">Address 1 :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_address_1}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight w-22">Address 2 :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_address_2}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_city_name}</div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_state_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_country}</div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_zip}</div>
              </div>
            </div>
            {/* Sold To */}
            {/* Ship To */}
            <div className="ship-to-Container">
              <div className="sold-to">
                <div className="font_weight w-22">Ship To :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_addressee}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight w-22">Name :</div>
                {/* <div className="sold-to-data">{headerData?.shipping_firstname ? `${headerData.shipping_firstname + ' ' + headerData.shipping_lastname}` : headerData?.ship_to_addressee ? headerData?.ship_to_addressee : `Attn: ${headerData?.customer_name}`}</div> */}
                <div className="sold-to-data">{headerData?.shipping_firstname ? `${headerData.shipping_firstname + ' ' + headerData.shipping_lastname}` : headerData?.ship_to_addressee ? headerData?.ship_to_addressee : `${headerData?.customer_name}`}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight w-22">Address 1 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_1}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight w-22">Address 2 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_2}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_city_name}</div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_state_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_country_name}</div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_zip}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Dept :</div>
                <div className="sold-to-data">{headerData && headerData.department}</div>
                <div className="font_weight">DC #</div>
                <div className="sold-to-data">{headerData && headerData.dc_no}</div>
                <div className="font_weight">Store :</div>
                <div className="sold-to-data">{headerData && headerData.store_name}</div>
              </div>
            </div>
            {/* Ship To */}
          </div>
          {/* Items details */}
        </div>
        {/* header Left */}
        {/* header Right */}
        <div className="header-right-side">
          <div className="sales-order-heading">Sales Order</div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Sales Order :</div>
            </div>
            <div className="sales_order_content">
              <div>{headerData && headerData.sale_order_no}</div>
            </div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Order Date :</div>
            </div>
            <div className="sales_order_content">
              <div>
                {headerData && moment(headerData.order_date).format("MM-DD-YYYY")}
              </div>
            </div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">P.O # :</div>
            </div>
            <div>{headerData && headerData.po_number}</div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Term :</div>
            </div>
            <div>{headerData && headerData.term}</div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Ship Method :</div>
            </div>
            <div className="overflow_txt">
              {headerData && headerData.ship_via}
            </div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Frieght Terms :</div>
            </div>
            <div>{headerData && headerData.frieght_terms}</div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Ship Date(From) :</div>
            </div>
            <div>
              {headerData && moment(headerData.ship_date_from).format("MM-DD-YYYY")}
            </div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Ship Date(To) :</div>
            </div>
            <div>
              {headerData && moment(headerData.cancel_date).format("MM-DD-YYYY")}
            </div>
          </div>
          <div className="new-sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Cancel Date :</div>
            </div>
            <div>{headerData && moment(headerData.cancel_date).format("MM-DD-YYYY")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSalesReportHeader;