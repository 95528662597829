import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Button, Table, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action"
import { ReadCookie } from "../../utils/readCookie";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import Loader from "react-loader-spinner";
import { getWashName } from "../../redux/actions/wash.action";
import { useRef } from "react";
import { intransitReport } from "../../redux/actions/intransitReport";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeasonYear } from "../../redux/actions/seasonYear.action";
import { getStyleShot } from "../../redux/actions/styleShot.action";
import numeral from "numeral";
import { loadCustomers } from "../../redux/actions/customerForm";
const { Option } = Select;


const StyleShot = ({ token }) => {
  TabTitle("Style Shot")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    gender,
    season,
    seasonYear,
    styleChange,
    Sku,
    wash,
    customerForms,
    styleShot
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [intransitList, setIntransitList] = useState([]);
  const [excelData, setExcelData] = useState([])
  const [filters, setFilters] = useState([]);
  const [allStatus, setAllStatus] = useState(true);
  const [customer, setCustomer] = useState("All");
  const [allFilters, setAllFilters] = useState([
    { id: 1, name: "Return", selected: false },
    { id: 2, name: "Pending", selected: false },
    { id: 3, name: "POD", selected: false },
    { id: 4, name: "Shipped", selected: false },
  ]);
  const initialDate = moment('2026-12-31')

  const statusSelectAll = (e) => {
    let copyArr = [...allFilters];
    copyArr.map((v) => {
      v.selected = false;;
      return v;
    });
    setAllFilters(copyArr);
    setAllStatus(e.target.checked);
  };

  useEffect(() => {
    if (allStatus) {
      // console.log("running")
      setFilters([]);
      // console.log(filters);
    }
  }, [allStatus])

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let query_sku = query.get("sku");
  // console.log("query_sku", query_sku)

  useEffect(() => {
    if (query_sku) {
      const values = {
        sku: query_sku
      }
      form.setFieldsValue({
        sku: values.sku,
      });
      setLoading(true)
      dispatch(intransitReport(token, values)).then((res) => {
        setLoading(false)
        setIntransitList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }
  }, [query_sku])


  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((data) => {
      let report = []
      allFilters.filter((item) => {
        if (item.selected === true) {
          report.push(item.id)
        }
        return item;
      });
      dispatch(getStyleShot(token, data, report)).then((res => {
        setLoading(false)
        setIntransitList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      }));
    });
  };

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getSeasonYear(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getProductClass(token, menuId));
    dispatch(getSize(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getFitCategory(token, menuId));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };


  const colums = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Season",
      dataIndex: "newseason",
      key: "newseason",
      sorter: (a, b) => {
        if ((a.newseason !== null) & (b.newseason !== null)) {
          return a.newseason.localeCompare(b.newseason);
        }
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      sorter: (a, b) => {
        return a.sku - b.sku
      },
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      sorter: (a, b) => {
        return a.inseam - b.inseam
      },
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
      sorter: (a, b) => {
        if ((a.style_name !== null) & (b.style_name !== null)) {
          return a.style_name.localeCompare(b.style_name);
        }
      }
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
      sorter: (a, b) => {
        if ((a.wash_name !== null) & (b.wash_name !== null)) {
          return a.wash_name.localeCompare(b.wash_name);
        }
      }
    },
    {
      title: "BLOOM",
      dataIndex: "bloom",
      key: "bloom",
      align: "right",
      sorter: (a, b) => {
        return a.bloom - b.bloom
      },
    },
    {
      title: "SF",
      dataIndex: "sf",
      key: "sf",
      align: "right",
      sorter: (a, b) => {
        return a.sf - b.sf
      },
    },
    {
      title: "SAKS",
      dataIndex: "saks",
      key: "saks",
      align: "right",
      sorter: (a, b) => {
        return a.saks - b.saks
      },
    },
    {
      title: "SHPBP",
      dataIndex: "shpbp",
      key: "shpbp",
      align: "right",
      sorter: (a, b) => {
        return a.shpbp - b.shpbp
      },
    },
    {
      title: "NORD",
      dataIndex: "nord",
      key: "nord",
      align: "right",
      sorter: (a, b) => {
        return a.nord - b.nord
      },
    },
    {
      title: "NUULY",
      dataIndex: "nuuly",
      key: "nuuly",
      align: "right",
      sorter: (a, b) => {
        return a.nuuly - b.nuuly
      },
    },
    {
      title: "NEIMN",
      dataIndex: "neiman_marcus",
      key: "neiman_marcus",
      align: "right",
      sorter: (a, b) => {
        return a.neiman_marcus - b.neiman_marcus
      },
    },
    {
      title: "AMZN",
      dataIndex: "amzn",
      key: "amzn",
      align: "right",
      sorter: (a, b) => {
        return a.amzn - b.amzn
      },
    },
    {
      title: "ZPPS",
      dataIndex: "zpps",
      key: "zpps",
      align: "right",
      sorter: (a, b) => {
        return a.zpps - b.zpps
      },
    },
    {
      title: "GH",
      dataIndex: "gh",
      key: "gh",
      align: "right",
      sorter: (a, b) => {
        return a.gh - b.gh
      },
    },
    {
      title: "MRKTING",
      dataIndex: "mrktng",
      key: "mrktng",
      align: "right",
      sorter: (a, b) => {
        return a.mrktng - b.mrktng
      },
    },
    {
      title: "ANTHR",
      dataIndex: "anthr",
      key: "anthr",
      align: "right",
      sorter: (a, b) => {
        return a.anthr - b.anthr
      },
    },
    {
      title: "RTR",
      dataIndex: "rtr",
      key: "rtr",
      align: "right",
      sorter: (a, b) => {
        return a.rtr - b.rtr
      },
    },
    {
      title: "BERG",
      dataIndex: "berg",
      key: "berg",
      align: "right",
      sorter: (a, b) => {
        return a.berg - b.berg
      },
    },
    {
      title: "JH",
      dataIndex: "jh",
      key: "jh",
      align: "right",
      sorter: (a, b) => {
        return a.jh - b.jh
      },
    },
    {
      title: "FREE PROPLE",
      dataIndex: "free_people",
      key: "free_people",
      align: "right",
      sorter: (a, b) => {
        return a.free_people - b.free_people
      },
    },
    {
      title: "MAJOR TTL",
      dataIndex: "majors_ttl",
      key: "majors_ttl",
      align: "right",
      sorter: (a, b) => {
        return a.majors_ttl - b.majors_ttl
      },
    },
    {
      title: "WC",
      dataIndex: "wc",
      key: "wc",
      align: "right",
      sorter: (a, b) => {
        return a.wc - b.wc
      },
    },
    {
      title: "EC",
      dataIndex: "ec",
      key: "ec",
      align: "right",
      sorter: (a, b) => {
        return a.ec - b.ec
      },
    },
    {
      title: "NE1",
      dataIndex: "ne1",
      key: "ne1",
      align: "right",
      sorter: (a, b) => {
        return a.ne1 - b.ne1
      },
    },
    {
      title: "MW",
      dataIndex: "mw",
      key: "mw",
      align: "right",
      sorter: (a, b) => {
        return a.mw - b.mw
      },
    },
    {
      title: "SE",
      dataIndex: "se",
      key: "se",
      align: "right",
      sorter: (a, b) => {
        return a.se - b.se
      },
    },
    {
      title: "SC",
      dataIndex: "sc",
      key: "sc",
      align: "right",
      sorter: (a, b) => {
        return a.sc - b.sc
      },
    },
    {
      title: "SPCLTY TTL",
      dataIndex: "spclty_ttl",
      key: "spclty_ttl",
      align: "right",
      sorter: (a, b) => {
        return a.spclty_ttl - b.spclty_ttl
      },
    },
    {
      title: "INT TTL",
      dataIndex: "int_ttl",
      key: "int_ttl",
      align: "right",
      sorter: (a, b) => {
        return a.int_ttl - b.int_ttl
      },
    },
    {
      title: "ECOM TTL",
      dataIndex: "ecom_ttl",
      key: "ecom_ttl",
      align: "right",
      sorter: (a, b) => {
        return a.ecom_ttl - b.ecom_ttl
      },
    },
    {
      title: "OP TTL",
      dataIndex: "op_ttl",
      key: "op_ttl",
      align: "right",
      sorter: (a, b) => {
        return a.op_ttl - b.op_ttl
      },
    },
    {
      title: "GRAND TTL",
      render: (text, record) => {
        return record.majors_ttl + record.spclty_ttl + record.int_ttl + record.ecom_ttl + record.op_ttl
      },
      align: "right",
      sorter: (a, b) => {
        const sumA = a.majors_ttl + a.spclty_ttl + a.int_ttl + a.ecom_ttl + a.op_ttl;
        const sumB = b.majors_ttl + b.spclty_ttl + b.int_ttl + b.ecom_ttl + b.op_ttl;
        return sumA - sumB
      },
    },
    {
      title: "OTS",
      dataIndex: "ots",
      key: "ots",
      align: "right",
      sorter: (a, b) => {
        return a.ots - b.ots
      },
    },
    {
      title: "ST %",
      align: "right",
      render: (text, record) => {
        return numeral(((record.majors_ttl + record.spclty_ttl + record.int_ttl + record.ecom_ttl) / (record.ots + record.majors_ttl + record.spclty_ttl + record.int_ttl + record.ecom_ttl)) * 100).format("0,00.00")
      },
      sorter: (a, b) => {
        const sumA = ((a.majors_ttl + a.spclty_ttl + a.int_ttl + a.ecom_ttl) / (a.ots + a.majors_ttl + a.spclty_ttl + a.int_ttl + a.ecom_ttl)) * 100
        const sumB = ((b.majors_ttl + b.spclty_ttl + b.int_ttl + b.ecom_ttl) / (b.ots + b.majors_ttl + b.spclty_ttl + b.int_ttl + b.ecom_ttl)) * 100;
        return sumA - sumB
      },
    },
  ];

  const handleGender = (gender_id) => {
    dispatch(getGenderCategoryChange(token, gender_id));
    dispatch(getStyleChange(token, gender_id, null, null));
    form.setFieldsValue({
      gender_category_id: "All",
      style_id: "All",
      sku: "All"
    })
  }


  const handleStyle = (gender_category_id) => {
    dispatch(getStyleChange(token, form.getFieldValue().gender_id, gender_category_id, null))
    form.setFieldsValue({
      style_id: "All",
      sku: "All"
    })
  }

  const handleSku = (sku_id) => {
    if (sku_id === null) {
      dispatch(getSku(token, null, null, null));
    } else {
      dispatch(getSku(token, form.getFieldValue().gender_id, form.getFieldValue().gender_category_id, sku_id));
    }
    form.setFieldsValue({
      sku: "All"
    })
  }

  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  }

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);

  const totalQuantity = () => {
    let sumQty = intransitList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.sum_quantity);
    }, 0);
    return sumQty;
  }


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Season": v.newseason,
          "SKU": v.sku,
          "Inseam": v.inseam,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "BLOOM": v.bloom,
          "SF": v.sf,
          "SAKS": v.saks,
          "SHPBP": v.shpbp,
          "NORD": v.nord,
          "NUULY": v.nuuly,
          "NEIMN": v.neiman_marcus,
          "AMZN": v.amzn,
          "ZPPS": v.zpps,
          "GH": v.gh,
          "MRKTING": v.mrktng,
          "ANTHR": v.anthr,
          "RTR": v.rtr,
          "BERG": v.berg,
          "JH": v.jh,
          "FREE PROPLE": v.free_people,
          "MAJOR TTL": v.majors_ttl,
          "WC": v.wc,
          "EC": v.ec,
          "NE1": v.ne1,
          "MW": v.mw,
          "SE": v.se,
          "SC": v.sc,
          "SPCLTY TTL": v.spclty_ttl,
          "INT TTL": v.int_ttl,
          "ECOM TTL": v.ecom_ttl,
          "OP TTL": v.op_ttl,
          "GRAND TTL": v.majors_ttl + v.spclty_ttl + v.int_ttl + v.ecom_ttl + v.op_ttl,
          "OTS": v.ots,
          "ST %": numeral(((v.majors_ttl + v.spclty_ttl + v.int_ttl + v.ecom_ttl) / (v.ots + v.majors_ttl + v.spclty_ttl + v.int_ttl + v.ecom_ttl)) * 100).format("0,00.00"),
        };
      })
    )
  }


  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedCus = useCallback(debounce(handleChanges), []);


  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"Style-Shot.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    // onClick={onDownload}
                    // className="cursor-not-allowed"
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>

              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Style Shot
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">From</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment()}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">To</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={initialDate}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-1" />
              <div className="row">
                <div className="checkboxes">
                  <label htmlFor="" style={{ marginRight: "25px" }}>Status</label>
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox
                        checked={allStatus} onChange={statusSelectAll}
                      // value={null}
                      >
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {allFilters &&
                    allFilters.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={it.selected}
                              // checked={allStatus ? false : filters.map(id=> console.log("id",id))}
                              // onChange={(e) => isFilter(it, e.target.checked)}
                              onChange={(e) => {
                                let copyArr = [...allFilters];
                                if (e.target.checked) {
                                  copyArr[index].selected = true;
                                } else {
                                  copyArr[index].selected = false;
                                }
                                setAllFilters(copyArr);
                                setAllStatus(false);
                              }}
                            >
                              {it.name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">Gender</label>
                    <div style={{ marginBottom: "5px" }} className=" w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleGender(e)}
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">Season</label>
                    <div style={{ marginBottom: "5px" }} className=" w-100">
                      <Form.Item name="current_season_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().current_season_id}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={status.season_id}
                                    value={status.season_id}
                                  >
                                    {status.season_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">S. Year</label>
                    <div style={{ marginBottom: "5px" }} className=" w-100">
                      <Form.Item name="current_season_year_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().current_season_year_id}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {seasonYear &&
                            seasonYear?.payload?.data?.map(
                              (res) => {
                                return (
                                  <Option
                                    key={res.season_year_id} value={res.season_year_id}>{res.season_year}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">Sku</label>
                    <div style={{ marginBottom: "5px" }} className=" w-100">
                      <Form.Item name="sku" initialValue={"All"}>
                        <Select
                          notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                          </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                          className={"select_Inputs"}
                          showSearch
                          onSearch={(value) => {
                            optimizedFn(value)
                          }}
                          // placeholder="Select Gender"
                          optionFilterProp="children"
                          size="small"
                        >
                          <Option value="All">All</Option>
                          {Sku &&
                            Sku?.payload?.data.map((it, index) => {
                              return (
                                // <Option value="ABC">ABC</Option>
                                <Option key={index} value={it.sku}>
                                  {it.sku}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">Style</label>
                    <div style={{ marginBottom: "5px" }} className=" w-100">
                      <Form.Item initialValue={"All"} name="style_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select a person"
                          optionFilterProp="children"

                          size="small"
                          onChange={(e) => handleSku(e)}
                        >
                          <Option value={null}>All</Option>
                          {
                            styleChange && styleChange?.payload?.data.length >= 0 ? (
                              styleChange && styleChange?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            ) : (
                              style && style?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            )
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">Wash</label>
                    <div style={{ marginBottom: "5px" }} className=" w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_id}
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Product Status"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={status.wash_id}
                                    value={status.wash_id}
                                  >
                                    {status.wash_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  {/* <label className=" no_visibility">none</label> */}
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">Customer</label>
                    <div style={{ marginBottom: "5px" }} className=" w-100">
                      <Form.Item name="customer" initialValue={"All"}>
                        <Select
                          notFoundContent={
                            customerForms.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loader
                                  type="Oval"
                                  color="#3c4b58"
                                  height={20}
                                  width={20}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                No Data
                              </div>
                            )
                          }
                          // defaultValue={form.getFieldValue().wash_id}
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Product Status"
                          optionFilterProp="children"
                          size="small"
                          loading={customerForms.loading}
                          onSearch={(value) => {
                            optimizedCus(value);
                          }}
                        >
                          <Option value={null}>All</Option>
                          {customerForms?.payload?.data.map((item) => {
                            return (
                              <Option
                                key={item.customer_id}
                                value={item.customer_id}
                              >
                                {" "}
                                {`${item.customer_code} ${item.customer_name}`}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  {/* <label className=" no_visibility">none</label> */}
                </div>
                <div className="col-12 col-md-3">
                  <div style={{ marginTop: "4px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div
        // className="business_class_oms mb-2 distributor" style={{ width: "1400px" }}
        className="table_content international_order"
        style={{ maxWidth: '1580px' }}
      >
        <div>
          {styleShot?.payload?.data !== undefined ?
            (
              styleShot?.payload?.data.length > 0 ? (
                <Table
                  className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={intransitList}
                  columns={colums}
                  loading={styleShot.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                />
              ) : (
                <div className="no-Data">
                  {/* {console.log(styleShot?.payload?.data.length, 'styleShot?.payload?.data.length')} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default StyleShot;
