import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import moment from "moment";
import {
  DatePicker,
  Input,
  Tabs,
  Button,
  Form,
  Select,
  Tooltip,
  Table,
  Popconfirm,
  InputNumber,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
// import { addItemTransferHeader, updateItemTransferHeader } from "../../../../redux/actions/itemTransfer.action";
import {
  addInvAdjustmentHeader,
  updateInvAdjustmentHeader,
  getAllAdjustmentAccount,
} from "../../../../redux/actions/inventoryAdjustment.action";
import { getOneInvAdjustmentNewHeader } from "../../../../redux/actions/inventoryAdjustmentNew.action";
// import { addItemTransferLine, getOneItemTransferLineDetail, deleteItemTransferLine, updateItemTransferLine } from "../../../../redux/actions/itemTransferLine.action";
import {
  addInvTransferLine,
  getOneInvTransferLineDetail,
  deleteInvTransferLine,
  updateInvTransferLine,
} from "../../../../redux/actions/inventoryAdjustmentLine.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getAdminLocation } from "../../../../redux/actions/adminLocation.action";
import numeral from "numeral";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableNew";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import Summary from "../../../../components/AdjustmentSummary/AdjustmentSummary";
import "../../../../screens/InventoryAdjustment/InventoryAdjustment.css";
import { addInvAdjustmentNewHeader } from "../../../../redux/actions/inventoryAdjustmentNew.action";
import {
  addInvTransferNewLine,
  updateInvTransferNewLine,
} from "../../../../redux/actions/inventoryAdjustmentNewLine.action";
import Loader from "react-loader-spinner";
import { getNewAdjustmentAccount } from "../../../../redux/actions/newAdjustmentAccount.action";
// import { ClimbingBoxLoader } from "react-spinners";

const { Option } = Select;
const { TabPane } = Tabs;

export default function InventoryAdjustmentForm({
  formData,
  val,
  token,
  formState,
  submit,
  adjustment_header_id,
  disable,
  changeState,
  locationID
}) {
  const dispatch = useDispatch();
  const submitButton = useRef(null);
  const [form] = Form.useForm();
  const {

    newAdjustmentAccount,
    inventoryAdjustment,
    ProductSale,
    invoiceGlPeriod,
    companyAll,
    adminLocation,
    systemLog,
    adjustmentaccount,
    glImpact
  } = useSelector((state) => {
    return state;
  });

  const [headerNo, setHeaderNo] = useState(ReadCookie("defaultCompany"));
  const [oDate, setOrderDate] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [showGrid, setShowGrid] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const companyId = ReadCookie("defaultCompany");
  const [headerID, setHeaderID] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment().format("MMM YYYY"));
  const [dDate, setDeliveryDate] = useState(moment());
  const [getLineRecord, setGetLineRecord] = useState([]);
  const [editModalShow, setEditModalShow] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [aadjustmentLocation, setAadjustmentLocation] = useState(null);

  useEffect(() => {
    dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
      let result = res?.payload?.find((item) => {
        return item.period_name === currentDate
      });
      setCurrentDate(result.period_id);
      form.setFieldsValue({ postingPeriod: result?.period_id });
    });
  }, [token, dispatch]);


  console.log("result CurrentDate", currentDate);
  var a = companyAll?.payload?.data[0]?.company_id;

  useEffect(() => {
    dispatch(getAllCompany(token));
    // dispatch(getAllAdjustmentAccount(token));
    dispatch(getNewAdjustmentAccount(token));
    dispatch(getAdminLocation(token));
   
  }, []);

  useEffect(() => {
    if (formState === "add") {
      console.log("result 128", currentDate);
      form.setFieldsValue({
        // adjustment_header_id:null,
        adjustmentAccount: null,
        postingPeriod: currentDate,
        estimatedTotalValue: null,
        memo: null,
        subsidiary: 2,
        adjust_date: oDate,
        adjustmentLocation: null,
        account_id: null,
        dileveryDate: dDate,
        // adjustment_header_id: inventoryAdjustmentAdd && inventoryAdjustmentAdd?.payloadSuccess[0]?.adjustment_header_no
      });
      setOrderItems([]);
    }
    if (formState === "formView") {
      setLoading(true)
      dispatch(getOneInvTransferLineDetail(token, adjustment_header_id)).then(
        (res) => {
          ///////////////////////////////////kkk\
          if (
            res.type ===
            "GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_SUCCESS"
          ) {
            res.payload.data.map((item) => {
              return (item.rate = item.estimated_cost);
              // item.record=item.amount
            });
            setOrderItems(res.payload.data);
            setLoading(false)
            const sum = res.payload.data.reduce((acc, item, index) => {
              acc = acc + parseInt(item.estimated_cost);
              return acc;
            }, 0);
            form.setFieldsValue({
              estimatedTotalValue: sum,
              // adjustment_header_id: inventoryAdjustmentAdd?.payloadSuccess?.adjustment_header_no
            });
          }
        }
      );
    }
  }, [formState]);



  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        adjustment_header_no: formData.adjustment_header_no,
        postingPeriod: formData.gl_period_id,
        adjust_date: moment(formData?.adjust_date),
        memo: formData.memo,
        subsidiary: formData.company_id,
        adjustmentLocation: formData.location_id,
        account_id: formData.account_id,
        dileveryDate: moment(formData?.delivery_date),
      });
    }
  }, [formData]);

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  useEffect(() => {
    if (formState === "formView") {
      dispatch(getGLImpact(token, { "transaction_header_id": adjustment_header_id, "transaction_type": "STOCKADJUST" }));
      dispatch(getSystemLog(token, { table_name: "inv_stock_adjustment_header", record_id: adjustment_header_id }));
    }
  }, [formState]);

  const handleDeleteOrder = (record) => {
    const dataSource = [...orderItems];
    const deleteDataSource = [...deletedItems];
    deleteDataSource.push(record);
    setDeletedItems(deleteDataSource);
    setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));
  };

  function handleChange(value, type) {
    if (type === "company") {
      headerNo(value);
      form.setFieldsValue({ company: value });
    }
  }
  function handleChangeAdjustmentLocation(value, type) {
    setAadjustmentLocation(value);
    if (type === "company") {
      headerNo(value);
      form.setFieldsValue({ company: value });
    }
  }

  const onFinish = async (e) => {
    submit(false);
    await form.validateFields().then((values) => {
      console.log("result values", values);
      console.log("result CCC", currentDate);

      setLoading(true);

      if (formState === "add") {
        if (orderItems.length > 0) {
          dispatch(addInvAdjustmentNewHeader(token, values)).then((res) => {
            setHeaderID(res?.payload?.adjustment_header_id);
            // values.adjustment_header_id = res?.payload?.adjustment_header_id;
            let headerId = res?.payload?.adjustment_header_id;

            if (res.type === "ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS") {
              dispatch(
                addInvTransferNewLine(
                  token,
                  orderItems.map((item) => {
                    let estCost = item.rate;
                    delete item.style_name;
                    delete item.cut_name;
                    delete item.fabric_name;
                    delete item.gender_category_name;
                    delete item.gender_name;
                    delete item.inseam;
                    delete item.isSelected;
                    delete item.quantity_available;
                    delete item.quantity_backorder;
                    delete item.quantity_committed;
                    delete item.quantity_on_hand;
                    delete item.rate;
                    delete item.size;
                    delete item.style_id;
                    delete item.wash_id;
                    delete item.wash_name;
                    delete item.amount;
                    delete item.commit_status;
                    delete item.already;
                    delete item.quantity_packed;
                    delete item.quantity_shipped;
                    delete item.quantity_billed;
                    delete item.pod_quantity;
                    // delete item.quantity;
                    return {
                      ...item,
                      estimated_cost: estCost,
                      current_value: 0,
                      adjustment_header_id: res.payload.adjustment_header_id,
                    };
                  })
                )
              ).then((res) => {
                if (
                  res.type === "ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS"
                ) {
                  setLoading(false);
                  disable(true);
                  history.push({
                    pathname: `/inventory-adjustment/edit/${headerId}`,
                    search: `?_id=${headerId}`,
                  });
                  notification(
                    "success",
                    "Inventory Adjustment",
                    "Inventory Adjustment created successfully!"
                  );
                } else if (
                  res.type === "ADD_INVENTORYADJUSTMENT_HEADER_ERROR"
                ) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res?.payload?.data?.data,
                  });
                }
              });
            } else if (res.type === "ADD_INVENTORYADJUSTMENT_HEADER_ERROR") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.payload?.data?.data,
              });
            }
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Select atleast one Inventory Adjustment line",
          });
          return;
        }
      } else if (formState === "edit") {
        if (deletedItems.length > 0) {
          dispatch(deleteInvTransferLine(token, 123, deletedItems));
        }
        dispatch(
          updateInvAdjustmentHeader(token, values, adjustment_header_id)
        ).then((res) => {
          if (res.type === "UPDATE_INVENTORYADJUSTMENT_HEADER_SUCCESS") {
            dispatch(
              updateInvTransferNewLine(token, orderItems, adjustment_header_id)
            ).then((res) => {
              if (
                res.type === "UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS"
              ) {
                setLoading(false);
                changeState("formView")
                notification("success", "Inventory Adjustment Updated");
                dispatch(
                  getOneInvTransferLineDetail(token, adjustment_header_id)
                );
              }
            });
          } else if (res.type === "UPDATE_INVENTORYADJUSTMENT_HEADER_ERROR") {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res?.payload?.data?.data,
            });
          }
        });
      }
    });
  };

  const handleTab = (key) => {
    if (key === "header") {
      dispatch(getSystemLog(token, { table_name: "inv_stock_adjustment_header", record_id: adjustment_header_id }));
    } else if (key === "line") {
      dispatch(getSystemLog(token, { table_name: "inv_stock_adjustment_lines", record_id: adjustment_header_id }));
    } 
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const addedItems = (value) => {
    const fillArr = [...orderItems];
    const onlyKey = [...value].map((sItem) => sItem.item_code);
    const indexofArr = fillArr.findIndex((f) => {
      return onlyKey.find((i) => i === f.item_code);
    });
    fillArr.splice(indexofArr, 1);
    if (orderItems.length > 0) {
      let uniqueOrderItems = [...orderItems, ...value];
      //remove Duplicates
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
    } else {
      //remove Duplicates
      let uniqueOrderItems = [...visibleItems, ...value];
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
    }
  };
  const showEditModal = (record) => {
    setGetLineRecord(record);
    setEditModalShow(true);
  };

  const updateQuantity = (type, qty, value, record, index) => {
    let arr = [...orderItems];

    let add =
      parseInt(record.quantity_available) +
      (parseInt(record.quantity_committed) - parseInt(record.pod_quantity));
    let committed = record.quantity_committed + value;

    if (type === "up") {
      if (committed < 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Quantity is Less than committed Quantity",
        });
      }
      if (add + value < 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Quantity is Less than Balance",
        });
        let arr = [...orderItems];
        arr[index].quantity = add * -1;
      } else {
        arr[index].quantity = value;
      }
    }

    if (type === "down") {
      if (committed < 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Quantity is Less than committed Quantity",
        });
      }
      if (add + value < 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Quantity is Less than Balance",
        });
        let arr = [...orderItems];
        arr[index].quantity = add * -1;
      } else {
        arr[index].quantity = value;
      }
    }

    setOrderItems(arr);
  };
  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
  };

  const columns = [
    {
      title: "Ite Code",
      dataIndex: "item_code",
    },
    {
      title: "Style Name",
      dataIndex: "style_name",
    },
    {
      title: "Wash Name",
      dataIndex: "wash_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 80,
      render: (text, record, index) => {
        return (
          <>
            {editModalShow && itemIndex === index ? (
              <InputNumber
                size="small"
                name="updatedQty"
                value={orderItems[index].quantity}
                onChange={(value) =>
                  updateQuantity("up", record.quantity, value, record, index)
                }
              />
            ) : (
              orderItems[index] && Number(orderItems[index].quantity).toFixed(0)
            )}
          </>
        );
      },
    },
    {
      title: "Available Quantity",
      dataIndex: "quantity_available",
    },
    {
      title: "Quantity Committed",
      dataIndex: "quantity_committed",
    },
    {
      title: "Quantity On Hand",
      dataIndex: "quantity_on_hand",
    },
    {
      title: "Pod Quantity",
      dataIndex: "pod_quantity",
    },

    {
      title: "Estimated Cost",
      dataIndex: "rate",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => {
        return parseFloat(record.quantity * record.estimated_cost).toFixed(2);
      },
    },

    {
      title: "Operation",
      width: 80,
      dataIndex: "operation",
      render: (_, record, index) => (
        <Tooltip title="Delete" color={"white"}>
          { formState==="formView"?"":
          <>
            <Popconfirm
              title={`Are you sure to delete the item ${record.item_id}?`}
              onConfirm={() => handleDeleteOrder(record)}
            >
              <DeleteOutlined className={`delete-Button`} />
            </Popconfirm>
            
            <EditOutlined
              className={`edit-Button`}
              onClick={() => {
                showEditModal(record, index);
                setItemIndex(index);
              }}
            />
          </>
            }
        </Tooltip>
      ),
    },
  ];
  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0])
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      }
    })
  }
  const glImpcatCols = [

    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text, record, index) => {
        return (
          <>
            $ {numeral(record.debit).format("0,0.00")}
          </>
        )
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) => {
        return (
          <>
            $ {numeral(record.credit).format("0,0.00")}
          </>
        )
      },
    },

    {
      title: "created_date",
      dataIndex: "created_date",
    }
  ]
  return (
    <>
      <EditableTable
        companyId={companyId}
        token={token}
        headerNo={headerNo}
        modalValue={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setOrderItems={addedItems}
        orderItems={orderItems}
        setShowGrid={setShowGrid}
        locationID={locationID}
        adjustmentLocation={aadjustmentLocation}
      />
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        name="basic"
      >
          {newAdjustmentAccount.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="adjustment_header_no"
                        label="Adjustment #"
                        labelAlign="left"
                        labelCol={{
                          xs: 9,
                          xxl: 7,
                        }}
                        defaultValue={
                          formState === "formView"
                            ? form.getFieldValue().adjustment_header_no
                            : ""
                        }
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={true}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{
                        xs: 9,
                        xxl: 7
                      }} defaultValue={form.getFieldValue().postingPeriod}>
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={!(formState === "add" || formState === "edit")}
                          loading={invoiceGlPeriod.loading}
                          defaultValue={form.getFieldValue().postingPeriod}
                          onChange={(value) => {
                            form.setFieldsValue({
                              postingPeriod: value,
                            });
                          }}
                        >
                          {invoiceGlPeriod?.payload?.map((item) => {
                            return (
                              <Option key={item.period_id} value={item.period_id}>
                                {item.period_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  { }
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="estimatedTotalValue"
                        label="Estimated Total Value"
                        labelAlign="left"
                        labelCol={{
                          xs: 9,
                          xxl: 7,
                        }}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={true}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  { }
                  { }
                  {/* <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Date"
                        name="adjust_date"
                        labelAlign="left"
                        labelCol={{
                          xs: 9,
                          xxl: 7
                        }}
                        rules={[
                          { required: true, message: "Date is required" },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().adjust_date
                              : moment(oDate)
                          }
                          format="MM-DD-YYYY"
                          allowClear
                          tabIndex={18}
                          className="dateInput customInputs"
                          onChange={(e) => setOrderDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol> */}

                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item label="Date" name="adjust_date" labelAlign="left"    labelCol={{
                          xs: 9,
                          xxl: 7
                        }} rules={[{ required: true, message: "Bill Date is required" }]}>
                        {/* <DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Bill Date"
													allowClear
													className="dateInput customInputs"
												/> */}
                        <DatePicker
                          size="small"
                          disabled={!(formState === "add" || formState === "edit")}
                          format="MM-DD-YYYY"
                          // placeholder="Transfer Date"
                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().adjust_date
                              : moment(oDate)
                          }
                          onChange={(e) => {
                            setOrderDate(moment(e))
                            let result = invoiceGlPeriod?.payload?.find((item) => {
                              return item.period_name === moment(e).format("MMM YYYY")
                            });
                            setCurrentDate(result?.period_id);
                            form.setFieldsValue({ postingPeriod: result?.period_id });
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="Memo"
                        labelAlign="left"
                        labelCol={{
                          xs: 9,
                          xxl: 7
                        }}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="account_id"
                        label="Adjustment Account:"
                        labelAlign="left"
                        labelCol={{
                          xs: 9,
                          xxl: 7
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Adjustment Account is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          allowClear
                          tabIndex={18}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().account_id}
                          onChange={(value) => handleChange(value)}
                        >
                          {newAdjustmentAccount &&
                            newAdjustmentAccount?.payload?.data?.map((item) => {
                              return (
                                <Option
                                  key={item.account_id}
                                  value={item.account_id}
                                >
                                  {item.full_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item label="Delivery Date" name="dileveryDate" labelAlign="left"    labelCol={{
                          xs: 9,
                          xxl: 7
                        }} rules={[{ required: true, message: "Delivery Date is required" }]}>

                        <DatePicker
                          size="small"
                          disabled={!(formState === "add" || formState === "edit")}
                          format="MM-DD-YYYY"
                          // placeholder="Transfer Date"
                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().dileveryDate
                              : moment(oDate)
                          }
                          onChange={(e) => setDeliveryDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <Summary
                  orderItems={orderItems.length > 0 ? orderItems : visibleItems}
                  shippingCost={
                    form.getFieldValue().shippingCost
                      ? form.getFieldValue().shippingCost
                      : 0
                  }
                />
              </Bcol>
              <SubTitles name={"Classification"} />
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="subsidiary"
                        label="Subsidiary"
                        labelAlign="left"
                        labelCol={{
                          xs: 9,
                          xxl: 7
                        }}
                        rules={[
                          { required: true, message: "Company Id is required" },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          allowClear
                          tabIndex={18}
                          loading={companyAll.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().subsidiary}
                          onChange={(value) => handleChange(value)}
                        >
                          {companyAll?.payload?.data.map((item) => {
                            return (
                              <Option
                                key={item.company_id}
                                value={item.company_id}
                              >
                                {item.company_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="adjustmentLocation"
                        label="Adjustment Location:"
                        labelAlign="left"
                        labelCol={{
                          xs: 9,
                          xxl: 7
                        }}
                        rules={[
                          {
                            required: true,
                            message: "To Location is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          allowClear
                          tabIndex={18}
                          loading={adminLocation.loading}
                          disabled={formState === "edit"||formState === "formView"}
                          defaultValue={form.getFieldValue().adjustmentLocation}
                          onChange={(value) =>
                            handleChangeAdjustmentLocation(value)
                          }
                        >
                          {adminLocation?.payload?.data.map((item) => {
                            return (
                              <Option
                                key={item.location_id}
                                value={item.location_id}
                              >
                                {item.location_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>

          <Tabs
            type="card"
            size="small"
            defaultActiveKey="1"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Product Information" key="1">
              <div className="pbpx-20">
                <Button
                  type="primary"
                  className="modalButton"
                  disabled={!(formState === "edit" || formState === "add")}
                  onClick={showModal}
                >
                  Add Item
                </Button>
              </div>
              {(orderItems.length > 0 || visibleItems.length > 0) && (
                <Table
                  type={"company"}
                  loading={ProductSale.loading}
                  scroll={({ x: 600 }, { y: 400 })}
                  size="small"
                  bordered
                  dataSource={orderItems}
                  columns={columns}
                  pagination={false}
                />
              )}
            </TabPane>
            {(formState === "edit" || formState === "formView") &&
              <TabPane tab="GL Impact" key="gl_impact">
                <div className="tabs">
                  <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={glImpact?.payload?.data}
                    col={glImpcatCols}
                    load={glImpact?.loading}
                    paginate
                  />
                </div>
              </TabPane>}
            {(formState === "edit" || formState === "formView") &&
              <TabPane tab="System Information" key="logs" >
              <Tabs
                type="card"
                size="small"
                defaultActiveKey="1"
                centered
              onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Header" key="header">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                <TabPane tab="Line" key="line">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                  </Tabs>
              </TabPane>}
          </Tabs>
        </div>
      </Form>
    </>
  );
}
