import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
// import "./styleSheet.css"
import "../OMSBooking/styleSheet.css";
import { Select, DatePicker, Form, Table, Spin, Button, Input } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getOmsReport } from "../../redux/actions/omsReport.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { Link, useParams, useLocation } from "react-router-dom";
import Tables from "../../components/Table/Tables";
import { CSVLink } from "react-csv";
import { Excel } from "antd-table-saveas-excel";
import { getOmsStateReport } from "../../redux/actions/omsRegionStateReport.action";
import { useLayoutEffect } from "react";
import { getWise_Stock_Report } from "../../redux/actions/wiseStockReport.action";

const { Option } = Select;
const OmsStockDetails = ({ token, id, propsData, formData }) => {
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const paramsData = useParams();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const [sDate, setSDate] = useState(moment().format("YYYY-MM-DD"))
  // const [eDate, setEDate] = useState(moment().format("YYYY-MM-DD"))
  const [data, setData] = useState([]);
  const {
    gender,
    region,
    companyAll,
    omsRegionStateReport,
    getWiseStockReport,
  } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let check = query.get("submited");
  let company_id = query.get("company_id");
  let date_type = query.get("date_type");
  let gender_id = query.get("gender_id");
  let term_id = query.get("term_id");
  let from_date = query.get("from_date");
  let to_date = query.get("to_date");
  let region_id = query.get("region_id");
  let selected_id = query.get("selected_id");
  let style_id = query.get("style_id");
  let as_on_date = query.get("as_on_date");
  let gender_category_id = query.get("gender_category_id")
  let sku = query.get("sku");
  let date_till = query.get("as_on_date");

  const stockDetailData = {
    // as_on_date: date_till,
    // season_id: null,
    // category_id: null,
    // fit_category_id: null,
    // style_id: style_id,
    // gender_id: null,
    // product_status_id: null,
    // product_class_id: null,
    // sort_on: null,
    // sku: sku,
    // include_bgrade: null,
    location_id: '1,2,17,18,25,29,-1',
    sku: sku,
    style: null,
    pending_date: date_till,
    season_id: null,
    product_status_id: null,
    gender_category_id:null
  };
  const handlePrint = () => {
    window.print();
};

  useLayoutEffect(() => {
    setLoading(true);
    dispatch(getWise_Stock_Report(token, stockDetailData)).then(() => {
      setSubmit(true);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "serial_no",
      align: "left",
      key: "serial_no",
      width: 30,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_id",
      width: 140,
      render: (record, text) => {
        return (
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to={{
              pathname: `/oms-booking`,
              search: `?submited=${true}&report=${3}&company_id=${company_id}&date_type=${date_type}&gender_id=${gender_id}&region_id=${region_id}&term_id=${term_id}&from_date=${moment(
                from_date
              ).format("MM-DD-YYYY")}&to_date=${moment(to_date).format(
                "MM-DD-YYYY"
              )}&select=${true}&selected_id=${selected_id}&state_id=${
                text.state_id
              }&state_name=${text.state_name}&region_name=${text.region_name}`,
            }}
          >
            {" "}
            {record}
          </Link>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state_name",
      align: "left",
      key: "state_id",
      width: 70,
      render: (record, text) => {
        return (
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to={{
              pathname: `/oms-booking`,
              search: `?submited=${true}&report=${3}&company_id=${
                formData.company_id
              }&date_type=${formData.date_type}&gender_id=${
                formData.gender_id
              }&region_id=${formData.region_id}&term_id=${
                formData.term_id
              }&from_date=${moment(formData.from_date).format(
                "MM-DD-YYYY"
              )}&to_date=${moment(formData.to_date).format(
                "MM-DD-YYYY"
              )}&select=${false}&selected_id=${selected_id}&state_id=${
                text.state_id
              }&state_name=${text.state_name}&region_name=${text.region_name}`,
            }}
          >
            {" "}
            {record}
          </Link>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 70,
      align: "right",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      width: 130,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}> {text}</span>,
    },
    {
      title: "%",
      align: "right",
      dataIndex: "percentage",
      key: "percentage",
      width: 70,
    },
  ];
  let result =
    getWiseStockReport &&
    getWiseStockReport?.payload?.data?.reduce(function (r, a) {
      r[a.item_code] = r[a.item_code] || [];
      r[a.item_code].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);
       
  let sumQuantity;

  let qty_1;
  let qty_2;
  let qty_3;
  let qty_4;
  let qty_5;
  let qty_6;
  let qty_7;
  let qty_8;
  let qty_9;
  let qty_10;
  let qty_11;
  let qty_12;
  let qty_13;
  let qty_14;
  let qty_15;
  let qty_16;
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row  justify-content-between">
                <div className="col-3 d-flex align-items-sm-center  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                {/* <div className='col-sm-6 box-space pl-sm-0'>
                                    <p className='brand-para'>
                                        DL1961 Premium Denim Inc.<br />
                                        121 Varick Street<br />4th Floor<br />
                                        New York, NY 10013<br />
                                        (P) 646-514-9736
                                        <br />
                                        (F) 212-991-9989
                                        <br />
                                        (E) sales@DL1961.com<br />
                                    </p>
                                </div> */}
                <div className="col-4 box-space right-content-header text-right">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end">
                    <div className='print_icon'>
                                            <i
                              id="Image1"
                              title="Print"
                              alt="Print"
                              src={btnPrint}
                              onClick={handlePrint}
                              className="fa-solid fa-print printing_class"
                            ></i>
                                            </div>

                      {/* <div
                        className="print_icon"
                        name="ImgExcel"
                        alt="Export to excel"
                        id="ImgExcel"
                        title="Export to excel"
                        src={xlsx}
                      >
                        <i className="fa-solid fa-file-excel"></i>
                      </div> */}
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                    Stock Detail
                    <br />
                    Rpt # 006
                    <br />
                    Login as:
                    <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                      {" "}
                      {userName}
                    </span>
                  </span>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col">
                  <table
                    id="Table1"
                    cellPadding="3"
                    cellSpacing="0"
                    align="center"
                    className="tablestyleControls"
                  >
                    <tbody>
                      <tr>
                        <td className="no-border-btm">Till Date :</td>
                        <td>
                          <b>{date_till}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="w-100 mt-3">
                    <div>
                      {wiseStockReport ? (
                        wiseStockReport.length > 0 ? (
                          wiseStockReport.map((data, index) => {
                            const sizes = data;
                              // 

              let qty1 = null;
              let qty2 = null;
              let qty3 = null;
              let qty4 = null;
              let qty5 = null;
              let qty6 = null;
              let qty7 = null;
              let qty8 = null;
              let qty9 = null;
              let qty10 = null;
              let qty11 = null;
              let qty12 = null;
              let qty13 = null;
              let qty14 = null;
              let qty15 = null;
              let qty16 = null;

              for (let i = 0; i < data.length; i++) {
                qty1 += parseInt(data[i].qty_1);
                qty2 += parseInt(data[i].qty_2);
                qty3 += parseInt(data[i].qty_3);
                qty4 += parseInt(data[i].qty_4);
                qty5 += parseInt(data[i].qty_5);
                qty6 += parseInt(data[i].qty_6);
                qty7 += parseInt(data[i].qty_7);
                qty8 += parseInt(data[i].qty_8);
                qty9 += parseInt(data[i].qty_9);
                qty10 += parseInt(data[i].qty_10);
                qty11 += parseInt(data[i].qty_11);
                qty12 += parseInt(data[i].qty_12);
                qty13 += parseInt(data[i].qty_13);
                qty14 += parseInt(data[i].qty_14);
                qty15 += parseInt(data[i].qty_15);
                qty16 += parseInt(data[i].qty_16);
              }
                            let sumQ = 0;
                            for (let i = 0; i < data.length; i++) {
                              sumQ += parseInt(data[i].sum_quantity);
                            }
                            return (
                              <div key={index}>
                                <div className="table_data-Heading">
                                  {data[0].item_code} - {data[0].description}
                                </div>
                                <table className="table_distributor-data">
                                  <tbody>
                                    <tr>
                                      <td>#</td>
                                      <td>Location</td>
                                      <td style={{ width: "60px" }}>Total</td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_1}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_2}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_3}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_4}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_5}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_6}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_7}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_8}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_9}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_10}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_11}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_12}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_13}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_14}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_15}
                                      </td>
                                      <td style={{ width: "30px" }}>
                                        {sizes[0].size_16}
                                      </td>
                                    </tr>
                                    {data.map((values, index) => {
                                      const filterQuantity = data.filter(
                                        (val) =>
                                          val.location_id === values.location_id
                                      );
                                      const totalQuantity = filterQuantity.map(
                                        (qty) => {
                                          return (
                                            Number(qty.qty_1) +
                                            Number(qty.qty_2) +
                                            Number(qty.qty_3) +
                                            Number(qty.qty_4) +
                                            Number(qty.qty_5) +
                                            Number(qty.qty_6) +
                                            Number(qty.qty_7) +
                                            Number(qty.qty_8) +
                                            Number(qty.qty_9) +
                                            Number(qty.qty_10) +
                                            Number(qty.qty_11) +
                                            Number(qty.qty_12) +
                                            Number(qty.qty_13) +
                                            Number(qty.qty_14) +
                                            Number(qty.qty_15) +
                                            Number(qty.qty_16)
                                          );
                                        }
                                      );
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{values.location_name}</td>
                                          {filterQuantity.map((quan, index) => {
                                            sumQuantity =
                                              Number(quan.qty_1) +
                                              Number(quan.qty_2) +
                                              Number(quan.qty_3) +
                                              Number(quan.qty_4) +
                                              Number(quan.qty_5) +
                                              Number(quan.qty_6) +
                                              Number(quan.qty_7) +
                                              Number(quan.qty_8) +
                                              Number(quan.qty_9) +
                                              Number(quan.qty_10) +
                                              Number(quan.qty_11) +
                                              Number(quan.qty_12) +
                                              Number(quan.qty_13) +
                                              Number(quan.qty_14) +
                                              Number(quan.qty_15) +
                                              Number(quan.qty_16);

                                            qty_1 = quan.qty_1;
                                            qty_2 = quan.qty_2;
                                            qty_3 = quan.qty_3;
                                            qty_4 = quan.qty_4;
                                            qty_5 = quan.qty_5;
                                            qty_6 = quan.qty_6;
                                            qty_7 = quan.qty_7;
                                            qty_8 = quan.qty_8;
                                            qty_9 = quan.qty_9;
                                            qty_10 = quan.qty_10;
                                            qty_11 = quan.qty_11;
                                            qty_12 = quan.qty_12;
                                            qty_13 = quan.qty_13;
                                            qty_14 = quan.qty_14;
                                            qty_15 = quan.qty_15;
                                            qty_16 = quan.qty_16;


                                            return (
                                              <>
                                                <td>
                                                  {totalQuantity.toString()}
                                                </td>
                                                {quan.qty_1 && (
                                                  <td key={index}>
                                                    {quan.qty_1}
                                                  </td>
                                                )}
                                                <td>{quan.qty_2}</td>
                                                <td>{quan.qty_3}</td>
                                                <td>{quan.qty_4}</td>
                                                <td>{quan.qty_5}</td>
                                                <td>{quan.qty_6}</td>
                                                <td>{quan.qty_7}</td>
                                                <td>{quan.qty_8}</td>
                                                <td>{quan.qty_9}</td>
                                                <td>{quan.qty_10}</td>
                                                <td>{quan.qty_11}</td>
                                                <td>{quan.qty_12}</td>
                                                <td>{quan.qty_13}</td>
                                                <td>{quan.qty_14}</td>
                                                <td>{quan.qty_15}</td>
                                                <td>{quan.qty_16}</td>
                                              </>
                                            );
                                          })}
                                        </tr>
                                      );
                                    })}
                                    
                                    <tr>
                                    <td className="font-weig"></td>
                                    <td className="font-weig"></td>
                                    <td className="font-weig">{sumQ}</td>
                                    <td className="font-weig">{qty1 !== qty1 ? "" : qty1}</td>
                                    <td className="font-weig">{qty2 !== qty2 ? "" : qty2}</td>
                                    <td className="font-weig">{qty3 !== qty3 ? "" : qty3}</td>
                                    <td className="font-weig">{qty4 !== qty4 ? "" : qty4}</td>
                                    <td className="font-weig">{qty5 !== qty5 ? "" : qty5}</td>
                                    <td className="font-weig">{qty6 !== qty6 ? "" : qty6}</td>
                                    <td className="font-weig">{qty7 !== qty7 ? "" : qty7}</td>
                                    <td className="font-weig">{qty8 !== qty8 ? "" : qty8}</td>
                                    <td className="font-weig">{qty9 !== qty9 ? "" : qty9}</td>
                                    <td className="font-weig">{qty10 !== qty10 ? "" : qty10}</td>
                                    <td className="font-weig">{qty11 !== qty11 ? "" : qty11}</td>
                                    <td className="font-weig">{qty12 !== qty12 ? "" : qty12}</td>
                                    <td className="font-weig">{qty13 !== qty13 ? "" : qty13}</td>
                                    <td className="font-weig">{qty14 !== qty14 ? "" : qty14}</td>
                                    <td className="font-weig">{qty15 !== qty15 ? "" : qty15}</td>
                                    <td className="font-weig">{qty16 !== qty16 ? "" : qty16}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })
                        ) : (
                          <div className="no-Data">
                            <h1>No Data</h1>
                          </div>
                        )
                      ) : (
                        loading && (
                          <div className="loader" style={{ marginTop: "20px" }}>
                            <Spin size="medium" />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OmsStockDetails;
