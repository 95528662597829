import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  Table, Spin, DatePicker, Input
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import moment from "moment";
import { getChartOfAccountReport } from "../../redux/actions/commissionReport.action";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;

const ChartofAccountReport = () => {
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [dscoInvoiceList, setDscoInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = ReadCookie("defaultCompany");
  const company_id = ReadCookie("defaultCompany");
  const {
    ChartOfAccount
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])

// console.log("Date",Date)
console.log(form.getFieldsValue('to_date'))

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "Account #",
      dataIndex: "account_number",
      key: "account_number",
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys, 
      //   confirm,
      //   clearFilters,
      // }) => {
      //   return (
      //     <>
      //       <Input
      //         autoFocus
      //         allowClear
      //         className='pop-search-gen pop-170'
      //         placeholder="Type text here"
      //         value={selectedKeys[0]}
      //         onChange={(e) => {
      //           setSelectedKeys(e.target.value ? [e.target.value] : []);
      //           confirm({ closeDropdown: false });
      //         }}
      //         onPressEnter={() => {
      //           confirm();
      //         }}
      //         onBlur={() => {
      //           confirm();
      //         }}
      //       />
      //     </>
      //   );
      // },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.account_number.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
          if ((a.account_number !== null) && (b.account_number !== null)) {
            return a.account_number.localeCompare(b.account_number);
          }
        },
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.account_type.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if ((a.account_type !== null) && (b.account_type !== null)) {
          return a.account_type.localeCompare(b.account_type);
        }
      },
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      render:(text, record)=>{
        return   <Link
        style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
        to={{ pathname: `/journal-ledger`, search: `?account_id=${record?.account_id}&date_to=${JSON.stringify(form.getFieldsValue('to_date'))}` }}
      >{record.account_name}
      </Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.account_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if ((a.account_name !== null) && (b.account_name !== null)) {
          return a.account_name.localeCompare(b.account_name);
        }
      },
    },
    {
      title: "Net Balance",
      dataIndex: "net_balance",
      key: "net_balance",
      align: "right",
      render: (text, record) => {
        return <>$ {numeral(record.net_balance).format('0,0.00')}</>
    },
      // width: 70,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.net_balance.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.net_balance - b.net_balance;
      },
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getChartOfAccountReport(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setDscoInvoiceList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Account #": v.account_number,
          "Account Type": v.account_type,
          "Account Name": v.account_name,
          // "Opening Balance": numeral(v.opening_balance).format("0,00.00"),
          // "Transaction Balance": numeral(v.trans_balance).format("0,00.00"),
          "Net Balance": numeral(v.net_balance).format("0,00.00"),
        };
      })
    )
  }



  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Chart of Account Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                            setDate = {form.getFieldsValue('to_date')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            dscoInvoiceList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"Chart-of-Account-Report.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(150px + 90%)',
                  y: 450,
                }}
                //   scrollX={"50rem"}
                dataSource={dscoInvoiceList}
                columns={columns}
                loading={ChartOfAccount.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 500,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
              summary={(pageData) => {
                let netBalance = pageData.reduce(function (
                  previousValue,
                  currentValue
                ) {
                  return (previousValue += parseFloat(
                    currentValue.net_balance
                  ));
                },
                  0);
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ textAlign: "right" }} className="totalquantity">
                          $ {netBalance.toLocaleString("en-US")}
                        </p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ChartofAccountReport;
