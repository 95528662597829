import {
  GET_ALL_QUERY_BUILDER_SUCCESS,
  GET_ALL_QUERY_BUILDER_REQUEST,
  GET_ALL_QUERY_BUILDER_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";
import axios from "axios";

export const getQueryBuilder = (token, query) => (dispatch) => {
  // console.log(query,"runningssssss")
  // console.log(query, "queryqueryqueryqueryqueryqueryquery");
  // console.log("RUNNINGS")
  dispatch({ type:GET_ALL_QUERY_BUILDER_REQUEST});
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/QueryBuilder`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { data: query },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_QUERY_BUILDER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_QUERY_BUILDER_ERROR,
        payload: error.response,
      });
    });
};
