import {
    ADD_CUSTOMER_REFUND_HEADER_REQUEST,
    ADD_CUSTOMER_REFUND_HEADER_SUCCESS,
    ADD_CUSTOMER_REFUND_HEADER_ERROR,
    GETALL_CUSTOMER_REFUND_HEADER_REQUEST,
    GETALL_CUSTOMER_REFUND_HEADER_SUCCESS,
    GETALL_CUSTOMER_REFUND_HEADER_ERROR,
    GET_ONE_CUSTOMER_REFUND_HEADER_REQUEST,
    GET_ONE_CUSTOMER_REFUND_HEADER_SUCCESS,
    GET_ONE_CUSTOMER_REFUND_HEADER_ERROR,
    DELETE_CUSTOMER_REFUND_HEADER_REQUEST,
    DELETE_CUSTOMER_REFUND_HEADER_SUCCESS,
    DELETE_CUSTOMER_REFUND_HEADER_ERROR,
    UPDATE_CUSTOMER_REFUND_HEADER_REQUEST,
    UPDATE_CUSTOMER_REFUND_HEADER_SUCCESS,
    UPDATE_CUSTOMER_REFUND_HEADER_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");

export const addCustomerRefundHeader = (token, formData) => (dispatch) => {
    
   
    dispatch({ type: ADD_CUSTOMER_REFUND_HEADER_REQUEST });
    let newData = {

        /////
        // payment_date: moment(formData.date).format("MM-DD-YYYY"),
        // payment_type_id: formData.paymentType,
        // payment_term_id: formData.paymentTerm,
        // gl_period_id: formData.postingPeriod,
        // customer_id: formData.customerID,
        customer_id: formData.customerID,
        gl_period_id: formData.postingPeriod,
        // account_id: formData.account,
        refund_date: moment(formData.date).format("MM-DD-YYYY"),
        memo: formData.memo,
        ar_account_id: formData.arAccount,
        company_id: formData.subsidiary,
        remarks: formData.remarks,
        account_id: formData.debit_account_id
        // credit_account_id: formData.arAccount,
        // debit_account_id: formData.debitAccount
    };
    return axios({
        method: "POST",
        url: `${BASEURL}create/customerRefundHeader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: ADD_CUSTOMER_REFUND_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CUSTOMER_REFUND_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getAllCustomerRefundHeaders = (token, data) => (dispatch) => {
    dispatch({ type: GETALL_CUSTOMER_REFUND_HEADER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/customerRefundHeader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            refund_header_no: data.transactionNo === "" ? null : data.transactionNo,
            customer_name: data.customerID === "" ? null : data.customerID,
            gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
            account_name: data.account === "" ? null : data.account,
            memo: data.memo === "" ? null : data.memo,
            ar_account_name: data.arAccount === "" ? null : data.arAccount,
            company_name: data.subsidiary === "" ? null : data.subsidiary,
            fromDate: moment(data.from_date).format("MM-DD-YYYY"),
            toDate: moment(data.to_date).format("MM-DD-YYYY"),
            company_id: 2,
        },
    })
        .then((response) => {

            return dispatch({
                type: GETALL_CUSTOMER_REFUND_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GETALL_CUSTOMER_REFUND_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getOneCustomerRefundHeader = (token, id) => (dispatch) => {
    dispatch({ type: GET_ONE_CUSTOMER_REFUND_HEADER_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/customerRefundHeader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_CUSTOMER_REFUND_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_CUSTOMER_REFUND_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const updateCustomerRefundHeader = (token, formData, customerRefundId) => (dispatch) => {
    let newData = {
        // date: moment(formData.refund_date).format("DD-MM-YYYY"),
        // date:formData.refund_date,
        gl_period_id: formData.postingPeriod,
        memo: formData.memo,
        remarks: formData.remarks,
        customer_id: formData.customerID,
        company_id: formData.subsidiary,
        refund_date: moment(formData.date).format("MM-DD-YYYY"),
        ar_account_id: formData.arAccount,
        // arAccount: formData.arAccount,
        // account_id: formData.account,
        account_id: formData.debit_account_id
    };
    dispatch({ type: UPDATE_CUSTOMER_REFUND_HEADER_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/customerRefundHeader/${customerRefundId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            // 
            return dispatch({

                type: UPDATE_CUSTOMER_REFUND_HEADER_SUCCESS,
                payloadUpdate: response?.data?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_CUSTOMER_REFUND_HEADER_ERROR,
                payloadUpdate: error.response,
            });
        });
};

export const deleteCustomerRefundHeader = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_CUSTOMER_REFUND_HEADER_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/customerRefundHeader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: DELETE_CUSTOMER_REFUND_HEADER_SUCCESS,
                payload: response?.data.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_CUSTOMER_REFUND_HEADER_ERROR,
                payload: error.response,
            });
        });
};
