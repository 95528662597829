import React, { useState, useEffect } from "react";
import "./login.css";
import { Form, Input, Button, Divider } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "../../redux/actions/auth";
import notification from "../../utils/notification";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import logo from "../../assets/images/OMS.png";

const Login = () => {
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => state);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [disable, setDisable] = useState(false);

	const handleLogin = () => {
		dispatch(userLogin(email, password)).then(async (res) => {
			if (res.type === "ADMIN_LOGIN_ERROR") {
				notification("error", "Login Error", res.payload.data.data);
			}
			if (res.type === "ADMIN_LOGIN_SUCCESS") {
				console.log({ res })
				console.log("res")
				localStorage.setItem("customer_type_id",` ${res?.payload?.customer_type_id ? res?.payload?.customer_type_id : null}`);
				localStorage.setItem("gender_id",` ${res?.payload?.gender_id ? res?.payload?.gender_id : null}`);
				// document.cookie = `customer_type_id=${res?.payload?.customer_type_id ? res?.payload?.customer_type_id : null}`;
				await dispatch(getDefaultCompany(res.payload.accessToken)).then((res) => {
					if (res.type === "GET_DEFAULT_COMPANY_SUCCESS") {
						console.log({ res })
						document.cookie = `defaultCompany=${res.payload.company_id}`;
						document.cookie = `defaultCompanyName=${res.payload.company_name}`;
					}
				});
				notification("success", "Login Success", "Login Successfully");
			}
			setPassword("");
		});
	};

	useEffect(() => {
		if (email === "" || password === "") {
			setDisable(true);
		} else {
			setDisable(false);
		}
		return () => {
			setDisable(false);
		};
	}, [email, password]);

	return (
		<div className="container-login">
			<div className="headerLogin">
				<div className="container">
					<img className="logoImage-login" style={{ userSelect: "none", height: "23.52px" }} src={logo} alt="DL1961" />
				</div>
			</div>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-8 col-lg-5">
						<div className="form login-form-main">
							<div className="heading login-title-area">
								{/* <h3>Fascom</h3> */}
								{/* <img className="loginLogo" src={logo} alt="DL1961" /> */}
								<h3 className="login-hd">OMS LOGIN</h3>
							</div>
							{/* <Divider /> */}
							<div className="form-inputs ">
								<Form className="ant-login-form" onFinish={() => handleLogin()}>
									<Input
										autoFocus
										className="input"
										size="large"
										placeholder="Username/Email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									<Input
										className="input"
										size="large"
										type={"password"}
										placeholder="Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<Button loading={auth.loading} disabled={disable} className="loginButton" htmlType="submit" type="primary" block>
										Login
									</Button>
									<Button className="forgotPassword" type="link" block>
										Forget your password?
									</Button>
								</Form>
							</div>
							<p className="login-form-text">
								By clicking on the Log In button, you understand and agree to{" "}
								<span className="links">
									<a href="#"> OMS Terms of Use</a>
								</span>{" "}
								and{" "}
								<span className="links">
									<a href="#"> OMS Privacy Policy</a>
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="footerLogin">
				<div className="container">
					<div className="row d-flex align-items-center justify-content-between">
						<div className="col-lg-6 text-lg-start text-center">
							<p className="copyright-txt">&copy; OMS | OMS Terms of Use for ERP Support Portal | OMS Privacy Policy </p>
						</div>
						<div className="col-lg-6 text-lg-end text-center">
							<p>OMS Suit</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Login;
