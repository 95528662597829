import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Button, Table } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import Loader from "react-loader-spinner";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { getARInventory } from "../../redux/actions/arInventory.action";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;


const ARInventory = ({ token }) => {
  TabTitle("AR Inventory")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    arInventory,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [intransitList, setIntransitList] = useState([]);
  const [excelData, setExcelData] = useState([])
  const initialDate = moment('2026-12-31')




  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((data) => {
      dispatch(getARInventory(token, data)).then((res => {
        setLoading(false)
        setIntransitList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      }));
    });
  };

  // useEffect(() => {
  //   dispatch(getStyle(token, menuId));
  //   dispatch(getGender(token, menuId));
  //   dispatch(getSeason(token, menuId));
  //   dispatch(getSeasonYear(token, menuId));
  //   dispatch(getProductStatus(token, menuId));
  //   dispatch(getProductClass(token, menuId));
  //   dispatch(getSize(token, menuId));
  //   dispatch(getWashName(token, menuId));
  //   dispatch(getFitCategory(token, menuId));
  //   dispatch(loadSalePerson(token));
  //   dispatch(getCustomerType(token));
  //   dispatch(getRegion(token, menuId));
  // }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };


  const colums = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
      sorter: (a, b) => {
        return a.item_code - b.item_code
      },
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      sorter: (a, b) => {
        if ((a.location_name !== null) & (b.location_name !== null)) {
          return a.location_name.localeCompare(b.location_name);
        }
      }
    },
    {
      children: [
        {
          title: 'T-Jacket',
          dataIndex: 'building',
          key: 'building',
          width: 60,
          children: [
            {
              title: 'Shirt',
              dataIndex: 'building',
              key: 'building',
              width: 40,
              children: [
                {
                  title: 'W-Plus',
                  dataIndex: 'building',
                  key: 'building',
                  width: 40,
                  children: [
                    {
                      title: 'Kids',
                      dataIndex: 'building',
                      key: 'building',
                      width: 40,
                      children: [
                        {
                          title: 'Men',
                          dataIndex: 'building',
                          key: 'building',
                          width: 40,
                          children: [
                            {
                              title: 'Women',
                              dataIndex: 'building',
                              key: 'building',
                              width: 55,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '"2/3"',
          align: 'right',
          dataIndex: 'qty_1',
          key: 'qty_1',
          width: 55,
          children: [
            {
              title: 'XS',
              dataIndex: 'qty_1',
              key: 'qty_1',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '10W',
                  dataIndex: 'qty_1',
                  key: 'qty_1',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '2',
                      dataIndex: 'qty_1',
                      key: 'qty_1',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '27',
                          dataIndex: 'qty_1',
                          key: 'qty_1',
                          align: 'right',
                          width: 50,
                          children: [
                            {
                              title: '23',
                              dataIndex: 'qty_1',
                              key: 'qty_1',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '"4/5"',
          align: 'right',
          dataIndex: 'qty_2',
          key: 'qty_2',
          width: 50,
          children: [
            {
              title: 'S',
              align: 'right',
              dataIndex: 'qty_2',
              key: 'qty_2',
              width: 55,
              children: [
                {
                  title: '12W',
                  align: 'right',
                  dataIndex: 'qty_2',
                  key: 'qty_2',
                  width: 55,
                  children: [
                    {
                      title: '3',
                      align: 'right',
                      dataIndex: 'qty_2',
                      key: 'qty_2',
                      width: 50,
                      children: [
                        {
                          title: '28',
                          align: 'right',
                          dataIndex: 'qty_2',
                          key: 'qty_2',
                          width: 50,
                          children: [
                            {
                              title: '24',
                              align: 'right',
                              dataIndex: 'qty_2',
                              key: 'qty_2',
                              width: 40,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '"6/6X"',
          dataIndex: 'qty_3',
          key: 'qty_3',
          align: 'right',
          width: 60,
          children: [
            {
              title: 'M',
              dataIndex: 'qty_3',
              key: 'qty_3',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '14W',
                  dataIndex: 'qty_3',
                  key: 'qty_3',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '4',
                      dataIndex: 'qty_3',
                      key: 'qty_3',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '29',
                          dataIndex: 'qty_3',
                          key: 'qty_3',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '25',
                              dataIndex: 'qty_3',
                              key: 'qty_3',
                              width: 45,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_4',
          key: 'qty_4',
          width: 50,
          align: 'right',
          children: [
            {
              title: 'L',
              dataIndex: 'qty_4',
              key: 'qty_4',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '16W',
                  dataIndex: 'qty_4',
                  key: 'qty_4',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '5',
                      dataIndex: 'qty_4',
                      key: 'qty_4',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '30',
                          dataIndex: 'qty_4',
                          key: 'qty_4',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '26',
                              dataIndex: 'qty_4',
                              key: 'qty_4',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_5',
          key: 'qty_5',
          width: 50,
          align: 'right',
          children: [
            {
              title: 'XL',
              dataIndex: 'qty_5',
              key: 'qty_5',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '18W',
                  dataIndex: 'qty_5',
                  key: 'qty_5',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '6',
                      dataIndex: 'qty_5',
                      key: 'qty_5',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '31',
                          dataIndex: 'qty_5',
                          key: 'qty_5',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '27',
                              dataIndex: 'qty_5',
                              key: 'qty_5',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_6',
          key: 'qty_6',
          width: 50,
          align: 'right',

          children: [
            {
              title: '1XL',
              dataIndex: 'qty_6',
              key: 'qty_6',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '20W',
                  dataIndex: 'qty_6',
                  key: 'qty_6',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '6x',
                      dataIndex: 'qty_6',
                      key: 'qty_6',
                      width: 50,
                      align: 'right',

                      children: [
                        {
                          title: '32',
                          dataIndex: 'qty_6',
                          key: 'qty_6',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '28',
                              dataIndex: 'qty_6',
                              key: 'qty_6',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_7',
          key: 'qty_7',
          width: 50,
          align: 'right',

          children: [
            {
              title: '2XL',
              dataIndex: 'qty_7',
              key: 'qty_7',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '22W',
                  dataIndex: 'qty_7',
                  key: 'qty_7',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '7',
                      dataIndex: 'qty_7',
                      key: 'qty_7',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '33',
                          dataIndex: 'qty_7',
                          key: 'qty_7',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '29',
                              dataIndex: 'qty_7',
                              key: 'qty_7',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_8',
          key: 'qty_8',
          width: 50,
          align: 'right',

          children: [
            {
              title: '3XL',
              dataIndex: 'qty_8',
              key: 'qty_8',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '24W',
                  dataIndex: 'qty_8',
                  key: 'qty_8',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '7x',
                      dataIndex: 'qty_8',
                      key: 'qty_8',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '34',
                          dataIndex: 'qty_8',
                          key: 'qty_8',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '30',
                              dataIndex: 'qty_8',
                              key: 'qty_8',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_9',
          key: 'qty_9',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_9',
              key: 'qty_9',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '26W',
                  dataIndex: 'qty_9',
                  key: 'qty_9',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '8',
                      dataIndex: 'qty_9',
                      key: 'qty_9',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '35',
                          dataIndex: 'qty_9',
                          key: 'qty_9',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '31',
                              dataIndex: 'qty_9',
                              key: 'qty_9',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_10',
          key: 'qty_10',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_10',
              key: 'qty_10',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '28W',
                  dataIndex: 'qty_10',
                  key: 'qty_10',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '10',
                      dataIndex: 'qty_10',
                      key: 'qty_10',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '36',
                          dataIndex: 'qty_10',
                          key: 'qty_10',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '32',
                              dataIndex: 'qty_10',
                              key: 'qty_10',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_11',
          key: 'qty_11',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_11',
              key: 'qty_11',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '30W',
                  dataIndex: 'qty_11',
                  key: 'qty_11',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '12',
                      dataIndex: 'qty_11',
                      key: 'qty_11',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '38',
                          dataIndex: 'qty_11',
                          key: 'qty_11',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '33',
                              dataIndex: 'qty_11',
                              key: 'qty_11',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_12',
          key: 'qty_12',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_12',
              key: 'qty_12',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '32W',
                  dataIndex: 'qty_12',
                  key: 'qty_12',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '14',
                      dataIndex: 'qty_12',
                      key: 'qty_12',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '40',
                          dataIndex: 'qty_12',
                          key: 'qty_12',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '34',
                              dataIndex: 'qty_12',
                              key: 'qty_12',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_13',
          key: 'qty_13',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_13',
              key: 'qty_13',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_13',
                  key: 'qty_13',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '16',
                      dataIndex: 'qty_13',
                      key: 'qty_13',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '42',
                          dataIndex: 'qty_13',
                          key: 'qty_13',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '35',
                              dataIndex: 'qty_13',
                              key: 'qty_13',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_14',
          key: 'qty_14',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_14',
              key: 'qty_14',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_14',
                  key: 'qty_14',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '18',
                      dataIndex: 'qty_14',
                      key: 'qty_14',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '44',
                          dataIndex: 'qty_14',
                          key: 'qty_14',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '36',
                              dataIndex: 'qty_14',
                              key: 'qty_14',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_15',
          key: 'qty_15',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_15',
              key: 'qty_15',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_15',
                  key: 'qty_15',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '20',
                      dataIndex: 'qty_15',
                      key: 'qty_15',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '46',
                          dataIndex: 'qty_15',
                          key: 'qty_15',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '37',
                              dataIndex: 'qty_15',
                              key: 'qty_15',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
        {
          title: '-',
          dataIndex: 'qty_16',
          key: 'qty_16',
          width: 50,
          align: 'right',

          children: [
            {
              title: '-',
              dataIndex: 'qty_16',
              key: 'qty_16',
              width: 50,
              align: 'right',
              children: [
                {
                  title: '-',
                  dataIndex: 'qty_16',
                  key: 'qty_16',
                  width: 50,
                  align: 'right',
                  children: [
                    {
                      title: '-',
                      dataIndex: 'qty_16',
                      key: 'qty_16',
                      width: 50,
                      align: 'right',
                      children: [
                        {
                          title: '48',
                          dataIndex: 'qty_16',
                          key: 'qty_16',
                          width: 50,
                          align: 'right',
                          children: [
                            {
                              title: '38',
                              dataIndex: 'qty_16',
                              key: 'qty_16',
                              width: 40,
                              align: 'right',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },]
            },
          ],
        },
      ],
    },
    {
      title: "Total Qty",
      dataIndex: "total_qty",
      key: "total_qty",
      align: "right",
      sorter: (a, b) => {
        return a.total_qty - b.total_qty
      },
    },
    {
      title: "Rate",
      dataIndex: "avg_Rate",
      key: "avg_Rate",
      align: "right",
      sorter: (a, b) => {
        return a.avg_Rate - b.avg_Rate
      },
    },
    {
      title: "Amount",
      dataIndex: "ending_inv_value",
      key: "shipped_quantity",
      align: "right",
      sorter: (a, b) => {
        return a.ending_inv_value - b.ending_inv_value
      },
    },
  ];


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "SKU": v.item_code,
          "Location": v.location_name,
          "=\"\"2/3\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": v.qty_1,
          "=\"\"4/5\"\"&CHAR(10)&\"\"S\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"28\"\"&CHAR(10)&\"\"24\"\"": v.qty_2,
          "=\"\"6/6X\"\"&CHAR(10)&\"\"M\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"29\"\"&CHAR(10)&\"\"25\"\"": v.qty_3,
          "=\"\"-\"\"&CHAR(10)&\"\"L\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"30\"\"&CHAR(10)&\"\"26\"\"": v.qty_4,
          "=\"\"-\"\"&CHAR(10)&\"\"XL\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"31\"\"&CHAR(10)&\"\"27\"\"": v.qty_5,
          "=\"\"-\"\"&CHAR(10)&\"\"1XL\"\"&CHAR(10)&\"\"6x\"\"&CHAR(10)&\"\"32\"\"&CHAR(10)&\"\"28\"\"": v.qty_6,
          "=\"\"-\"\"&CHAR(10)&\"\"2XL\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"33\"\"&CHAR(10)&\"\"29\"\"": v.qty_7,
          "=\"\"-\"\"&CHAR(10)&\"\"3XL\"\"&CHAR(10)&\"\"7x\"\"&CHAR(10)&\"\"34\"\"&CHAR(10)&\"\"30\"\"": v.qty_8,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"35\"\"&CHAR(10)&\"\"31\"\"": v.qty_9,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"36\"\"&CHAR(10)&\"\"32\"\"": v.qty_10,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"12\"\"&CHAR(10)&\"\"38\"\"&CHAR(10)&\"\"33\"\"": v.qty_11,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"14\"\"&CHAR(10)&\"\"40\"\"&CHAR(10)&\"\"34\"\"": v.qty_12,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"16\"\"&CHAR(10)&\"\"42\"\"&CHAR(10)&\"\"35\"\"": v.qty_13,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"18\"\"&CHAR(10)&\"\"44\"\"&CHAR(10)&\"\"36\"\"": v.qty_14,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"20\"\"&CHAR(10)&\"\"46\"\"&CHAR(10)&\"\"37\"\"": v.qty_15,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"48\"\"&CHAR(10)&\"\"38\"\"": v.qty_16,
          "Total Qty": v.total_qty,
          "Rate": v.avg_Rate,
          "Amount": v.ending_inv_value,
        };
      })
    )
  }


  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"AR-Inventory.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    // onClick={onDownload}
                    // className="cursor-not-allowed"
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>

              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
             AR Inventory
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">From</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment()}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">To</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={initialDate}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div style={{ marginTop: "4px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
              <hr className="my-1" />
            </div>
          </div>
        </Form>
      </div>
      <div
        // className="business_class_oms mb-2 distributor" style={{ width: "1400px" }}
        className="table_content international_order"
        style={{ maxWidth: '1260px' }}
      >
        <div>
          {arInventory?.payload?.data !== undefined ?
            (
              arInventory?.payload?.data.length > 0 ? (
                <Table
                  className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={intransitList}
                  columns={colums}
                  loading={arInventory.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                />
              ) : (
                <div className="no-Data">
                  {/* {console.log(arInventory?.payload?.data.length, 'arInventory?.payload?.data.length')} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ARInventory;
