import React from 'react'
import { Card } from 'antd';

const OrderReportFooter = () => {
  return (
    <div className='order_report_footer' 
    // style={{width: "1150px"}}
    >
      <div>
        Note: This estimates does Not include Sales Taxes. You will be invoiced with sales tax if you have not provided a valid resale Certificate
      </div>
      <div style={{display :"flex", justifyContent : "space-between"}}>
        <Card style={{ width: "420px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center" }}>Return Policy</p>
          <p className='footer_order_p'>1) No return of goods after 45 days of shipment.</p>
          <p className='footer_order_p'>2) Damage goods must be returned within 10 days of shipment.</p>
          <p className='footer_order_p'>3) No returns would be accepted without RA.</p>
          <p className='footer_order_p'>4) All Return must include a copy of this packing list.</p>
        </Card>
    

        <Card style={{ width: "310px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center"}}>Return Address</p>
          <p className='footer_order_p'>DL1961 Premium Denim c/o Bergen Logistics <br/>
            Bergen Shippers <br />
            299 Thomas E.Dunn Memorial Highway, 
            Rutherford, NJ 07070 <br/>
            Tel: 201 854 1512 Ext.276  </p>
        </Card>
      </div>

    </div>

  )
}

export default OrderReportFooter;