import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select, Tooltip, Table, Popconfirm, InputNumber, Checkbox } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import Swal from "sweetalert2";
import { addBillHeader, updateBillHeader } from "../../../../redux/actions/bill.action";
import { addBillLine, updateBillLine, getOneBillLineDetail, deleteBillLine } from "../../../../redux/actions/billLine.action";
import { addExpenseLine, updateExpenseLine, getOneExpenseLineDetail, deleteExpenseLine } from "../../../../redux/actions/expenseLine.action";
import { getChartofAccount } from "../../../../redux/actions/chartofaccount.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getLog } from "../../../../redux/actions/log.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import Summary from "../../../../components/OrderSummary/OrderSummary";
import EditableTable from "../../../../components/EditableTable/EditableTable";
import EditExpenseTable from "../../../../components/EditExpenseTable/EditExpenseTable";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAdminCurrency } from "../../../../redux/actions/adminCurrency.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getAdminLocation } from "../../../../redux/actions/adminLocation.action";
import { getShipVia } from "../../../../redux/actions/shipvia.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import numeral from "numeral";
import { getAccountPayable } from "../../../../redux/actions/accountPayable.action";
import { CSVLink } from "react-csv";
import { getTransactionNo } from "../../../../redux/actions/getTransactionNo.action";


const { Option } = Select;
const { TabPane } = Tabs;

export default function BillForm({ formData, val, token, formState, submit, saleOrderID, loading: deleteLoading,changeState }) {
	const dispatch = useDispatch();
	const { systemLog, arAccount, accountPayable, billFormData, expenseBill, ProductSale, invoiceGlPeriod, currency, companyAll, adminLocation, ShipVia, glImpact } = useSelector((state) => state);
	const companyId = ReadCookie("defaultCompany");
	const menuId = ReadCookie('menuId');
	const [form] = Form.useForm();
	const submitButton = useRef(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [visibleItems, setVisibleItems] = useState([]);
	const [orderItems, setOrderItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	//Expense Modal
	const [isExpenseModalVisible, setIsExpenseModalVisible] = useState(false);
	const [visibleExpenseItems, setVisibleExpenseItems] = useState([]);
	const [expenseItems, setExpenseItems] = useState([]);
	const [test, setTest] = useState(null);
	const [resultArr, setresultArr] = useState([]);
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
	const [oDate, setOrderDate] = useState(moment());
	const [dDate, setDeliveryDate] = useState(moment());
	const [adjustmentLocation, setAadjustmentLocation] = useState(null);
	const [headerNo, setHeaderNo] = useState(ReadCookie("defaultCompany"));
	const [deletedItems, setDeletedItems] = useState([]);
	const [deletedExpensesItems, setDeletedExpenseItems] = useState([]);
	const [lineId, setLineId] = useState([]);
	const [lineExpId, setLineExpId] = useState([]);
	const [editModalShow, setEditModalShow] = useState(false);
	const [itemIndex, setItemIndex] = useState(null);
	const [getLineRecord, setGetLineRecord] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [discountBasis, setDiscountBasis] = useState("");
	const [toogle, setToogle] = useState(null);
	const [adjLoc, setadjLoc] = useState(null);
	const [check,setCheck] = useState(false);

const handleDTransaction=(e)=>{
	
	dispatch(getTransactionNo(token,e.target.value)).then((res)=>{
		form.setFieldsValue({ dAmount: res?.payload?.data[0]?.gross_amount });
	});

}
const handleFTransaction=(e)=>{
	dispatch(getTransactionNo(token,e.target.value)).then((res)=>{
		
		form.setFieldsValue({ fAmount: res?.payload?.data[0]?.gross_amount });
	});
	// dispatch(getTransactionNo(dTransactionNo));
}
	useLayoutEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
			let result = res?.payload?.find((item) => {
				return item.period_name === currentDate
			});
			setCurrentDate(result?.period_id);
			form.setFieldsValue({ postingPeriod: result?.period_id });
		});
	}, [token, dispatch]);


	useEffect(() => {
		setLoading(deleteLoading);
	}, [deleteLoading]);

	useEffect(() => {
		dispatch(getChartofAccount(token));
		dispatch(getAdminCurrency(token, menuId));
		dispatch(getAllCompany(token));
		dispatch(getShipVia(token));
		dispatch(getAdminLocation(token));
		dispatch(getAccountPayable(token));
	}, []);
	var a = companyAll?.payload?.data[0]?.company_id;
	useEffect(() => {
		if (formState === "add") {
			form.setFieldsValue({
				billHeaderno: null,
				account: null,
				approveStatus: "A",
				billDate: oDate,
				dileveryDate: dDate,
				memo: null,
				postingPeriod: currentDate,
				referenceNo: null,
				fTransactionNo: null,
				dTransactionNo: null,
				shipVia: 2,
				vendor: null,
				subsidiary: 2,
				adjustmentLocation: null,
				currency: 1
			});
			setOrderItems([]);
			setExpenseItems([]);
		}
	}, [formState]);

	useEffect(() => {
		if (formState === "formView") {
			setLoading(true)
			dispatch(getOneBillLineDetail(token, saleOrderID)).then((res) => {
				if (res.type === "GET_ONE_BILL_LINE_DETAIL_SUCCESS") {
					setOrderItems(res.payload.data);
					setLoading(false)
				}
			});

			dispatch(getOneExpenseLineDetail(token, saleOrderID)).then((res) => {
				if (res.type === "GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS") {
					setExpenseItems(res.payload.data);
					setLoading(false)
				}
			});
			dispatch(getGLImpact(token, { "transaction_header_id": saleOrderID, "transaction_type": "BILL" }));
			dispatch(getSystemLog(token, { table_name: "pay_purchase_bill_header", record_id: saleOrderID }));
		}
	}, [formState]);

	useEffect(() => {
		if (formData) {
			console.log(formData?.delivery_date,"formData?.delivery_date");
			setLoading(false);

			form.setFieldsValue({
				approveStatus: formData?.is_approved,
				account: formData.account_id,
				billDate: moment(formData?.bill_date),
				dileveryDate:  formData?.delivery_date == null ? dDate : moment(formData?.delivery_date),
				postingPeriod: formData.gl_period_id,
				vendor: formData.vendor_id,
				shipVia: formData.ship_via,
				referenceNo: formData.reference_no,
				fTransactionNo: formData.f_transaction,
				dTransactionNo: formData.d_transaction,
				famount: formData?.f_amount,
				damount: formData?.d_amount,
				memo: formData.memo,
				billHeaderno: formData.bill_header_no,
				currency: formData.currency_name,
				adjustmentLocation: formData.location_id,
				subsidiary: formData.company_id,
			});
			setadjLoc(formData?.location_id)

		}
	}, [formData]);

	useEffect(() => {
		let filtered = orderItems.filter((f) => f.isSelected === true);
		setVisibleItems(filtered);
	}, [orderItems]);

	useEffect(() => {
		let filtered = expenseItems.filter((f) => f.isSelected === true);
		setVisibleExpenseItems(filtered);
	}, [expenseItems]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	// const handleDeleteOrder = (record) => {
	// 	const dataSource = [...orderItems];
	// 	if (formState !== "add" && record.bill_item_id) {
	// 		dispatch(deleteBillLine(token, record.bill_item_id)).then((res) => {
	// 			notification("success", "Item Transfer Deleted", res?.payload?.data);
	// 		});
	// 	}
	// 	if (dataSource.length > 0) {
	// 		setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));
	// 	} else {
	// 		const dataSource = [...visibleItems];
	// 		setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));
	// 	}
	// };
	const handleDeleteOrder = (record) => {
		const dataSource = [...orderItems];
		const deleteSource = [...deletedItems];
		const deleteEditableItem = [...filteredItems];
		deleteSource.push(record)
		setDeletedItems(deleteSource);
		setOrderItems(
			dataSource.filter((item) => item.item_id !== record.item_id)
		);
		setFilteredItems(
			deleteEditableItem.filter((item) => item.item_id !== record.item_id)
		);
		notification("success", "Item Deleted");
	};
	const handleDeleteExpense = (record) => {
		const dataSource = [...expenseItems];
		if (formState !== "add" && record.bill_item_id) {
			dispatch(deleteExpenseLine(token, record.bill_item_id)).then((res) => {
				notification("success", "Expense Deleted", res?.payload?.data);
			});
		}
		if (dataSource.length > 0) {
			setExpenseItems(dataSource.filter((item) => item.account_id !== record.account_id));
		} else {
			const dataSource = [...visibleExpenseItems];
			setExpenseItems(dataSource.filter((item) => item.account_id !== record.account_id));
		}
	};
	const handleDeleteExpenseOrder = (record) => {

		const dataSource = [...expenseItems];
		const deleteDataSource = [...deletedExpensesItems];
		deleteDataSource.push(record);
		setLineExpId(record.bill_item_id)
		setDeletedExpenseItems(deleteDataSource);
		setExpenseItems(dataSource.filter((item) => item.bill_item_id !== record.bill_item_id));
	};
	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
	}
	function handleChangeAdjustmentLocation(value, type) {
		setAadjustmentLocation(value);
		if (type === "company") {
			headerNo(value);
			form.setFieldsValue({ company: value });
		}
	}
	const onFinish = async (e) => {
		submit(false);
		setCheck(true)
		await form.validateFields().then((values) => {
			orderItems.filter((test) => {
			})
			setLoading(true);
			if (formState === "add") {
				if (orderItems.length > 0 || expenseItems.length > 0) {
					dispatch(addBillHeader(token, values, parseInt(companyId))).then((res) => {
						let id = res?.payload?.bill_header_id
						if (res.type === "ADD_BILL_HEADER_SUCCESS") {
							dispatch(
								addBillLine(
									token,
									orderItems.map((item) => {
										let amountt = item.amount * item.quantity;
										delete item.style_name;
										delete item.cut_name;
										delete item.fabric_name;
										delete item.gender_category_name;
										delete item.gender_name;
										delete item.inseam;
										delete item.isSelected;
										delete item.already;
										delete item.quantity_available;
										delete item.quantity_backorder;
										delete item.quantity_committed;
										delete item.quantity_on_hand;
										// delete item.rate;
										delete item.size;
										// delete item.style_id;
										// delete item.wash_id;
										delete item.wash_name;
										delete item.amount;
										delete item.quantity_packed;
										delete item.quantity_shipped;
										delete item.quantity_billed;
										delete item.commit_status;
										delete item.tax_amount;
										delete item.discount;
										delete item.discount_percent;
										delete item.tax_percent;
										delete item.net_amount;

										// .map(({
										// 	key1: stroke,
										// 	...rest
										//   }) => ({
										// 	stroke,
										// 	...rest
										//   }));
										// return { ...item, bill_header_id: res.payload.bill_header_id, gross_amount: item.amount };
										return {
											gross_amount: amountt,
											bill_header_id: res.payload.bill_header_id,
											...item,

										};
									})
								)
							).then((resline) => {
								if (resline.type === "ADD_BILL_LINE_SUCCESS") {
									dispatch(
										addExpenseLine(
											token,
											expenseItems.map((item) => {
												delete item.account_no;
												delete item.full_name;
												delete item.quantity;
												delete item.key;
												delete item.isSelected;
												return { ...item, bill_header_id: res.payload.bill_header_id };
											})
										)
									).then((res) => {
										if (res.type === "ADD_EXPENSE_LINE_SUCCESS") {
											notification("success", "Bill", "Bill created successfully!");
											// notification("success", "Bill Created", res?.payload?.data);
											setLoading(false);
											history.push({
												pathname: `/bill/edit/${id}`,
												search: `?_id=${id}`
											})
										} else if (res.type === "ADD_EXPENSE_LINE_ERROR") {
											Swal.fire({
												icon: "error",
												title: "Oops...",
												text: res?.payload?.data?.data,
											});
										}
									});
								} else if (res.type === "ADD_BILL_LINE_ERROR") {
									Swal.fire({
										icon: "error",
										title: "Oops...",
										text: res?.payload?.data?.data,
									});
								}
							});
						} else if (res.type === "ADD_BILL_HEADER_ERROR") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: res?.payload?.data?.data,
							});
						}
					})
				}
				else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "select atleast one Bill line",
					});
					return
				}
			} else if (formState === "edit" && check) {
				if (deletedItems.length > 0) {
					dispatch(deleteBillLine(token, saleOrderID, deletedItems));
				}
				if (deletedExpensesItems.length > 0) {
					dispatch(deleteExpenseLine(token, saleOrderID, deletedExpensesItems));
				}
				console.log(values,"dekh")
				dispatch(updateBillHeader(token, values, saleOrderID, parseInt(companyId))).then((res) => {
					if (res.type === "UPDATE_BILL_HEADER_SUCCESS") {

						dispatch(
							updateBillLine(
								token,
								orderItems.map((item) => {
									delete item.style_name;
									delete item.cut_name;
									delete item.fabric_name;
									delete item.gender_category_name;
									delete item.gender_name;
									delete item.inseam;
									delete item.isSelected;
									delete item.quantity_available;
									delete item.quantity_backorder;
									delete item.quantity_committed;
									delete item.quantity_on_hand;
									// delete item.rate;
									delete item.size;
									delete item.commit_status;
									delete item.tax_amount;
									delete item.discount;
									delete item.discount_percent;
									delete item.tax_percent;
									delete item.net_amount;
									delete item.already;
									// delete item.style_id;
									// delete item.wash_id;
									delete item.wash_name;
									delete item.amount;
									delete item.item_code;
									delete item.quantity_packed;
									delete item.quantity_shipped;
									delete item.quantity_billed;
									// delete item.gross_amount;
									return { ...item, bill_header_id: saleOrderID, gross_amount: Number(item.rate) * Number(item.quantity) };
								}),
								saleOrderID
							)
						).then((resline) => {
							if (resline.type === "UPDATE_BILL_LINE_SUCCESS") {
								dispatch(
									updateExpenseLine(
										token,
										expenseItems.map((items) => {
											return { ...items, bill_header_id: saleOrderID };
										}),
										saleOrderID
									)
								).then((res) => {
									if (res.type === "UPDATE_EXPENSE_LINE_SUCCESS") {
										setLoading(false);
										notification("success", "Bill Updated");
										changeState("formView")
									} else if (res.type === "UPDATE_EXPENSE_LINE_ERROR") {
										Swal.fire({
											icon: "error",
											title: "Oops...",
											text: res?.payload?.data?.data,
										});
									}
								});
							} else if (res.type === "UPDATE_BILL_LINE_ERROR") {
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					} else if (res.type === "UPDATE_BILL_HEADER_ERROR") {
						setLoading(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		});
	};

	const handleTab = (key) => {
		if (key === "header") {
		  dispatch(getSystemLog(token, { table_name: "pay_purchase_bill_header", record_id: saleOrderID }));
		} else if (key === "line") {
		  dispatch(getSystemLog(token, { table_name: "pay_purchase_bill_lines", record_id: saleOrderID }));
		} 
	  };
	const showModal = () => {
		setIsModalVisible(true);
	};
	const showEditModal = (record, index) => {
		setGetLineRecord(record);
		setEditModalShow(true);
		let copyArr = [...orderItems];
	};

	const showExpenseModal = () => {
		setIsExpenseModalVisible(true);
	};

	const discountAmount = (amount, orderItem = []) => {
		let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
		if (orderItem.length > 0) {
			orderIt = orderItem;
		}

		const sum = orderIt.map((item) => item.amount).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
		let totalAmount = orderIt.map((item) => item.quantity * item.rate);
		if (amount !== null || amount !== undefined) {
			if (orderIt.length > 0) {
				let calculateDiscount = orderIt.map((item) => {
					const totalSum = totalAmount.reduce((accum, value) => {
						return accum + value
					})
					item.discount = numeral((item.quantity * item.rate * numeral((amount / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00").replace(/,/g, '');
					item.discount_percent = numeral((amount / totalSum) * 100).format("0,0.00");
					item.net_amount = item.quantity * item.rate - numeral((item.quantity * item.rate * numeral((amount / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00");
					return item;
				});
				setOrderItems(calculateDiscount);
			}
		}
	};
	const discountPercent = (percent, orderItem = []) => {

		let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
		if (orderItem.length > 0) {
			orderIt = orderItem;
		}
		if (percent !== null || percent !== undefined) {
			if (orderIt.length > 0) {
				let calculateDiscount = orderIt.map((item, index) => {
					item.discount_percent = numeral(percent).format("0");
					item.discount = numeral(((item.quantity * item.rate) * percent) / 100).format("0,0.00").replace(/,/g, '');
					item.net_amount = numeral((item.quantity * item.rate) - (((item.quantity * item.rate) * percent) / 100) +
						(((item.quantity * item.rate) * item.tax_percent) / 100)).format("0.00");
					return item;
				});
				setOrderItems(calculateDiscount);
			}
		}
	};
	const addedItems = (value) => {
		const fillArr = [...orderItems];
		const discount = form?.getFieldValue()?.discount ? form.getFieldValue().discount : 0;
		const discountPercentage = form?.getFieldValue()?.discountPercent ? form.getFieldValue().discountPercent : 0;
		const onlyKey = [...filteredItems].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => { return onlyKey.find((i) => i === f.item_code) });
		fillArr.splice(indexofArr, 1);
		if (orderItems.length > 0) {
			let uniqueOrderItems = [...orderItems, ...filteredItems];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
			if (discountBasis === "A") {
				if (discount !== null || discount !== undefined) {
					discountAmount(parseFloat(discount).toFixed(0), uniqueOrderItems);
				}
			} else if (discountBasis === "P") {
				if (discountPercentage !== null || discountPercentage !== undefined) {
					discountPercent(parseFloat(discountPercentage).toFixed(0), uniqueOrderItems);
				}
			}
		} else {
			//remove Duplicates
			let uniqueOrderItems = [...visibleItems, ...filteredItems];
			uniqueOrderItems = uniqueOrderItems.filter(
				(v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
			);
			setOrderItems(uniqueOrderItems);

			if (discountBasis === "A") {
				if (discount !== null || discount !== undefined) {
					discountAmount(discount, uniqueOrderItems.map((item) => { return item; })
					);
				}
			} else if (discountBasis === "P") {
				if (discountPercentage !== null || discountPercentage !== undefined) {
					discountPercent(parseFloat(discountPercentage).toFixed(0), uniqueOrderItems);
				}
			}
		}
		notification("success", "Item Added Successfully");
	};

	const addedExpense = (value) => {
		const fillArr = [...orderItems];
		const onlyKey = [...value].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => {
			return onlyKey.find((i) => i === f.item_code);
		});
		fillArr.splice(indexofArr, 1);
		if (expenseItems.length > 0) {
			let uniqueOrderItems = [...expenseItems, ...value];
			//remove Duplicates
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.account_id === v.account_id) === i);
			setExpenseItems(uniqueOrderItems);
		} else {
			//remove Duplicates
			let uniqueOrderItems = [...visibleExpenseItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.account_id === v.account_id) === i);
			setExpenseItems(uniqueOrderItems);
		}
	};

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};

	const updateCostPrice = (type, item_code, value, index) => {

		setToogle(true);
		orderItems[index].rate = value;
		// setUpdatedCost(orderItems[itemIndex].rate);
	};


	const updateQuantity = (type, itemCode, value, p_qty, index) => {
		setToogle(true);
		orderItems[index].quantity = value;
	};


	const updateCommitt = (p_qty, p_avail, p_shipped, p_adjust, p_commit_status, index) => {
		let copyArr = [...orderItems];
		let balance_qty = p_qty - ((p_shipped === null || p_shipped === undefined) ? 0 : p_shipped) - ((p_adjust === null || p_adjust === undefined) ? 0 : p_adjust);
		if (balance_qty > 0) {
			if (p_commit_status === 1) {
				if (p_avail > balance_qty) {
					copyArr[index].quantity_committed = balance_qty + copyArr[index].quantity_committed;
					copyArr[index].quantity_backorder = 0;
					copyArr[index].commit_status = p_commit_status;
				}
				else {
					copyArr[index].quantity_committed = p_avail;
					copyArr[index].commit_status = p_commit_status;
					copyArr[index].quantity_backorder = balance_qty - p_avail;
				}
			}
			else if (p_commit_status === 3) {
				let packedShipped = copyArr[index].quantity_packed - copyArr[index].quantity_shipped
				let totalCommitQty = copyArr[index].quantity_committed - (packedShipped);
				copyArr[index].quantity_committed = packedShipped;
				copyArr[index].quantity_backorder = totalCommitQty;
				copyArr[index].commit_status = p_commit_status;

			}
			setOrderItems(copyArr);
		}
		else {
		// copyArr[index].commit_status = p_commit_status;
		
		}
	}


	const updateExpenseCostPrice = (value, index) => {
		let copyArr = [...expenseItems];

		copyArr[itemIndex].amount = value;
		setExpenseItems(copyArr);

	}


	const glImpcatCols = [

		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},

		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const columns = [

		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Style",
			dataIndex: "style_name",
		},

		{
			title: "Wash",
			dataIndex: "wash_name",
			editable: true,
		},
		{
			title: "Rate",
			dataIndex: "rate",
			width: 80,
			render: (text, record, index) => {
				return <>

					<InputNumber
						size="small"
						value={record.rate}
						onChange={(value) => {
							updateCostPrice("up", record.item_code, value, orderItems.findIndex((v) => v.item_code === record.item_code))
							updateCommitt(record.quantity);
						}}

					/>

				</>
			}
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			width: 80,
			editable: true,
			render: (text, record, index) => {
				return <>
					<InputNumber
						size="small"
						value={record.quantity}
						onChange={(value) => {
							updateCommitt(record.quantity);
							updateQuantity("up", record.item_code, value, record.quantity_committed, orderItems.findIndex((v) => v.item_code === record.item_code));
						}}
					/>

				</>;
			},
		},
		{

			title: "Amount",
			width: 80,
			render: (_, record, index) => {
				return numeral(record.quantity * record.rate).format("0,0.00");
				// toogle
				// 	? numeral(orderItems[index].quantity * orderItems[index].rate).format("0,0.00")
				// : 
			},
		},



		// {
		// 	title: "Rate",
		// 	dataIndex: "rate",
		// 	editable: true,
		// },

		// {
		// 	title: "Operation",
		// 	dataIndex: "operation",

		// },
		// {
		// 	title: "Operation",
		// 	dataIndex: "operation",
		// 	render: (_, record) => (
		// 		<Tooltip title="Delete" color={"white"}>
		// 			<Popconfirm title={`Are you sure to delete the expense ${record.bill_item_id}?`} onConfirm={() => handleDeleteOrder(record)}>
		// 				<DeleteOutlined className="delete-Button" />
		// 			</Popconfirm>
		// 		</Tooltip>
		// 	),
		// },
		{
			title: "Operation",
			width: 80,
			dataIndex: "operation",
			render: (_, record, index) => (
				<Tooltip title="Delete" color={"white"}>
					{ formState==="formView"?"":
					<>
						<Popconfirm
							title={`Are you sure to delete the item ${record.item_id}?`}
							onConfirm={() => handleDeleteOrder(record)}
						>
							<DeleteOutlined className={`delete-Button`} />
						</Popconfirm>
						{/* <EditOutlined
							className={`edit-Button`}
							onClick={() => {
								showEditModal(record, orderItems.findIndex((v) => v.item_code === record.item_code))
								setItemIndex(orderItems.findIndex((v) => v.item_code === record.item_code))
							}}
						// onClick={() => showEditModal(record)}
						/> */}
					</>
		}
				</Tooltip>
			),
		},
	];


	console.log("OrderItems", orderItems)

	const expenseColumns = [
		{
			title: "Account Id",
			dataIndex: "account_id",
		},
		{
			title: "Account No",
			dataIndex: "account_no",
		},
		{
			title: "Full Name",
			dataIndex: "full_name",
			editable: true,
		},
		{
			title: "Amount",
			dataIndex: "amount",
			editable: true,
			render: (_, record, index) => {

				return (
					<>
						{editModalShow && itemIndex === index ? (
							<InputNumber
								size="small"
								defaultValue={expenseItems[itemIndex].amount}
								onChange={(value) => {
									updateExpenseCostPrice(value, index)
								}}

							/>
						) : (
							<>
								{numeral(record.amount).format("00.00")}
							</>
							// orderItems[index] && orderItems[index].rate
						)}
					</>
				)
			}
		},
		{
			title: "Operation",
			dataIndex: "operation",
			render: (_, record, index) => (
				<Tooltip title="Delete" color={"white"}>
					{ formState==="formView"?"":
					<Popconfirm title={`Are you sure to delete the expense ${record.account_id}?`} onConfirm={() => handleDeleteExpenseOrder(record)}>
						<DeleteOutlined className="delete-Button" />
					</Popconfirm>}
					{ formState==="formView"?"":
					<EditOutlined
						className={`edit-Button`}
						onClick={() => {
							showEditModal(record, index)
							setItemIndex(index)
						}}
					// onClick={() => showEditModal(record)}
					/>}
				</Tooltip>
			),
		},
	];
	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}



	return (
		<>
			<EditableTable
				companyId={companyId}
				token={token}
				headerNo={headerNo}
				modalValue={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				setOrderItems={addedItems}
				orderItems={orderItems}
				//   setShowGrid={setShowGrid}
				adjustmentLocation={adjustmentLocation}
				setFilteredItems={setFilteredItems}
				adjLoc={adjLoc}
			/>
			<EditExpenseTable
				token={token}
				companyId={companyId}
				modalValue={isExpenseModalVisible}
				setIsModalVisible={setIsExpenseModalVisible}
				setOrderItems={addedExpense}
				orderItems={expenseItems}
			/>
			{/* {loading && (
				<div className="loaderDiv">
					<Loader type="Grid" color="#212121" height={40} width={40} />
				</div>
			)} */}
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>

							<Bcol md={12} xl={12} xxl={10} className="pbpx-10">

								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="billHeaderno" label="Bill Header No" labelAlign="left" labelCol={{ span: 5 }} >
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Bill Date" name="billDate" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Bill Date is required" }]}>
												{/* <DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Bill Date"
													allowClear
													className="dateInput customInputs"
												/> */}
												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Transfer Date"
													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().billDate
															: moment(oDate)
													}
													onChange={(e) => {
														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id })
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="account" label="A/P Account" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Account is required" }]}>
												<Select
													showSearch
													allowClear
													size="small"
													className="styleInput"
													loading={accountPayable.loading}
													disabled={!(formState === "add" || formState === "edit")}
													optionFilterProp="children"
												>
													{accountPayable?.payload?.data?.map((acc) => {
														return <Option key={acc.account_id} value={acc.account_id}>{acc.account_name}</Option>;
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													showSearch
													allowClear
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													// placeholder="Select Gl Period"
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="currency" label="Currency" labelAlign="left" labelCol={{ span: 5 }}>
												<Select
													showSearch
													allowClear
													size="small"
													className="styleInput"
													// placeholder="Currency"
													disabled={!(formState === "add" || formState === "edit")}
													optionFilterProp="children"
												>

													{currency && currency?.payload?.data?.map((acc) => {
														return <Option value={acc.currency_id}>{acc.currency_name}</Option>;
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="vendor" label="Vendor" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Vendor is required" }]}>
												<Select
													size="small"
													autoComplete={"nope"}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													showSearch
													allowClear
													loading={billFormData?.loading}
													disabled={!(formState === "add" || formState === "edit")}
													defaultValue={form.getFieldValue().vendor}
													// placeholder="Select Vendor"
													onChange={(value) => handleChange(value)}
												>
													{billFormData?.payload?.vendor.map((item) => {
														return (
															<Option key={item.vendor_id} value={item.vendor_id}>
																{item.vendor_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="shipVia" label="Transit Type" labelAlign="left" labelCol={{ span: 5 }}>
												<Select
													size="small"
													autoComplete={"nope"}
													filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
													showSearch
													allowClear
													loading={ShipVia?.loading}
													disabled={!(formState === "add" || formState === "edit")}
													defaultValue={form?.getFieldValue().shipVia}
													// placeholder="Select Ship Via"
													onChange={(value) => handleChange(value)}
												>
													{ShipVia?.payload?.data.map((item) => {
														return (
															<Option key={item.ship_via_id} value={item.ship_via_id}>
																{item.ship_via_description}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="approveStatus"
												label="Approve Status"
												labelAlign="left"
												labelCol={{ span: 5 }}
											>
												<Select
													size="small"
													autoComplete={"nope"}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													showSearch
													allowClear
													disabled={!(formState === "add" || formState === "edit")}
													defaultValue={form.getFieldValue().approveStatus}
													// placeholder="Select Approve Status"
													onChange={(value) => handleChange(value)}
												>
													<Option key={1} value={"A"} id={11}>
														Approved
													</Option>
													<Option key={2} value={"P"}>
														Pending
													</Option>
												</Select>
											</Form.Item>
										</div>
										{/* <Checkbox onChange={handlePaymentHold}>Payment Hold</Checkbox> */}
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="referenceNo"
												label="Reference No"
												labelAlign="left"
												labelCol={{ span: 5 }}
											>
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 5 }}>
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="adjustmentLocation"
												label="Location:"
												labelAlign="left"
												labelCol={{
													// xs: 9,
													// xxl: 7
													span: 5
												}}
											// rules={[{ required: true, message: "Adjustment Location is required" }]}
											>
												<Select
													size="small"
													autoComplete={"nope"}
													filterOption={(input, option) =>
														option.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}
													showSearch
													allowClear
													tabIndex={18}
													loading={adminLocation.loading}
													disabled={!(formState === "add" || formState === "edit")}
													defaultValue={form.getFieldValue().adjustmentLocation}
													onChange={(value) =>
														handleChangeAdjustmentLocation(value)
													}
												>
													{adminLocation?.payload?.data?.map((item) => {
														return (
															<Option
																key={item.location_id}
																value={item.location_id}
															>
																{item.location_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Delivery Date" name="dileveryDate" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Delivery Date is required" }]}>
												{/* <DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Bill Date"
													allowClear
													className="dateInput customInputs"
												/> */}
												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Transfer Date"
													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().dileveryDate
															: moment(oDate)
													}
													onChange={(e) => setDeliveryDate(moment(e))}
												/>
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
								<Brow>
								<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="fTransactionNo"
												label="F Transaction #"
												labelAlign="left"
												labelCol={{ span: 5 }}
											>
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} onChange={handleFTransaction}/>
											</Form.Item>
										</div>
									</Bcol>
								<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="dTransactionNo"
												label="D Transaction #"
												labelAlign="left"
												labelCol={{ span: 5 }}
											>
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} onChange={handleDTransaction}/>
											</Form.Item>
										</div>
									</Bcol>
								<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="fAmount"
												label="F Amount"
												labelAlign="left"
												labelCol={{ span: 5 }}
												defaultValue={form.getFieldValue().fAmount}
											>
												<Input tabIndex={1} size="small" className="customInputs" disabled={(formState === "add" || formState === "edit"|| formState === "formView")} />
											</Form.Item>
										</div>
									</Bcol>
								<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="dAmount"
												label="D Amount"
												labelAlign="left"
												labelCol={{ span: 5 }}
												defaultValue={form.getFieldValue().dAmount}
												>
												<Input tabIndex={1} size="small" className="customInputs" disabled={(formState === "add" || formState === "edit"|| formState === "formView")} />
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
							<Bcol md={4} xl={3} xxl={2}>
								<Summary orderItems={orderItems.length > 0 ? orderItems : visibleItems} shippingCost={form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0} expenseItems={expenseItems.length > 0 ? expenseItems : 0} />
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					<SubTitles name={"Classification"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Bcol md={12} xl={9} xxl={10}>
							<Brow>
								<Bcol lg={6} xxl={4}>
									<div className="firstSectionRo">
										<Form.Item
											name="subsidiary"
											label="Subsidiary"
											labelAlign="left"
											labelCol={{ span: 5 }}
											// rules={[{ required: true, message: "Subsidiary Id is required" }]}
										>
											<Select
												size="small"
												autoComplete={"nope"}
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												showSearch
												allowClear
												disabled={true}
												loading={companyAll.loading}
												// disabled={!(formState === "add" || formState === "edit")}
												defaultValue={form.getFieldValue().subsidiary}
												// placeholder="Select Subsidiary"
												onChange={(value) => handleChange(value)}
											>
												{companyAll?.payload?.data.map((item) => {
													return (
														<Option key={item.company_id} value={item.company_id}>
															{item.company_name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</div>
								</Bcol>
							</Brow>
						</Bcol>
					</div>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Items" key="1">
							<div style={{display: "flex", gap: 10}} className="pbpx-20">
								<Button type="primary" className="modalButton" disabled={!(formState === "edit" || formState === "add")} onClick={showModal}>
									Add Item
								</Button>
								{
									orderItems.length > 0 ? (
										<CSVLink style={{border: "1px solid black", padding: "3px 15px 3px 15px", borderRadius: 5}} data={orderItems.map((v, i) => {
											return {
												"item_code": `${v.item_code} -`,
												style_name: v.style_name,
												wash_name: v.wash_name,
												rate: v.rate,
												quantity: v.quantity,
												Amount: v.quantity * v.rate,
											}
										})}>Export to Excel</CSVLink>
									) : null
								}
							</div>

							{(orderItems.length > 0 || visibleItems.length > 0) && (
								<Table
									type={"company"}
									loading={ProductSale.loading}
									// scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									// dataSource={[...orderItems.from({ length: (1 - 1) * 10 })]}
									dataSource={orderItems.length > 0 ? orderItems : []}
									columns={columns}
									paginate
									perPage={15}
								/>
							)}
						</TabPane>
						<TabPane tab="Expenses" key="bill">
							<div className="pbpx-20">
								<Button type="primary" className="modalButton" disabled={!(formState === "edit" || formState === "add")} onClick={showExpenseModal}>
									Add Expense
								</Button>
							</div>
							{(expenseItems.length > 0 || visibleExpenseItems.length > 0) && (
								<Table
									type={"company"}
									loading={expenseBill.loading}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									dataSource={expenseItems}
									columns={expenseColumns}
									pagination={false}
								/>
							)}
						</TabPane>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact">
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
								  <Tabs
                type="card"
                size="small"
                defaultActiveKey="1"
                centered
              onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Header" key="header">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                <TabPane tab="Line" key="line">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                  </Tabs>
							</TabPane>}
					</Tabs>
				</div>
			</Form>
		</>
	);
}
