import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { getAccountType, addAccountType, updateAccountType, deleteAccountType } from "../../redux/actions/accounttype.action";
import { Row, Col, Form, Input, Checkbox, Divider, Select } from "antd";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { getRights } from "../../redux/actions/userRights.action";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { accountTypeColumn } from "../../utils/cols";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import Tables from "../../components/Table/Tables";
import { CSVLink } from "react-csv";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
const { Option } = Select;

const rateList = ["AVERAGE", "CURRENT", "HISTORICAL"];
const AccountType = ({ token }) => {
  TabTitle("Account Type")
  const dispatch = useDispatch();
  const { userRights, accounttype } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [account_type_code, setAccountName] = useState("");
  const [formState, setFormState] = useState("view");
  const [form] = Form.useForm();
  const accountNameInput = useRef(null);
  const [disable, setDisable] = useState(false);
  const [accountTypeObj, setAccountTypeObj] = useState(null);
  const [check, setCheck] = useState(true);
  const [leftt, setLeft] = useState(true);
  const [include_inreval_default, setInterval] = useState(true);
  const [balance_sheet, setBalance] = useState(true);
  const [default_general_rate_type, setGeneralRate] = useState("");
  const [default_cash_flow_rate_type, setFlowRate] = useState("");
  const [account_type_description, setDescription] = useState("");
  const [elimination_algo, setElimination] = useState(null);
  const [user_can_change_reval_option, setReveal] = useState(false);
  const id = ReadCookie("menuId");

  useEffect(() => {
    dispatch(getAccountType(token, id));
  }, [token, id]);

  const handleDelete = () => {
    if (accountTypeObj) {
      dispatch(deleteAccountType(token, accountTypeObj.account_type_id)).then(
        (res) => {
          if (res.type === "DELETE_ACCOUNT_TYPE_SUCCESS") {
            notification(
              "success",
              "Account Type Deleted",
              "Account Type Deleted Successfully!"
            );
          }
          if (res.type === "DELETE_ACCOUNT_TYPE_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          form.setFieldsValue({
            id: null,
            name: "",
            active: accountTypeObj.is_active,
            eli: null,
            desc: "",
            cash: null,
            rate: null,
            left: true,
            interval: true,
            balance: true,
            reveal: true,
          });
          setAccountName("");
          setAccountTypeObj(null);
          setFormState("view");
          dispatch(getAccountType(token, id));
        }
      );
    }
    setFormState("view");
  };

  useLayoutEffect(() => {
    dispatch(getRights(token, id)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);
  useEffect(() => {
    if (accountTypeObj) {
      setFormState("view");
      form.setFieldsValue({
        id: accountTypeObj.account_type_id,
        name: accountTypeObj.account_type_code,
        active: accountTypeObj.is_active,
        eli: accountTypeObj.elimination_algo,
        desc: accountTypeObj.account_type_description,
        cash: accountTypeObj.default_cash_flow_rate_type,
        rate: accountTypeObj.default_general_rate_type,
        left: accountTypeObj.leftt,
        interval: accountTypeObj.include_inreval_default,
        balance: accountTypeObj.balance_sheet,
        reveal: accountTypeObj.user_can_change_reval_option,
      });
      setAccountName(accountTypeObj.account_type_code);
      setBalance(accountTypeObj.balance_sheet);
      setLeft(accountTypeObj.leftt);
      setFlowRate(accountTypeObj.default_cash_flow_rate_type);
      setGeneralRate(accountTypeObj.default_general_rate_type);
      setInterval(accountTypeObj.include_inreval_default);
      setReveal(accountTypeObj.user_can_change_reval_option);
      setElimination(accountTypeObj.elimination_algo);
      setDescription(accountTypeObj.account_type_description);
      setCheck(accountTypeObj.is_active);
    }
  }, [accountTypeObj]);
  useEffect(() => {
    if (account_type_code === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [account_type_code]);

  const formSave = () => {
    if (formState === "add") {
      dispatch(
        addAccountType(
          token,
          {
            account_type_code,
            account_type_description,
            elimination_algo,
            default_cash_flow_rate_type,
            default_general_rate_type,
            user_can_change_reval_option,
            balance_sheet,
            include_inreval_default,
            leftt,
            is_active: check,
          },
          id
        )
      ).then((res) => {
        if (res.type === "ADD_ACCOUNT_TYPE_ERROR") {
          notification("error", "Not Created", res?.payload?.data?.data);
        }
        if (res.type === "ADD_ACCOUNT_TYPE_SUCCESS") {
          notification(
            "success",
            "Account Type Created",
            "Account Type Created Successfully!"
          );
          form.setFieldsValue({
            id: null,
            name: "",
            active: true,
            eli: null,
            desc: "",
            cash: null,
            rate: null,
            left: true,
            interval: true,
            balance: true,
            reveal: true,
          });
          setFormState("view");
          setAccountName("");
          setBalance(true);
          setCheck(true);
          setDescription("");
          setElimination(null);
          setFlowRate(null);
          setGeneralRate(null);
          setInterval(true);
          setLeft(true);
          setAccountTypeObj(null);
        }

        dispatch(getAccountType(token, id));
      });
    }
    if (formState === "edit") {
      dispatch(
        updateAccountType(
          token,
          {
            account_type_code,
            account_type_description,
            elimination_algo,
            default_cash_flow_rate_type,
            default_general_rate_type,
            user_can_change_reval_option,
            balance_sheet,
            include_inreval_default,
            leftt,
            is_active: check,
          },
          accountTypeObj.account_type_id
        )
      ).then((res) => {
        if (res.type === "UPDATE_ACCOUNT_TYPE_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_ACCOUNT_TYPE_SUCCESS") {
          notification(
            "success",
            "Account Type Updated",
            "Account Type Updated Successfully"
          );
        }
        dispatch(getAccountType(token, id));
      });
    }
  };

  useEffect(() => {
    if (formState === "add") {
      accountNameInput.current.focus();
      form.setFieldsValue({
        id: null,
        name: "",
        active: true,
        eli: null,
        desc: "",
        cash: null,
        rate: null,
        left: true,
        interval: true,
        balance: true,
        reveal: true,
      });

      setAccountName("");
      setBalance(true);
      setCheck(true);
      setDescription("");
      setElimination(null);
      setFlowRate(null);
      setGeneralRate(null);
      setInterval(true);
      setLeft(true);
      setAccountTypeObj(null);
    }
  }, [formState]);
  const filterReset = () => {
    setFormState("view");
    setAccountTypeObj(null);
    setCheck(true);
    form.setFieldsValue({
      id: null,
      name: "",
      active: true,
      eli: null,
      desc: "",
      cash: null,
      rate: null,
      left: true,
      interval: true,
      balance: true,
      reveal: true,
    });
    setFormState("view");
    setAccountName("");
    setBalance(true);
    setCheck(true);
    setDescription("");
    setElimination(null);
    setFlowRate(null);
    setGeneralRate(null);
    setInterval(true);
    setLeft(true);
    setAccountTypeObj(null);
  };
  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Account Type"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                formType={formState}
                rights={userRights?.payload}
                formState={setFormState}
                reset={filterReset}
                disable={disable}
                save={formSave}
                onDelete={handleDelete}
              />
            </div>
            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <CSVLink
                      filename={"chart-of-account.csv"}
                      data={accounttype?.payload?.data?.length > 0 ? accounttype?.payload?.data : []}
                    >
                      Download Excel
                    </CSVLink>
                    <Tables
                      type="accounttype"
                      formState={formState}
                      // scrollX={"20rem"}
                      accountTypeObj={setAccountTypeObj}
                      source={accounttype?.payload?.data}
                      col={accountTypeColumn}
                      paginate
                      scroll={602}
                      perPage={25}
                      load={accounttype.loading}
                      rowKey="account_type_id"
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">

                    <Form onFinish={formSave} form={form}>
                      <div className="customRow styleFormUpper d-flex flex-row flex-wrap justify-content-start">
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Account Type Code"
                            name="name"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            rules={[
                              {
                                required: true,
                                message: "Account Name is required",
                              },
                            ]}
                          >
                            <Input
                              size="small"
                              allowClear
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setAccountName(e.target.value)}
                              className="customInputs no-border-first"
                              ref={accountNameInput}
                            />
                          </Form.Item>
                        </div>

                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Account Type Description"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            name="desc"
                            rules={[
                              {
                                required: true,
                                message: "Description is required",
                              },
                            ]}
                          >
                            <Input
                              size="small"
                              allowClear
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setDescription(e.target.value)}
                              className="customInputs no-border-first"
                            />
                          </Form.Item>
                        </div>
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Elimination Algo"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            name="eli"
                            rules={[
                              {
                                required: true,
                                message: "Elimination Algo is required",
                              },
                            ]}
                          >
                            <Input
                              size="small"
                              allowClear
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setElimination(e.target.value)}
                              className="customInputs no-border-first"
                            />
                          </Form.Item>
                        </div>
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Cash Flow Rate Type"
                            name="cash"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            rules={[
                              {
                                required: true,
                                message: "Cash Flow rate is required",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              className="styleInput"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              showSearch
                              optionFilterProp="children"
                              onChange={(e) => setFlowRate(e)}
                            >
                              {rateList.map((list) => {
                                return <Option value={list}>{list}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="General Rate Type"
                            name="rate"
                            rules={[
                              {
                                required: true,
                                message: "General rate is required",
                              },
                            ]}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Select
                              size="small"
                              className="styleInput"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              showSearch
                              optionFilterProp="children"
                              onChange={(e) => setGeneralRate(e)}
                            >
                              {rateList.map((list) => {
                                return <Option value={list}>{list}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Active "
                            name="active"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setCheck(e.target.checked)}
                              checked={check}
                            ></Checkbox>
                          </Form.Item>
                        </div>

                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Left"
                            name="left"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setLeft(e.target.checked)}
                              checked={leftt}
                            ></Checkbox>
                          </Form.Item>
                        </div>
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Balance Sheet"
                            name="balance"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setBalance(e.target.checked)}
                              checked={balance_sheet}
                            ></Checkbox>
                          </Form.Item>
                        </div>
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Include Interval"
                            name="interval"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setInterval(e.target.checked)}
                              checked={include_inreval_default}
                            ></Checkbox>
                          </Form.Item>
                        </div>
                        <div className="customCols slc m-0 width400">
                          <Form.Item
                            label="Change Reveal Option"
                            name="reveal"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              onChange={(e) => setReveal(e.target.checked)}
                              checked={user_can_change_reval_option}
                            ></Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    {/* </div> */}

                    <div className="modalStyle"></div>
                    <Divider />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountType;
