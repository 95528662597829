import React, { useEffect, useState, useRef } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { ReadCookie } from "../../utils/readCookie";
import { getWashName } from "../../redux/actions/wash.action";
import { getStyle } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { useHistory } from "react-router-dom";
import { Excel } from "antd-table-saveas-excel";
import Loader from "react-loader-spinner";
import { getOtsDashboard } from "../../redux/actions/otsDashboard.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import numeral from "numeral";
const dateFormatList = ["MM-DD-YYYY"];

const OTSDashboard = ({ token }) => {
  TabTitle("OTS Dashboard")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    getWiseStockReport,
    productType,
    gender,
    genderCategory,
    season,
    productStatus,
    productClass,
    size,
    wash,
    fitCategory,
    omsStockSummary,
    genderCategoryChange,
    styleChange,
    Sku,
    otsDashboard
  } = useSelector((state) => state);
  const history = useHistory();
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [isChecked, setIsChecked] = useState([]);
  const [isChecked2, setIsChecked2] = useState([]);
  const [allSesion, setAllSesion] = useState(true);
  const [allStatus, setAllStatus] = useState(true);
  const [isPCChecked, setIsPCChecked] = useState([]);
  const [allPC, setAllPC] = useState(true);
  const [loading, setLoading] = useState(false);
  const [BgradeCheck, setBgradeCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState([])
  const tableRef = useRef(null);
  const [excelData, setExcelData] = useState([])


  let result =
    otsDashboard &&
    otsDashboard?.payload?.data?.reduce(function (r, a) {
      r[a.gender] = r[a.gender] || [];
      r[a.gender].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);

  const values = {
    item_id: null,
    location_id: null,
  };

  const { Option } = Select;
  let pendingDate = "";

  const onChangeDate = (date, dateString) => {
    pendingDate = dateString;
  };

  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      console.log(values, 'values-----');
      dispatch(
        getOtsDashboard(token, values, isChecked, isChecked2, isPCChecked, BgradeCheck)).then((res => {
          setLoading(false)
          setData(res?.payload?.data)
          downloadExcelFileData(res?.payload?.data);
        }));
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Gender": v.gender,
          "CURR SSN": v.current_season,
          "# SKU": v.noofsku,
          "BERGEN": v.bergan,
          "TRNST #": v.transit,
          "PK": v.pakistan,
          "PROD": v.production,
          "PEND": v.pending,
          "INT PEND": v.international,
          "OTS": v.ots,
          "ECOM": v.ecommerce,
          "Nord": v.nord,
          "Nemin": v.neiman,
          "Saks": v.saks,
          "TOTAL OTS": v.ots + v.nord + v.saks + v.ecommerce + v.neiman,
          "Speciality": v.shipped_special,
          "Major's": v.shipped_depart,
          "Online": v.shipped_online,
          "OFF Price": v.shipped_offprice,
          "International": v.shipped_international,
          "Internal": v.shipped_internal,
          "Total": parseFloat(v.shipped_special) + parseFloat(v.shipped_depart) + parseFloat(v.shipped_online) + parseFloat(v.shipped_offprice) + parseFloat(v.shipped_international) + parseFloat(v.shipped_internal),
         
        };
      })
    )
  }

  useEffect(() => {
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getGender(token, menuId));
  }, [dispatch, menuId, token]);


  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus])


  const handlePrint = () => {
    window.print();
  };

  const handleExcelExport = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columsPageOne)
      .addDataSource(otsDashboard?.payload?.data, {
        str2Percent: true,
      })
      .setTHeadStyle()
      .saveAs("Excel.xlsx");
  };

  const columsPageOne = [
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Gender Cateogry",
      dataIndex: "gender_cateogry",
      key: "gender_cateogry",
    },
    {
      title: "Current Season",
      dataIndex: "current_season",
      key: "current_season",
    },
    {
      title: "Fabric",
      dataIndex: "fabric",
      key: "fabric",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
    },
    {
      title: "Wash",
      dataIndex: "wash",
      key: "wash",
    },
    {
      title: "Fit Category",
      dataIndex: "fit_category",
      key: "fit_category",
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "producti  on",
    },
    {
      title: "PK",
      dataIndex: "pakistan",
      key: "pakistan",
    },
    {
      title: "Transit",
      dataIndex: "transit",
      key: "transit",
    },
    {
      title: "Bergen",
      dataIndex: "bergan",
      key: "bergan",
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
    },
    {
      title: "Intl",
      dataIndex: "international",
      key: "international",
    },
    {
      title: "OTS",
      dataIndex: "ots",
      key: "ots",
    },
    {
      title: "Retail",
      dataIndex: "retail_price",
      key: "retail_price",
    },
    {
      title: "Cost Price",
      dataIndex: "ws_price",
      key: "ws_price",
    },
  ];

  return (
    <div className="header_class business_header_oms">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"OTS-Dashboard.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                  // onClick={handleExcelExport}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              OTS Dashboard
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season</label>
                    <div className="w-100">
                      <Form.Item name="season_id" initialValue={'All'}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Product Status"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={status.season_id}
                                  >
                                    {status.season_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Gender</label>
                    <div className="w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Gender"
                          optionFilterProp="children"
                          size="small"
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Third*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">From Date</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf('year')}
                      >
                        <DatePicker
                          size="small"
                          onChange={onChangeDate}
                          format={dateFormatList}

                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Fourth*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">To Date</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          onChange={onChangeDate}
                          format={dateFormatList}

                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="justify-content-end" style={{ display: "flex", alignItems: "end" }}>
                    <label className=" no_visibility">none</label>
                    <Button
                      style={{ width: "fit-content" }}
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0 float-end"
                    >
                      Generate
                    </Button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div>
        <div ref={tableRef} className="overflow" style={{ marginBottom: "30px" }}>
          {wiseStockReport ? (
            wiseStockReport.length > 0 ? (
              wiseStockReport.map((data, index) => {
                const sizes = data;
                let bergen = null;
                let trnst = null;
                let pk = null;
                let prod = null;
                let pend = null;
                let intl = null;
                let ots = null;
                let ecom = null;
                let nord = null;
                let nemin = null;
                let saks = null;
                let speciality = null;
                let major = null;
                let online = null;
                let offPrice = null;
                let international = null;
                let internal = null;

                for (let i = 0; i < data.length; i++) {
                  bergen += parseInt(data[i].bergan);
                  trnst += parseInt(data[i].transit);
                  pk += parseInt(data[i].pakistan);
                  prod += parseInt(data[i].production);
                  pend += parseInt(data[i].pending);
                  intl += parseInt(data[i].international);
                  ots += parseInt(data[i].ots);
                  ecom += parseInt(data[i].ecommerce);
                  nord += parseInt(data[i].nord);
                  nemin += parseInt(data[i].neiman);
                  saks += parseInt(data[i].saks);
                  speciality += parseInt(data[i].shipped_special);
                  major += parseInt(data[i].shipped_depart);
                  online += parseInt(data[i].shipped_online);
                  offPrice += parseInt(data[i].shipped_offprice);
                  international += parseInt(data[i].shipped_international);
                  internal += parseInt(data[i].shipped_internal);
                }

                let sumQ = 0;
                for (let i = 0; i < data.length; i++) {
                  sumQ += parseInt(data[i].sum_quantity);
                }

                return (
                  <div key={index} className="fitWidth" style={{ margin: "0 auto" }}>
                    <div className="table_data-Heading">
                      {data[0].gender}
                    </div>
                    <table className="table_distributor-data">
                      <tbody>
                        <tr>
                          <td colSpan="17"></td>
                          <td colSpan="7" style={{ textAlign: "center", fontWeight: "900" }} className="font-weig">Shipped</td>
                        </tr>
                        <tr className=" font_weight">
                          <td style={{ width: "20px" }} className="font-weig">#</td>
                          <td style={{ width: "50px", fontWeight: "800" }} className="font-weig">Gender</td>
                          <td style={{ width: "40px" }} className="font-weig">CURR SSN</td>
                          <td style={{ width: "90px", textAlign: "right" }} className="font-weig"># SKU</td>
                          <td style={{ width: "60px", textAlign: "right" }} className="font-weig">BERGEN</td>
                          <td style={{ width: "55px", textAlign: "right" }} className="font-weig">TRNST #</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">PK</td>
                          <td style={{ width: "40px", textAlign: "right" }} className="font-weig">PROD</td>
                          <td style={{ width: "40px", textAlign: "right" }} className="font-weig">PEND</td>
                          <td style={{ width: "50px", textAlign: "right" }} className="font-weig">INT PEND</td>
                          <td style={{ width: "40px", textAlign: "right" }} className="font-weig">OTS</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">ECOM</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Nord</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Nemin</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Saks</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">TOTAL OTS</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Speciality</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Major's</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Online</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">OFF Price</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">International</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Internal</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">Total</td>
                          <td style={{ width: "70px", textAlign: "right" }} className="font-weig">ST %</td>
                        </tr>
                        {data.map((values, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{values.gender}</td>
                              <td>
                                <Link
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                  to={{
                                    pathname: '/oms-stock-summary',
                                    state: { data, values }
                                  }}
                                >
                                  {values.current_season}
                                </Link>
                              </td>
                              <td style={{ textAlign: "right" }}>{values.noofsku}</td>
                              <td style={{ textAlign: "right" }}>{values.bergan}</td>
                              <td style={{ textAlign: "right" }}>{values.transit}</td>
                              <td style={{ textAlign: "right" }}>{values.pakistan}</td>
                              <td style={{ textAlign: "right" }}>{values.production}</td>
                              <td style={{ textAlign: "right" }}>{values.pending}</td>
                              <td style={{ textAlign: "right" }}>{values.international}</td>
                              <td style={{ textAlign: "right" }}>{values.ots}</td>
                              <td style={{ textAlign: "right" }}>{values.ecommerce}</td>
                              <td style={{ textAlign: "right" }}>{values.nord}</td>
                              <td style={{ textAlign: "right" }}>{values.neiman}</td>
                              <td style={{ textAlign: "right" }}>{values.saks}</td>
                              <td style={{ textAlign: "right" }}>{values.ots + values.nord + values.saks + values.ecommerce + values.neiman}</td>
                              <td style={{ textAlign: "right" }}>{numeral(values.shipped_special).format("0,00,0")}</td>
                              <td style={{ textAlign: "right" }}>{numeral(values.shipped_depart).format("0,00,0")}</td>
                              <td style={{ textAlign: "right" }}>{numeral(values.shipped_online).format("0,00,0")}</td>
                              <td style={{ textAlign: "right" }}>{numeral(values.shipped_offprice).format("0,00,0")}</td>
                              <td style={{ textAlign: "right" }}>{numeral(values.shipped_international).format("0,00,0")}</td>
                              <td style={{ textAlign: "right" }}>{numeral(values.shipped_internal).format("0,00,0")}</td>
                              {/* <td style={{textAlign: "right"}}>{values.shipped_special + values.shipped_depart + values.shipped_online + values.shipped_offprice + values.shipped_international + values.shipped_internal}</td> */}
                              <td style={{ textAlign: "right" }}>{parseFloat(values.shipped_special) + parseFloat(values.shipped_depart) + parseFloat(values.shipped_online) + parseFloat(values.shipped_offprice) + parseFloat(values.shipped_international) + parseFloat(values.shipped_internal)}</td>
                              <td style={{ textAlign: "right" }}>{numeral(((parseFloat(values.shipped_special) + parseFloat(values.shipped_depart) + parseFloat(values.shipped_online) + parseFloat(values.shipped_offprice) + parseFloat(values.shipped_international) + parseFloat(values.shipped_internal)) / ((values.ots + values.nord + values.saks + values.ecommerce + values.neiman) + (parseFloat(values.shipped_special) + parseFloat(values.shipped_depart) + parseFloat(values.shipped_online) + parseFloat(values.shipped_offprice) + parseFloat(values.shipped_international) + parseFloat(values.shipped_internal)))) * 100).format("0.00")}</td>

                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan="3"></td>
                          <td className="font-weig">{"Total Quantity"}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(bergen).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(trnst).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(pk).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(prod).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(pend).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(intl).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(ots).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(ecom).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(nord).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(nemin).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(saks).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(ots + nord + saks + ecom + nemin).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(speciality).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(major).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(online).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(offPrice).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(international).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(internal).format("0,00,0")}</td>
                          <td className="font-weig" style={{ textAlign: "right" }}>{numeral(speciality + major + online + offPrice + international + internal).format("0,00,0")}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })
            ) : (
              <div className="no-Data">
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OTSDashboard;
