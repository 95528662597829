import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Select, Input, Tooltip, message } from "antd";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";
import { updateItems } from "../../redux/actions/updateItems.action";
import Loader from "react-loader-spinner";
import PageHeader from "../../components/PageHeader/PageHeader";

const { Option } = Select;

export default function ProductPrimaryFormForUpdate({
  genderCategory,
  setGenderCategory,
  setSize,
  submit,
  formState,
  companyId,
  val,
  token,
  formData,
  data,
  sku,
  changeState,
  randomData,
  dCompany,
  flagSet,
  itemId,
  valSKU,
  combinationData,
  setNewID,
  newID,
  newSize,
  newInseam,
  inseams,
  productID,
  setArrSizes,
  setArr,
  setInseams,
  sizes,
  setSizes,
  seasonYear,
  resList,
  setResList,
  userId
}) {
  const [form] = Form.useForm();
  const history = useHistory();
  const submitButton = useRef(null);
  const focusInput = useRef(null);
  const [companyChange, setCompanyChange] = useState(false);
  const [gender, setGender] = useState(null);
  const [reset, setReset] = useState(false);
  const [excel, setExcel] = useState([]);
  const [excelHeader, setExcelHeader] = useState([]);
  const [keys, setKeys] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const { companyAll, productType, fabricClass } = useSelector(
    (state) => state
  );

  let getgender = data?.payload?.genderCategoryDetail?.filter((item) => {
    return item.id === gender;
  });

  let getGenderCategory =
    getgender &&
    getgender[0]?.data?.filter((item) => {
      return item.gender_category_id === genderCategory;
    });

  let getGenderResult = getGenderCategory && getGenderCategory[0]?.size_chart;

  const onFinish = async (e) => {
    // if (formData.cost) {

    // }
    await form.validateFields().then((values) => {
      console.log(values, "values");
      Object.keys(values).forEach((key) => {
        if (values[key] === undefined) {
          delete values[key];
        }
      });
      if (Object.keys(values).includes("new_season_year")) {
        values.new_season_year = values.new_season_year.toString();
      }
      if (Object.keys(values).includes("season_year")) {
        values.season_year = values.season_year.toString();
      }
      if (Object.keys(values).includes("cost_price")) {
        values.cost_price = Number(values.cost_price);
      }
      if (Object.keys(values).includes("rate")) {
        values.rate = Number(values.rate);
      }
      console.log(values, "values132");
      if (!items.length) {
        Swal.fire({ icon: "error", title: "Oops...", text: "Please Upload File of SKU" });
      }
      if (!Object.keys(values).length) {
        Swal.fire({ icon: "error", title: "Oops...", text: "Please select atleast one field for update" });
      }
      else{
          //   setloading(true);

          // dispatch(updateItems(token, values, items, userId)).then((res) => {
          //   if (res?.type === "UPDATE_ITEMS_SUCCESS") {
          //     console.log(res?.payload?.item_id, "ressss");
          //     setResList(res?.payload?.item_id);
          //     console.log(resList, "res");
          //     setloading(false);
          //     Swal.fire({ icon: "success", title: "Success", text: "Updated" });
          //     //   setLines(res?.payload?.data);
          //   }
          // });
      }
    });
  };

  function search(value) {
    const newValue = value;
    if (/^[0-9\._]+$/.test(newValue)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "can not type integer value",
      });
    }
  }
  let json;
  let jsonArray = [];
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        //
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        //
        json = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "" });

        console.log(json, "json");
        jsonArray = json;
        excel.push(jsonArray);
        setExcel(jsonArray);
        excelHeader.push(Object.keys(jsonArray[0]));
        // keys.push(Object.keys(excel[0][0]));
        let itemCodes = json.map((obj) => Object.values(obj)[0]);

        setItems(itemCodes);
        console.log(items, itemCodes, "items");
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  return (
    <div className="">
      <PageHeader title={"Update Items"} />
      {loading ? (
        <div className="loaderDiv">
          <Loader type="Grid" color="#212121" height={40} width={40} />
        </div>
      ) : null}
      <div className="formProduct p-0">
        <Form onFinish={onFinish} form={form} name="basic">
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol">
                  <Form.Item
                    //   name="sku"
                    label="SKU"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <input
                      className="upload_input"
                      type="file"
                      name="upload"
                      id="upload"
                      onChange={readUploadFile}
                    />
                  </Form.Item>
                </div>
              </Bcol>

              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol slc">
                  <div className="relative">
                    <Form.Item
                      name="style_id"
                      label="Style"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Select
                        size="small"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        loading={data.loading}
                        className="w-100"
                        onSearch={(value) => search(value)}
                      >
                        {data?.payload?.styleDetail?.map((item) => {
                          return (
                            <Option
                              disabled={!item.is_active}
                              key={item.style_id}
                              value={item.style_id}
                            >
                              {item.style_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Bcol>

              {/* <Bcol lg={6} xl={4} xxl={3}>
              <div className="customCol slc">
                <div className="relative">
                  <Form.Item
                    name="season_id"
                    label="Season"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Select
                      size="small"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      loading={data.loading}
                      defaultValue={
                        formState === "edit"
                          ? form.getFieldValue().season
                          : null
                      }
                      className="w-100"
                    >
                      {data?.payload?.seasonDetail?.map((item) => {
                        return (
                          <Option
                            disabled={!item.is_active}
                            key={item.season_id}
                            value={item.season_id}
                          >
                            {item.season_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Bcol>
            <Bcol lg={6} xl={4} xxl={3}>
              <div className="customCol slc">
                <div className="relative">
                  <Form.Item
                    name="season_year"
                    label="Season Year"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Select
                      size="small"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      loading={data.loading}
                      defaultValue={
                        formState === "edit"
                          ? form.getFieldValue().seasonYear
                          : null
                      }
                      className="w-100"
                    >
                      {" "}
                      {seasonYear?.payload?.data?.map((res) => (
                        <Option
                          key={res.season_year_id}
                          value={res.season_year_id}
                        >
                          {res.season_year}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Bcol> */}
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol slc">
                  <div className="relative">
                    <Form.Item
                      name="new_season_id"
                      label="New Season"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Select
                        size="small"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        loading={data.loading}
                        defaultValue={
                          formState === "edit"
                            ? form.getFieldValue().newSeason
                            : null
                        }
                        className="w-100"
                      >
                        {data?.payload?.seasonDetail?.map((item) => {
                          return (
                            <Option
                              disabled={!item.is_active}
                              key={item.season_id}
                              value={item.season_id}
                            >
                              {item.season_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol slc">
                  <div className="relative">
                    <Form.Item
                      name="new_season_year"
                      label="New Season Year"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Select
                        size="small"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        loading={data.loading}
                        defaultValue={
                          formState === "edit"
                            ? form.getFieldValue().newSeasonYear
                            : null
                        }
                        className="w-100"
                      >
                        {seasonYear?.payload?.data?.map((res) => (
                          <Option
                            key={res.season_year_id}
                            value={res.season_year_id}
                          >
                            {res.season_year}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Bcol>

              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol">
                  <div className="relative">
                    <Form.Item
                      name="cost_price"
                      label="Cost"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      rules={[
                        //   {
                        //     required: true,
                        //     message: "Cost is required",
                        //   },
                        {
                          pattern: new RegExp(
                            /^(?![.]$|[.]$|$)\d+[.]?\d{0,6}$/
                          ),
                          message: "Please enter correct amount.",
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        className="styleInput"
                        //   defaultValue={`0.00`}
                        // placeholder={"Cost"}
                        // disabled={!(formState === "add" || formState === "edit")}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol">
                  <div className="relative">
                    <Form.Item
                      name="rate"
                      label="Retail Price"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      rules={[
                        //   {
                        //     required: true,
                        //     message: "Retail Price is required",
                        //   },
                        {
                          pattern: new RegExp(
                            /^(?![.,]$|[.,]$|$)\d+[,.]?\d{0,6}$/
                          ),
                          message: "Please enter correct amount.",
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        className="styleInput"
                        //   defaultValue={`0.00`}
                        // placeholder={"Retail Price"}
                        // disabled={!(formState === "add" || formState === "edit")}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Bcol>

              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol slc">
                  <div className="relative">
                    <Form.Item
                      // rules={[{ required: true, message: "Status is required" }]}
                      name="product_status_id"
                      label="Status"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Select
                        size="small"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        loading={data.loading}
                        // disabled={!(formState === "add" || formState === "edit")}
                        defaultValue={
                          formState === "edit"
                            ? form.getFieldValue().status
                            : null
                        }
                        className="w-100"
                        // placeholder={"Status"}
                      >
                        {data?.payload?.productStatusDetail?.map((item) => {
                          return (
                            <Option
                              disabled={!item.is_active}
                              key={item.product_status_id}
                              value={item.product_status_id}
                            >
                              {item.product_status_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Bcol>
              <Bcol
                lg={6}
                xl={4}
                xxl={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="customCol slc">
                  <div className="relative">
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </div>
                </div>
              </Bcol>

              {/* <div className='customRow'> */}

              {(formState === "edit" || formState === "formView") && (
                <Bcol lg={6} xl={4} xxl={3}>
                  <div className="customCol slc">
                    <div className="relative">
                      <Form.Item
                        name="active"
                        label="Active"
                        labelAlign="left"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                      >
                        <Select
                          size="small"
                          loading={data.loading}
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().active
                              : null
                          }
                          disabled={formState !== "edit"}
                          className="w-100"
                          // placeholder="Active"
                        >
                          <Option key={1} value={true}>
                            True
                          </Option>
                          <Option key={2} value={false}>
                            False
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </Bcol>
              )}
              {(formState === "add" || formState === "view") && (
                <>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="customCol slc">
                      <div className="relative"></div>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="customCol slc">
                      <div className="relative"></div>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="customCol slc">
                      <div className="relative"></div>
                    </div>
                  </Bcol>
                </>
              )}
              {itemId && (
                <>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="customCol slc invisible">
                      <div className="relative"></div>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="customCol slc invisible">
                      <div className="relative"></div>
                    </div>
                  </Bcol>
                </>
              )}
              <Button
                style={{ display: "none" }}
                onClick={onFinish}
                ref={submitButton}
              />
              {/* </div> */}
            </Brow>
          </div>
        </Form>
      </div>
    </div>
  );
}
