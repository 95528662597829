import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubTitle from "../../components/SubTitle/SubTitle";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import { } from "@ant-design/icons";
import { getLog } from "../../redux/actions/log.action";
import { loadJobTitle } from "../../redux/actions/salePerson/jobTitle.action";
import { loadReportCurrency } from "../../redux/actions/salePerson/reportCurrency.action";
import { loadSupervisor } from "../../redux/actions/salePerson/supervisor.action";
import { getRights } from "../../redux/actions/userRights.action";
import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { addVendor, deleteVendor, getOneVendor, getVendors, updateVendor } from "../../redux/actions/vendor.action"
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tabs, Tooltip, Divider, Select, Checkbox, Radio } from "antd";
import { CloseCircleOutlined, UndoOutlined } from "@ant-design/icons";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { logColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import { ReadCookie } from "../../utils/readCookie";

import "../Style/style.css";
import "./vendor.css";
// import "./saleperson.css";
// import "../Customer/product.css";
import "../Product/product.css";
import VendorShipping from "./vendorShipping";
import VendorBilling from "./vendorBilling";

import { addVendorBiller, getVendorBilling } from "../../redux/actions/vendor_bill.action";
import { addVendorShipper, getVendorShipping } from "../../redux/actions/vendor_ship.action";
import { TabTitle } from "../../utils/GeneralFunction"
const { TabPane } = Tabs;
const { Option } = Select;

export default function Vendor({ token }) {
    TabTitle("Vendor")

    const vendorInput = useRef(null);
    const [form] = Form.useForm();
    const [submit, setSubmit] = useState(false);
    const submitButton = useRef(null);
    const [rights, setRights] = useState(false);
    const [salePersonAllId, setSalepersonAllId] = useState(null);
    const dispatch = useDispatch();
    const { log, companyAll, userRights, salePersonForm, vendor } = useSelector((state) => state);
    //Primary Info
    const focusInput = useRef(null);
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState("view");
    const menuId = ReadCookie("menuId");
    const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
    const [disable, setDisable] = useState(false);
    const [salepersonGridView, setSalePersonGridView] = useState(false);

    const [filteredInfo, setFilteredInfo] = useState(null);
    const [sortedInfo, setSortedInfo] = useState(null);
    const [dataLen, setDataLen] = useState(0);
    const [show, setShow] = useState(false);
    const [typeValue, setTypeValue] = React.useState("I");
    const [addressDataBill, setAddressDataBill] = useState([]);
    const [addressDataShip, setAddressDataShip] = useState([]);
    const [customerID, setCustomerId] = useState("");
    const [check, setCheck] = useState(true);

    const queryParams = new URLSearchParams(window.location.search);
    const vendor_id = queryParams.get("vendor_id");
    console.log("🚀 ~ file: vendor.js:70 ~ Vendor ~ vendor_id:", vendor_id)

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table");
                }
            }
        });
    }, []);

    useEffect(() => {
        if (formState === "table") {
            setSalepersonAllId(null);
            setShow(false);
            setFilteredInfo(null);
            setSortedInfo(null);
            //setDCol([]);
            dispatch(getVendors(token)).then((res) => {
                if (res.type === "GET_VENDOR_SUCCESS") {
                    //     setCol(Object.keys(res.payload?.data?.[0]));
                    //     Object.keys(res.payload?.data?.[0])?.map((single) => {
                    //       if (single === "is_active") {
                    //         setDCol((col) => [
                    //           ...col,
                    //           {
                    //             title: single.replace(/_/g, " "),
                    //             dataIndex: single,
                    //             key: single,
                    //             render: (text, row) => (row.is_active ? "True" : "False"),
                    //           },
                    //         ]);
                    //       } else if (
                    //         single === "salesperson_name" ||
                    //         single === "sales_person_id" ||
                    //         single === "email"
                    //       ) {
                    //         setDCol((col) => [
                    //           ...col,
                    //           {
                    //             title: single.replace(/_/g, " "),
                    //             dataIndex: single,
                    //             key: single,
                    //             filterDropdown: ({
                    //               setSelectedKeys,
                    //               selectedKeys,
                    //               confirm,
                    //               clearFilters,
                    //             }) => {
                    //               return (
                    //                 <Input
                    //                   allowClear
                    //                   autoFocus
                    //                   style={{
                    //                     position: "absolute",
                    //                     top: -70,
                    //                     right: 0,
                    //                     borderRadius: "10px",
                    //                     width: "9rem",
                    //                   }}
                    //   placeholder="Search"
                    //                   value={selectedKeys[0]}
                    //                   onChange={(e) => {
                    //                     setSelectedKeys(
                    //                       e.target.value ? [e.target.value] : []
                    //                     );
                    //                     confirm({ closeDropdown: false });
                    //                   }}
                    //                   onPressEnter={() => {
                    //                     confirm();
                    //                   }}
                    //                   onBlur={() => {
                    //                     confirm();
                    //                   }}
                    //                 />
                    //               );
                    //             },
                    //             filterIcon: (filtered) =>
                    //               filtered ? <FilterFilled /> : <SearchOutlined />,
                    //             onFilter: (value, record) => {
                    //               if (
                    //                 single === "sales_person_id" &&
                    //                 record.sales_person_id
                    //               ) {
                    //                 return record.sales_person_id === parseInt(value);
                    //               }
                    //               if (
                    //                 single === "salesperson_name" &&
                    //                 record.salesperson_name
                    //               ) {
                    //                 return record.salesperson_name
                    //                   .toLowerCase()
                    //                   .includes(value.toLowerCase());
                    //               }
                    //               if (single === "email" && record.email) {
                    //                 return record.email
                    //                   .toLowerCase()
                    //                   .includes(value.toLowerCase());
                    //               }
                    //             },
                    //           },
                    //         ]);
                    //       } else {
                    //         setDCol((col) => [
                    //           ...col,
                    //           {
                    //             title: single.replace(/_/g, " "),
                    //             dataIndex: single,
                    //             key: single,
                    //           },
                    //         ]);
                    //       }
                    //     });
                }
            });
        }
    }, [formState]);

    useEffect(() => {
        if (companyId) {
            setCompanyId(parseInt(companyId));
            form.setFieldsValue({
                company: companyId,
            });
        }
        dispatch(getAllCompany(token));
    }, [companyId]);

    useEffect(() => {
        dispatch(loadSupervisor(token));
        dispatch(loadJobTitle(token));
        dispatch(loadReportCurrency(token));
    }, [token]);

    useEffect(() => {
        if (!show) {
            setDataLen(0);
        }
        if (show) {
            setDataLen(vendor?.payload?.data.length)
        }
    }, [show]);

    const onTypeChange = e => {

        setTypeValue(e.target.value);
    };

    console.log({ salePersonAllId })
    useEffect(() => {
        if (vendor_id) {
            setSalePersonGridView(false);
            setFormState("formView");
            dispatch(getOneVendor(token, vendor_id)).then((vendor) => {

                if (vendor.type === "GET_ONE_VENDOR_SUCCESS") {
                    form.setFieldsValue({
                        company: vendor?.payload?.data[0].company_id,
                        vendorID: vendor?.payload?.data[0].vendor_id,
                        vendorName: vendor?.payload?.data[0].vendor_name,
                        vendorType: vendor?.payload?.data[0].vendor_type,
                        vendorCode: vendor?.payload?.data[0].vendor_code,
                        webaddress: vendor?.payload?.data[0].web_address,
                        email: vendor?.payload?.data[0].email_address,
                        vendorCat: vendor?.payload?.data[0].vendor_category_id,
                        fax: vendor?.payload?.data[0].fax,
                        mobilePhone: vendor?.payload?.data[0].mobile,
                        officePhone: vendor?.payload?.data[0].office,
                        // active: vendor?.payload?.data[0].is_active,
                    });
                    setCustomerId(vendor?.payload?.data[0].vendor_id);
                    setCheck(vendor?.payload?.data[0].is_active);


                    // setSalepersonAllId(saleperson.payload.sales_person_id);

                    // if (saleperson.payload.hire_date === null) {
                    //     form.setFieldsValue({
                    //         hireDate: "",
                    //     });
                    // } else {
                    //     form.setFieldsValue({
                    //         hireDate: moment(saleperson.payload.hire_date),
                    //     });
                    // }
                }

                // if (saleperson.type === "GET_ONE_SALEPERSON_ERROR") {
                //     notification("error", "Customer", saleperson?.payload?.data);
                // }
            });
        } else {
        }
    }, [salePersonAllId]);

    useEffect(() => {
        if (formState === "table") {
            setSalePersonGridView(true);
            //setFormState("edit");
        }

    }, [formState]);

    const onSearch = (val) => {

    };

    const handleTab = (key) => {

        if (key === "shipping" && formState === 'formView') {
            dispatch(getVendorShipping(token, customerID));
        }

        if (key === "billing" && formState === 'formView') {
            dispatch(getVendorBilling(token, customerID));
        }

    };

    useEffect(() => {
        if (formState === "view" || formState === "table" || formState === "add") {

            form.setFieldsValue({
                company: companyId,
                vendorID: null,
                vendorName: null,
                vendorType: null,
                vendorCode: null,
                webaddress: null,
                webaddress: null,
                vendorCat: null,
                officePhone: null,
                mobilePhone: null,
                active: false,
                fax: null,
                email: null,

            });

        }
    }, [formState]);

    const formSave = () => {
        setSubmit(true);
        submitButton.current.click();
    };

    const onFinish = async (e) => {
        setSubmit(false);
        form.setFieldsValue({ [e.target.name]: e.target.value });
        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addVendor(token, values, check)).then((res) => {

                    if (res.type === "ADD_VENDOR_ERROR") {
                        notification(
                            "error",
                            res?.payload?.request?.statusText,
                            res?.payload?.data?.data
                        );
                    }
                    if (res.type === "ADD_VENDOR_SUCCESS") {

                        if (addressDataBill.length > 0) {
                            addressDataBill.map((bill) => {
                                delete bill.key
                                delete bill.country_name
                                delete bill.city_name
                                delete bill.state_name
                                return bill.vendor_bill_to_id = res?.payload?.vendor_id, bill.vendor_id = res?.payload?.vendor_id
                            })
                            dispatch(addVendorBiller(token, addressDataBill, res?.payload?.vendor_id)).then((res) => {

                                if (res.type === "ADD_VENDOR_BILLING_SUCCESS") {
                                }
                            });
                        }
                        if (addressDataShip.length > 0) {
                            addressDataShip.map((ship) => {
                                delete ship.key
                                delete ship.country_name
                                delete ship.city_name
                                delete ship.state_name
                                return ship.vendor_id = res?.payload?.vendor_id
                            })
                            dispatch(addVendorShipper(token, addressDataShip, res?.payload?.vendor_id))

                        }
                        notification(
                            "success",
                            "Vendor Created",
                            "Vendor created successfully!"
                        );
                        form.setFieldsValue({
                            company: companyId,
                            vendorID: null,
                            vendorName: null,
                            vendorType: null,
                            vendorCode: null,
                            webaddress: null,
                            webaddress: null,
                            vendorCat: null,
                            officePhone: null,
                            mobilePhone: null,
                            active: false,
                            fax: null,
                            email: null,


                        });
                        // setFormState("table");
                        setFormState("formView");
                        dispatch(getVendors(token));
                        setSalePersonGridView(true);
                    }
                });
            }
            if (formState === "edit") {


                dispatch(updateVendor(token, values, vendor_id)).then(
                    (res) => {

                        if (res.type === "UPDATE_VENDOR_ERROR") {
                            notification("error", "Update", "Something went wrong");
                        }
                        if (res.type === "UPDATE_VENDOR_SUCCESS") {
                            notification(
                                "success",
                                "vendor Updated",
                                "vendor updated successfully"
                            );
                            form.setFieldsValue({
                                company: companyId,
                                vendorID: null,
                                vendorName: null,
                                vendorType: null,
                                vendorCode: null,
                                webaddress: null,
                                vendorCat: null,
                                officePhone: null,
                                mobilePhone: null,
                                active: false,
                                fax: null,
                                email: null,
                            });
                            setFormState("formView");
                            // setFormState("table");
                            setSalepersonAllId(null);
                        }
                        dispatch(getVendors(token));
                    }
                );
                setSalePersonGridView(true);
            }
        });
    };

    const handleClose = () => {
        setFormState("view");
        setSalePersonGridView(false);
    };

    const handleDelete = () => {
        dispatch(deleteVendor(token, salePersonAllId)).then((res) => {
            if (res.type === "DELETE_VENDOR_SUCCESS") {
                notification(
                    "success",
                    "Vendor Deleted",
                    "Vendor deleted successfully!"
                );
                form.setFieldsValue({
                    company: companyId,
                    employee_id: null,
                    empName: null,
                    jobTitle: null,
                    SuperVisor: null,
                    reportingCurrency: null,
                    hireDate: null,
                    email: null,
                    officePhone: null,
                    mobilePhone: null,
                    homePhone: null,
                    fax: null,
                });
                setFormState("table");
                setSalepersonAllId(null);
            }
            if (res.type === "DELETE_VENDOR_ERROR") {
                notification(
                    "error",
                    res?.payload?.request?.statusText,
                    res?.payload?.data?.data
                );
            }
            dispatch(getVendors(token));
            setSalePersonGridView(true);
        });
    };

    ///For Another Table
    const colTest = [
        {
            title: "Vendor ID",
            dataIndex: "vendor_id",
            key: "vendor_id",
            width: 130,
            filteredValue: filteredInfo ? filteredInfo.vendor_id || null : null,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        className="search-pop-style w-11-em"
                        // placeholder="Search"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.vendor_id)
                    return record.vendor_id
                        .toLowerCase()
                        .includes(value.toLowerCase());
            },
            sortOrder: sortedInfo
                ? sortedInfo.columnKey === "vendor_id" && sortedInfo.order
                : null,
            sorter: (a, b) => a.vendor_id.localeCompare(b.vendor_id),
        },
        {
            title: "Vendor Name",
            dataIndex: "vendor_name",
            key: "vendor_name",
            width: 130,
            filteredValue: filteredInfo ? filteredInfo.vendor_name || null : null,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        className="search-pop-style w-11-em"
                        // placeholder="Search"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.vendor_name)
                    return record.vendor_name === parseInt(value)
            },
            sortOrder: sortedInfo
                ? sortedInfo.columnKey === "vendor_name" && sortedInfo.order
                : null,
            sorter: (a, b) => a.vendor_name - b.vendor_name,
        },
        {
            title: "Vendor Type",
            dataIndex: "vendor_type",
            key: "vendor_type",
            width: 130,
            filteredValue: filteredInfo ? filteredInfo.vendor_type || null : null,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        className="search-pop-style w-11-em"
                        // placeholder="Search"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.vendor_type)
                    return record.vendor_type
                        .toLowerCase()
                        .includes(value.toLowerCase());
            },
            sortOrder: sortedInfo
                ? sortedInfo.columnKey === "vendor_type" && sortedInfo.order
                : null,
            sorter: (a, b) => a.vendor_type.localeCompare(b.vendor_type),
        },
        {
            title: "Email",
            dataIndex: "web_address",
            key: "web_address",
            width: 130,
            filteredValue: filteredInfo
                ? filteredInfo.web_address || null
                : null,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        className="search-pop-style w-11-em"
                        // placeholder="Search"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.web_address)
                    return record.web_address.toLowerCase()
                        .includes(value.toLowerCase());

            },
            sortOrder: sortedInfo
                ? sortedInfo.columnKey === "web_address" && sortedInfo.order
                : null,
            sorter: (a, b) => a.web_address.localeCompare(b.web_address),
        },
        {
            title: "Email",
            dataIndex: "email_address",
            key: "email_address",
            width: 130,
            filteredValue: filteredInfo
                ? filteredInfo.email_address || null
                : null,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        className="search-pop-style w-11-em"
                        // placeholder="Search"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.email_address)
                    return record.email_address
                        .toLowerCase()
                        .includes(value.toLowerCase());
            },
            sortOrder: sortedInfo
                ? sortedInfo.columnKey === "email_address" && sortedInfo.order
                : null,
            sorter: (a, b) =>
                a.email_address.localeCompare(b.email_address),
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            width: 130,
            filteredValue: filteredInfo
                ? filteredInfo.mobile || null
                : null,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        className="search-pop-style w-11-em"
                        // placeholder="Search"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.mobile)
                    return record.mobile
                        .toLowerCase()
                        .includes(value.toLowerCase());
            },
            sortOrder: sortedInfo
                ? sortedInfo.columnKey === "mobile" && sortedInfo.order
                : null,
            sorter: (a, b) =>
                a.mobile.localeCompare(b.mobile),
        },
    ];

    const handleFilter = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
        setShow(true);
    };

    const clearFilters = () => {
        if (show) {
            setFilteredInfo(null);
            setSortedInfo(null);
            setShow(false);
        }
        if (!show) {
            setShow(!show);
        }
    };

    const filterReset = () => {
        form.setFieldsValue({
            company: companyId,
        });
        setFormState("view");
        setSalepersonAllId(null);
    };

    return (
        <>
            {salepersonGridView === true ? (
                <div className="styleContainer">
                    <div className="styleBody h-100">
                        <PageHeader title={"Vendor"} />
                        <div className="bodyStyle">
                            {vendor.loading ? (
                                <div className="mainProductContainer">
                                    <SkeletonLoad />
                                </div>
                            ) : (
                                <div className="mainProductContainer">
                                    <div className="productTable">
                                        <div className='productListDiv' >
                                            <SubTitle name={"SalePersons List"} />
                                            {userRights?.payload?.can_add &&
                                                <Button className="iconBtn crudBTN close-button-sub" shape='circle' icon={<CloseCircleOutlined className="color-red" />} onClick={() => handleClose()} />
                                            }
                                        </div>
                                        <div className="pbpx-10">
                                            <Button type="primary"
                                                className="mlpx-5"
                                                onClick={() => clearFilters()}
                                            >
                                                {show ? "Clear All" : "Show All"}
                                            </Button>
                                        </div>
                                        <Tables
                                            dataLen={dataLen}
                                            setDataLen={setDataLen}
                                            show={show}
                                            handleFilter={handleFilter}
                                            loadHeight={40}
                                            loadWidth={40}
                                            type={"vendor"}
                                            salePersonAllId={setSalepersonAllId}
                                            //customerObj={setCustomerObj}
                                            scroll={570}
                                            perPage={25}
                                            scrollX={"80rem"}
                                            col={colTest}
                                            source={vendor?.payload?.data}
                                            load={vendor.loading}
                                            paginate

                                        />
                                        <span className="bottom-records-numbers" >
                                            {
                                                dataLen === 0 ?
                                                    <>No of Records:  <span className="fw-500">  0</span> </> :
                                                    < > No of Records: <span className="fw-500"> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>
                                            }
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="styleContainer">
                    <div className="styleBody h-100">
                        <PageHeader
                            title={"Vendor"}
                            minimize={setMinimize}
                            check={minimize}
                        />
                        {!minimize && (
                            <div className="bodyStyle">
                                <div className="crudDiv">
                                    <CrudButtons
                                        name={"vendor"}
                                        rights={userRights?.payload}
                                        reset={filterReset}
                                        formType={formState}
                                        save={formSave}
                                        disable={disable}
                                        formState={setFormState}
                                        onDelete={handleDelete}
                                    />
                                </div>

                                <SubTitle name={"Primary Information"} />
                                <div className="formProduct p-0">
                                    {formState === "edit" && (
                                        <div className="undoDiv">
                                            <Tooltip
                                                placement="left"
                                                title="Form Reset"
                                                color="#fbfbfb"
                                            >
                                                <Button
                                                    className="iconBtn undoBtn "
                                                    icon={<UndoOutlined />}
                                                    shape="circle"
                                                />
                                            </Tooltip>
                                        </div>
                                    )}

                                    <Form onFinish={onFinish} form={form} name="basic">
                                        <div className="container-fluid pbpx-5 ptpx-5">
                                            <Brow>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="slc">
                                                        <Form.Item
                                                            name="company"
                                                            label="Company"
                                                            labelAlign="left"
                                                            labelCol={{ span: 8 }}
                                                            wrapperCol={{ span: 16 }}
                                                            initialValue={formState === "add" ? null : form.getFieldValue().company}
                                                        >
                                                            <Select
                                                                size='small'
                                                                showSearch
                                                                optionFilterProp="children"
                                                                onSearch={onSearch}
                                                                filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                disabled={formState === "add" || formState === "edit" || formState === "view" || formState === "formView"}
                                                                className="styleInput w-100"
                                                            >
                                                                {companyAll?.payload?.data.map((item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.company_id}
                                                                            value={item.company_id}
                                                                        >
                                                                            {item.company_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div>
                                                        <div className="relative">
                                                            <Form.Item
                                                                name="vendorID"
                                                                label="Vendor ID:"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().vendorID}
                                                            >
                                                                <Input
                                                                    size='small'
                                                                    className="styleInput"
                                                                    // placeholder="Vendor ID"
                                                                    disabled={formState === "add" || formState === "edit" || formState === "view" || formState === "formView"}
                                                                    ref={vendorInput}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div>
                                                        <div className="relative">
                                                            <Form.Item
                                                                name="vendorCode"
                                                                label="Vendor Code:"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().vendorCode}
                                                            >
                                                                <Input
                                                                    size='small'
                                                                    className="styleInput"
                                                                    // placeholder="Vendor Code"
                                                                    disabled={formState === "add" || formState === "edit" || formState === "view" || formState === "formView"}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">
                                                            <Form.Item
                                                                name="vendorName"
                                                                label="Vendor Name"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().vendorName}
                                                                rules={[{ required: true, message: "Name is required" }]}
                                                            >
                                                                <Input
                                                                    className="styleInput"
                                                                    size='small'
                                                                    // placeholder="Vendor Name"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">

                                                            <Form.Item
                                                                name="vendorType"
                                                                label="Vendor Type"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().vendorType}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Name is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Radio.Group onChange={onTypeChange} value={typeValue}>
                                                                    <Radio value="I">Indivisual</Radio>
                                                                    <Radio value="C">Company</Radio>

                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">

                                                            <Form.Item
                                                                name="vendorCat"
                                                                label="Vendor Category"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().vendorCat}
                                                                rules={[{ required: true, message: "Name is required" }]}
                                                            >
                                                                <Input
                                                                    className="styleInput"
                                                                    size='small'
                                                                    // placeholder="Name"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">

                                                            <Form.Item
                                                                name="webaddress"
                                                                label="web Address"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().webadd}
                                                                rules={[{ required: true, message: "Name is required" }]}
                                                            >
                                                                <Input
                                                                    className="styleInput"
                                                                    size='small'
                                                                    // placeholder="web Address"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">

                                                            <Form.Item
                                                                name="active"
                                                                label="Active"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().active}
                                                                rules={[{ required: true, message: "Name is required" }]}
                                                            >
                                                                <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)}  ></Checkbox>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Button
                                                    style={{ display: "none" }}
                                                    onClick={onFinish}
                                                    ref={submitButton}
                                                />
                                            </Brow>
                                        </div>
                                    </Form>
                                </div>

                                <SubTitle name={"Email | Phone | Address"} />

                                <div className="formProduct p-0">
                                    {formState === "edit" && (
                                        <div className="undoDiv">
                                            <Tooltip
                                                placement="left"
                                                title="Form Reset"
                                                color="#fbfbfb"
                                            >
                                                <Button
                                                    className="iconBtn undoBtn "
                                                    icon={<UndoOutlined />}
                                                    shape="circle"
                                                />
                                            </Tooltip>
                                        </div>
                                    )}

                                    <Form onFinish={onFinish} form={form} name="basic">

                                        <div className="container-fluid pbpx-5 ptpx-5">
                                            <Brow>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">
                                                            <Form.Item
                                                                name="email"
                                                                label="Email"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().email}
                                                                rules={[{ type: "email", message: "EMAIL is not Valid" }]}
                                                            >
                                                                <Input size='small'
                                                                    className="styleInput"
                                                                    // placeholder="Email"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">
                                                            <Form.Item
                                                                name="officePhone"
                                                                label="Office Phone:"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().officePhone}
                                                                rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
                                                            >
                                                                <Input
                                                                    size='small'
                                                                    className="styleInput"
                                                                    // placeholder="office Phone"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">
                                                            <Form.Item
                                                                name="mobilePhone"
                                                                label="Mobile Phone:"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().mobilePhone}
                                                                rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
                                                            >
                                                                <Input
                                                                    size='small'
                                                                    className="styleInput"
                                                                    // placeholder="Mobile Phone"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="">
                                                        <div className="relative">
                                                            <Form.Item
                                                                name="fax"
                                                                label="Fax:"
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                initialValue={formState === "add" ? null : form.getFieldValue().fax}
                                                            >
                                                                <Input size='small'
                                                                    className="styleInput"
                                                                    // placeholder="Fax"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Bcol>
                                                <Button
                                                    style={{ display: "none" }}
                                                    onClick={onFinish}
                                                    ref={submitButton}
                                                />
                                            </Brow>
                                        </div>
                                    </Form>
                                </div>

                                {/* <br /> */}
                                <Divider />
                                <div className="styleTab">
                                    <Tabs
                                        size="small"
                                        type='card'
                                        tabBarGutter={1}
                                        defaultActiveKey="1"
                                        centered
                                        onChange={(key) => handleTab(key)}
                                    >
                                        <TabPane tab="Billing" key="billing">
                                            <div className="tabs">
                                                <VendorBilling
                                                    addressDataBill={setAddressDataBill}
                                                    customer_id={customerID}
                                                    formState={setFormState}
                                                    formType={formState}
                                                    token={token}
                                                />

                                            </div>
                                        </TabPane>
                                        <TabPane tab="Shipping" key="shipping">
                                            <div className="tabs">
                                                <VendorShipping
                                                    addressDataShip={setAddressDataShip}
                                                    formState={setFormState}
                                                    customer_id={customerID}
                                                    formType={formState}
                                                    token={token}
                                                />
                                            </div>
                                        </TabPane>
                                        <TabPane tab="Log Info" key="logs">
                                            <div className="tabs">
                                                <Tables
                                                    type="company"
                                                    scrollX={"50rem"}
                                                    scroll={360}
                                                    perPage={15}
                                                    source={log?.payload?.data}
                                                    col={logColumns}
                                                    load={log.loading}
                                                    paginate
                                                />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                                {/* <div className="styleTab">
                                    <Tabs>
                                        <TabPane tab="Log Info" key="logs">
                                            <div className="tabs">
                                                <Tables
                                                    type="company"
                                                    scroll={360}
                                                    perPage={15}
                                                    scrollX={"50rem"}
                                                    source={log?.payload?.data}
                                                    col={logColumns}
                                                    load={log.loading}
                                                    paginate
                                                />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div> */}
                            </div>
                        )}
                    </div>
                    {minimize && (
                        <div className="CustomerFooter">
                            <Button
                                className='minimize-button-style'
                                onClick={() => setMinimize(false)}
                            >
                                SalePerson
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
