import React, { useEffect, useState, useRef } from "react";
import "./Distributor2.css";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, Form, Table } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import numeral, { Numeral } from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getBalanceDetail } from "../../redux/actions/incomeStatement.action";
import Input from "rc-input";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";


const BalanceDetail = ({ token }) => {
  TabTitle("Statement Detail")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { balaceDetail } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [detailStatement, setDetailStatement] = useState([]);
  const [excelData, setExcelData] = useState([])

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  let account_id = query.get("account_id");
  let date_to = query.get("date_to");

  // console.log({account_name});



  useEffect(() => {
    if (account_id) {
      let value = {
        account_id: account_id,
        date_to: date_to
      };
      setLoading(true)
      dispatch(getBalanceDetail(token, value)).then((res) => {
        setDetailStatement(res?.payload?.data)
        downloadExcelFileData(res?.payload?.data);
        setLoading(false)
      });
    }
  }, [account_id])

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Transaction Type": v.transaction_type,
          "Transaction No": v.transaction_no,
          "Transaction Date": moment(v.transaction_date).format("MM-DD-YYYY"),
          "Customer": v.customer_name,
          "Balance": numeral(v.balance).format("0,00.00"),
        };
      })
    )
  }

  const balance = balaceDetail?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)

  const columns = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1
      }
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_type !== null) {
          return record.transaction_type
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_type !== null) & (b.transaction_type !== null)) {
          return a.transaction_type.localeCompare(b.transaction_type);
        }
      },
    },
    {
      title: "Transaction No",
      dataIndex: "transaction_no",
      key: "transaction_no",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_no !== null) {
          return record.transaction_no
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_no !== null) & (b.transaction_no !== null)) {
          return a.transaction_no.localeCompare(b.transaction_no);
        }
      },
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_date !== null) {
          return record.transaction_date
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_date !== null) & (b.transaction_date !== null)) {
          return a.transaction_date.localeCompare(b.transaction_date);
        }
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.balance !== null) {
          return record.balance
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.balance !== null) & (b.balance !== null)) {
          return a.balance.localeCompare(b.balance);
        }
      },
    },
  ];


  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="distributor header_class distributor_table">
      <div className="row justify-content-between distributr">
        {/**** / Logo *****/}
        <div className="col-3 d-flex align-items-sm-center box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-3 box-space right-content-header text-sm-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8 printing_class">
              <div className="print_icon printing_class">
                <i
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  onClick={handlePrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"Balance-Detail.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    // onClick={onDownload}
                    // className="cursor-not-allowed"
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Balance Detail
              <br />
              Rpt # 489
              <br />
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>

            </span>
          </div>
        </div>
      </div>
      <hr className="mt-0 mb-2" />

      {/* Table  Data */}
      <div
        // className="business_class_oms mb-2 distributor" style={{ width: "1400px" }
        className="table_content international_order"
        style={{ maxWidth: '1460px' }}
      >
        <tr>
          <td className="font_weight">
            Account Name :
          </td>
          <td style={{ width: "406px" }} colSpan={3}>
            <b className="font_weight">

              {balaceDetail?.payload?.data[0].account_search_display_name}
            </b>
          </td>

        </tr>
        <div>
          {balaceDetail?.payload?.data !== undefined ?
            (
              balaceDetail?.payload?.data.length > 0 ? (
                <Table
                  className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={balaceDetail?.payload?.data}
                  columns={columns}
                  loading={balaceDetail.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              $ {(numeral(balance).format("0,00.00")).toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              ) : (
                <div className="no-Data">
                  {/* {console.log(intransit?.payload?.data.length, 'intransit?.payload?.data.length')} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default BalanceDetail;
