import React from "react";
import Logo from "../../../assets/images/logo.png";
import "../../SalesReport/SalesReport.css";
import { useSelector } from "react-redux";
import moment from "moment";

const SalesInvoiceReportHeader = () => {
  const { invoicePrint } = useSelector((state) => state);
  const headerData = invoicePrint && invoicePrint.payload?.data[0];

  return (
    <div className="sales-report" style={{width: "99%"}}>
      <div className="sales-report-header" style={{marginLeft: "0px"}}>
        {/* header Left */}
        <div className="header-left-side ">
          {/* Logo & Text */}
          <div className="sales-report-Logo-Container">
            <div>
              <img width={250} height={72} src={Logo} />
            </div>
            {/* Logo Items */}
            <div className="sales-report-Logo_items">
              <div>DL1961 Premium Denim Inc</div>
              <div>121 Varick Street,4th Floor</div>
              <div>NEW YORK ,NY 10013, USA</div>
              <div>TEL : (646) 514-9736</div>
              <div>FAX : (212) 991-9989</div>
              <div>DUNS # : 827403291</div>
            </div>
            {/* Logo Items */}
          </div>
          {/* Items details */}
          <div className="items-detail-container">
            {/* Sold To */}
            <div className="sold-to-Container">
            <div className="sold-to">
                <div className="font_weight invoice-w37">Customer P.O# :</div>
                <div className="sold-to-data">
                {headerData && headerData.referecne_no}

                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight invoice-w37">Sold To :</div>
                <div className="sold-to-data">
                  {headerData && headerData.bill_to_customer}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight invoice-w37">Name :</div>
                <div className="sold-to-data">
                  {(headerData && headerData?.billing_firstname) ?  headerData.billing_firstname + ' ' + headerData.billing_lastname : headerData?.customer_name}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight invoice-w37">Address 1 :</div>
                <div className="sold-to-data">
                  {headerData && headerData.bill_to_address_1}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight invoice-w37">Address 2 :</div>
                <div className="sold-to-data">
                  {headerData && headerData.bill_to_address_2}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">
                  {headerData && headerData.bill_to_city_name}
                </div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">
                  {headerData && headerData.bill_to_state_name}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">
                  {headerData && headerData.bill_to_country}
                </div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">
                  {headerData && headerData.bill_to_zip}
                </div>
              </div>
            </div>
            {/* Sold To */}
            {/* Ship To */}
            <div className="ship-to-Container">
              <div className="sold-to">
                <div className="font_weight invoice-w37">Ship To :</div>
                <div className="sold-to-data">
                  {headerData && headerData.ship_to_addressee}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight invoice-w37">Name :</div>
                <div className="sold-to-data">
                  {/* Attn:  */}
                  {(headerData && headerData?.shiping_firstname) ? headerData?.shiping_firstname + ' ' + headerData.shiping_lastname :  headerData?.customer_name}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight invoice-w37">Address 1 :</div>
                <div className="sold-to-data">
                  {headerData && headerData.ship_to_address_1}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight invoice-w37">Address 2 :</div>
                <div className="sold-to-data">
                  {headerData && headerData.ship_to_address_2}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">
                  {headerData && headerData.ship_to_city_name}
                </div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">
                  {headerData && headerData.ship_to_state_name}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">
                  {headerData && headerData.ship_to_country_name}
                </div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">
                  {headerData && headerData.ship_to_zip}
                </div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Dept :</div>
                <div className="sold-to-data">
                  {headerData && headerData.department}
                </div>
                <div className="font_weight">DC #</div>
                <div className="sold-to-data">
                  {headerData && headerData.dc_no}
                </div>
                <div className="font_weight">Store :</div>
                <div className="sold-to-data">
                  {headerData && headerData.store_name}
                </div>
              </div>
            </div>
            {/* Ship To */}
          </div>
          {/* Items details */}
        </div>
        {/* header Left */}
        {/* header Right */}
        <div className="invoice-header-right-side">
          <div className="sales-order-heading">Commercial Invoice</div>
          <br/>
          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Invoice No :</div>
            </div>
            <div className="sales_order_content">
              <div>DL{headerData && headerData.invoice_header_no}/{headerData && moment(headerData.invoice_date).format("YY")}</div>
            </div>
          </div>

          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Invoice Date :</div>
            </div>
            <div className="sales_order_content">
              <div>
                {headerData &&
                  moment(headerData.invoice_date).format("MM/DD/YYYY")}
              </div>
            </div>
          </div>



          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Term :</div>
            </div>
            <div className="sales_order_content">
              <div>
                {headerData && headerData.term}
                </div>
            </div>
          </div>

          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Ship Via :</div>
            </div>
            <div className="sales_order_content">
              <div 
              // className="overflow_txt"
              >
                {headerData && headerData.ship_via}
              </div>
            </div>
          </div>

          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Freight Term:</div>
            </div>
            <div className="sales_order_content">
              <div>{headerData && headerData.frieght_terms}</div>
            </div>
          </div>

          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Pick Ticket # :</div>
            </div>
            <div className="sales_order_content">
              <div>
                {headerData &&
                  headerData.ship_header_no}
              </div>
            </div>
          </div>

          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Order No :</div>
            </div>
            <div className="sales_order_content">
              <div>
                {headerData &&
                  headerData.sale_order_no}
              </div>
            </div>
          </div>

          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Vendor No :</div>
            </div>
            <div className="sales_order_content">
              <div>
              </div>
            </div>
          </div>
          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Boxes :</div>
            </div>
            <div className="sales_order_content">
              <div>
              </div>
            </div>
          </div>
        </div>
        {/* header Right */}
      </div>
    </div>
  );
};

export default SalesInvoiceReportHeader;
