import React, { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBillHeader } from "../../redux/actions/bill.action";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { billColumns } from "../../utils/cols";
import moment from "moment";
import { dateChecker } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker, Collapse, Row, Col } from "antd";
import { getRights } from "../../redux/actions/userRights.action";
import { CSVLink } from "react-csv";
export default function BillList({ token }) {
	console.log(token,"token")
	const dispatch = useDispatch();
	const { billList } = useSelector((state) => state);
	const dateFormat = "MM-DD-YYYY";
	const [show, setShow] = useState(false);
	const [dataLen, setDataLen] = useState(0);
	const { Panel } = Collapse;
	const history = useHistory();
	const [userRights, setUserRights] = useState({})
	const [form] = Form.useForm();
	const worker = {
		billHeaderno: null,
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		postingPeriod: null,
		currency: null,
		account: null,
		vendor: null,
		shipVia: null,
		approveStatus: null,
		referenceNo: null,
		fromAmount: null,
		toAmount: null,
		memo:null
	};
	function downloadExcelFileData(data) {
		if (data) {
		  return data.map((v) => {
			console.log(v,"Test<----");
			return {
			  "Bill Header No": v.bill_header_no,
			  "Invoice No": v.reference_no,
			  "Vendor Name": v.vendor_name,
			  "Posting Period": v.gl_period_name,
			  "Account Name": v.account_name,
			  "Currency": v.currency_name,
			  "Amount": v.amount,
			  "Memo": v.memo,
			  "Location": v.location_name,
			  "Bill Date": v.bill_date,
			  "Delivery Date": v.delivery_date,
			};
		  });
		}
	  }
	useLayoutEffect(() => {
		dispatch(getRights(token, 59)).then((res) => {
			console.log(res?.payload)
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => dateChecker(values.from_date, values.to_date, "billList", token, values, dispatch))
	};
	const handleFilter = (pagination, filters, sorter, extra) => {

		setShow(true);
	};
	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Bill List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => {
										history.push("/bill/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								// initialValues={{
								// 	remember: true,
								// }}
								form={form}
								onFinish={onFinishFilter}
								initialValues={worker}
								// onFinishFailed={onFinishFailed}
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="billHeaderno" label="Bill Header No" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												format={dateFormat}
												className="dateInput customInputs"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker size="small" id="toDate" format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="currency" label="Currency">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="account" label="Account">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="vendor" label="Vendor" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="shipVia" label="ShipVia">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="approveStatus" label="Approve Status" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="referenceNo" label="Reference No " >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="fromAmount" label="From Amount " rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="toAmount" label="To Amount " rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="memo" label="Memo" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={billList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{/* {billList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div className="productTable">
								<Tables
									dataLen={dataLen}
									setDataLen={setDataLen}
									show={show}
									handleFilter={handleFilter}
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									//   customerAllId={setCustomerAllId}
									//customerObj={setCustomerObj}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={billColumns}
									source={billList?.payload?.data}
									load={billList.loading}
									paginate
								/>
								
							</div>
						</div>
					)} */}

{billList?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                {billList?.payload?.data?.length > 0 && (
                  <CSVLink
                    filename={"Bill.csv"}
                    data={
                      downloadExcelFileData(billList?.payload?.data) || []
                    }
                    onClick={() => {}}
                  >
                    Download Excel
                  </CSVLink>
                )}
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={billColumns}
                  source={billList?.payload?.data}
                  load={billList?.loading}
                  paginate
                />
              </div>
            </div>
          )}
				</div>
			</div>
		</div>
	);
}
