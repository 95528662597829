import {
	ADD_SALE_RETURN_HEADER_ERROR, ADD_SALE_RETURN_HEADER_REQUEST, ADD_SALE_RETURN_HEADER_SUCCESS, DELETE_SALE_RETURN_HEADER_ERROR,
	DELETE_SALE_RETURN_HEADER_REQUEST, DELETE_SALE_RETURN_HEADER_SUCCESS, GET_ALL_SALERETURN_ERROR, GET_ALL_SALERETURN_REQUEST,
	GET_ALL_SALERETURN_SUCCESS, GET_ONE_SALE_RETURN_HEADER_DETAIL_ERROR, GET_ONE_SALE_RETURN_HEADER_DETAIL_REQUEST,
	GET_ONE_SALE_RETURN_HEADER_DETAIL_SUCCESS, GET_ONE_SALE_RETURN_HEADER_ERROR, GET_ONE_SALE_RETURN_HEADER_REQUEST,
	GET_ONE_SALE_RETURN_HEADER_SUCCESS, GET_SALE_RETURN_FORM_DATA_ERROR, GET_SALE_RETURN_FORM_DATA_REQUEST,
	GET_SALE_RETURN_FORM_DATA_SUCCESS, UPDATE_SALE_RETURN_HEADER_ERROR, UPDATE_SALE_RETURN_HEADER_REQUEST, UPDATE_SALE_RETURN_HEADER_SUCCESS,
	DELETE_SALE_RETURN_LINE_REQUEST,
	DELETE_SALE_RETURN_LINE_SUCCESS,
	DELETE_SALE_RETURN_LINE_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");

export const getSaleReturneHeaderFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_SALE_RETURN_FORM_DATA_REQUEST });
	if (clear) {
		return dispatch({
			type: GET_SALE_RETURN_FORM_DATA_SUCCESS,
			payload: {
				berganPaymentTerm: [],
				termForPrint: [],
				factorDetail: [],
				customerDetail: [],
				adminShipTaxCode: [],
				adminShipMethod: [],
				shipVia: [],
				customerTerm: [],
				freightTerm: [],
				berganBilling: [],
				salesPersonDetail: [],
				adminRegionDetail: [],
				orderStatusDetail: [],
				gender: [],
				locationDetail: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/shipHeaderFormList`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_SALE_RETURN_FORM_DATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_SALE_RETURN_FORM_DATA_ERROR,
				payload: error.response,
			});
		});
};

export const addSaleReturnHeader = (token, LineItem, values, invoice_header_id, order_header_id,) => (dispatch) => {

	let newData = {
		remarks: values.remarks,
		// return_date: values.return_date,
		season: values.season,
		ra_no: values.RaApproval,
		year: values.year,
		lot_no: values.lotNumber,
		reason_id: values.returnReason,
		return_type_id: values.returnType,
		return_date: values.return_date,
		customer_id: values.customer,
		order_header_id: values.order_header_id ? values.order_header_id : null,
		order_header_no: values.order_header_no ? values.order_header_no : null,
		ship_header_id: values.ship_header_id ? values.ship_header_id : null,
		ship_header_no: values.ship_header_no ? values.ship_header_no : null,
		invoice_header_id: values.invoice_header_id ? values.invoice_header_id : null,
		invoice_header_no: values.invoice_header_no ? values.invoice_header_no : null,
		// remarks
		// ship_header_id: values.pickTicket,
		status_id: null,
		memo: values.memo,
		company_id: 2,
		billing_to_id: values.billingCustomer,
		shipping_id: values.shippingCustomer,
		term_id: values.paymentTermId,
		shipping_account_no: null,
		freight_term_id: null,
		location_id: values.location,
		gender_id: values.gender,
		sales_person_id: values.salesPerson,
		sp_region_category_id: values.category,
		region_id: values.region,
		return_type_id: values.returnType,
		is_receipt: null,
		discount_code: null,
		discount_amount: null,
		discount_id: null,
		discount_percentage: null,
		discount_basis: null,
		reference_no: values.reference_no,
		remarks: values.remarks,
		ref_invoice_no: values.invoceNumber,
		is_active: null,
		internal_id: null,
		randomData: LineItem,
		billing_firstName: values.billing_firstname ? values.billing_firstname : null,
		billing_lastName: values.billing_lastname ? values.billing_lastname : null,
		shipping_firstName: values.shipping_firstname ? values.shipping_firstname : null,
		shipping_lastName: values.shipping_lastname ? values.shipping_lastname : null
	}
	dispatch({ type: ADD_SALE_RETURN_HEADER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/saleReturnHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_SALE_RETURN_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_SALE_RETURN_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateSaleReturnHeader = (token, LineItem, values, id) => (dispatch) => {
	console.log(values, 'values');
	let newData = {
		return_date: values.return_date,
		customer_id: values.customer,
		order_header_id: null,
		invoice_header_id: null,
		// ship_header_id: values.pickTicket,
		order_header_id: values.order_header_id === null ? null : values.order_header_id,
		order_header_no: values.order_header_no === null ? null : values.order_header_no,
		ship_header_id: values.ship_header_id === null ? null : values.ship_header_id,
		ship_header_no: values.ship_header_no === null ? null : values.ship_header_no,
		invoice_header_id: values.invoice_header_id === null ? null : values.invoice_header_id,
		invoice_header_no: values.invoice_header_no === null ? null : values.invoice_header_no,
		// return_header_id: values.return_header_id === null ? null : values.return_header_id,
		return_header_no: values.return_header_no === null ? null : values.return_header_no,
		status_id: null,
		memo: values.memo,
		ra_no: values.RaApproval,
		season: values.season,
		year: values.year,
		lot_no: values.lotNumber,
		reason_id: values.returnReason,
		return_type_id: values.returnType,
		company_id: 2,
		billing_to_id: values.billingCustomer,
		shipping_id: values.shippingCustomer,
		term_id: values.paymentTermId,
		shipping_account_no: null,
		freight_term_id: null,
		location_id: values.location,
		gender_id: values.gender,
		sales_person_id: values.salesPerson,
		sp_region_category_id: values.category,
		region_id: values.region,
		return_type_id: values.returnType,
		is_receipt: null,
		discount_code: null,
		discount_amount: null,
		discount_id: null,
		discount_percentage: null,
		discount_basis: null,
		reference_no: values.reference_no,
		remarks: values.remarks,
		ref_invoice_no: values.invoceNumber,
		is_active: null,
		internal_id: null,
		randomData: LineItem,
		billing_firstName: values.billing_firstname ? values.billing_firstname : null,
		billing_lastName: values.billing_lastname ? values.billing_lastname : null,
		shipping_firstName: values.shipping_firstname ? values.shipping_firstname : null,
		shipping_lastName: values.shipping_lastname ? values.shipping_lastname : null,
		asn_to_bergen: values.ASNToBergen
	}
	dispatch({ type: UPDATE_SALE_RETURN_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/saleReturnHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_SALE_RETURN_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_SALE_RETURN_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getAllSalesReturnHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_SALERETURN_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/saleReturnHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			ra_no: data.raNo === "" ? null : data.raNo,
			return_header_no: data.returnNo === "" ? null : data.returnNo,
			invoice_header_no: data.invoiceNo === "" ? null : data.invoiceNo,
			customer_name: data.customer === "" ? null : data.customer,
			region_name: data.region === "" ? null : data.region,
			po_no: data.po === "" ? null : data.po,
			order_header_no: data.orderNumber === "" ? null : data.orderNumber,
			memo: data.memo === "" ? null : data.memo,
			gender_name: data.gender === "" ? null : data.gender,
			location_name: data.location === "" ? null : data.location,
			salesperson_name: data.salesPerson === "" ? null : data.salesPerson,
			payment_term_name: data.paymentTermId === "" ? null : data.paymentTermId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_SALERETURN_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_SALERETURN_ERROR,
				payload: error.response,
			});
		});
};

export const getOneSaleReturnHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_SALE_RETURN_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/saleReturnHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_SALE_RETURN_HEADER_SUCCESS,
				payload: response?.data.data[0],
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_SALE_RETURN_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneSaleReturnHeaderDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_SALE_RETURN_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/getSaleHeaderDetail/${id}`,
		//  url: `${BASEURL}getOne/invoiceHeader/${id}`,

		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_SALE_RETURN_HEADER_DETAIL_SUCCESS,
				payload: response?.data[0],
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_SALE_RETURN_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteSaleReturnHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_SALE_RETURN_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/saleReturnHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_SALE_RETURN_HEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_SALE_RETURN_HEADER_ERROR,
				payload: error.response,
			});
		});
};


export const deleteSaleReturnLine = (token, data, id) => (dispatch) => {
	dispatch({ type: DELETE_SALE_RETURN_LINE_REQUEST });

	data.map((val) => {
		delete val.amount;
		delete val.balance_quantity;
		delete val.created_by;
		delete val.created_date;
		delete val.deleted_by;
		delete val.deleted_date;
		delete val.discount;
		delete val.discount_id;
		delete val.gender_category_name;
		delete val.gross_amount;
		delete val.inseam;
		delete val.item_code;
		delete val.item_id;
		delete val.last_updated_by;
		delete val.last_updated_date;
		delete val.net_amount;
		delete val.parent_item_id;
		delete val.quantity;
		delete val.rate;
		delete val.return_header_id;
		delete val.size;
		delete val.style_name;
		delete val.tax_amount;
		delete val.tax_id;
		delete val.tax_percent;
		delete val.wash_name;
	})


	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/saleReturnLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_SALE_RETURN_LINE_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_SALE_RETURN_LINE_ERROR,
				payload: error.response,
			});
		});
};
