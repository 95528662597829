import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Col, Row, Button, Divider, Spin, } from 'antd';
import axios from 'axios';
import Swal from "sweetalert2";
import notification from '../../utils/notification';
import Tables from '../../components/Table/Tables';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ReadCookie } from '../../utils/readCookie';
import { TabTitle } from '../../utils/GeneralFunction';
const Routine = () => {
    TabTitle("Routine")
    const token = ReadCookie("token");
    const [productionLoading, setProductionLoading] = useState(false);
    const [productionSuccess, setProductionSuccess] = useState(false);
    const [canadaLoading, setCandaLoading] = useState(false);
    const [canadaSuccess, setCanadaSuccess] = useState(false);
    const [berganLoading, setBerganLoading] = useState(false);
    const [berganSuccess, setBerganSuccess] = useState(false);
    const [bGradeLoading, setBgradeLoading] = useState(false);
    const [bGradeSuccess, setBgradeSuccess] = useState(false);
    const [intlLoading, setIntlLoading] = useState(false);
    const [intlSuccess, setIntlSuccess] = useState(false);
    const [tblLoading, setTblLoading] = useState(false);
    const [tblSuccess, setTblSuccess] = useState(false);
    const [pkSuccess, setPkSuccess] = useState(false);
    const [pkLoading, setPKLoading] = useState(false);
    const [nuLoading, setNuLoading] = useState(false);
    const [nuSuccess, setNuSuccess] = useState(false);
    const [routineLog, setRoutineLog] = useState([]);

    console.log("token", token);

    useEffect(() => {
        getRoutineLog()
    }, []);

    const getRoutineLog = () => {
        axios({
            method: "POST",
            url: 'https://backend.dl1961.com/v1/getAll/Routine_stats',
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }).then(function (response) {
            console.log(response?.data)
            setRoutineLog(response?.data?.data)
        })
    }


    const column = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 40,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            //   width: 150
        },
        {
            title: "Routine Name",
            dataIndex: "routine_name",
            key: "routine_name",
            //   width: 150
        },
        {
            title: "Routine Date",
            dataIndex: "r_date",
            key: "r_date",
            // render: (text, record) => {
            //     return moment(record.date).format("MM-DD-YYYY")
            // }
        },
        {
            title: "Routine Time",
            dataIndex: "r_time",
            key: "r_time",
            // render: (text, record) => {
            //     return moment(record.r_time, "HH:mm:ss.SSSSSS").format("HH:mm:ss")

            // }
        },
        {
            title: "Total SKU",
            dataIndex: "total_skus",
            key: "total_skus",
        },
        {
            title: "Total Qty",
            dataIndex: "total_quantity",
            key: "total_quantity",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                return text === true ? "True" : "False"

            }
        },
        {
            title: "Status",
            dataIndex: "user_name",
            key: "user_name",

        },
    ];


    const getProductionStock = () => {
        setProductionLoading(true)
        axios.get('https://migration.dl1961.com/api/mssql/moveMSSQLproductionstock')
            .then(function (response) {
                console.log(response)
                // handle success
                if (response.status == 200) {
                    setProductionLoading(false)
                    setProductionSuccess(true)
                    notification('success', 'Production Stock', 'Production Stock Updated Successfully')
                    getRoutineLog()

                }
            })
            .catch(function (error) {
                setProductionSuccess(false)
                setProductionLoading(false)
                // handle error
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Production Stock Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    const getPKStock = () => {
        setPKLoading(true)
        axios.get('https://migration.dl1961.com/api/mssql/moveMSSQLPKStock')
            .then(function (response) {
                // handle success
                if (response.status == 200) {
                    setPKLoading(false)
                    setPkSuccess(true)
                    getRoutineLog()

                    notification('success', 'PK Stock', 'PK Stock Updated Successfully')
                }
            })
            .catch(function (error) {
                // handle error
                setPKLoading(false)
                setPkSuccess(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "PK Stock Failed",
                });

                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    const getIntlStock = () => {
        setIntlLoading(true)
        axios.get('https://migration.dl1961.com/api/mssql/moveMSSQLInternationalSP')
            .then(function (response) {
                // handle success
                console.log(response)
                if (response.status == 200) {
                    setIntlLoading(false)
                    setIntlSuccess(true)
                    getRoutineLog()

                    notification('success', 'Internationl', 'International Stock Updated Successfully')
                }
            })
            .catch(function (error) {
                // handle error
                setIntlLoading(false)
                setIntlSuccess(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "International Stock Failed",
                });

                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    const getBerganStock = () => {
        setBerganLoading(true)
        axios.get('https://migration.dl1961.com/api/mssql/moveMSSQLBergenCanadaSP')
            // axios.get('http://172.16.0.69:8002/api/mssql/moveMSSQLBergenCanadaSP')
            .then(function (response) {
                // handle success
                if (response.status == 200) {
                    setBerganLoading(false)
                    setBerganSuccess(true)
                    getRoutineLog()

                    notification('success', 'Bergan Stock', 'Bergan Stock Updated Successfully')
                }
            })
            .catch(function (error) {
                // handle error
                setBerganLoading(false)
                setBerganSuccess(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Bergan Stock Failed",
                });
                console.log({ error });
            })
            .finally(function () {
                // always executed
            });
    }

    const getCandaStock = () => {
        setCandaLoading(true)
        axios.get('https://migration.dl1961.com/api/mssql/moveMSSQLSOCanadaSP')
            .then(function (response) {
                // handle success
                if (response.status === 200) {
                    setCandaLoading(false)
                    setCanadaSuccess(true)
                    getRoutineLog()

                    notification('success', 'Canada', 'Canada Stock Updated Successfully')
                }
            })
            .catch(function (error) {
                // handle error
                setCandaLoading(false)
                setCanadaSuccess(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Canada Stock Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    const getTblStock = () => {
        setTblLoading(true)
        axios.get('https://migration.dl1961.com/api/mssql/moveProdutionToERP')
            .then(function (response) {
                // handle success
                if (response.status == 200) {
                    setTblLoading(false)
                    setTblSuccess(true)
                    getRoutineLog()
                    notification('success', 'TBL', 'TBL Stock Updated Successfully')
                }
            })
            .catch(function (error) {
                // handle error
                setTblLoading(false)
                setTblSuccess(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "TBL Stock Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    const getBgradeStock = () => {
        setBgradeLoading(true)
        axios.get('https://migration.dl1961.com/api/mssql/moveMSSQLBGradeStock')
            .then(function (response) {
                // handle success
                if (response.status == 200) {
                    setBgradeLoading(false)
                    setBgradeSuccess(true)
                    getRoutineLog()
                    notification('success', 'B-Grade Stock', 'B-Grade Stock Updated Successfully')
                }
            })
            .catch(function (error) {
                // handle error
                setBgradeSuccess(false)
                setBgradeLoading(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "B-Grade Stock Failed",
                });

                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }


    const getNuOrderTest = () => {
        setNuLoading(true)
        axios.get('https://netswitchserver.herokuapp.com/nuOrdertest')
            .then(function (response) {
                // handle success
                if (response.status == 200) {
                    setNuLoading(false)
                    setNuSuccess(true)
                    getRoutineLog()
                    notification('success', 'Nu Order', 'Nu Order Successfully')
                }
            })
            .catch(function (error) {
                // handle error
                setNuLoading(false)
                setNuSuccess(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Nu Order Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }
    return (
        <>
            <Row gutter={[18, 12]}>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: productionSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={productionSuccess && <CheckOutlined />} size='large' loading={productionLoading} onClick={() => getProductionStock()}>
                            Production Stock
                        </Button>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: pkSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={pkSuccess && <CheckOutlined />} size='large' loading={pkLoading} onClick={() => getPKStock()}>
                            PK Stock
                        </Button>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: intlSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={intlSuccess && <CheckOutlined />} size='large' loading={intlLoading} onClick={() => getIntlStock()}>
                            International Orders
                        </Button>
                    </div>                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: canadaSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={canadaSuccess && <CheckOutlined />} size='large' loading={canadaLoading} onClick={() => getCandaStock()}>
                            Canada Orders
                        </Button>
                    </div>                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: berganSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={berganSuccess && <CheckOutlined />} size='large' loading={berganLoading} onClick={() => getBerganStock()}>
                            Bergan Canada Stock
                        </Button>
                    </div>                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: tblSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={tblSuccess && <CheckOutlined />} size='large' loading={tblLoading} onClick={() => getTblStock()}>
                            TBL Production
                        </Button>
                    </div>                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: bGradeSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={bGradeSuccess && <CheckOutlined />} size='large' loading={bGradeLoading} onClick={() => getBgradeStock()}>
                            B-Grade Stock
                        </Button>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: nuSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={nuSuccess && <CheckOutlined />} size='large' loading={nuLoading} onClick={() => getNuOrderTest()}>
                            Nu Order
                        </Button>
                    </div>
                </Col>
            </Row >
            <div style={{ paddingTop: "200px" }}>
                <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={routineLog}
                    col={column}
                    paginate
                />
            </div>
        </>
    )
};
export default Routine;