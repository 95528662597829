import React, { useState, useEffect, useRef } from "react";
import { Select, DatePicker, Form, Table, Spin, Input, Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getOmsReport } from "../../redux/actions/omsReport.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { useLocation, Link, useHistory, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";
import printJS from "print-js";
import { Excel } from "antd-table-saveas-excel";
// import "./styleSheet.css";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getPoLookup } from "../../redux/actions/quickPoLookup.action";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";

const { Option } = Select;
const dateFormatList = ["MM/DD/YYYY"];
const POLookup = ({ token }) => {
  TabTitle("Quick Po Lookup")
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    gender,
    region,
    companyAll,
    OmsReprot,
    regionStateReport,
    omsRegionStateReport,
    omsReportByCustomer,
    quickPoLookup,
  } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [propsData, setPropsData] = useState({});
  const [paginatePrint, setPaginatePrint] = useState(false);
  const [excelToogle, setExcelToogle] = useState(false);
  const { submited } = useParams();
  const [data, setData] = useState([])
  const [excelData, setExcelData] = useState([])

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  const handlePrint = () => {
    window.print();

  }
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let check = query.get("submited");
  let company_id = query.get("company_id");
  let date_type = query.get("date_type");
  let gender_id = query.get("gender_id");
  let term_id = query.get("term_id");
  let from_date = query.get("from_date");
  let to_date = query.get("to_date");
  let region_id = query.get("region_id");
  let selected_id = query.get("selected_id");
  let report = query.get("report");

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (check) {
      form.setFieldsValue({
        company_id: Number(company_id),
        date_type: date_type,
        gender_id: gender_id === "All" ? gender_id : Number(gender_id),
        region_id: region_id === "All" ? region_id : Number(region_id),
        term_id: Number(term_id),
        from_date: moment(from_date),
        to_date: moment(to_date),
      });
      dispatch(getOmsReport(token, { company_id, date_type, gender_id, from_date, to_date, term_id, region_id, }));
    }
  }, []);

  const handleChange = (value) => { };

  const onFinish = async (e) => {
    setSubmit(true);
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getPoLookup(token, values)).then((res) => {
        setData(res?.payload?.data)
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      });
      // setValues(values);
      // setPropsData({
      //     company_id,
      //     date_type,
      //     gender_id,
      //     term_id,
      //     from_date,
      //     to_date,
      // });
      // history.push({
      //     pathname: "/oms-booking",
      //     search: `?submited=${true}&report=${1}&company_id=${values.company_id
      //         }&date_type=${values.date_type}&gender_id=${values.gender_id
      //         }&region_id=${values.region_id}&term_id=${values.term_id
      //         }&from_date=${moment(values.from_date).format(
      //             "MM-DD-YYYY"
      //         )}&to_date=${moment(values.to_date).format("MM-DD-YYYY")}`,
      // });
      // dispatch(getOmsReport(token, values)).then((res) => {
      //     setLoading(false);
      // });
    });

  };


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Order #": v.order_header_no,
          "Order Date": moment(v.order_date).format("MM-DD-YYYY"),
          "PO #": v.po_no,
          "City": v.city_name,
          "Qty": v.quantity,
          "Amount": v.amount,
          "POD Qty": v.pod_quantity,
          "Pending Qty": v.pending_qty,
          "Ship Qty": v.ship_qty,
          "Adjust Qty": v.qty_adjust,

        };
      })
    )
  }


  const columns = [
    {
      title: "#",
      width: 70,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Order #",
      dataIndex: "order_header_no",
      key: "order_header_no",
      width: 90,
      render: (text, record) => {
        return <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          to={{
            pathname: '/booked-detail-report',
            state: record
          }}
        >{record.order_header_no}</Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.order_header_no !== null) {
          return record.order_header_no.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        // if (a.order_header_no !== null & b.order_header_no !== null) {
        return a.order_header_no, b.order_header_no
        // }
      },
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 90,
      render: (text, record) => {
        return (
          <div>
            {moment(text).format("MM-DD-YYYY")}
          </div>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.order_date !== null) {
          return record.order_date.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.order_date !== null & b.order_date !== null) {
          return a.order_date.localeCompare(b.order_date)
        }
      },
    },
    {
      title: "PO #",
      dataIndex: "po_no",
      key: "po_no",
      width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.po_no !== null) {
          return record.po_no.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.po_no !== null & b.po_no !== null) {
          return a.po_no.localeCompare(b.po_no)
        }
      },
    },
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_name",
      width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.city_name !== null) {
          return record.city_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.city_name !== null & b.city_name !== null) {
          return a.city_name.localeCompare(b.city_name)
        }
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 60,
      align: "right",
      render: (text, record) => {
        return <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          to={{
            pathname: '/booked-detail-report',
            state: record
          }}
        >{record.quantity}</Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.quantity !== null) {
          return record.quantity.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        // if (a.quantity !== null & b.quantity !== null) {
        return a.quantity, b.quantity
        // }
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 90,
      align: "right",
      render: (text, record) => {
        return <>$ {numeral(record.amount).format("0,00")}</>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.amount !== null) {
          return record.amount.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.amount !== null & b.amount !== null) {
          return a.amount.localeCompare(b.amount)
        }
      },
    },
    {
      title: "POD Qty",
      dataIndex: "pod_quantity",
      key: "pod_quantity",
      align: "right",
      width: 90,
      render: (text, record) => {
        return <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          to={{
            pathname: '/pod-detail-report',
            state: record
          }}
        >{record.pod_quantity}</Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.pod_quantity !== null) {
          return record.pod_quantity.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        // if (a.pod_quantity !== null & b.pod_quantity !== null) {
        return a.pod_quantity, b.pod_quantity
        // }
      },
    },
    {
      title: "Pending Qty",
      dataIndex: "pending_qty",
      key: "pending_qty",
      width: 90,
      align: "right",
      render: (text, record) => {
        return <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          to={{
            pathname: '/pending-detail-summary-report',
            state: record
          }}
        >{record.pending_qty}</Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.pending_qty !== null) {
          return record.pending_qty.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        // if (a.pending_qty !== null & b.pending_qty !== null) {
        return a.pending_qty, b.pending_qty
        // }
      },
    },
    {
      title: "Ship Qty",
      dataIndex: "ship_qty",
      key: "ship_qty",
      width: 90,
      align: "right",
      render: (text, record) => {
        return <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          to={{
            pathname: '/shipped-detail',
            state: record
          }}
        >{record.ship_qty}</Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.ship_qty !== null) {
          return record.ship_qty.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.ship_qty !== null & b.ship_qty !== null) {
          return a.ship_qty.localeCompare(b.ship_qty)
        }
      },
    },
    {
      title: "Adjust Qty",
      dataIndex: "qty_adjust",
      key: "qty_adjust",
      width: 90,
      align: "right",
      render: (text, record) => {
        return <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          to={{
            pathname: '/order-adjustment-detail-report',
            state: record
          }}> {numeral(record.qty_adjust).format("0")}</Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.qty_adjust !== null) {
          return record.qty_adjust.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.qty_adjust !== null & b.qty_adjust !== null) {
          return a.qty_adjust.localeCompare(b.qty_adjust)
        }
      },
    },
  ];

  const columsPageOne = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Order #",
      dataIndex: "order_header_no",
      key: "order_header_no",
      width: 75,
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 90,
    },
    {
      title: "PO #",
      dataIndex: "po_no",
      key: "po_no",
      width: 50,
    },
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_name",
      width: 50,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 60,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 80,
      align: "right",
    },
    {
      title: "POD Qty",
      dataIndex: "pod_quantity",
      key: "pod_quantity",
      width: 90,
    },
    {
      title: "Pending Qty",
      dataIndex: "pending_qty",
      key: "pending_qty",
      width: 90,
      align: "right",
    },
    {
      title: "Ship Qty",
      dataIndex: "ship_qty",
      key: "ship_qty",
    },
  ];


  const excelReport = OmsReprot?.payload?.data;

  // Total Quantity Excel
  let sumExcelQty = excelReport && excelReport.reduce(function (previousValue, currentValue) {
    return (previousValue += parseFloat(currentValue.quantity.replace(/,/g, "")));
  }, 0);

  // total Amount Excel
  let sumExcelAmount = excelReport && excelReport.reduce(function (previousValue, currentValue) {
    return (previousValue += parseFloat(currentValue.net_amount.replace(/\$|,/g, "")));
  }, 0);

  let sumExcelLocalize = sumExcelAmount && sumExcelAmount.toLocaleString("en-US");

  const totalSumExcel = {
    region_name: "Total",
    quantity: sumExcelQty,
    net_amount: `$ ${sumExcelLocalize}`,
  };

  let concatenate = excelReport && excelReport.concat(totalSumExcel);

  const handleExcelExport = () => {
    // if (check && report === "1") {
    //   if (concatenate.length > 3000) {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columsPageOne)
      .addDataSource(quickPoLookup?.payload?.data, {
        str2Percent: true,
      })
      .setTHeadStyle()
      .saveAs("Excel.xlsx");
    //   } else {
    //     setExcelToogle(true);
    //   }
    // }

  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-sm-4 box-space right-content-header text-right pb-0">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class mr-8">
                      <div className="print_icon printing_class">

                        {OmsReprot?.payload?.data.length ? (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"

                          ></i>
                        ) : (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"
                            onClick={handlePrint}
                          ></i>
                        )}
                      </div>
                      <div className="print_icon printing_class">
                        <CSVLink
                          filename={"PO Lookup.csv"}
                          data={excelData}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        </CSVLink>
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Quick PO Lookup
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row printing_class">
                <div className="col text-sm-right pb-1">

                </div>
              </div>
              {report < 2 && (
                <>
                  <div className="row">
                    <div className="col">
                      <Form
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                      >
                        <div className="main_form row">
                          <div className=" col-6">
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className="labels width30">
                                PO #:
                              </label>
                              <div className=" w-100">
                                <Form.Item
                                  name="PO"
                                  initialValue={form.getFieldValue().PO}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter PO Number",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="small"
                                    allowClear

                                  // disabled={!(formState === "add" || formState === "edit")}
                                  // onChange={(e) => setElimination(e.target.value)}
                                  // className='customInputs no-border-first'
                                  // placeholder="Enter PO#"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className=" col-6">
                            <div
                              style={{
                                marginTop: "4px",
                                // marginRight: "150px",
                              }}
                            >
                              {/* <label className="no_visibility">none</label> */}
                              <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm ant-btn ant-btn-primary m-0"
                              >
                                Submit
                              </Button>{" "}
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row" ref={tableRef}>
              <div className="col">
                <div className="maxWidth mt-3">
                  <>
                    {/* Report 1 */}
                    {loading ? (
                      <div className="maxWidth d-flex align-items-center justify-content-center spin-style mt-3">
                        <Spin />
                      </div>
                    ) : quickPoLookup?.payload?.data.length ? (
                      <Table
                        showSorterTooltip={false}
                        // scroll={{ x: 200 }}
                        className="maxWidth test04 myclass"
                        size="small"
                        pagination={{ pageSize: 3000 }}
                        summary={(pageData) => {
                          console.log(pageData[0]?.po_no);
                          let sumQty = pageData.reduce(function (
                            previousValue,
                            currentValue
                          ) {
                            return (previousValue += parseFloat(
                              currentValue.quantity
                            ));
                          },
                            0);

                          let sumAmount = pageData.reduce(function (
                            previousValue,
                            currentValue
                          ) {
                            return (previousValue += parseFloat(
                              currentValue.amount
                            ));
                          },
                            0);

                          let podQty = pageData.reduce(function (
                            previousValue,
                            currentValue
                          ) {
                            return (previousValue += parseFloat(
                              currentValue.pod_quantity
                            ));
                          },
                            0);
                          let shipQty = pageData.reduce(function (
                            previousValue,
                            currentValue
                          ) {
                            return (previousValue += parseFloat(
                              currentValue.ship_qty
                            ));
                          },
                            0);
                          let pendQty = pageData.reduce(function (
                            previousValue,
                            currentValue
                          ) {
                            return (previousValue += parseFloat(
                              currentValue.pending_qty
                            ));
                          },
                            0);
                          let adjQty = pageData.reduce(function (
                            previousValue,
                            currentValue
                          ) {
                            return (previousValue += parseFloat(
                              currentValue.qty_adjust
                            ));
                          },
                            0);
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <p
                                    style={{
                                      paddingTop: "10px",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Total
                                  </p>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <Link
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                    to={{
                                      pathname: '/booked-detail-report',
                                      state: {po_no: pageData[0]?.po_no}
                                    }}>
                                    <p
                                      style={{
                                        fontWeight: "bolder",
                                        textAlign: "right",
                                        width: "100%",
                                        borderTop: "1px solid black",
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      {sumQty.toLocaleString("en-US")}
                                    </p>
                                  </Link>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      textAlign: "right",
                                      width: "100%",
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    $ {sumAmount.toLocaleString("en-US")}
                                  </p>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                <Link
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                    to={{
                                      pathname: '/pod-detail-report',
                                      state: {po_no: pageData[0]?.po_no}
                                    }}>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      textAlign: "right",
                                      width: "100%",
                                      borderTop: "1px solid green",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    {podQty.toLocaleString("en-US")}
                                  </p>
                                  </Link>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                <Link
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                    to={{
                                      pathname: '/pending-detail-summary-report',
                                      state: {po_no: pageData[0]?.po_no}
                                    }}>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      textAlign: "right",
                                      width: "100%",
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    {pendQty.toLocaleString("en-US")}
                                  </p>
                                  </Link>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                <Link
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                    to={{
                                      pathname: '/shipped-detail',
                                      state: {po_no: pageData[0]?.po_no}
                                    }}>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      textAlign: "right",
                                      width: "100%",
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    {shipQty.toLocaleString("en-US")}
                                  </p>
                                  </Link>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                <Link
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                    to={{
                                      pathname: '/order-adjustment-detail-report',
                                      state: {po_no: pageData[0]?.po_no}
                                    }}>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      textAlign: "right",
                                      width: "100%",
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    {adjQty.toLocaleString("en-US")}
                                  </p>
                                  </Link>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                        bordered
                        columns={columns}
                        dataSource={
                          quickPoLookup?.payload?.data
                            ? quickPoLookup?.payload?.data
                            : []
                        }
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#CCCCCC",
                          borderWidth: "1pt",
                          borderStyle: "Dashed",
                          fontWeight: "bold",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        No record(s)
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default POLookup;