import React from "react";
import { Button, Tooltip, Divider, Tag, Popconfirm } from "antd";
import { EditOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory, Link } from "react-router-dom";

export default function CrudButtons({ save, disable, formState, formType, onDelete, handleMarked, reset, name, flag, rights, saleOrderNewID, customerCode, formData, ID, saleOrderID, userRights, user_type }) {

  const history = useHistory();

  // ************ CONFIM DELETE FUNCTION **************
  const confirmDelete = () => {
    onDelete()
  };

  // ************ HANDLE MARKED FUNCTION **************
  const markedHandler = () => {
    handleMarked();
  }


  function disableBtns() {
    let result = false;
    // if (!userRights?.can_edit && user_type !== "PK" || formState === "add") {
    //   result = true;
    // }
    if (!userRights?.can_edit || formState === "add") {
      result = true;
    }
    if (user_type !== "PK") {
      result = true;
    }
    else {
      result = false;
    }
    return result;
  }

  // ************ HANDLE FORMSTATE CRUD FUNCTION **************
  const handleFormState = () => {
    if (formType === "view") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              onClick={() => history.push("/order-shipment-new-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>

        </>
      )
    }
    else if (formType === "add") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/order-shipment-new-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={
                !(
                  (formType === "add" || formType === "edit") &&
                  (name === "product"
                    ? formType === "edit"
                      ? true
                      : flag
                        ? true
                        : false
                    : !disable)
                )
              }
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      )
    }
    else if (formType === "formView" || formType === "edit") {
      return (

        <>
          {formData?.fulfilled && formType !== "add" ? (
            <Tooltip placement="bottom" title="Fulfillment " color="#fbfbfb">
              <Button
                type="primary"
                disabled
                className="modalButton cursor-pointer"
              >
                Fulfilled
              </Button>
            </Tooltip>
          ) : (
            formType !== "add" &&
            customerCode && (
              <Tooltip placement="bottom" title="Fulfillment " color="#fbfbfb">
                <Button
                  type="primary"
                  onClick={() => {
                    history.push({
                      pathname: `/order-shipment/edit/${saleOrderNewID}`,
                      state: { detail: 'editt' }
                    })
                  }}
                  className="modalButton"
                >
                  Fulfillment
                </Button>
              </Tooltip>
            )
          )}
          {
            userRights.can_print ? (
              <Link
                style={{ padding: "10px", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
                to={{ pathname: `/New-order-shipment-report`, search: `?ship_header_id=${ID}` }}
              >
                View Report
              </Link>
            ) : ""
          }
          {
            formData && formData.invoice_id === null || formData && formData.invoice_id === 0 && formData.status_id === 3 && (
              <Tooltip placement="bottom" color="#fbfbfb"  >
                <Button type="primary"
                  disabled={disableBtns()}
                  // disabled={((formState === "add" || formState === "view")) || userRights.can_delete === true ? false : true}
                  onClick={() => history.push(`/invoice?mode=invoice&order_header_id=${ID}&customer_id=${formData.customer_id}`)}
                  className="modalButton">Invoice </Button>
              </Tooltip>
            )
          }
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
                <Button
                  disabled={(userRights.can_edit === true ? false : true) || (disable || formType === "view" || formType === "add")}
                  onClick={() => formState("edit")}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )
          }

          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/order-shipment-new-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Delete" color="#fbfbfb"   >
                {rights?.can_delete === false ?
                  <Button disabled={true} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} /> :
                  <Popconfirm
                    disabled={userRights.can_delete === true ? false : true}
                    // disabled={(disable || (formType === "add" || formType === "view")) || userRights.can_delete === true ? false : true}
                    placement="topLeft"
                    title={`Are you sure to delete this Item?`}
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      disabled={userRights.can_delete === true ? false : true}
                      // disabled={(disable || (formType === "add" || formType === "view")) || userRights.can_delete === true ? false : true}
                      className="iconBtn crudBTN"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                }
              </Tooltip>
            )
          }

          {
            formType === "add" || formType === "edit" && (
              <Tooltip placement="bottom" title="Save" color="#fbfbfb">
                <Button
                  disabled={
                    !(
                      (formType === "add" || formType === "edit") &&
                      (name === "product"
                        ? formType === "edit"
                          ? true
                          : flag
                            ? true
                            : false
                        : !disable)
                    )
                  }
                  onClick={() => save()}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<FileDoneOutlined />}
                />
              </Tooltip>
            )
          }


        </>
      )

    }
  }
  return (
    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null : (
            <div className="buttons-div">
              <Tag color={"green"} key={1}>
                {formType.toUpperCase()}
              </Tag>
              {formType !== "view" && (
                <Button
                  onClick={() => reset()}
                  className="iconBtn"
                  shape="circle"
                  icon={<UndoOutlined className="fspx-12" />}
                />
              )}

              {
                (formData && formData.invoice_id === null || formData && formData.invoice_id === 0) && (
                  <Tooltip placement="bottom" color="#fbfbfb"  >
                    <Button
                      disabled={disableBtns()}
                      // disabled={((formState === "add" || formState === "view")) || userRights.can_delete === true ? false : true}
                      type="primary" onClick={markedHandler}
                      className="modalButton">Marked As {formData && formData?.status_id === 2 ? "Shipped" : formData?.status_id === 3 || 1 ? "Packed" : ""}</Button>
                  </Tooltip>
                )
              }
            </div>
          )}
        </div>
        <div>
          {handleFormState()}
        </div>
      </div>
      <Divider />
    </>
  );
}
