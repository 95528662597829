import React from 'react'
import { Card } from 'antd';

const SalesReturnReportFooter = () => {
  return (
    <div className='order_report_footer' 
    // style={{width: "1150px"}}
    >
      <div style={{display :"flex", justifyContent : "space-between"}}>
        <Card style={{ width: "450px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center", textDecoration: "underline" }}>Return Instruction</p>
          <p className='footer_order_p'>1) No return will be accepted without RA#.</p>
          <p className='footer_order_p'>2) All Return must include a copy of this packing list.</p>
          <p className='footer_order_p'>3) Only approved styles and Quantities are returnable against this RA.</p>
          <p className='footer_order_p'>4) Returns will not be accepted if merchandise has a sensor on it.</p>
          <p className='footer_order_p'>5) Return will not be accepted after expire date.</p>
          <p className='footer_order_p'>6) Only Credit Memo will be issued for returned goods - no refund.</p>
        </Card>
    
        <Card style={{ width: "310px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center", textDecoration: "underline" }}>Return Address</p>
          <p className='footer_order_p'>DL1961 Premium Denim c/o Bergen Logistics <br/>
           299 Thomas E. Dunn Memorial Highway<br/>
            Rutherford, NJ 07070 <br/>
            Contact Person: Karla Hernandez <br/>
            Tel: 201 624 2170 Ext.231  <br/>
            Tel: 201 854 8136</p>
        </Card>
      </div>

    </div>
  )
}

export default SalesReturnReportFooter;