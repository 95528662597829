import { GET_ALL_ORDER_ADJ_DETAIL_REQUEST, GET_ALL_ORDER_ADJ_DETAIL_SUCCESS, GET_ALL_ORDER_ADJ_DETAIL_ERROR } from "./types";
import { BASEURL } from "../../constant/config.js"
import axios from "axios";
import moment from "moment";

export const getOrderAdjDetail = (token, values) => (dispatch) => {
    let cleanedVal = values.customer === undefined ? null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
    let cleanedVal1 = values.billing_addressee === undefined ? null : values.billing_addressee.includes("'") ? values.billing_addressee.replace(/'/g, "''") : values.billing_addressee;

    dispatch({ type: GET_ALL_ORDER_ADJ_DETAIL_REQUEST });
    const newData = {
        customer_type_id: values.cutomer_category === "All" ? null : values.cutomer_category,
        from_date: moment(values.from_date).format("MM-DD-YYYY"),
        to_date: moment(values.to_date).format("MM-DD-YYYY"),
        order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
        order_header_adj_no: values.order_adj_no === undefined || values.order_adj_no === "" ? null : values.order_adj_no,
        // invoice_header_no: values.invoice_no === undefined || values.invoice_no === "" ? null : values.invoice_no,
        // ship_header_no: values.ship_no === undefined || values.ship_no === "" ? null : values.ship_no,
        reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
        customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
        bill_addressee: cleanedVal1 === undefined || cleanedVal1 === "" ? null : cleanedVal1,
        sp_id: values.sale_person === "All" ? null : values.sale_person,
        region_id: values.region === "All" ? null : values.region,
        gender_id: values.gender === "All" ? null : values.gender,
        payment_term_id: values.terms === "All" ? null : values.terms,
        // store_id: values.store === undefined || values.store === "" ? null : values.store,
        company_id: values.subsidary === undefined ? 2 : 2,
        // source_type: values.source === undefined || values.source === "" ? null : values.source,
        sku: values.sku === undefined || values.sku === "" ? null : values.sku,
        // status: values.status === undefined || values.status === "All" ? null : values.status,
        // ntype: 1,
        location_id: values.location === undefined || values.location === "All" ? null : values.location

    };
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/SOAdjDetail`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_ALL_ORDER_ADJ_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ALL_ORDER_ADJ_DETAIL_ERROR,
                payload: [],
            });

        });
};
