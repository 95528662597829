import React, { useEffect } from "react";
import "../SalesReport.css";
import Logo from "../../../assets/images/logo.png";
import { getSalesReportOrderPrint } from "../../../redux/actions/salesReport.action";
import { useSelector } from "react-redux";
import { ReadCookie } from "../../../utils/readCookie";
import { useDispatch } from "react-redux";
import moment from "moment";

const dateFormatList = ["MM-DD-YYYY"];
const SalesAdjustmentReportHeader = () => {
  const token = ReadCookie("token");
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch()
  const { salesReportOrderPrint, salesAdjustmentPrint } = useSelector((state) => state);




  const headerData = salesAdjustmentPrint && salesAdjustmentPrint?.payload?.data[0];
  // useEffect(() => {
  //   dispatch(getSalesReportOrderPrint(token, values));
  // }, [dispatch, menuId, token]);

  return (
    <div className='sales-report'>

      <div className="sales-report-header">
        {/* header Left */}
        <div className="header-left-side ">
          {/* Logo & Text */}
          <div className="sales-report-Logo-Container">
            <div>
              <img width={250} height={72} src={Logo} />
            </div>
            {/* Logo Items */}
            <div className="sales-report-Logo_items">
              <div>DL1961 Premium Denim Inc.</div>
              <div>121 Varick Street,4th Floor</div>
              <div>NEW YORK ,NY 10013, USA</div>
              <div>TEL : (646) 514-9736</div>
              <div>FAX : (212) 991-9989</div>
              <div>EMAIL : sales@DL1961.com</div>
            </div>
            {/* Logo Items */}
          </div>
          {/* Items details */}
          <div className="items-detail-container">
            {/* Sold To */}
            <div className="sold-to-Container">
              <div className="sold-to">
                <div className="font_weight">Sold To :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_customer}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Name :</div>
                <div className="sold-to-data">{headerData && headerData.customer_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 1 :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_address_1}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 2 :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_address_2}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_city_name}</div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_state_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_country}</div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_zip}</div>
              </div>

            </div>
            {/* Sold To */}
            {/* Ship To */}
            <div className="ship-to-Container">
              <div className="sold-to">
                <div className="font_weight">Ship To :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_addressee}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Name :</div>
                <div className="sold-to-data">
                  {/* Attn:  */}
                {headerData && headerData.customer_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 1 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_1}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 2 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_2}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_city_name}</div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_state_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_country_name}</div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_zip}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Dept :</div>
                <div className="sold-to-data">{headerData && headerData.department}</div>
                <div className="font_weight">DC #</div>
                <div className="sold-to-data">{headerData && headerData.dc_no}</div>
                <div className="font_weight">Store :</div>
                <div className="sold-to-data">{headerData && headerData.store_name}</div>
              </div>

            </div>
            {/* Ship To */}
          </div>
          {/* Items details */}
        </div>
        {/* header Left */}
        {/* header Right */}
        <div className="header-right-side">
          <div className="sales-order-heading">Sale Order Adjustment</div>
          <div className="sales-order-items-container">
            <div className="sales-order-items-left">
              <div className="font_weight">Adjustment # :</div>
              <div className="font_weight">Adjustment Date :</div>
              <div className="font_weight">P.O # :</div>
              <div className="font_weight">Sale Order # :</div>
              <div className="font_weight">Term :</div>
              <div className="font_weight">Ship Via :</div>
              <div className="font_weight">Frieght Terms :</div>
              <div className="font_weight">Ship Date(From) :</div>
              <div className="font_weight">Ship Date(To) :</div>
              <div className="font_weight">Cancel Date :</div>
            </div>
            <div className="sales_order_content">
              <div>{headerData && headerData.adj_no}</div>
              {/* <div>{headerData && headerData.order_date}</div> */}
              <div>{headerData && moment(headerData.order_date).format("MM-DD-YYYY")}</div>
              <div>{headerData && headerData.po_number}</div>
              <div>{headerData && headerData.sale_order_no}</div>
              <div>{headerData && headerData.term}</div>
              <div className="overflow_txt">{headerData && headerData.ship_via}</div>
              <div>{headerData && headerData.frieght_terms}</div>
              {/* <div>{headerData && headerData.ship_date_from}</div>
            <div>{headerData && headerData.ship_date_to}</div> */}
              {/* <div>{headerData && headerData.cancel_date}</div> */}
              <div>{headerData && moment(headerData.ship_date_from).format("MM-DD-YYYY")}</div>
              <div>{headerData && moment(headerData.ship_date_to).format("MM-DD-YYYY")}</div>
              <div>{headerData && moment(headerData.cancel_date).format("MM-DD-YYYY")}</div>
            </div>
          </div>
        </div>
        {/* header Right */}
      </div>
    </div>
  );
};

export default SalesAdjustmentReportHeader;
