import React, { useEffect, useState, useCallback, useRef } from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
    Input,
    Form,
    Select,
    Table,
    InputNumber,
    Checkbox,
    Button,
} from "antd";
import Text from 'antd/lib/typography/Text';
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie } from "../../utils/readCookie";
import { getProducts } from '../../redux/actions/product.action';
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { addRelocateItems, getAllRelocateItems } from '../../redux/actions/relocateItems.action';
import moment from 'moment';
import notification from "../../utils/notification";
import Swal from 'sweetalert2'
import { TabTitle } from '../../utils/GeneralFunction';

const { Option } = Select;


const RelocateItems = () => {
    TabTitle("Re-Allocation")
    const [minimize, setMinimize] = useState(false);
    const [form] = Form.useForm();
    const { product, adminLocation, relocateItem } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const dispatch = useDispatch();
    const [products, setProducts] = useState("All");
    const [itemId, setItemId] = useState(null);
    const [relocateItems, setRelocateItems] = useState([]);


    useEffect(() => {
        dispatch(getAdminLocation(token, menuId));
    }, [dispatch])
    // Sum of Quantity Commited
    let sumQuantityCommited = 0;
    if (relocateItems.length > 0) {
        for (let i = 0; i < relocateItems.length; i++) {
            sumQuantityCommited += relocateItems[i].quantity_committed;
        }
    }

    // Sum of Quantity Order	
    let sumQuantityOrder = 0;
    if (relocateItems.length > 0) {
        for (let i = 0; i < relocateItems.length; i++) {
            sumQuantityOrder += relocateItems[i].quantity;
        }
    }

    // Sum of Quantity Packed	
    let sumQuantityPacked = 0;
    if (relocateItems.length > 0) {
        for (let i = 0; i < relocateItems.length; i++) {
            sumQuantityPacked += relocateItems[i].quantity_packed;
        }
    }

    function debounce(cb, delay = 1000) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }

    const handleChanges = (val) => {
        if (val === "" || val === "All") return;
        setProducts(val);
        dispatch(getProducts(token, menuId, val)).then(
            (res) => {
                if (res?.type === "GET_ALL_PRODUCT_SUCCESS") {
                    setProducts(res?.payload?.data);
                }
            }
        );
    };

    const optimizedFn = useCallback(debounce(handleChanges), []);
    let itemsLines = relocateItems && relocateItems.filter((v) => v.order_header_no !== 0);

    const onFinish = async () => {
        const filterSelectedItem = relocateItems.map((val) => {
            delete val.available_quantity;
            delete val.commit_criteria_name;
            delete val.commit_status;
            delete val.customer_name;
            delete val.item_code;
            delete val.onhand_quantity;
            delete val.order_date;
            delete val.order_header_no;
            delete val.quantity;
            delete val.quantity_packed;
            delete val.location_id;
            return val;
        })
        dispatch((addRelocateItems(token, filterSelectedItem))).then((res) => {
            if (res.type === "ADD_ALL_RELOCATEITEMS_SUCCESS") {
                notification(
                    "success",
                    " Re-Alocation Success",
                    "Re-Allocation Suc!"
                );
            }
            if (res.type === "ADD_ALL_RELOCATEITEMS_ERROR") {
                notification(
                    "error",
                    res?.payload?.request?.statusText,
                    res?.payload?.data?.data
                );
            }
            form.setFieldsValue({
                item: null,
                location: null,
            });
            setRelocateItems([])
        })
    }


    const onChangeCheckBox = (e, i, isSelected, record) => {
        let item = [...relocateItems];
        let index = i + 1;

        if (!e.target.checked) {
            relocateItems[index].quantity_backorder = item[index].quantity_committed + relocateItems[index].quantity_backorder;
            item[index].quantity_committed = 0;
            item[index].already = false;
            relocateItems[index].isSelected = false;
            // setRelocateItems(item)
        }
        else if (e.target.checked) {
            if (item[index].commit_criteria_name !== "Do Not Commit") {
                let availableqty = (relocateItems.length > 0 && relocateItems[0].onhand_quantity) - sumQuantityCommited;
                // let QuantityValue = relocateItem?.payload?.data[index].quantity_backorder;
                let QuantityValue = relocateItems[index].quantity_backorder;
                let value;

                if (availableqty >= QuantityValue) {
                    value = QuantityValue;
                }
                else if (availableqty <= QuantityValue) {
                    value = availableqty;
                }
                relocateItems[index].quantity_backorder = relocateItems[index].quantity_backorder - value;
                item[index].quantity_committed = value;
                relocateItems[index].isSelected = true;
                item[index].already = true;
            } else {
                return Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "You can't Select it, Your Order is Do Not Commit",
                });

            }
        }
        setRelocateItems(item);

    }

    const updateQuantityCommited = (record, value, i) => {
        let index = i + 1;
        let PreviousV = relocateItems[index].quantity_committed;
        let Newv = value;

        let check = relocateItems[0].onhand_quantity - sumQuantityPacked;
        // copy array
        let item = [...relocateItems];
        
        // Total Commited SUM
        const commitedSum = relocateItems.reduce((accum, value) => {
            console.log("quantity_committed", accum, value.quantity_committed)
            relocateItems[index].quantity_committed = Newv;
            return accum + value.quantity_committed;
        }, 0);
        console.log("commitedSum", commitedSum);

        if(commitedSum <= check) {
            
            Swal.fire(
                'OOPS!',
                "Inputs Sum is Less",
                'error'
            )
            
        }else {
            Swal.fire(
                'OOPS!',
                "Inputs Sum is Greater Than",
                'error'
            )
        }

        // if (item[index].quantity_backorder < Newv) {
            // return Swal.fire(
            //     'OOPS!',
            //     "Qty Commited Should be Less Than Qty Remaining",
            //     'error'
            // )
        // } else {
            // check avaialbe units as per current  committed value
            let availqty = item[0].onhand_quantity - item[index].quantity_committed;
            

            if (availqty < 0) {
                // notification("error", "Available Qty is less than Commit Qty.");
                Swal.fire(
                    'OOPS!',
                    'Available Qty is less than Commit Qty!',
                    'error'
                )
                item[index].quantity_committed = item[index].quantity_committed + availqty;
                item[index].quantity_backorder = item[index].quantity - item[index].quantity_committed;

            }
            else {
                item[index].quantity_backorder = item[index].quantity - item[index].quantity_committed;
                // add new value in committed textbox
                item[index].quantity_committed = Newv;
            }
        // }
        setRelocateItems(item);

    }

    let DisabledQty = (relocateItems.length > 0 && relocateItems[0].onhand_quantity) - sumQuantityCommited;

    console.log("itemsLines", itemsLines);
    const columns = [
        {
            // title: 'Order Date',
            render: (val, record, rowInd) => {
                return (
                    <>
                        <Checkbox
                            disabled={DisabledQty === 0 && itemsLines[rowInd].quantity_committed === 0 && itemsLines[rowInd].order_header_no === 0}
                            defaultChecked={val.already ? true : false}
                            checked={itemsLines[rowInd].quantity_committed == 0 ? false : true}
                            // onChange={handleCheckboxChangeFactory(rowIndex)}
                            onChange={(e) => onChangeCheckBox(e, rowInd, "is_selected", record)}

                        />
                    </>
                )
            }
        },
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            key: 'order_date',
            render: (text, record, index) => {
                return (
                    <>{moment(record.order_date).format("DD-MM-YYYY")}</>
                )
            }
        },
        {
            title: 'Order No',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
        },

        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: 'Quantity Order',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record, index) => {
                return (
                    <>{record.quantity}</>
                )
            }
        },
        {
            title: 'Quantity Remaining',
            dataIndex: 'quantity_backorder',
            key: 'quantity_backorder',
            render: (text, record, index) => {
                return (
                    <>{record.quantity_backorder}</>
                    // <>3</>
                )
            }
        },
        {
            title: 'Commit',
            dataIndex: 'commit_criteria_name',
            key: 'commit_criteria_name',
            render: (text, record, index) => {
                return (
                    <>{record.commit_criteria_name}</>
                )
            }
        },
        {
            title: 'Quantity Commited',
            dataIndex: 'quantity_committed',
            key: 'quantity_committed',

            render: (text, record, index) => {
                return (
                    <>
                        <InputNumber
                            size="small"
                            disabled={
                                // relocateItems[index].isSelected ? false : true 
                                itemsLines[index].quantity_committed == 0 ? true : false
                            }
                            // onStep={(value) => updateQuantityCommited(value, index)}
                            // placeholder="Rate"
                            // value={rate ? record.rate : costPrice}
                            // defaultValue={record.quantity_committed}
                            value={itemsLines[index].quantity_committed}
                            onChange={(value) => updateQuantityCommited(record, value, index)}
                        /></>
                )
            }
        },
    ];


    const handleItems = () => {
        const findLocationId = adminLocation?.payload?.data.find((val) => val.location_name === form.getFieldValue().location);
        const data = {
            item_id: form.getFieldValue().item,
            location_id: findLocationId ? findLocationId.location_id : null
        }

        if (data.item_id && data.location_id) {
            dispatch(getAllRelocateItems(token, data)).then((res) => {
                if (res?.type === "GET_ALL_RELOCATEITEMS_SUCCESS") {
                    const item = res?.payload?.data.map((val) => {
                        if (val.quantity_committed > 0) {
                            return ({ ...val, already: true, isSelected: true })
                        }
                        return ({ ...val, isSelected: false })
                        // return val;

                    })
                    setRelocateItems(item);
                }
            });
        }
    }

    let sortedProducts = product && product?.payload?.data.sort(function (a, b) {
        return a.item_code.localeCompare(b.item_code, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    });

    return (
        <div>
            <PageHeader
                title={"Relocate Items"}
                minimize={setMinimize}
                check={minimize}
            />
            <Form
                requiredMark={false}
                autoComplete="off"
                onFinish={onFinish}
                form={form}
                // onFinishFailed={onFinishFailed}
                name="basic"
            >
                <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
                    <Brow>
                        <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                            <Brow>
                                <Bcol lg={6} xxl={4}>
                                    <div className="firstSectionRo">
                                        <Form.Item
                                            name="item"
                                            label="Item"
                                            labelAlign="left"
                                            labelCol={{ span: 5 }}
                                            rules={[
                                                { required: true, message: "Please Select Item" },
                                            ]}
                                        >
                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children
                                                        ?.toLowerCase()
                                                        .indexOf(input?.toLowerCase()) >= 0
                                                }
                                                onSearch={(value) => {
                                                    optimizedFn(value);
                                                }}
                                                onChange={(e) => handleItems(e)}
                                                // onChange={(e) => {
                                                //     setItemId(e);
                                                //     // handleItems(e);
                                                // }}
                                                loading={product.loading}
                                            >
                                                {
                                                    sortedProducts && sortedProducts.map((val, index) => {
                                                        return (
                                                            <Option key={index} value={val.item_id}>
                                                                {val.item_code}
                                                            </Option>
                                                        )
                                                    })
                                                }


                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Bcol>
                                <Bcol>
                                    <Text>Quantity Required</Text> <br />
                                    <Text>{sumQuantityOrder}</Text>
                                </Bcol>
                            </Brow>
                            <Brow>
                                <Bcol lg={6} xxl={4}>
                                    <div className="firstSectionRo">
                                        <Form.Item
                                            name="location"
                                            label="Location"
                                            labelAlign="left"
                                            labelCol={{ span: 5 }}
                                            rules={[{ required: true, message: "Please Select Location" }]}
                                        >
                                            <Select
                                                size="small"
                                                tabIndex={1}
                                                autoComplete={"nope"}
                                                allowClear
                                                showSearch
                                                loading={adminLocation.loading}
                                                onChange={(e) => handleItems(e)}
                                            >
                                                {
                                                    adminLocation?.payload?.data.map((val, index) => {
                                                        return (
                                                            <Option key={index} value={val.location_name}>
                                                                {val.location_name}
                                                            </Option>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Bcol>
                                <Bcol>
                                    <Text>Quantity Packed</Text> <br />
                                    <Text>{sumQuantityPacked}</Text>
                                </Bcol>
                            </Brow>
                            <Brow>
                                <Bcol>
                                    <Text>Quantity On Hand</Text><br />
                                    <Text>{relocateItems.length > 0 && relocateItems[0].onhand_quantity ? relocateItems[0].onhand_quantity : 0}</Text>
                                </Bcol>

                            </Brow>
                            <Brow>
                                <Text>Quantity Commited</Text>
                                <Text>{sumQuantityCommited}</Text>
                            </Brow>
                        </Bcol>
                    </Brow>
                    {relocateItems ? (
                        <>
                            <Table
                                size="small"
                                scroll={{ x: "50rem", y: 360 }}
                                bordered
                                loading={relocateItem?.loading}
                                dataSource={itemsLines.map((it) => ({ ...it, key: it.order_header_id }))}
                                // dataSource={relocateItems.filter((v) => v.order_header_no !== 0).map((it) => ({ ...it, key: it.order_header_id }))}
                                columns={columns}
                                // order_header_no
                                rowClassName="editable-row"
                                pagination={false}
                            />
                            <Button
                                htmlType="submit"
                                style={{ marginTop: "10px" }}
                                disabled={relocateItems.length > 0 ? false : true}
                            >Submit
                            </Button>
                        </>
                    ) : (null)
                    }
                </div>
            </Form>

        </div>
    )
}

export default RelocateItems;