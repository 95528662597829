import React, { useState, useEffect , useLayoutEffect} from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { deleteInvAdjustmentHeader, getOneInvAdjustmentHeader, getInvAdjustmentFormData, getAllAdjustmentAccount } from "../../redux/actions/inventoryAdjustment.action";
import { deleteInvAdjustmentHeader } from "../../redux/actions/inventoryAdjustment.action"
import CrudButtons from "./components/CrudButtons";
import InventoryAdjustmentNewForm from "./components/Form/InventoryAdjusmentNewForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { getAllAdjustmentAccount } from "../../redux/actions/inventoryAdjustment.action";
import { getOneInvAdjustmentNewHeader } from "../../redux/actions/inventoryAdjustmentNew.action";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";

export default function InventoryAdjustmentNew({ token }) {
	TabTitle("Inventory Adjustment")
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();


	const dCompanyId = ReadCookie("defaultCompany");
	const { log,  InvAdjHeader } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [flag, setFlag] = useState(false);
	const [locationID,setLocationID]=useState();
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const [userRights, setUserRights] = useState({})

	



	useLayoutEffect(() => {
	  dispatch(getRights(token, 474)).then((res) => {
		// console.log(res?.payload)
		if (res.type === "GET_USER_RIGHTS_SUCCESS") {
		  setUserRights(res?.payload)
		}
	  });
	}, [token])

	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		dispatch(deleteInvAdjustmentHeader(token, id)).then((res) => {
			if (res.type === "DELETE_INVENTORYADJUSTMENT_HEADER_SUCCESS") {
				notification("success", "Inventory Adjustment Deleted", "Inventory Adjustment deleted successfully!");
				// notification("success", "Inventory Adjustment Deleted", res.payload);
				setFlag(true);
				history.push("/inventory-adjustment/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
	};
	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, [mode]);

	// useEffect(() => {
	// 	dispatch(getInvAdjustmentFormData(token));
	// }, []);

	useEffect(() => {
		if (id) {

			setFormState("formView");
			dispatch(getOneInvAdjustmentNewHeader(token, id)).then((res)=>{
setLocationID(res?.payload?.data[0].location_id)
			})
			// dispatch(getInvAdjustmentFormData(token))

			// dispatch(getOneInvAdjustmentHeader(token, id)).then((res) => 
			// dispatch(getOneItemInvAdjustmentHeader(token, id))
			// dispatch(getOneInvAdjustmentHeader(token, adjustment_header_id)).then((val) => 

		}
	}, [id]);


	// 
	// 

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Inventory Adjustment"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								userRights={userRights}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								inventoryAdjustmentID={id}
								formData={InvAdjHeader?.payload}
							/>
							<div className="mainProductContainer">
								<InventoryAdjustmentNewForm
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={formState === "formView" || formState === "edit" ? InvAdjHeader?.payload : null}
									formState={formState}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									locationID={locationID}
									adjustment_header_id={id}
									disable={setDisable}

								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Inv Transfer{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
