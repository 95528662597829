import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Form, Button, Table, Input } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import Loader from "react-loader-spinner";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { getInventoryValuation } from "../../redux/actions/inventoryValuation.action";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
const { Option } = Select;


const InventoryValuation = ({ token }) => {
  TabTitle("Inventory Valuation")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    adminLocation,
    inventoryValuation
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [excelData, setExcelData] = useState([])
  const initialDate = moment('2026-12-31')


  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((data) => {
      dispatch(getInventoryValuation(token, data)).then((res => {
        setLoading(false)
        setInventoryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      }));
    });
  };

  useEffect(() => {
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);


  const handlePrint = () => {
    window.print();
  };

  const colums = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.item_code?.toString()?.toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
          if ((a.item_code !== null) & (b.item_code !== null)) {
            return a.item_code.localeCompare(b.item_code);
          }
        }
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if ((a.location_name !== null) & (b.location_name !== null)) {
          return a.location_name.localeCompare(b.location_name);
        }
      }
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      sorter: (a, b) => {
        return a.quantity - b.quantity
      },
    },
    {
      title: "Avg Rate",
      dataIndex: "avg_rate",
      key: "avg_rate",
      align: "right",
      render: (text, record) => {
        return "$" + record.avg_rate
      },
      sorter: (a, b) => {
        return a.avg_rate - b.avg_rate
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (text, record) => {
        return "$" + numeral(record.amount).format('0,0.00')
      },
      sorter: (a, b) => {
        return a.amount - b.amount
      },
    },
  ];


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "SKU": v.item_code,
          "Location": v.location_name,
          "Quantity": v.quantity,
          "Avg Rate": v.avg_Rate,
          "Amount": v.amount,
        };
      })
    )
  }

  const totalQuantity = inventoryList.reduce((acc, item, index) => acc + parseFloat(item.quantity), 0)
  const totalAmount = inventoryList.reduce((acc, item, index) => acc + parseFloat(item.amount), 0)

  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"Inventory-Valuation.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    // onClick={onDownload}
                    // className="cursor-not-allowed"
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Inventory Valuation
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44">Sku</label>
                    <div className="w-100">
                      <Form.Item name="sku" className="sku_input">
                        <Input size="small" className={"select_Inputs mb-0"} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <div className="w-100">
                      <Form.Item
                        name="location"
                        label="Location"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 20 }}
                        initialValue={"All"}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          autoComplete={"nope"}
                          loading={adminLocation.loading}
                        >
                          <Option value={null}>All</Option>
                          {
                            adminLocation?.payload?.data.map((val, index) => {
                              return (
                                <Option key={val.location_id} value={val.location_id}>
                                  {val.location_name}
                                </Option>
                              )
                            })
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">From</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">To</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12">
                  <div style={{ marginTop: "4px", float: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
              <hr className="my-1" />
            </div>
          </div>
        </Form>
      </div>
      <div
        // className="business_class_oms mb-2 distributor" style={{ width: "1400px" }}
        className="table_content international_order"
        style={{ maxWidth: '1200px' }}
      >
        <div>
          {inventoryValuation?.payload?.data !== undefined ?
            (
              inventoryValuation?.payload?.data.length > 0 ? (
                <Table
                  className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={inventoryList}
                  columns={colums}
                  loading={inventoryValuation.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              {totalQuantity.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              ${totalAmount.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              ) : (
                <div className="no-Data">
                  {/* {console.log(arInventory?.payload?.data.length, 'arInventory?.payload?.data.length')} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default InventoryValuation;
