import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { customerRefundCols } from "../../utils/cols";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getAllCustomerRefundHeaders } from "../../redux/actions/customerRefundHeader.action";
import { useHistory } from "react-router-dom";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import Swal from "sweetalert2";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { getRights } from "../../redux/actions/userRights.action";

export default function PaymentHeaderList({ token }) {
	TabTitle("Customer Refund")
	const [form] = Form.useForm();
	const history = useHistory();
	const [userRights, setUserRights] = useState({})
	const dispatch = useDispatch();
	const { Panel } = Collapse;
	const { customerRefundHeaderList } = useSelector((state) => state);
	const dateFormat = "MM-DD-YYYY";
	const [menuId, setMenuId] = useState(870);
	const worker = {
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		transactionNo: null,
		postingPeriod: null,
		customerID: null,
		subsidiary: null,
		arAccount: null,
		account: null,
	};


	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "customerRefundList", token, values, dispatch);
		})
	};

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Customer Refund No": v.refund_header_no,
					"Customer Name": v.customer_name,
					"Subsidiary": v.company_name,
					"Posting Period": v.period_name,
					"Account Name": v.account_name,
					"A/R Account": v.ar_account_name,
					"Applied Amount": v.applied_amount,
					"Date": moment(v.refund_date).format("MM-DD-YYYY"),
				};
			})
		}
	}


	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])


	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Customer Refund"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									onClick={() => {
										history.push("/customer-refund/add?mode=add");
									}}
									disabled={userRights.can_add === true ? false : true}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="transactionNo" label="Transaction Number" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="customerID" label="customer">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="subsidiary" label="Subsidiary">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="arAccount" label="arAccount" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="account" label="Account" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit">
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{customerRefundHeaderList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{
									customerRefundHeaderList?.payload?.data && (
										<CSVLink
											filename={"Customer-Refund.csv"}
											data={downloadExcelFileData(customerRefundHeaderList?.payload?.data) || []}
											onClick={() => {
											}}
										>
											Download Excel
										</CSVLink>
									)
								}
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={customerRefundCols}
									source={customerRefundHeaderList?.payload?.data}
									load={customerRefundHeaderList.loading}
									paginate
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
