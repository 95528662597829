import React, { useState, useEffect } from "react";
import { Table, Empty, } from "antd";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import "./tables.css";

export default function Tables({
  scroll,
  perPage,
  size,
  col,
  source,
  paginate,
  load,
  styleObj,
  type,
  scrollX,
  itemId,
  salePersonAllId,
  customerAllId,
  washObj,
  cutObj,
  fabricObj,
  fitCategoryObj,
  genderObj,
  genderCategoryObj,
  riseObj,
  productClassObj,
  inseamLabelObj,
  productStatusObj,
  riseLabelObj,
  seasonObj,
  inseamObj,
  sizeObj,
  productTypeObj,
  loadHeight,
  loadWidth,
  customerObj,
  shippingObj,
  billingObj,
  categoryObj,
  setFilterLen,
  handleFilter,
  show,
  setDataLen,
  regionObj,
  stateObj,
  countryObj,
  cityObj,
  catObj,
  shipViaObj,
  shipMethodObj,
  freightTermObj,
  berganBillingObj,
  courierServiceObj,
  menuObj,
  adminUserObj,
  paymentObj,
  taxObj,
  chartObj,
  adminCompanyObj,
  accountTypeObj,
  orderStatusObj,
  pickTicketObj,
  shipStatusObj,
  fiscalYearObj,
  returnObj,
  locationObj,
  targetObj,
  vendorCatObj,
  returnTypeObj,
  fabricCompositionObj,
  itemTypeObj,
  fabricClassObj,
  fabricClass2Obj,
  fabricTypeObj,
  fabricUsableObj,
  commitCriteriaObj,
  chargeBackTypeObj,
  paymentTypeObj,
  adminModuleObj,
  customerTypeObj,
  termForPrintObj,
  adminJobObj,
  adminDiscountObj,
  factorObj,
  adminMenuTypeObj,
  paymentMethodTypeObj,
  adminCurrencyObj,
  barganPaymentTermObj,
  scmBrandTypeObj,
  scmFigureObj,
  customerCatObj,
  seasonYearObj
}) {
  const history = useHistory();
  const [page, setPage] = useState(perPage);
  const [flag, setFlag] = useState(false);
  const onShowSizeChange = (current, pageSize) => {
    setPage(pageSize);
  };

  // const changeColor = () => {
  //       backgroundColor = "red";
  // }

  const changeFilter = (pagination, filters, sorter, extra) => {
    handleFilter(pagination, filters, sorter, extra);
    if (
      type === "item" ||
      type === "salePerson" ||
      type === "customer" ||
      "vendor"
    ) {
      setFlag(true);
      setDataLen(extra.currentDataSource.length);
    }
  };

  useEffect(() => {
    setFlag(show);
  }, [show]);
  return (
    <Table
      rowKey={(record) => {
        if (type === "style") {
          return record.style_id;
        }
        if (type === "company") {
          return record.company_id;
        }
        if (type === "item") {
          return record.sku;
        }
        if (type === "saleOrder") {
          return record.order_header_id;
        }
        if (type === "wash") {
          return record.wash_id;
        }
        if (type === "logs") {
          return record.system_information_id;
        }
        if (type === "cut") {
          return record.cut_id;
        }
        if (type === "fabric") {
          return record.fabric_id;
        }
        if (type === "fitCategory") {
          return record.fit_category_id;
        }
        // state
        if (type === "state") {
          return record.state_id;
        }
        if (type === "gender") {
          return record.gender_id;
        }
        if (type === "genderCategory") {
          return record.gender_category_id;
        }
        if (type === "rise") {
          return record.rise_id;
        }
        if (type === "productClass") {
          return record.product_class_id;
        }
        if (type === "inseamLabel") {
          return record.inseam_label_id;
        }
        if (type === "productStatus") {
          return record.product_status_id;
        }
        if (type === "productType") {
          return record.product_type_id;
        }
        if (type === "riseLabel") {
          return record.rise_label_id;
        }
        if (type === "season") {
          return record.season_id;
        }
        if (type === "inseam") {
          return record.inseam_id;
        }
        if (type === "size") {
          return record.size_id;
        }
        if (type === "region") {
          return record.region_id;
        }
        if (type === "country") {
          return record.country_id;
        }
        if (type === "city") {
          return record.city_id;
        }
        if (type === "regionCat") {
          return record.sp_region_category_id;
        }
        if (type === "shipVia") {
          return record.ship_via_id;
        }
        if (type === "shipMethod") {
          return record.shipping_method_id;
        }
        if (type === "freightTerm") {
          return record.freight_term_id;
        }
        if (type === "bergenBilling") {
          return record.bergan_billing_id;
        }
        if (type === "courierService") {
          return record.courier_service_id;
        }
        if (type === "adminUser") {
          return record.user_id;
        }
        if (type === "adminMenu") {
          return record.menu_id;
        }
        if (type === "paymentterm") {
          return record.term_id;
        }
        if (type === "taxsetup") {
          return record.tax_id;
        }
        if (type === "chartofaccount") {
          return record.account_id;
        }
        if (type === "adminCompany") {
          return record.user_company_id;
        }
        if (type === "accounttype") {
          return record.account_type_id;
        }
        if (type === "return") {
          return record.return_reason_id;
        }
        if (type === "location") {
          return record.location_id;
        }
        if (type === "target") {
          return record.target_id;
        }
        if (type === "orderStatus") {
          return record.order_status_id;
        }
        if (type === "pickTicketStatus") {
          return record.pt_status_id;
        }
        if (type === "shipStatus") {
          return record.ship_status_id;
        }

        if (type === "fiscalYear") {
          return record.fiscal_year_id;
        }

        if (type === "receiptList") {
          return record.receipt_header_id;
        }
        if (type === "returnType") {
          return record.return_type_id;
        }
        if (type === "fabricComposition") {
          return record.fabric_composition_id;
        }
        if (type === "itemType") {
          return record.item_type_id;
        }
        if (type === "fabricClass") {
          return record.fabric_class_id;
        }
        if (type === "fabricClass2") {
          return record.fabric_class_id2;
        }
        if (type === "fabricType") {
          return record.fabric_type_id;
        }
        if (type === "fabricUsable") {
          return record.fabric_usable_id;
        }
        if (type === "commitCriteria") {
          return record.commit_criteria_id;
        }
        if (type === "chargeBackType") {
          return record.charge_back_type_id;
        }
        if (type === "payment") {
          return record.payment_type_id;
        }
        if (type === "adminModule") {
          return record.module_id;
        }
        if (type === "customerType") {
          return record.customer_type_id;
        }
        if (type === "termForPrint") {
          return record.term_id;
        }
        if (type === "adminCurrency") {
          return record.currency_id;
        }
        if (type === "adminCurrency") {
          return record.currency_id;
        }
        if (type === "customerCat") {
          return record.customer_sales_category_id;
        }
        if (type === "saleOrderList") {
          return record.order_header_id;
        }
        if (type === "saleOrderLine") {
          return record.item_id;
        }
        if (type === "logs") {
          return record.log_id;
        }
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            // 
            // if (type === "customer") {
            //   customerObj(record)
            // }
            if (type === "shipping") {
              shippingObj(record);
            }
            if (type === "billing") {
              billingObj(record);
            }
            if (type === "category") {
              categoryObj(record);
            }
            if (type === "chargeBackType") {
              chargeBackTypeObj(record);
            }
            if (type === "style") {
              styleObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.onClick ={changeColor};

              // tr.style.background = "red";
            }
            if (type === "seasonYearObj") {
              seasonYearObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.onClick ={changeColor};

              // tr.style.background = "red";
            }
            if (type === "cut") {
              cutObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "wash") {
              washObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fabric") {
              fabricObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fitCategory") {
              fitCategoryObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "gender") {
              genderObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            // State
            if (type === "state") {
              stateObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "city") {
              cityObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "regionCat") {
              catObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "genderCategory") {
              genderCategoryObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "rise") {
              riseObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "productClass") {
              productClassObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "inseamLabel") {
              inseamLabelObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "productType") {
              productTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "productStatus") {
              productStatusObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "region") {
              regionObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "country") {
              countryObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "riseLabel") {
              riseLabelObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "season") {
              seasonObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "inseam") {
              inseamObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "size") {
              sizeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "shipVia") {
              shipViaObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "shipMethod") {
              shipMethodObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "freightTerm") {
              freightTermObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "bergenBilling") {
              berganBillingObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "courierService") {
              courierServiceObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "adminUser") {
              adminUserObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
            }
            if (type === "adminMenu") {
              menuObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "paymentterm") {
              paymentObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "taxsetup") {
              taxObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }

            if (type === "chartofaccount") {
              chartObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "adminCompany") {
              adminCompanyObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "accounttype") {
              accountTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "return") {
              returnObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "location") {
              locationObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "target") {
              targetObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "orderStatus") {
              orderStatusObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "pickTicketStatus") {
              pickTicketObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "shipStatus") {
              shipStatusObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fiscalYear") {
              fiscalYearObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "vendorCatObj") {
              fiscalYearObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "returnType") {
              returnTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fabricComposition") {
              fabricCompositionObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "itemType") {
              itemTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "adminJob") {
              adminJobObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "adminDiscount") {
              adminDiscountObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "factor") {
              factorObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "adminMenuType") {
              adminMenuTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "paymentMethodType") {
              paymentMethodTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "barganPaymentTerm") {
              barganPaymentTermObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "ScmBrandType") {
              scmBrandTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fabricClass") {
              fabricClassObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fabricClass2") {
              fabricClass2Obj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fabricType") {
              fabricTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "fabricUsable") {
              fabricUsableObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "commitCriteria") {
              commitCriteriaObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "chargeBackType") {
              chargeBackTypeObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }
            if (type === "adminCurrency") {
              adminCurrencyObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }

            if (type === "ScmFigureTabName") {
              scmFigureObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }

            if (type === "customerCat") {
              customerCatObj(record);
              let tr = event.target.parentNode;
              if (tr.nodeName !== "TR") {
                tr = tr.parentNode;
              }
              for (let i = 0; i < tr.parentNode.childNodes.length; i++) {
                tr.parentNode.childNodes[i].style.color = "black";
              }
              tr.style.color = "#0572f2";
              // tr.style.backgroundColor = "red";
            }

            if (type === "wash") {
              washObj(record);
            }
            if (type === "cut") {
              cutObj(record);
            }
            if (type === "fabric") {
              fabricObj(record);
            }
            if (type === "fitCategory") {
              fitCategoryObj(record);
            }
            if (type === "gender") {
              genderObj(record);
            }
            // state
            if (type === "state") {
              stateObj(record);
            }
            if (type === "city") {
              cityObj(record);
            }
            if (type === "regionCat") {
              catObj(record);
            }
            if (type === "genderCategory") {
              genderCategoryObj(record);
            }
            if (type === "rise") {
              riseObj(record);
            }
            if (type === "productClass") {
              productClassObj(record);
            }
            if (type === "inseamLabel") {
              inseamLabelObj(record);
            }
            if (type === "productType") {
              productTypeObj(record);
            }
            if (type === "productStatus") {
              productStatusObj(record);
            }
            if (type === "riseLabel") {
              riseLabelObj(record);
            }
            if (type === "season") {
              seasonObj(record);
            }
            if (type === "inseam") {
              inseamObj(record);
            }
            if (type === "size") {
              sizeObj(record);
            }
            if (type === "region") {
              regionObj(record);
            }
            if (type === "country") {
              regionObj(record);
            }
            if (type === "shipVia") {
              shipViaObj(record);
            }
            if (type === "shipMethod") {
              shipMethodObj(record);
            }
            if (type === "freightTerm") {
              freightTermObj(record);
            }
            if (type === "bergenBilling") {
              berganBillingObj(record);
            }
            if (type === "courierService") {
              courierServiceObj(record);
            }
            if (type === "adminUser") {
              adminUserObj(record);
            }
            if (type === "adminMenu") {
              menuObj(record);
            }
            if (type === "paymentterm") {
              paymentObj(record);
            }
            if (type === "taxsetup") {
              taxObj(record);
            }
            if (type === "chartofaccount") {
              chartObj(record);
            }

            if (type === "adminCompany") {
              adminCompanyObj(record);
            }
            if (type === "accounttype") {
              accountTypeObj(record);
            }
            if (type === "return") {
              returnObj(record);
            }
            if (type === "location") {
              locationObj(record);
            }
            if (type === "target") {
              targetObj(record);
            }
            if (type === "orderStatus") {
              orderStatusObj(record);
            }
            if (type === "pickTicketStatus") {
              pickTicketObj(record);
            }
            if (type === "shipStatus") {
              shipStatusObj(record);
            }
            if (type === "fiscalYear") {
              fiscalYearObj(record);
            }
            if (type === "vendorCat") {
              vendorCatObj(record);
            }
            if (type === "returnType") {
              returnTypeObj(record);
            }
            if (type === "fabricComposition") {
              fabricCompositionObj(record);
            }
            if (type === "itemType") {
              itemTypeObj(record);
            }
            if (type === "adminJob") {
              adminJobObj(record);
            }
            if (type === "adminDiscount") {
              adminDiscountObj(record);
            }
            if (type === "factor") {
              factorObj(record);
            }
            if (type === "adminMenuType") {
              adminMenuTypeObj(record);
            }
            if (type === "paymentMethodType") {
              paymentMethodTypeObj(record);
            }
            if (type === "barganPaymentTerm") {
              barganPaymentTermObj(record);
            }
            if (type === "ScmBrandType") {
              scmBrandTypeObj(record);
            }
            if (type === "ScmFigureTabName") {
              scmFigureObj(record);
            }
            if (type === "fabricClass") {
              fabricClassObj(record);
            }
            if (type === "fabricClass2") {
              fabricClass2Obj(record);
            }
            if (type === "fabricType") {
              fabricTypeObj(record);
            }
            if (type === "fabricUsable") {
              fabricUsableObj(record);
            }
            if (type === "commitCriteria") {
              commitCriteriaObj(record);
            }
            if (type === "chargeBackType") {
              chargeBackTypeObj(record);
            }
            if (type === "payment") {
              paymentTypeObj(record);
            }
            if (type === "adminModule") {
              adminModuleObj(record);
            }
            if (type === "customerType") {
              customerTypeObj(record);
            }
            if (type === "termForPrint") {
              termForPrintObj(record);
            }
            if (type === "adminCurrency") {
              adminCurrencyObj(record);
            }
            if (type === "customerCat") {
              customerCatObj(record);
            }
          },

          onDoubleClick: (event) => {
            if (type === "customer") {
              customerAllId(record.customer_id);
            }
            if (type === "item") {
              history.push(`/product-info?mode=edit&product_id=${record.item_id}`);
              itemId(record.item_id);
            }
            if (type === "salePerson") {
              salePersonAllId(record.sales_person_id);
            }
            if (type === "vendor") {
              salePersonAllId(record.vendor_id);
            }
            // if (type === "saleOrder") {

            //   saleOrderID(record.order_header_id)
            // }
          },
        };
      }}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      loading={
        load && {
          indicator: (
            <Loader
              type="Grid"
              color="rgba(44, 62, 80,0.8)"
              height={loadHeight ? loadHeight : 20}
              width={loadWidth ? loadWidth : 20}
            />
          ),
        }
      }
      locale={{
        emptyText: <Empty description={"No Records"} />,
      }}
      className="styleTable"
      style={
        type !== "item" ||
          type !== "customer" ||
          type !== "salePerson" ||
          type !== "vendor"
          ? { padding: "0 0px 10px 0" }
          : {}
      }
      scroll={scrollX ? { x: scrollX, y: scroll } : { y: scroll }}
      bordered={true}
      size="small"
      columns={col}
      // dataSource={source}
      dataSource={source}
      pagination={
        paginate
          ? {
            showSizeChanger: true,
            pageSizeOptions: ["100","150","200","500"],
            pageSize: page,
            defaultPageSize: perPage,
            onShowSizeChange: onShowSizeChange,
          }
          : false
      }
      showSorterTooltip={false}
      onChange={(pagination, filters, sorter, extra) =>
        changeFilter(pagination, filters, sorter, extra)
      }
    />
  );
}