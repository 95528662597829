import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getOmsReport } from "../../redux/actions/omsReport.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    Table,
    InputNumber,
    Space, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getBookSummary } from "../../redux/actions/bookSummary.action";
import { getPendingSummary } from "../../redux/actions/pendingSummary.action";
import { getShippedSummary } from "../../redux/actions/shippedSummary.action";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { CSVLink } from "react-csv";
import numeral from "numeral";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../utils/GeneralFunction";
import { getFactor } from "../../redux/actions/factor.action";

const { Option } = Select;


const ShippedSummary = () => {
    TabTitle("Shipped Summary")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const submitButton = useRef(null);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const tableRef = useRef(null);
    const [shippingSummaryList, setShippingSummaryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {
        gender,
        region,
        companyAll,
        OmsReprot,
        regionStateReport,
        omsRegionStateReport,
        omsReportByCustomer,
        quickPoLookup,
        adminLocation,
        style,
        salePersonForm,
        defaultCompany,
        paymentterm,
        customerTypes,
        getShippedSummaryReducer,
        factor
    } = useSelector((state) => state);
    const [excelData, setExcelData] = useState([])




    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
        dispatch(getAllCompany(token));
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getDefaultCompany(token));
        dispatch(getPaymentTerm(token));
        dispatch(getCustomerType(token));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getFactor(token, menuId));
    }, [dispatch, menuId, token]);





    const columns = [
        {
            title: 'Invoice #',
            dataIndex: 'invoice_header_no',
            key: 'invoice_header_no',
            render: (text, record) => (
                <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                  to={"/invoice?mode=edit&order_header_id=" + record.order_header_id + "&invoice_header_id=" + record.invoice_header_id + "&customer_id=" + record.customer_id}
                >
                  {text}
                </Link>
              ),
        },
        {
            title: 'Invoice Date',
            // dataIndex: 'order_date',
            // key: 'order_date',
            render: (text, record) => {
                return <> {moment(record.invoice_date).format("MM-DD-YYYY")}</>
            }
        },
        {
            title: 'PT #',
            dataIndex: 'ship_header_no',
            key: 'ship_header_no',
        },
        {
            title: 'Order #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
        },
        {
            title: 'Customer',
            dataIndex: 'billingcustomer',
            key: 'billingcustomer',
        },
        {
            title: 'Payment Term',
            dataIndex: 'payment_term',
            key: 'payment_term',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => {
                return <>{parseFloat(record.quantity).toFixed(0)}</>
            },
            align: "right",
        },
        {
            title: 'Amount',
            dataIndex: 'net',
            key: 'net',
            render: (text, record) => {
                return <>{numeral(record.net).format('0,0.00')}</>
            },
            align: "right",
        },
        {
            title: 'EDI #',
            dataIndex: 'edi',
            key: 'edi',
        },
        {
            title: 'Billing City',
            dataIndex: 'city_name',
            key: 'city_name',
        },
        {
            title: 'Billing State',
            dataIndex: 'state_name',
            key: 'state_name',
        },
        {
            title: 'Customer Category',
            dataIndex: 'customer_category',
            key: 'customer_category',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Due Date',
            // dataIndex: 'order_date',
            // key: 'order_date',
            render: (text, record) => {
                return <> {moment(record.due_date).format("MM-DD-YYYY")}</>
            }
        },
        {
            title: 'Gender',
            dataIndex: 'gender_name',
            key: 'gender_name',
        },
        {
            title: 'Region',
            dataIndex: 'region_name',
            key: 'region_name',
        },
        {
            title: 'Salesperson',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
        },
        {
            title: 'Scf No',
            dataIndex: 'scf_no',
            key: 'scf_no',
        },
        {
            title: 'Store',
            dataIndex: 'store_id',
            key: 'store_id',
            // align: "right"
        },
        {
            title: 'Order Date',
            // dataIndex: 'order_date',
            // key: 'order_date',
            render: (text, record) => {
                return <> {moment(record.order_date).format("MM-DD-YYYY")}</>
            }
        },
        {
            title: 'Payment Class',
            dataIndex: 'factor_name',
            key: 'factor_name',
            // align: "right"
        },
    ];


    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "invoice_no": v.invoice_header_no,
                    "Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
                    "PT #": v.ship_header_no,
                    "Order #": v.order_header_no,
                    "PO #": v.reference_no,
                    "Billing Customer": v.billingcustomer,
                    "Payment Term": v.payment_term,
                    "Billing City": v.city_name,
                    "Billing State": v.state_name,
                    "Billing State": v.state_name,
                    "Customer Category": v.customer_category,
                    "Status": v.status,
                    "Quantity": parseFloat(v.quantity).toFixed(0),
                    "Amount": parseFloat(v.net).toFixed(2),
                    "Due Date": moment(v.due_date).format("MM-DD-YYYY"),
                    "Gender": v.gender_name,
                    "EDI #": v.edi,
                    "Region": v.region_name,
                    "Salesperson": v.salesperson_name,
                    "Scf No": v.scf_no,
                    "Store": v.store_id,
                    "Order Date": moment(v.order_date).format("MM-DD-YYYY"),
                    "Payment Class": v.factor_name,
                };
            })
        )
    }



    const onFinish = async () => {
        setLoading(true);
        await form.validateFields().then((values) => {
            dispatch(getShippedSummary(token, values)).then((res) => {
                setLoading(false);
                setShippingSummaryList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);

            });
        }).catch((error) => {
            setLoading(false);
        })
    }


    const totalQuantity = () => {
        let sumQty = shippingSummaryList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.quantity);
        }, 0);
        return sumQty;
    }

    const totalSum = () => {
        let sumAmount = shippingSummaryList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.net);
        }, 0);
        return sumAmount;
    }



    return (
        <div className="styleContainer">
            <div className="styleBodyPendingSummary">
                <PageHeader
                    title={"Shipped Summary"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="invoice_no"
                                                        label="Invoice #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
                                                    // initialValue={
                                                    //   formState === "add"
                                                    //     ? null
                                                    //     : form.getFieldValue().employee_id
                                                    // }
                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="ship_no"
                                                        label="PT #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
                                                    // initialValue={
                                                    //   formState === "add"
                                                    //     ? null
                                                    //     : form.getFieldValue().employee_id
                                                    // }
                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    // initialValue={
                                                    //   formState === "add"
                                                    //     ? null
                                                    //     : form.getFieldValue().employee_id
                                                    // }
                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="cutomer_category"
                                                    label="Customer Category"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}

                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        defaultValue={form.getFieldValue().cutomer_category}
                                                        className="styleInput w-100"
                                                    >
                                                        <option value={null}>All</option>
                                                        {
                                                            customerTypes?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().startOf('year')}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().endOf('year')}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    // initialValue={
                                                    //   formState === "add"
                                                    //     ? null
                                                    //     : form.getFieldValue().employee_id
                                                    // }
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    // initialValue={
                                                    //   formState === "add"
                                                    //     ? null
                                                    //     : form.getFieldValue().employee_id
                                                    // }
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="sale_person"
                                                    label="Sale Person"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}

                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().sale_person}
                                                    >
                                                        <option value={null}>All</option>
                                                        {
                                                            salePersonForm?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.salesperson_id}>{val.salesperson_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}

                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().region}
                                                    >
                                                        <option value={null}>All</option>
                                                        {
                                                            region?.payload?.data.map((val) => {
                                                                return (

                                                                    <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="gender"
                                                    label="Gender"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().gender}
                                                    >
                                                        <option value={null}>All</option>
                                                        {
                                                            gender?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="terms"
                                                    label="Terms"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().terms}
                                                    >
                                                        <option value={null}>All</option>
                                                        {
                                                            paymentterm?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.term_id}>{val.term_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="source"
                                                    label="Source"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Input
                                                        size='small'
                                                        className="styleInput"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="store"
                                                        label="Store #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    // initialValue={
                                                    //   formState === "add"
                                                    //     ? null
                                                    //     : form.getFieldValue().employee_id
                                                    // }
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="subsidary"
                                                    label="Subsidary"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        defaultValue={form.getFieldValue().subsidary}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option value={defaultCompany?.payload?.company_id}>{defaultCompany?.payload?.company_name}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="status"
                                                    label="Status"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                // initialValue={
                                                //   formState === "add"
                                                //     ? null
                                                //     : form.getFieldValue().employee_id
                                                // }
                                                >
                                                    <Input
                                                        size='small'
                                                        className="styleInput"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="sku"
                                                        label="Sku #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    // initialValue={
                                                    //   formState === "add"
                                                    //     ? null
                                                    //     : form.getFieldValue().employee_id
                                                    // }
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="style"
                                                    label="Style"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                //   initialValue={
                                                //     formState === "add"
                                                //       ? null
                                                //       : form.getFieldValue().company
                                                //   }
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().style}
                                                    >
                                                        <option value={null}>All</option>
                                                        {
                                                            style?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.style_id}>{val.style_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                            <Form.Item
                                            label="Payment Class"
                                            name="paymentClass"
                                            labelAlign="left"
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                            initialValue={"All"}
                                        >

                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                                showSearch
                                                allowClear
                                                loading={factor.loading}
                                            >
                                                {factor?.payload?.data.map(
                                                    (item) => {
                                                        return (
                                                            <Select.Option
                                                                key={item.factor_id}
                                                                // disabled={!item.is_active}
                                                                value={item.factor_id}
                                                            >
                                                                {item.factor_name}
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                            </div>
                                        </Bcol>
                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>


                    </div>
                )}
            </div>
            <div style={{ marginTop: 40 }}>
                {

                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (
                        shippingSummaryList.length > 0 ? (
                            <Table
                                title={() =>
                                    <CSVLink
                                        filename={"Shipped-Summary.csv"}
                                        data={excelData}
                                        onClick={() => {

                                        }}
                                    >
                                        Download Excel
                                    </CSVLink>
                                }
                                ref={tableRef}
                                // style={{width:"900px"}}
                                size="small"
                                bordered={false}
                                type="company"
                                scroll={{
                                    x: 'calc(1700px + 90%)',
                                    y: 450,
                                }}
                                perPage={15}
                                //   scrollX={"50rem"}
                                summary={(pageData) => {
                                    let sumQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.quantity
                                        ));
                                    },
                                        0);
                                        let Amount = pageData.reduce(function (
                                            previousValue,
                                            currentValue
                                        ) {
                                            return (previousValue += parseFloat(
                                                Number(currentValue.net)
                                            ));
                                        },
                                            0);
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {sumQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                    $ {numeral(Amount).format('0,0.00')}
                                                    </p>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                                dataSource={shippingSummaryList}
                                columns={columns}
                                loading={getShippedSummaryReducer.loading}
                                pagination={{
                                    defaultCurrent: 1,
                                    defaultPageSize: 100,
                                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                                }}
                            />
                        ) : <div
                            style={{
                                backgroundColor: "#CCCCCC",
                                borderWidth: "1pt",
                                borderStyle: "Dashed",
                                fontWeight: "bold",
                                height: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            No record(s)
                        </div>
                    )

                }
            </div>

        </div>
    )
}

export default ShippedSummary;
