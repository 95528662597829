import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  Table, Spin, DatePicker, Input
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import moment from "moment";
import { getCommissionReport } from "../../redux/actions/commissionReport.action";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
const { Option } = Select;

const CommissionReport = () => {
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [dscoInvoiceList, setDscoInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = ReadCookie("defaultCompany");
  const company_id = ReadCookie("defaultCompany");
  const {
    dscoInvoice,
    commissionReport
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      // width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "Payment #",
      dataIndex: "payment_header_id",
      key: "payment_header_id",
      // width: 70,
    },
    {
      title: "SalePerson Name",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
      // width: 70,
    },
    {
      title: "Voucher No",
      dataIndex: "voucher_no",
      key: "voucher_no",
      // width: 100,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (text, record) => {
        return moment(text).format("MM-DD-YYYY")
      }
    },
    {
      title: "Invoice #",
      dataIndex: "invoice_no",
      key: "invoice_no",
      // width: 70,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text, record) => {
        return moment(text).format("MM-DD-YYYY")
      }
    },
    {
      title: "Order #",
      dataIndex: "order_no",
      key: "order_no",
      // width: 70,
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (text, record) => {
        return moment(text).format("MM-DD-YYYY")
      }
    },
    {
      title: "PO",
      dataIndex: "po_no",
      key: "po_no",
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Shipping Cost",
      dataIndex: "shipping_cost",
      key: "shipping_cost",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Net Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className="pop-search-gen pop-170"
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record?.commission
          // .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
          ,
      sorter: (a, b) => {
        return a.commission - b.commission;
      },
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type_name",
      key: "payment_type_name",
      // width: 70,
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
    },
    {
      title: "UnApplied",
      dataIndex: "un_applied",
      key: "un_applied",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getCommissionReport(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setDscoInvoiceList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Payment #": v.payment_header_id,
          "SalePerson Name": v.salesperson_name,
          "Voucher No": v.voucher_no,
          "Payment Date": moment(v.payment_date).format("MM-DD-YYYY"),
          "Invoice #": v.invoice_no,
          "Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
          "Order #": v.order_no,
          "Order Date": moment(v.order_date).format("MM-DD-YYYY"),
          "PO": v.po_no,
          "Customer": v.customer_name,
          "Region": v.region_name,
          "Amount Paid": numeral(v.amount_paid).format("0,00.00"),
          "Shipping Cost": numeral(v.shipping_cost).format("0,00.00"),
          "Net Amount": numeral(v.net_amount).format("0,00.00"),
          "Commission": numeral(v.commission).format("0,00.00"),
          "Payment Type": v.payment_type_name,
          "Gender": v.gender_name,
          "UnApplied": numeral(v.un_applied).format("0,00.00"),
        };
      })
    )
  }

  const totalQuantity = () => {
    let sumQty = dscoInvoiceList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.total_qty);
    }, 0);
    return sumQty;
  }

  const totalSum = () => {
    let sumAmount = dscoInvoiceList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.cost_price);
    }, 0);
    return sumAmount;
  }


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Commission Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            dscoInvoiceList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"Commission-Report.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(550px + 90%)',
                  y: 450,
                }}
                //   scrollX={"50rem"}
                dataSource={dscoInvoiceList}
                columns={columns}
                loading={commissionReport.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  let amountPaid = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.amount_paid
                    ));
                  },
                    0);
                  let commission = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.commission
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell> </Table.Summary.Cell>
                        <Table.Summary.Cell> </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {amountPaid.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {commission.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CommissionReport;
