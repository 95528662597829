import {
    GET_CUSTOMER_PAYMENT_DATA_REQUEST,
    GET_CUSTOMER_PAYMENT_DATA_SUCCESS,
    GET_CUSTOMER_PAYMENT_DATA_ERROR,
} from "../actions/types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getCustomerPaymentReport = (token, data) => (dispatch) => {
    // 
    dispatch({ type: GET_CUSTOMER_PAYMENT_DATA_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/funcGetCustomerPaymentLedgerReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            // from_date: moment(data.from_date).format("YYYY-MM-DD"),
            // to_date: moment(data.to_date).format("YYYY-MM-DD"),
            from_date: moment(data.from_date).format("MM-DD-YYYY"),
            to_date: moment(data.to_date).format("MM-DD-YYYY"),
            customer_id: data.customer_id,
            status: data.status === "All" ? null : data.status,
        }
    })
        .then((response) => {
            return dispatch({ type: GET_CUSTOMER_PAYMENT_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_CUSTOMER_PAYMENT_DATA_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_CUSTOMER_PAYMENT_DATA_ERROR,
                payload: error.response,
            });
        });
};
