import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOneSaleOrderHeader } from "../../redux/actions/salesOrder.action";
import CrudButtons from "./components/CrudButtons";
import SaleOrderForm from "./components/Form/SalesOrderForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import Loader from "react-loader-spinner";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";

export default function SaleOrder({ token }) {
  TabTitle("Sale Order")
  const { log, salesOrder, userType } = useSelector((state) => { return state });
  const defaultCompanyId = ReadCookie("defaultCompany");
  const [formState, setFormState] = useState("view");
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const mode = queryParams.get("mode");
  const orderID = queryParams.get("order_id");
  const customerID = queryParams.get("customer_id");
  const [loading, setLoading] = useState(false);
  const [saleOrderNewID, setSaleOrderNewID] = useState(null);
  const [userRights, setUserRights] = useState({})
  // const menuId = ReadCookie("menuId");
  const[menuId, setMenuId] = useState(53);

  // ******** FETCHED USER RIGHTS *******
  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        setUserRights(res?.payload)
      }
    });
  }, [token])


  // ******** USEEFFECT ON MODE CHANGE *******
  useEffect(() => {
    if (mode === "edit") {
      setLoading(true)
      dispatch(getOneSaleOrderHeader(token, orderID)).then((res) => {
        if (res.type === "GET_ONE_SALEORDERHEADER_SUCCESS") {
          setLoading(false)
        }
      });
      setFormState("formView")
    }
  }, [mode])

  // ******** FILTER RESET FUNCTION *******
  const filterReset = () => {
    setFormState("formView");
  };


  // ******** FORM SAVE FUNCTION *******
  const formSave = () => {
    setSubmit(true);
  };

  return (
    <>
      <div className="styleContainer">
        <div className="styleBody h-100">
          <PageHeader
            title={"Sales Order"}
            minimize={setMinimize}
            check={minimize}
          />

          <div className="bodyStyle">
            {loading ?
              <div className='loaderDiv' >
                <Loader
                  type="Grid"
                  color="#212121"
                  height={50}
                  width={50}
                />
              </div> :
              null}
            <div className="crudDiv">
              <CrudButtons
                user_type={userType?.payload?.data[0]?.coalesce}
                userRights={userRights}
                saleOrderNewID={saleOrderNewID}
                defaultCompanyId={defaultCompanyId}
                name={"saleOrder"}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                formData={salesOrder?.payload}
              />
              <div className="mainProductContainer">
                <SaleOrderForm
                user_type={userType?.payload?.data[0]?.coalesce}
                userRights={userRights}
                  newLoading={loading}
                  setLoadingNew={setLoading}
                  mode={mode}
                  orderID={orderID}
                  customerID={customerID}
                  saleOrderNewID={saleOrderNewID}
                  setSaleOrderNewID={setSaleOrderNewID}
                  submit={setSubmit}
                  val={submit}
                  formData={formState === "formView" || formState === "edit" ? salesOrder?.payload : null}
                  formState={formState}
                  changeState={setFormState}
                  token={token}
                  logs={log}
                  setDisable={setDisable}
                />
              </div>
            </div>
          </div>
        </div>
        {minimize && (
          <div className="styleFooter">
            {" "}
            <Button
              className="minimize-button-style"
              onClick={() => setMinimize(false)}
            >
              {" "}
              Sales Order{" "}
            </Button>{" "}
          </div>
        )}
      </div>
    </>
  );
}
