import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { PlusOutlined, FilterFilled, SearchOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select, Space } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import {  getCustomerPayment } from "../../../../redux/actions/AR_Account.action";
import { getAdminPaymentTypes } from "../../../../redux/actions/adminPaymentType.action";
import { getAllName } from "../../../../redux/actions/name.action";
import { addDepositHeader, getOneDepositHeader, updateDepositHeader } from "../../../../redux/actions/depositHeader.action";
import { addDepositLine, getOneDepositLine, updateDepositLine, deleteDepositLine } from "../../../../redux/actions/depositLine.action";
import DepositModal from "../../components/addDepositModal";
import { getPaymentMethod } from "../../../../redux/actions/paymentMethod.action";
import { useHistory } from "react-router-dom";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import numeral from "numeral";
import { getChartOfAccount } from "../../../../redux/actions/Chart_All_Account.action";
const { Option } = Select;
const { TabPane } = Tabs;

export default function DepositForm({ formData, val, token, formState, submit, depositId, checkFlag, changeState }) {
  const dispatch = useDispatch();
  const submitButton = useRef(null);
  const [headerId, setHeaderId] = useState("");
  const [form] = Form.useForm();
  const { customerRefundFormData, invoiceGlPeriod, companyAll, customrPayment, glImpact, systemLog } = useSelector((state) => state);
  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const [oDate, setOrderDate] = useState(moment())

  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState("");
  const [visible, setVisible] = useState(false);

  const [biliingObj, setBillingObj] = useState(null);
  const [depositList, setDepositList] = useState([]);
  const [singleDeposit, setSingleDeposit] = useState([]);
  const [deletedDepositItem, setDeletedDepositItems] = useState([]);
  const history = useHistory();
  const [resultArr, setresultArr] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
  const [deletedItems, setDeletedItems] = useState([]);

  useLayoutEffect(() => {
    dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
      let result = res?.payload?.find((item) => {
        return item.period_name === currentDate
      });
      setCurrentDate(result?.period_id);
      form.setFieldsValue({ postingPeriod: result?.period_id });
    });
  }, [token, dispatch]);
  // const [defaultdate,setDefaultdate]=useState();
  const getModalData = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (token) {
      // dispatch(getPaymentTerm(token, menuId));
      dispatch(getAllCompany(token));
      dispatch(getCustomerPayment(token));
      dispatch(getAllName(token));
      dispatch(getPaymentMethod(token));
      dispatch(getAdminPaymentTypes(token));
      dispatch(getChartOfAccount(token));
    }
  }, []);
  useEffect(() => {
    if (checkFlag === true) {
      form.setFieldsValue({
        applied_amount: null,
      });
      setFlag(false);
    }
  }, [checkFlag]);

  const deleteDepositItem = (record) => setDepositList([...depositList].filter((item) => item.id !== record.id));





  const handleDeleteOrder = (record, index) => {
    const dataSource = [...depositList];
    const deleteDataSource = [...deletedItems];
    deleteDataSource.push(record);
    setDeletedItems(deleteDataSource);
    setDepositList(dataSource.filter((item) => item.other_deposit_lines_id !== record.other_deposit_lines_id));


  };
  const editDepositForm = (record) => {
    setSingleDeposit(record)
    setVisible(true)
  }
  const glImpcatCols = [

    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text, record, index) => {
        return (
          <>
            $ {numeral(record.debit).format("0,0.00")}
          </>
        )
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) => {
        return (
          <>
            $ {numeral(record.credit).format("0,0.00")}
          </>
        )
      },
    },

    {
      title: "created_date",
      dataIndex: "created_date",
    }
  ]
  const billingColumns = [
    {
      title: "Name",
      dataIndex: "entity_type",
      key: "entity_type",
      render: (text, record, index) => {

        return <> {record.entity_type}
        </>;
      },

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) => (filtered ? <FilterFilled /> : <SearchOutlined />),
      onFilter: (value, record) => record.entity_type.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.entity_type - b.entity_type;
      },

    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) => (filtered ? <FilterFilled /> : <SearchOutlined />),
      onFilter: (value, record) => record.amount.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.amount - b.amount;
      },
    },
    {
      title: "Account",
      dataIndex: "account_name",
      key: "account_name",
      render: (text, record, index) => {

        return <> {record.account_name}
        </>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) => (filtered ? <FilterFilled /> : <SearchOutlined />),
      onFilter: (value, record) => record.account_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.account_name - b.account_name;
      },
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method_name",
      key: "payment_method_name",
      render: (text, record, index) => {

        return <> {record.payment_method_name}
        </>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) => (filtered ? <FilterFilled /> : <SearchOutlined />),
      onFilter: (value, record) => record.payment_method_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.payment_method_name - b.payment_method_name;
      },
    },
    {
      title: "Number",
      dataIndex: "instrument_no",
      key: "instrument_no",
      render: (text, record, index) => {

        return <> {record.instrument_no}
        </>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) => (filtered ? <FilterFilled /> : <SearchOutlined />),
      onFilter: (value, record) => record.instrument_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.instrument_no - b.instrument_no;
      },
    },

    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              // placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) => (filtered ? <FilterFilled /> : <SearchOutlined />),
      onFilter: (value, record) => record.memo.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.memo - b.memo;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          {
            <Button
              size="small"
              icon={<EditOutlined />}
              onClick={() => editDepositForm(record)}
              disabled={!(formState === "add" || formState === "edit")}
            />
          }
          {
            <Button
              size="small"
              icon={<DeleteOutlined />}
              disabled={!(formState === "add" || formState === "edit")}
              onClick={() => handleDeleteOrder(record, index)}

            // onClick={() => hanldeDeleteItems(record)}
            // disabled={!(formType === "add" || formType === "edit")}
            />
          }
        </Space>
      ),
    },
  ];
  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0])
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      }
    })
  }
  useEffect(() => {
    if (formState === "add") {
      form.setFieldsValue({
        // date:null,
        postingPeriod: currentDate,
        memo: null,
        remarks: null,
        account: null,
        date: oDate,
        subsidiary: 2,
        customerID: null,
        transactionNo: null,
        arAccount: null,
      });
    }
  }, [formState]);

  useEffect(() => {
    if (formState === "formView") {
      setLoading(true)
      dispatch(getOneDepositLine(token, depositId)).then((res) => {
        // 
        if (res.type === "GET_ONE_DEPOSIT_LINE_SUCCESS") {
          setDepositList(res?.payload?.data.map((item) => {
            setLoading(false)
            return { ...item, id: item.other_deposit_lines_id }
          }));
        }
      });
      dispatch(getGLImpact(token, { "transaction_header_id": depositId, "transaction_type": "DEPOSIT" }));
      dispatch(getSystemLog(token, { table_name: "pay_bank_deposit_header", record_id: depositId }));
    }
  }, [formState]);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        date: moment(formData[0]?.deposit_date),
        // date:formData[0].refund_date,
        postingPeriod: formData[0]?.gl_period_id,
        memo: formData[0]?.memo,
        subsidiary: formData[0]?.company_id,
        account: formData[0]?.account_id,
        transactionNo: Number(formData[0]?.deposit_header_id),
      });
    }
  }, [formData]);



  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  const onFinish = async (values) => {
    submit(false);
    await form.validateFields().then((values) => {
      setLoading(true);
      if (formState === "add") {
        if (depositList.length > 0) {
          dispatch(addDepositHeader(token, values, parseInt(companyId))).then((res) => {
            let headerID = res?.payload?.deposit_header_id;
            setHeaderId(headerID)
            if (res.type === "ADD_DEPOSIT_HEADER_SUCCESS") {
              dispatch(getOneDepositHeader(token, res?.payload?.deposit_header_id));
              dispatch(
                addDepositLine(
                  token,
                  depositList.map((v) => (


                    {
                      entity_type: v.entity_type,
                      deposit_header_id: res?.payload?.deposit_header_id,
                      amount: v.amount,
                      account_id: v.accountId,
                      payment_method_id: v.paymentMethodId,
                      instrument_no: v.instrument_no,
                      memo: v.memo,

                    }
                  )
                  )
                )
              ).then((res) => {
                if (res.type === "ADD_DEPOSIT_LINE_SUCCESS") {
                  notification("success", "Deposit Created",
                    // res?.payload?.data
                    "Deposit created successfully");
                  setLoading(false);
                  // history.push("/deposit/add");
                  history.push({
                    pathname: `/deposit/edit/${headerID}`,
                    search: `?_id=${headerID}`
                  })
                  // formState("edit")
                  changeState("formView")

                  //disable(true)
                } else if (res.type === "ADD_DEPOSIT_LINE_ERROR") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res?.payload?.data?.data,
                  });
                }
              });
            } else if (res.type === "ADD_DEPOSIT_HEADER_ERROR") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.payload?.data?.data,
              });
            }
          })
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "select atleast one Deposit line",
          });
          return
        }
      } else if (formState === "edit") {
        if (deletedItems.length > 0) {
          dispatch(deleteDepositLine(token, depositId, deletedItems))
        }
        // dispatch(deleteDepositLine(token, depositId, deletedDepositItem))
        dispatch(updateDepositHeader(token, values, depositId)).then((res) => {
          notification("success", "Deposit Updated", "Deposit updated successfully");
          if (res.type === "UPDATE_DEPOSIT_HEADER_SUCCESS") {
            // history.push("/Payment-Header/list");
            let data = depositList.map((item) => {
              // delete item.account_id;
              return {
                other_deposit_lines_id: item.other_deposit_lines_id,
                entity_type: item.entity_type,
                payment_method_id: item.payment_method_id,
                // account_id: item.account_id,
                instrument_no: item.instrument_no,
                memo: item.memo,
                amount: item.amount,
                applied_amount: item.applied_amount,
              };
            })
            const newInsertedItem = data.filter(item => !Boolean(item.other_deposit_lines_id))
            if (newInsertedItem.length > 0) {
              dispatch(
                addDepositLine(
                  token,
                  newInsertedItem.map((v) => (
                    {
                      entity_type: v.entity_type,
                      deposit_header_id: depositId,
                      amount: v.amount,
                      account_id: v.account_id,
                      payment_method_id: v.payment_method_id,
                      instrument_no: v.instrument_no,
                      memo: v.memo,

                    }
                  )
                  )
                ))
              dispatch(getOneDepositLine(token, depositId))
            }


            dispatch(
              updateDepositLine(
                token,
                data.filter(item => !!item.other_deposit_lines_id),
                depositId
              )
            ).then((res) => {
              if (res.type === "UPDATE_DEPOSIT_LINE_REQUEST") {
                setLoading(false);
                notification("success", "Deposit Updated");
              }
            });
          } else if (res.type === "UPDATE_DEPOSIT_LINE_ERROR") {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res?.payload?.data?.data,
            });
          }
          dispatch(getOneDepositLine(token, depositId))
        }
        );
        setLoading(false);
      }
    });
  };

  const handleTab = (key) => {
    if (key === "header") {
      dispatch(getSystemLog(token, { table_name: "pay_bank_deposit_header", record_id: depositId }));
    } else if (key === "line") {
      dispatch(getSystemLog(token, { table_name: "pay_bank_deposit_lines", record_id: depositId }));
    }
  };

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
  };

  return (
    <>
      <Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
        {customerRefundFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Brow>
              <Bcol md={12} xl={9} xxl={8} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item name="transactionNo" label="Deposit #" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().transactionNo}>
                        <Input tabIndex={1} size="small" className="customInputs" disabled={true} />
                      </Form.Item>
                    </div>
                  </Bcol>
                  {/* <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item label="Date" name="date" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Date is required" }]}>
                        <DatePicker
                          size="small"
                          disabled={!(formState === "add" || formState === "edit")}
                          // placeholder={date}
                          defaultValue={formState === "edit" ? form.getFieldValue().date : moment(oDate)}
                          format="MM-DD-YYYY"
                          allowClear
                          className="dateInput customInputs"
                          onChange={(e) => setOrderDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol> */}
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item label="Date" name="date" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Bill Date is required" }]}>
                        {/* <DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Bill Date"
													allowClear
													className="dateInput customInputs"
												/> */}
                        <DatePicker
                          size="small"
                          disabled={!(formState === "add" || formState === "edit")}
                          format="MM-DD-YYYY"
                          // placeholder="Transfer Date"
                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().date
                              : moment(oDate)
                          }
                          onChange={(e) => {
                            setOrderDate(moment(e))
                            let result = invoiceGlPeriod?.payload?.find((item) => {
                              return item.period_name === moment(e).format("MMM YYYY")
                            });
                            setCurrentDate(result?.period_id);
                            form.setFieldsValue({ postingPeriod: result?.period_id })
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Posting Period"
                        name="postingPeriod"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().postingPeriod}
                        rules={[
                          {
                            required: true,
                            message: "PostingPeriod is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={!(formState === "add" || formState === "edit")}
                          loading={invoiceGlPeriod.loading}
                          defaultValue={form.getFieldValue().postingPeriod}
                          // defaultValue={form.getFieldValue().postingPeriod}
                          // placeholder="Posting Period"
                          onChange={(value) => {
                            form.setFieldsValue({
                              postingPeriod: value,
                            });
                          }}
                        >
                          {invoiceGlPeriod?.payload?.map((item) => {
                            return (
                              <Option key={item.period_id} value={item.period_id}>
                                {item.period_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item label="Account" name="account" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().account} rules={[{ required: true, message: "Account is required" }]}>
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={!(formState === "add" || formState === "edit")}
                          loading={customrPayment.loading}
                          defaultValue={form.getFieldValue().account}
                          // placeholder="Select Account"
                          onChange={(value) => {
                            form.setFieldsValue({
                              account: value,
                            });
                          }}
                        >
                          {customrPayment?.payload?.data?.map((item) => {
                            return (
                              <Option key={item.account_id} value={item.account_id}>
                                {item.account_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 5 }}>
                        <Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
            </Brow>
            <Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
          </div>
        </div>
        <SubTitles name={"Classification"} />
        <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
          <Brow>
            <Bcol md={12} xl={9} xxl={8} className="pbpx-10">
              <Brow>
                <Bcol lg={6} xxl={6}>
                  <div className="firstSectionRo">
                    <Form.Item label="Subsidiary" name="subsidiary" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().subsidiary} rules={[{ required: true, message: "Subsidiary is required" }]}>
                      <Select
                        size="small"
                        tabIndex={18}
                        autoComplete={"nope"}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        className="filter-fields"
                        disabled={!(formState === "add" || formState === "edit")}
                        loading={invoiceGlPeriod.loading}
                        defaultValue={form.getFieldValue().subsidiary}
                        // defaultValue={form.getFieldValue().postingPeriod}
                        onChange={(value) => {
                          form.setFieldsValue({
                            subsidiary: value,
                          });
                        }}
                      >
                        {companyAll?.payload?.data.map((item) => {
                          return (
                            <Option key={item.company_id} value={item.company_id}>
                              {item.company_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Bcol>
              </Brow>
            </Bcol>
          </Brow>
          <Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
          <Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
            <TabPane tab="Deposit" key="1">
              <Button
                // disabled={!(formType === "add" || formType === "edit")}
                className="iconBtn crudBTN float-right mbpx-5"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => getModalData()}
              />
              <Tables
                type="billing"
                billingObj={setBillingObj}
                scroll={520}
                perPage={5}
                scrollX={"40rem"}
                source={depositList.map((item) => {


                  return {
                    ...item,
                  }
                })}
                col={billingColumns}
                // load={depositList.loading}
                paginate
              /></TabPane>
            {(formState === "edit" || formState === "formView") &&
              <TabPane tab="GL Impact" key="gl_impact">
                <div className="tabs">
                  <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={glImpact?.payload?.data}
                    col={glImpcatCols}
                    load={glImpact?.loading}
                    paginate
                  />
                </div>
              </TabPane>}

            {(formState === "edit" || formState === "formView") &&
              <TabPane tab="System Information" key="logs" >
                <Tabs
                type="card"
                size="small"
                defaultActiveKey="1"
                centered
              onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Header" key="header">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                <TabPane tab="Line" key="line">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                  </Tabs>
              </TabPane>}
          </Tabs>
        </div>
      </Form>


      <DepositModal depositList={depositList} setDepositList={setDepositList} setVisible={setVisible} visible={visible} setSingleDeposit={setSingleDeposit}
        singleDeposit={singleDeposit} />
    </>
  );
}