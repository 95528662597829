import {
	ADD_CREDIT_MEMO_HEADER_ERROR,
	ADD_CREDIT_MEMO_HEADER_REQUEST,
	ADD_CREDIT_MEMO_HEADER_SUCCESS,

	DELETE_CREDIT_MEMO_HEADER_ERROR,
	DELETE_CREDIT_MEMO_HEADER_REQUEST,
	DELETE_CREDIT_MEMO_HEADER_SUCCESS,

	GET_ALL_CREDIT_MEMO_ERROR,
	GET_ALL_CREDIT_MEMO_REQUEST,
	GET_ALL_CREDIT_MEMO_SUCCESS,

	GET_CREDIT_MEMO_FORM_DATA_ERROR,
	GET_CREDIT_MEMO_FORM_DATA_REQUEST,
	GET_CREDIT_MEMO_FORM_DATA_SUCCESS,

	GET_ONE_CREDIT_MEMO_HEADER_DETAIL_ERROR,
	GET_ONE_CREDIT_MEMO_HEADER_DETAIL_REQUEST,
	GET_ONE_CREDIT_MEMO_HEADER_DETAIL_SUCCESS,

	GET_ONE_CREDIT_MEMO_HEADER_ERROR,
	GET_ONE_CREDIT_MEMO_HEADER_REQUEST,
	GET_ONE_CREDIT_MEMO_HEADER_SUCCESS,

	UPDATE_CREDIT_MEMO_HEADER_ERROR,
	UPDATE_CREDIT_MEMO_HEADER_REQUEST,
	UPDATE_CREDIT_MEMO_HEADER_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");

export const addCreditMemoHeader = (token, formData, companyId) => (dispatch) => {
	console.log(formData, "formData");
	dispatch({ type: ADD_CREDIT_MEMO_HEADER_REQUEST });
	let newData = {
		memo_date: moment(formData.date).format("MM-DD-YYYY"),
		// company_id: companyId,
		gl_period_id: formData.postingPeriod,
		memo: formData.memo,
		customer_id: formData.customer,
		term_id: formData.paymentTerm,
		company_id: formData.subsidiary,
		gender_id: formData.gender,
		ar_account_id: formData?.arAccount,
		sales_person_id: formData?.sale_person,
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/arcreditmemoheader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_CREDIT_MEMO_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_CREDIT_MEMO_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateCreditMemoHeader = (token, formData, billId, companyId) => (dispatch) => {
	console.log(formData, "term_id");
	let newData = {
		memo: formData.memo,
		term_id: formData.paymentTerm,
		customer_id: formData.customer_id,
		memo_date: moment(formData.date).format("MM-DD-YYYY"),
		company_id: formData.company_id,
		gl_period_id: 2,
		memo_header_no: formData.creditMemo,
	};
	dispatch({ type: UPDATE_CREDIT_MEMO_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/arcreditmemoheader/${billId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {

			return dispatch({
				type: UPDATE_CREDIT_MEMO_HEADER_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_CREDIT_MEMO_HEADER_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const getAllCreditMemoHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_CREDIT_MEMO_REQUEST });
	return axios({
		method: "POST",
		// url: `${BASEURL}getAll/arcreditmemoheader`,
		url: `${BASEURL}getAll/Memolist`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			"memo_header_id": data.creditMemoid === "" ? null : data.creditMemoid,
			"memo_header_no": data.creditMemo === "" ? null : data.creditMemo,
			"customer_name": data.customer === "" ? null : data.customer,
			"gl_period_name": data.postingPeriod === "" ? null : data.postingPeriod,
			"term_name": data.paymentTerm === "" ? null : data.paymentTerm,
			"from_date": moment(data.from_date).format("MM-DD-YYYY"),
			"to_date": moment(data.to_date).format("MM-DD-YYYY")
			// company_name: null,
			// memo_header_no: data.creditMemo === "" ? null : data.creditMemo,
			// paymentTerm: data.paymentTerm === "" ? null : data.paymentTerm,
			// from_date: moment(data.from_date).format("MM-DD-YYYY"),
			// due_date: moment(data.due_date).format("MM-DD-YYYY"),
			// to_date: moment(data.to_date).format("MM-DD-YYYY"),
			// customer_name: data.customer === "" ? null : data.customer,///
			// gender: data.gender === "" ? null : data.gender,////
			// gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,///
			// arAccount: data.arAccount === "" ? null : data.arAccount,
			// salePerson: data.salePerson === "" ? null : data.salePerson,////
			// company_id: 2
			//////////////////////////////////////


		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_CREDIT_MEMO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_CREDIT_MEMO_ERROR,
				payload: error.response,
			});
		});
};

export const getOneCreditMemoHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_CREDIT_MEMO_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payPurchaseBillHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_HEADER_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneCreditMemoDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_CREDIT_MEMO_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/arcreditmemoheader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_HEADER_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteCreditMemoHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_CREDIT_MEMO_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/arcreditmemoheader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_CREDIT_MEMO_HEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_CREDIT_MEMO_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getCreditMemoFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_CREDIT_MEMO_FORM_DATA_REQUEST });

	if (clear) {
		return dispatch({
			type: GET_CREDIT_MEMO_FORM_DATA_SUCCESS,
			payload: {
				location: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/arcreditmemoheader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_CREDIT_MEMO_FORM_DATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_CREDIT_MEMO_FORM_DATA_ERROR,
				payload: error.response,
			});
		});
};