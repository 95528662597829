// export const BASEURL = "https://erpbe.fasreports.com/v1/"
// export const BASEURL1 = "https://erpbe.fasreports.com/"

// export const BASEURL = "http://devubuntu.fascom.com:8000/v1/"
// export const BASEURL1 = "http://devubuntu.fascom.com:8000/"

// export const BASEURL = "https://erpbackend.fasreports.com/v1/"
// export const BASEURL1 = "https://erpbackend.fasreports.com/"

// // export const BASEURL = "http://15.184.156.229:8000/v1/"
// // export const BASEURL1 = "http://15.184.156.229:8000/"
export const BASEURL = "https://cadbackend.dl1961.com/v1/"
export const BASEURL1 = "https://cadbackend.dl1961.com" 
// export const BASEURL = "http://localhost:8000/v1/"
// export const BASEURL1 = "http://localhost:8000/"






