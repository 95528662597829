import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addInvoiceHeader, getInvoiceHeaderFormData, getOneInvoiceGlPeriod, updateInvoiceHeader, getOneInvoiceHeader } from "../../../../redux/actions/invoiceHeader.action";
import { getOneInvoiceLine, getOneInvoiceLineDetail, deleteInvoiceLine } from "../../../../redux/actions/invoiceHeaderLine.action";
import { getSaleOrderGenderHeader } from "../../../../redux/actions/saleOrderGenderHeader.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { relatedTransactionColumns } from "../../../../utils/cols";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import { CaretRightOutlined, EditOutlined, DeleteOutlined, MailOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import numeral from "numeral";
import { DatePicker, Input, Tabs, Button, Form, Select, Card, Row, Col, Table, InputNumber, Collapse, Popconfirm, Tooltip, Skeleton, Divider } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import "../../../../screens/SaleOrder/salesOrder.css";
import Swal from "sweetalert2";
import { getOneInvoiceRelatedRecord, updateInvoiceLine } from "../../../../redux/actions/invoiceLine.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getOneCustomerAddress } from "../../../../redux/actions/customerAddress.action";
import { getOnesaleOrderRelatedRecord } from "../../../../redux/actions/saleOrderLine.action";
import { sendEmailInvoice } from "../../../../redux/actions/emailinvoice.action";


const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;

export default function InvoiceForm({ userRights, setDisable, formData, val, token, formState, submit, dCompany, orderHeaderId, saleOrderID, checkFlag, setFlag, changeState, shipHeaderId, edit,
  loading: deleteLoading, mode, order_id, shipHeaderID, invoice_header_id, setInvoiceHeaderID, customer_id, order_header_id, invoiceHeaderID,user_type }) {
  const submitButton = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { invoiceHeaderFormDataReducer, invoiceGlPeriod, saleOrderFormData, glImpact, customerAddress, saleOrderRelatedRecord, systemLog } = useSelector((state) => state);
  const companyId = ReadCookie("defaultCompany");
  const [gender, setGender] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [oDate, setOrderDate] = useState(moment());
  const [sDate, setStartDate] = useState(moment());
  const [eDate, setEndDate] = useState(moment());
  const [invoiceDate, setInvoiceDate] = useState(moment());
  const [dueDate, setDueDate] = useState(moment());
  const [shippingCharges, setShippingCharges] = useState("");
  const [discountBasis, setDiscountBasis] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [editModalShow, setEditModalShow] = useState(false);
  const [getLineRecord, setGetLineRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [toogle, setToogle] = useState(null);
  const [itemIndex, setItemIndex] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
  const [invRelatedTransaction, setInvRelatedTransaction] = useState([]);




  // *********** LOADING *************
  useEffect(() => {
    setLoading(deleteLoading);
  }, [deleteLoading]);


  useEffect(() => {
    dispatch(getInvoiceHeaderFormData(token, false))
    dispatch(getOneInvoiceGlPeriod(token));
  }, []);


  // *********** UPDATE QUANTITY FUNCTION *************
  const updateQuantity = (type, id, value, p_qty) => {
    setToogle(true);
    let arr = [...orderItems];
    if (value > arr[itemIndex].balance_qty) {
      Swal.fire({ icon: "error", title: "OOPS", text: "Quantity Should Be Less Than Balance Quantity" });
      arr[itemIndex].quantity = arr[itemIndex].balance_qty;
    } else {
      arr[itemIndex].quantity = value;
      arr[itemIndex].gross_amount = arr[itemIndex].quantity * parseInt(arr[itemIndex].rate);
      arr[itemIndex].discount = ((arr[itemIndex].quantity) * parseInt(arr[itemIndex].rate) * parseInt(arr[itemIndex].discount_percent)) / 100;
      arr[itemIndex].tax_amount = (arr[itemIndex].quantity * parseInt(arr[itemIndex].rate)) * parseInt(arr[itemIndex].tax_percent) / 100;
      arr[itemIndex].net_amount = arr[itemIndex].gross_amount - arr[itemIndex].discount + arr[itemIndex].tax_amount;

    }
    setOrderItems(arr);

  };


  const updateCostPrice = (type, id, value, p_qty) => {
    setToogle(true);
    let arr = [...orderItems];
    arr[itemIndex].rate = value;
    arr[itemIndex].gross_amount = arr[itemIndex].quantity * parseInt(arr[itemIndex].rate);
    arr[itemIndex].discount = ((arr[itemIndex].quantity) * parseInt(arr[itemIndex].rate) * parseInt(arr[itemIndex].discount_percent)) / 100;
    arr[itemIndex].tax_amount = (arr[itemIndex].quantity * parseInt(arr[itemIndex].rate)) * parseInt(arr[itemIndex].tax_percent) / 100;
    arr[itemIndex].net_amount = arr[itemIndex].gross_amount - arr[itemIndex].discount + arr[itemIndex].tax_amount;
    setOrderItems(arr);
    console.log("VALUE", value);
  }



  // *********** SHOW WDIT MODAL FUNCTION *************
  const showEditModal = (record, index) => {
    setGetLineRecord(record);
    setEditModalShow(true);
  };

  // *********** AMOUNT SUM  *********** 
  let amountSum = 0;
  orderItems.map((val) => amountSum += val.quantity * val.rate)

  // *********** GL IMPACT ROWS COLUMNS *************
  const glImpcatCols = [

    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text, record, index) => {
        return (
          <>
            $ {numeral(record.debit).format("0,0.00")}
          </>
        )
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) => {
        return (
          <>
            $ {numeral(record.credit).format("0,0.00")}
          </>
        )
      },
    },

    {
      title: "created_date",
      dataIndex: "created_date",
    }
  ]

  // *********** RENDER COLUMNS FUNCTIONS *************
  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0])
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      }
    })
  }

  // *********** UPDATE TAX PERCENT FUNCTIONS *************
  const updateTaxPercent = (p_qty, p_rate, tax_perc) => {
    let copArr = [...orderItems];
    copArr[itemIndex].tax_percent = tax_perc;
    copArr[itemIndex].tax_amount = (((p_qty * p_rate) * tax_perc / 100));
    setOrderItems(copArr);
  }

  const updateDiscountPercent = (p_qty, p_rate, value) => {
    let copArr = [...orderItems];
    copArr[itemIndex].discount_percent = value;
    copArr[itemIndex].discount = (((p_qty * p_rate) * value / 100));
    setOrderItems(copArr);
  }


  // *********** ROWS COLUMNS *************
  const columns = [
    {
      title: "SKU",
      dataIndex: "item_code",
      width: 100,
      fixed: 'left',
    },
    {
      title: "Style",
      dataIndex: "style_name",
      width: 120,
    },

    {
      title: "Wash",
      dataIndex: "wash_name",
    },
    {
      title: "Gender Category",
      dataIndex: "gender_category_name",
      width: 150,
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Cost Price",
      dataIndex: "rate",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            {editModalShow && itemIndex === index ? (
              <InputNumber
                size="small"
                value={orderItems[itemIndex].rate}
                onChange={(value) =>
                  updateCostPrice("up", record.item_code, value, index)
                }
              />
            ) : (
              numeral(record.rate).format("0,0.00")
            )}
          </>
        );
      },
    },
    {
      title: "Balance Qty",
      dataIndex: "balance_qty",
      editable: true,
      render: (text, record, index) => record.balance_qty,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            {editModalShow && itemIndex === index ? (
              <InputNumber
                size="small"
                value={orderItems[itemIndex].quantity}
                onChange={(value) =>
                  updateQuantity("up", record.item_code, value, index)
                }
              />
            ) : (
              Math.trunc(record.quantity)
            )}
          </>
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "gross_amount",
      render: (_, record) => parseFloat(record.gross_amount).toFixed(2),
    },
    {
      title: "Discount Percent",
      render: (_, record, index) => {
        return (
          <>
            {editModalShow && itemIndex === index ? (
              <InputNumber
                size="small"
                defaultValue={orderItems[itemIndex].discount_percent}
                onChange={(value) =>
                  updateDiscountPercent(orderItems[itemIndex].quantity, orderItems[itemIndex].rate, value)
                }
              />
            ) : (
              parseFloat(record.discount_percent).toFixed(2) + "%"
            )}
            {/* {record.discount_percent == null
              ? numeral(record.discount_percent).format("0,0.00") + "%"
              : parseFloat(record.discount_percent).toFixed(2) + "%"} */}
          </>
        );
      },
      width: 70,
    },
    {
      title: "Discount Amount",
      render: (_, record) => numeral(record.discount).format("0,0.00"),
      width: 70,
    },

    {
      title: "Tax Percent",
      width: 70,
      render: (text, record, index) => {
        return (
          <>
            {editModalShow && itemIndex === index ? (
              <InputNumber
                size="small"
                defaultValue={orderItems[itemIndex].tax_percent}
                onChange={(value) =>
                  updateTaxPercent(orderItems[itemIndex].quantity, orderItems[itemIndex].rate, value)
                }
              />
            ) : (
              parseFloat(record.tax_percent).toFixed(2) + "%"
            )}
          </>
        );
      },
    },
    {
      title: "Tax Amount",
      render: (_, record) => parseFloat(record.tax_amount).toFixed(2),
      width: 70,
    },
    {
      title: "Net Amount",
      dataIndex: "net_amount",
      render: (_, record, index) => {
        let fn_qty = record.quantity;
        let fn_rate = record.rate;
        let fn_disperc = record.discount_percent;
        let fn_taxper = record.tax_percent;

        record.net_amount = numeral(((fn_qty * fn_rate) - (((fn_qty * fn_rate) * fn_disperc) / 100) + (((fn_qty * fn_rate) * fn_taxper) / 100)
        )).format("00.00");
        return (
          <>
            {numeral((
              (fn_qty * fn_rate)
              - (((fn_qty * fn_rate) * fn_disperc) / 100)
              + (((fn_qty * fn_rate) * fn_taxper) / 100)
            )).format("0,0.00")}
          </>
        )
      },
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record, index) => {
        return (
          <>
            {
              formState === "formView" ? null : (
                <>
                  <Tooltip title="Delete" color={"red"}>
                    {true ? (
                      <>
                        <EditOutlined
                          className={`edit-Button`}
                          onClick={() => {
                            showEditModal(record, index)
                            setItemIndex(index)
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <EditOutlined className={`edit-Button opacity-40`} />
                      </>
                    )}
                  </Tooltip>
                  <Popconfirm
                    title={`Are you sure to delete the item ${record.item_id}?`}
                    onConfirm={() => handleDelete(record)}
                  >
                    <DeleteOutlined className={`delete-Button`} />
                  </Popconfirm>
                </>
              )
            }
          </>
        )
      },
    },
  ];

  function sendingEmail() {

    console.log({ formData: formData })
    const data = {
      invoice_header_id
    }
    dispatch(sendEmailInvoice(token, data)).then((res) => {
      if (res.type === "SEND_EMAIL_INVOICE_SUCCESS") {
        notification("success", res?.payload?.data);
      }
    });
  }

  // *********** HANDLE CHANGE FUNCTIONS *************
  function handleChange(value, type) {
    if (type === "company") {
      companyId(value);
      form.setFieldsValue({ company: value });
    }

    if (type === "factor") {
      form.setFieldsValue({ factor: value });
    }

    if (type === "gender") {
      form.setFieldsValue({
        category: null,
        region: null,
        salesPerson: null,
      });
      if (customer) {
        setGender(value);
        dispatch(getSaleOrderGenderHeader(token, value, customer)).then(
          (res) => {
            if (
              res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
              res?.payload.length > 0
            ) {
              form.setFieldsValue({
                category: res.payload[0].sp_region_category_id,
                region: res.payload[0].region_id,
                salesPerson: res.payload[0].salesperson_id,
              });
            }
          }
        );
      } else {
        setGender(null);
      }
    }
  }
  // *********** FORMSTATE USEEFFECT *************
  useEffect(() => {
    if (mode === "invoice" && formState === "add" && formData) {
      console.log("RUNNING")
      dispatch(getOneInvoiceLineDetail(token, formData?.ship_header_id)).then((res) => {
        if (res.type === "GET_ONE_INVOICE_HEADER_LINE_DETAIL_SUCCESS") {
          setOrderItems(res?.payload);
        } else if (res.type === "GET_ONE_INVOICE_HEADER_LINE_DETAIL_ERROR") {
          notification("warning", "No Items Found", res?.payload?.data.data);
        }
      })
    }
    else if (mode === "edit") {
      console.log("RUNNING")
      dispatch(getOneInvoiceLine(token, invoice_header_id)).then((res) => {
        if (res.type === "GET_ONE_INVOICE_HEADER_LINE_SUCCESS") {
          setOrderItems(res.payload.data);
        } else if (res.type === "GET_ONE_INVOICE_HEADER_LINE_ERROR") {
          notification("warning", "No Items Found", res?.payload?.data.data);
        }
      });
    }

    form.setFieldsValue({
      glPeriodId: currentDate,
      orderDate: oDate,
      startDate: sDate,
      endDate: eDate,
    });
    if (formState === "view") {
      dispatch(getAllCompany(token)).then(() => {
        form.setFieldsValue({
          company: parseInt(dCompany),
        });
      });
    }

  }, [formState]);

  // *********** DISCOUNT AMOUNT FUNCTION *************
  const discountAmount = (amount, orderItem = []) => {

    let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
    if (orderItem.length > 0) {
      orderIt = orderItem;
    }

    const sum = orderIt.map((item) => item.amount).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
    let totalAmount = orderIt.map((item) => item.quantity * item.rate);
    if (amount !== null || amount !== undefined) {
      if (orderIt.length > 0) {
        let calculateDiscount = orderIt.map((item) => {
          const totalSum = totalAmount.reduce((accum, value) => {
            return accum + value
          })
          item.discount = numeral((item.quantity * item.rate * numeral((amount / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00").replace(/,/g, '');
          item.discount_percent = numeral((amount / totalSum) * 100).format("0,0.00");
          item.net_amount = item.quantity * item.rate - numeral((item.quantity * item.rate * numeral((amount / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00");
          return item;
        });
        setOrderItems(calculateDiscount);
      }
    }
  };

  // *********** DISCOUNT PERCENT FUNCTION *************
  const discountPercent = (percent, orderItem = []) => {
    let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
    if (orderItem.length > 0) {
      orderIt = orderItem;
    }
    if (percent !== null || percent !== undefined) {
      if (orderIt.length > 0) {
        let calculateDiscount = orderIt.map((item) => {
          item.discount_percent = numeral(percent).format("0.00");
          item.discount = numeral(((item.quantity * item.rate) * percent) / 100).format("0,0.00").replace(/,/g, '');
          item.net_amount = item.quantity * item.rate - numeral((item.quantity * item.rate * percent) / 100).format("0,0.00");
          return item;
        });
        setOrderItems(calculateDiscount);
      }
    }
  };

  // *********** DISCOUNT BASIS FUNCTION *************
  const handleDiscountBasis = (discountBasis) => {
    const discount = form.getFieldValue().gross_amount ? form.getFieldValue().gross_amount : 0;
    const discountPercentage = form.getFieldValue().discountPercent ? form.getFieldValue().discountPercent : 0;
    let orderItem = orderItems.length > 0 ? orderItems : visibleItems;
    if (discountBasis === "P") {
      if (discountPercentage !== null || discountPercentage !== undefined) {
        discountPercent(parseFloat(discountPercentage).toFixed(0), orderItem);
      }
      setDiscountBasis("P");
    }
    if (discountBasis === "A") {
      if (discount !== null || discount !== undefined) {
        discountAmount(parseFloat(discount).toFixed(0), orderItem);
      }
      setDiscountBasis("A");
    }
  };


  // *********** ONFINISH FUNCTION *************
  const onFinish = async (e) => {
    submit(false);
    setDisable(true);
    await form.validateFields().then((values) => {
      setLoading(true);
      if (orderItems.length <= 0) {
        Swal.fire({ icon: "error", title: "Request Failed...", text: "Line Items Should not be Empty, Please Reload the page" })
        setLoading(false);
        setDisable(false);
        return
      }
      if (formState === "add") {
        dispatch(addInvoiceHeader(token, values, formData,

          orderItems.map((v) => (
            delete v.gender_category_name,
            delete v.balance_qty,
            delete v.inseam,
            delete v.size,
            { ...v })))
        ).then((response) => {

          if (response.type === "ADD_INVOICE_HEADER_SUCCESS") {
            form.setFieldsValue({ invoiceNo: response?.payload?.invoice_header_id });
            setInvoiceHeaderID(response?.payload?.invoice_header_id)
            dispatch(getOneInvoiceHeader(token, response?.payload?.invoice_header_id));
            changeState("formView")
            notification("success", "Invoice Created", response?.payload?.data);
            setLoading(false);
            setDisable(false);
            history.push(`/invoice?mode=edit&order_header_id=${order_header_id}&invoice_header_id=${response?.payload.invoice_header_id}&customer_id=${formData.customer_id}`)
          }
          else if (response.type === "ADD_INVOICE_HEADER_ERROR") {
            setLoading(false);
            Swal.fire({ icon: "error", title: "Oops...", text: response?.payload?.data?.data })
          }
        });

      }

      if (formState === "edit") {

        dispatch(updateInvoiceHeader(token, invoice_header_id, values)).then((res) => {
          if (res.type === "UPDATE_INVOICE_HEADER_SUCCESS") {
            let items = orderItems.map(({
              created_by,
              created_date,
              deleted_by,
              deleted_date,
              item_code,
              last_updated_by,
              last_updated_date,
              parent_item_id,
              style_name,
              wash_name,
              gender_category_name,
              inseam,
              size,
              ...rest
            }) => rest
            );

            dispatch(updateInvoiceLine(token, invoice_header_id, items))
            changeState("formView")
            if (deletedItems.length > 0) {
              dispatch(deleteInvoiceLine(token, deletedItems, invoice_header_id))
            }
            notification("success", "Invoice Updated");
            setLoading(false);
            setDisable(false);
          }
        }
        );

      }


    });
  };

  // *********** FORMDATA USEEFFECT *************
  useEffect(() => {
    if (formData === "P") {
      let discountPercentage = orderItems[0] && orderItems[0].discount_percent;
      let decimalDiscountPercentage = Math.trunc(discountPercentage);
      form.setFieldsValue({ discountPercent: decimalDiscountPercentage });
    }
    if (formData === "A") {
      let discountAmount = orderItems[0] && orderItems[0].gross_amount;
      form.setFieldsValue({ gross_amount: discountAmount });
    }
  }, [formData]);

  useEffect(() => {
    setLoading(true);
    if (invoiceGlPeriod?.payload && invoiceGlPeriod?.payload.length > 0) {
      let result = invoiceGlPeriod?.payload?.find((item) => {
        return item.period_name === currentDate
      });
      if (result) {
        setCurrentDate(result?.period_id);
        setLoading(false);
      }
    }
  }, [])



  // *********** FORMDATA USEEFFECT *************
  useEffect(() => {
    if (formData) {
      dispatch(getOneCustomerAddress(token, customer_id, formData?.order_header_id));
      dispatch(getGLImpact(token, { "transaction_header_id": invoice_header_id, "transaction_type": "INVOICE" }));
      setCustomer(formData.customer_name);
      setDiscountBasis(formData.discount_basis);
      form.setFieldsValue({
        discountPercent: formData.discount_percent,
        gross_amount: formData.gross_amount,
        berganBillingId: formData.bergan_billing_id,
        cancelDate: moment(formData.cancel_date),
        invoiceDate: moment(formData.invoice_date),
        category: formData.sp_region_category_id,
        company: formData.company_id,
        customer: formData.customer_name,
        dc: formData.dc_no,
        deptDescription: formData.department_description,
        discountBasis: formData.discount_basis,
        divisionCode: formData.division_code,
        divisionDescription: formData.division_description,
        endDate: moment(formData.end_date),
        externalRefID: formData.reference_no,
        factor: formData.factor_id,
        freightAmount: formData.freight_amount,
        freightCharges: formData.freight_charges,
        freightTermId: formData.freight_term_id,
        gender: formData.gender_name,
        genderId: formData.gender_id,
        glPeriodId: formData.gl_period_id ? formData.gl_period_id : currentDate,
        invoiceNo: formData.invoice_header_no,
        isClosed: formData.is_closed,
        isShipComplete: formData.is_ship_complete,
        location: formData.location_name,
        markForAddress1: formData.mark_for_address_1,
        markForAddress2: formData.mark_for_address_2,
        markForCity: formData.mark_for_city,
        markForCode: formData.mark_for_code,
        markForCompanyName: formData.mark_for_company_name,
        markForCountry: formData.mark_for_country,
        markForEmail: formData.mark_email,
        markForPhone: formData.mark_for_phone,
        markForState: formData.mark_for_state,
        markForZip: formData.mark_for_zip,
        memo: formData.memo,
        somemo: formData.so_memo,
        ptmemo: formData.pt_memo,
        remarks: formData.remarks,
        orderDate: moment(formData.order_date),
        dueDate: moment(formData.due_date),
        edi: formData.edi,
        orderDate: moment(formData.order_date),
        orderNumber: formData.order_header_no,
        orderStatus: formData.order_status_id,
        paymentTermId: formData.payment_term_id,
        po: formData.po_no,
        region: formData.region_name,
        resaleCertificateNo: formData.release_certificate_no,
        salesPerson: formData.salesperson_name,
        shipHeaderId: formData.ship_header_no,
        shippingAccountNo: formData.ship_account_no,
        shippingAddressID: formData.shipping_address_id,
        shippingCost: formData.shipping_cost,
        shippingMethodId: formData.shipping_method_id,
        shippingTaxCode: formData.shipping_tax_code,
        shippingTaxRate: formData.shipping_tax_rate,
        shipViaId: formData.ship_via_id,
        sourceType: formData.source_type,
        startDate: moment(formData.start_date),
        store: formData.customer_store_no,
        termForPrint: formData.term_for_print,
        billing_firstname: formData.billing_firstname,
        billing_lastname: formData.billing_lastname,
        shipping_firstname: formData.shipping_firstname,
        shipping_lastname: formData.shipping_lastname,
        // Billing
        billingCustomer: formData.billing_address_id,
        billing_firstname: formData.bill_to_first_name,
        billing_lastname: formData.bill_to_last_name,
        addressOneBilling: formData.bill_to_address_1,
        addressTwoBilling: formData.bill_to_address_2,
        cityBilling: formData.bill_to_city_name,
        stateBilling: formData.bill_to_state_name,
        zipBilling: formData.bill_to_zip_code,
        countryBilling: formData.bill_to_country_name,
        telBilling: formData.bill_to_phone,
        billingEmail: formData.bill_to_email,
        // shipping
        shippingCustomer: formData.shipping_address_id,
        shipping_firstname: formData.ship_to_first_name,
        shipping_lastname: formData.ship_to_last_name,
        addressOneShipping: formData.ship_to_address_1,
        addressTwoShipping: formData.ship_to_address_2,
        shippingEmail: formData.ship_to_email,
        cityShipping: formData.ship_to_city_name,
        telShipping: formData.ship_to_phone,
        stateShipping: formData.ship_to_state_name,
        zipShipping: formData.ship_to_zip_code,
        countryShipping: formData.ship_to_country_name,
      });
    }
  }, [formData]);

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);


  // *********** HANDLE TAB FUNCTION *************
  const handleTab = (key) => {
    if (key === "header") {
      dispatch(getSystemLog(token, { table_name: "scm_order_invoice_header", record_id: invoice_header_id }));
    }
    if (key === "line") {
      dispatch(getSystemLog(token, { table_name: "scm_order_invoice_lines", record_id: invoice_header_id }));
    }
    else if (key === "relatedTransaction" && formState === "formView" || formState === "edit") {
      dispatch(getOneInvoiceRelatedRecord(token, invoice_header_id)).then((res) => {
        if (res?.type === "GET_ONE_INVOICE_RELATED_RECORD_SUCCESS") {
          setInvRelatedTransaction(res?.payload?.data);
        }
      });
    }
  };


  // *********** CHECKFLAG USEEFFECT *************
  useEffect(() => {
    if (checkFlag === true) {
      form.setFieldsValue({
        addressOneBilling: null,
        berganBillingId: null,
        billingAddressID: null,
        billingCustomer: null,
        billingEmail: null,
        cancelDate: null,
        category: null,
        cityBilling: null,
        cityShipping: null,
        company: null,
        countryBilling: null,
        countryShipping: null,
        customer: null,
        dc: null,
        deptDescription: null,
        divisionCode: null,
        divisionDescription: null,
        endDate: null,
        externalRefID: null,
        freightAmount: null,
        freightCharges: null,
        freightTermId: null,
        gender: null,
        // glPeriodId: null,
        isClosed: false,
        isShipComplete: false,
        location: null,
        memo: null,
        orderDate: null,
        dueDate: null,
        edi: null,
        orderStatus: null,
        paymentTermId: null,
        po: null,
        region: null,
        resaleCertificateNo: null,
        salesPerson: null,
        shippingAccountNo: null,
        shippingAddressID: null,
        shippingCost: null,
        shippingCustomer: null,
        shippingEmail: null,
        shippingMethodId: null,
        shippingTaxCode: null,
        shippingTaxRate: null,
        shipViaId: null,
        sourceType: null,
        startDate: null,
        stateBilling: null,
        stateShipping: null,
        store: null,
        termForPrint: null,
        zipBilling: null,
        zipShipping: null,
        discountPercent: null,
        billing_firstname: null,
        billing_lastname: null,
        shipping_firstname: null,
        shipping_lastname: null
      });
      setVisibleItems([]);
      setFlag(false);
    }
  }, [checkFlag]);

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);


  // *********** ONFINISH FAILED FUNCTION *************
  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
  };


  // *********** SUMMARY FUNCTION *************
  const summary = () => {
    let orderSummary = orderItems;
    let amount = 0;
    let quantity = 0;
    let discount = 0;
    let tax = 0;
    let shippingCost = form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0;
    let net = 0;
    if (orderSummary.length > 0) {
      orderSummary?.forEach(function (item) {
        if (item.hasOwnProperty("rate")) {
          amount += parseFloat(item.rate) * parseFloat(item.quantity);
          net += parseFloat(item.rate) * parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("quantity")) {
          quantity += parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("discount")) {
          discount += parseFloat(item.discount);
        }
        if (item.hasOwnProperty("tax_amount")) {
          tax += parseFloat(item.tax_amount);
        }
      });
    }


    return {
      quantity: quantity,
      amount: numeral(amount).format("0,0.00"),
      discount: numeral(discount).format("0,0.00"),
      tax: numeral(tax).format("0,0.00"),
      shippingCost: numeral(shippingCost).format("0,0.00"),
      // (summary().amount - summary().discount) + Number(summary().tax)
      net: numeral(
        numeral(amount).value() -
        numeral(discount).value() +
        numeral(tax).value() +
        numeral(shippingCost).value()
        // numeral(amount).value() +
        // numeral(shippingCost).value() -
        // numeral(discount).value()
      ).format("0,0.00"),
    };
  };


  // *********** AVOID FOR ON ENTER SAVE FUNCTION *************
  window.addEventListener('keydown', function (e) { if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) { if (e.target.nodeName == 'INPUT' && e.target.type == 'text') { e.preventDefault(); return false; } } }, true);

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);


  // *********** DELTE ORDER ITEMS FUNCTIONS *************
  const handleDelete = (record) => {
    const dataSource = [...orderItems];
    const deleteSource = [...deletedItems];
    deleteSource.push(record);
    setDeletedItems(deleteSource);
    setOrderItems(
      dataSource.filter((item) => item.item_id !== record.item_id)
    );
    notification("success", "Item Deleted");
  };

  // *********** CHANGE SHIPPING COST FUNCTIONS *************
  const changeShippingCost = (value) => {
    setShippingCharges(numeral(value * 1.1).format("00.00"));
    form.setFieldsValue({ shippingCost: numeral(value * 1.1).format("00.00") });
  }


  // function disableBtns() {
  //   let result = "auto";
  //   if (!userRights?.can_edit && user_type !== "PK") {
  //     result = "none";
  //   }
  //   else {
  //     result = "none";
  //   }
  //   return result;
  // }

  function disableLinks() {
    let result = "auto";
    if (!userRights?.can_edit && user_type !== "PK") {
      result = "none";
    }
    else {
      result = "auto";
    }
    return result;
  }




  return (
    <>
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        name="basic"
      >
        {/* {invoiceHeaderFormDataReducer.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
              <p>Hello</p>
            </div>
          ))} */}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-15">
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  < Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="invoiceNo"
                        label="Invoice #"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <Input
                          size="small"
                          disabled
                          className="customInputs without_border"
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Invoice Date"
                        name="invoiceDate"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <DatePicker
                          size="small"
                          disabled={formState === "formView"}
                          defaultValue={moment(invoiceDate)}
                          format="MM-DD-YYYY"
                          allowClear
                          className="dateInput customInputs"
                          onChange={(e) => setInvoiceDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>



                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="customer"
                        label="Customer"

                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <Input
                          disabled={true}
                          size="small"
                          tabIndex={1}
                          className="customInputs without_border"
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="po"
                        label="P.O #"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <Input
                          disabled={true}
                          size="small"
                          tabIndex={1}
                          className="customInputs without_border"
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="orderNumber"
                        label="Sale Order #"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <a
                          // style={{ pointerEvents: userRights?.can_edit ? "auto" : "none" }}
                          style={{ pointerEvents: disableLinks() }}
                          target="_blank" href={`/sale-order?mode=edit&order_id=${formData?.order_header_id}&customer_id=${formData?.customer_id}`}>
                          {formData?.order_header_no}
                        </a>
                        {/* <Input
                          disabled={true}
                          size="small"
                          className="customInputs without_border"
                        /> */}
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="shipHeaderId"
                        label="PT #"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <a
                        // style={{ pointerEvents: userRights?.can_edit ? "auto" : "none" }}
                        style={{ pointerEvents: disableLinks() }}
                        target="_blank" href={`/order-shipment?mode=edit&order_id=${formData?.ship_header_id}&customer_id=${formData?.customer_id}`}>
                          {formData?.ship_header_no}
                        </a>
                        {/* <Input
                          disabled={true}
                          size="small"
                          tabIndex={10}
                          className="customInputs without_border"
                        /> */}
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="glPeriodId"
                        label="Posting Period"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: "Gl Period is required" }]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          loading={invoiceGlPeriod.loading}
                          disabled={formState === "formView"}
                          onChange={(value) => handleChange(value)}
                        >
                          {invoiceGlPeriod?.payload?.map((item) => {
                            return (
                              <Option
                                key={item.period_id}
                                value={item.period_id}
                              >
                                {item.period_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Due Date"
                        name="dueDate"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <DatePicker
                          size="small"
                          defaultValue={moment(dueDate)}
                          disabled={formState === "formView"}
                          format="MM-DD-YYYY"
                          allowClear
                          className="dateInput customInputs"
                          onChange={(e) => setDueDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="edi"
                        label="Edi"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <Input
                          disabled={formState === "formView"}
                          tabIndex={27}
                          size="small"
                          className={`customInputs`}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="Invoice Memo"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >
                        <Input
                          disabled={formState === "formView"}
                          tabIndex={27}
                          size="small"
                          className={`customInputs`}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="somemo"
                        label="SO Memo"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >

                        <Input
                          size="small"
                          disabled={formState === "formView" || formState === "edit"}
                          readOnly
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="ptmemo"
                        label="PT Memo"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                      >

                        <Input
                          size="small"
                          disabled={formState === "formView" || formState === "edit"}
                          readOnly
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <div className="firstSectionRo">
                  <div className="order-summary">
                    <div className="summary-title">Invoice Summary</div>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Quantity:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {summary().quantity}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Sub Total:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${numeral(summary().amount).format("0,0.00")}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Less Discount:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${numeral(summary().discount).format("0,0.00")}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Tax:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${numeral(summary().tax).format("0,0.00")}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Shipping Cost:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${summary().shippingCost}
                        </p>
                      </Bcol>
                    </Brow>
                    <Divider className="my-1" />
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className=" mb-0">Total:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">${summary().net}</p>
                      </Bcol>
                    </Brow>
                  </div>
                </div>
              </Bcol>
            </Brow>
            <Brow className="secondSectio">
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Billing Information"
                >
                  <div className="secondSectionRow">
                    {customerAddress?.loading ? <Skeleton
                      paragraph={{ rows: 6 }}
                    /> :
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="billingCustomer"
                            label="Name"
                            className="name-field-form selectNamee"
                            labelAlign="left"
                            labelCol={{
                              xs: { span: 24 }, sm: { span: 4 }, md: { span: 4 }, g: { span: 2 }
                            }}
                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={4}
                              autoComplete={"nope"}
                              showSearch={true}
                              className="customInputs fw-400"
                              loading={customerAddress?.loading}
                            >
                              {customerAddress.payload?.data?.map((item, index) => {
                                return (
                                  <Option key={index} value={item.customer_address_id}>{" "}
                                    {`${item.address_addressee}(${item.address_zip_code})`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_firstname"
                            label={`First Name`}
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneBilling"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              disabled={formState === "formView"}
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoBilling"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              disabled={formState === "formView"}
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityBilling"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateBilling"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipBilling"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryBilling"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telBilling"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}

                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    }

                  </div>
                </Card>
              </Bcol>
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Shipping Information"
                >
                  {customerAddress?.loading ? <Skeleton
                    paragraph={{ rows: 6 }}
                  /> :
                    <div className="secondSectionRow">
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="shippingCustomer"
                            label="Name"
                            labelAlign="left"
                            className="name-field-form"
                            labelCol={{
                              xs: { span: 24 },
                              sm: { span: 4 },
                              md: { span: 4 },
                              lg: { span: 2 },
                            }}
                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={5}
                              autoComplete={"nope"}
                              showSearch={true}
                              className="customInputs fw-400"
                              loading={customerAddress?.loading}

                            >
                              {customerAddress.payload?.data?.map((item, index) => {
                                return (
                                  <Option key={index} value={item.customer_address_id}>{" "}
                                    {`${item.address_addressee}(${item.address_zip_code})`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_firstname"
                            label="First Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneShipping"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              disabled={formState === "formView"}
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoShipping"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              disabled={formState === "formView"}
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityShipping"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateShipping"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipShipping"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryShipping"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telShipping"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>



                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shippingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  }
                </Card>
              </Bcol>
            </Brow>

            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>
          <SubTitles name={"Date"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Start Date is required" }]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={6}
                      disabled={true}
                      format="MM-DD-YYYY"
                      allowClear
                      className="dateInput customInputs without_border"
                      onChange={(e) => setStartDate(moment(e).format("MM-DD-YYYY"))}
                      defaultValue={moment(sDate)}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="cancelDate"
                    label="Cancel Date"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: false, message: "Cancel Date is required" }]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={8}
                      disabled={true}
                      disabledDate={(d) => !d || d.isBefore(eDate)}
                      format="MM-DD-YYYY"
                      allowClear
                      className="dateInput customInputs without_border"
                    />
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <SubTitles name={"Sales Information"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Gender is required" }]}
                  >
                    <Input
                      disabled={formState === "formView"}
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="region"
                    label="Region"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Region is required" }]}
                  >
                    <Input
                      disabled={formState === "formView"}
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="category"
                    label="Category"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <Input
                      disabled={formState === "formView"}
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="location"
                    label="Location"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Location is required" },
                    ]}
                  >
                    <Input
                      disabled={formState === "formView"}
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="salesPerson"
                    label="Sales Person"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Sales Person is required" }]}
                  >
                    <Input
                      disabled={formState === "formView"}
                      size="small"
                      tabIndex={11}
                      readOnly
                      className="customInputs without_border"
                    />
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <Collapse
            bordered={false}
            defaultActiveKey={1}
            className="pbpx-10 bg-white"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header="Classification" key="1">
              <Tabs
                type="card"
                size="small"
                defaultActiveKey="shipping"
                centered
                onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Shipping" key="shipping">
                  <div className="container-fluid pbpx-0 ptpx-0">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingAccountNo"
                            label="Shipping Account No"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={14}
                              className="customInputs"
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shipViaId"
                            label="Ship Via"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Ship Via is required", }]}
                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={15}
                              autoComplete={"nope"}
                              allowClear
                              loading={invoiceHeaderFormDataReducer.loading}
                              defaultValue={form.getFieldValue().shipViaId}
                              onChange={(value) => handleChange(value)}
                            >
                              {invoiceHeaderFormDataReducer?.payload?.shipVia.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.courier_service_id}
                                      disabled={!item.is_active}
                                      value={item.courier_service_id}
                                    >
                                      {item.service_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingMethodId"
                            label="Shipping Method"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Please select Shipping Method" }]}
                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={16}
                              autoComplete={"nope"}
                              allowClear
                              loading={invoiceHeaderFormDataReducer.loading}
                              defaultValue={form.getFieldValue().shippingMethodId}
                              onChange={(e) => handleChange(e)}
                            >
                              {invoiceHeaderFormDataReducer?.payload?.adminShipMethod.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.shipping_method_id}
                                      disabled={!item.is_active}
                                      value={item.shipping_method_id}
                                    >
                                      {item.shipping_method_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingCost"
                            label="Shipping Cost"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <InputNumber
                              disabled={formState === "formView"}
                              size="small"
                              onInput={(val) => {
                                form.setFieldsValue({ shippingCost: val });
                                setShippingCharges(val);
                              }}
                              onPressEnter={(e) => changeShippingCost(e.target.value)}
                              tabIndex={17}
                              className="customInputs w-100"
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxCode"
                            label="Shipping Tax Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={18}
                              autoComplete={"nope"}
                              allowClear
                              loading={invoiceHeaderFormDataReducer.loading}
                              defaultValue={form.getFieldValue().shippingTaxCode}
                              onChange={(value) => handleChange(value)}
                            >
                              {invoiceHeaderFormDataReducer?.payload?.adminShipTaxCode.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.ship_tax_id}
                                      disabled={!item.is_active}
                                      value={item.ship_tax_id}
                                    >
                                      {item.ship_tax_description}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxRate"
                            label="Shipping Tax Rate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <InputNumber
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={19}
                              className="customInputs w-100"
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="freightTermId"
                            label="Freight Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Please Select Freight Term" }]}
                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={20}
                              autoComplete={"nope"}
                              allowClear
                              loading={invoiceHeaderFormDataReducer.loading}
                              defaultValue={form.getFieldValue().freightTermId}
                              onChange={(value) => handleChange(value)}
                            >
                              {invoiceHeaderFormDataReducer?.payload?.freightTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.freight_term_id}
                                      disabled={!item.is_active}
                                      value={item.freight_term_id}
                                    >
                                      {item.freight_term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="berganBillingId"
                            label="Bergen Billing Option"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Bergan Billing is required" }]}
                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={24}
                              autoComplete={"nope"}
                              allowClear
                              loading={invoiceHeaderFormDataReducer.loading}
                              defaultValue={form.getFieldValue().berganBillingId}
                              onChange={(value) => handleChange(value, "berganBillingId")}
                            >
                              {invoiceHeaderFormDataReducer?.payload?.berganBilling.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.bergan_billing_id}
                                      disabled={!item.is_active}
                                      value={item.bergan_billing_id}
                                    >
                                      {item.bergan_billing_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="paymentTermId"
                            label="Payment Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}                          >
                            <Select
                              disabled={formState === "formView"}
                              size="small"
                              tabIndex={24}
                              autoComplete={"nope"}
                              allowClear
                              loading={invoiceHeaderFormDataReducer.loading}
                              defaultValue={form.getFieldValue().paymentTermId}
                              onChange={(value) => handleChange(value, "paymentTermId")}
                            >
                              {invoiceHeaderFormDataReducer?.payload?.paymentTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.term_id}
                                      disabled={!item.is_active}
                                      value={item.term_id}
                                    >
                                      {item.term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="remarks"
                            label="Remarks"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              disabled={formState === "formView"}
                              tabIndex={27}
                              size="small"
                              className="customInputs w-100"
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                    </Brow>
                  </div>
                </TabPane>
                <TabPane tab="Related Transaction" key="relatedTransaction">
                  <div className="tabs">
                    <Tables
                      type="company"
                      scrollX={"50rem"}
                      scroll={360}
                      perPage={15}
                      source={
                        invRelatedTransaction
                        // invRelatedTransaction.length > 0 ? invRelatedTransaction : "Record Not Found"
                        // saleOrderRelatedRecord?.payload?.data?.data ===
                        //   "Record not found"
                        //   ? []
                        //   : saleOrderRelatedRecord.payload
                      }
                      col={relatedTransactionColumns}
                      load={relatedTransactionColumns.loading}
                      paginate
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Panel>
          </Collapse>
          <SubTitles name={"Discount"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountId"
                    label="Discount"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <Select
                      size="small"
                      tabIndex={24}
                      autoComplete={"nope"}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={formState === "formView"}
                      defaultValue={form.getFieldValue().discountId}>
                      {saleOrderFormData?.payload?.adminDiscount &&
                        saleOrderFormData?.payload?.adminDiscount.map(
                          (item) => {
                            return (
                              <Option
                                key={item.discount_id}
                                value={item.discount_id}
                              >
                                {item.discount_name}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountCode"
                    label="Discount Code"
                    labelAlign="left"
                    labelCol={{ span: 6 }}>
                    <Input
                      disabled={formState === "formView"}
                      tabIndex={25}
                      size="small"
                      className={`customInputs `}

                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountBasis"
                    label="Discount Type"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <Select

                      size="small"
                      tabIndex={26}
                      autoComplete={"nope"}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={formState === "formView"}
                      defaultValue={formState === "edit" ? form.getFieldValue().discountBasis : null}
                      onChange={(value) => handleDiscountBasis(value)}
                    >
                      <Option key={1} value={"A"}>
                        Amount
                      </Option>
                      <Option key={2} value={"P"}>
                        Percent
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>

              <Bcol
                className={discountBasis === "A" ? "d-block" : "d-none"}
                lg={6}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="discount"
                    label="Discount Amount"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                  >
                    <InputNumber
                      min={0}
                      defaultValue={form.getFieldValue().gross_amount}
                      size="small"
                      onInput={(val) => { form.setFieldsValue({ gross_amount: val }) }}
                      onChange={discountAmount}
                      disabled={formState === "formView"}
                      className={`customInputs fw-400  `}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol
                className={discountBasis === "P" ? "d-block" : "d-none"}
                lg={6}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountPercent"
                    label="Discount Percent"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <InputNumber
                      tabIndex={26}
                      min={0}
                      defaultValue={form.getFieldValue().discountPercent}
                      size="small"
                      onInput={(val) => { form.setFieldsValue({ discount_percent: val }) }}
                      onChange={discountPercent}
                      disabled={formState === "formView"}
                      className={`customInputs fw-400  `}
                    />
                  </Form.Item>
                </div>
              </Bcol>

            </Brow>
          </div>
          <Tabs
            type="card"
            size="small"
            defaultActiveKey="1"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Product Information" key="1">
              <Table
                type={"company"}
                scroll={{ x: 1680, y: 400 }}
                size="small"
                bordered
                dataSource={orderItems.sort((a, b) => a.item_code.localeCompare(b.item_code))}
                columns={columns}
                pagination={false}
              />
            </TabPane>

            <TabPane tab="GL Impact" key="gl_impact">
              <div className="tabs">
                <Tables
                  type="company"
                  scrollX={"50rem"}
                  scroll={360}
                  perPage={15}
                  source={glImpact?.payload?.data}
                  col={glImpcatCols}
                  load={glImpact.loading}
                  paginate
                />
              </div>
            </TabPane>
            {(formState === "edit" || formState === "formView") &&
              <TabPane tab="System Information" key="logs" >
                <Tabs
                  type="card"
                  size="small"
                  defaultActiveKey="1"
                  centered
                  onChange={(key) => handleTab(key)}
                >
                  <TabPane tab="Header" key="header">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                  <TabPane tab="Line" key="line">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                </Tabs>
              </TabPane>}
          </Tabs>
        </div>
      </Form >
    </>
  );
}
