import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { FilterOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
// import { Modal, DatePicker, Button } from "antd";
import Tables from "../../components/Table/Tables";
import { payCustomerPaymentCols } from "../../utils/cols";
import moment from "moment";
import { dateChecker } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getAllCustomerPaymentHeaders } from "../../redux/actions/customerPaymnetHeader.action";
import { useHistory } from "react-router-dom";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { getAdminPaymentTypes } from "../../redux/actions/adminPaymentType.action";
import { getRights } from "../../redux/actions/userRights.action";
export default function PaymentHeaderList({ token }) {
	const [form] = Form.useForm();
	const [dateOP, setDateOP] = useState("");
	const [userRights, setUserRights] = useState({})
	const history = useHistory();
	const dispatch = useDispatch();
	const { Option } = Select;
	const dateFormat = "MM-DD-YYYY";
	const { payCustomerPaymentHeaderList, adminPaymentType } = useSelector((state) => state);
	const [menuId, setMenuId] = useState(408);

	const worker = {
		customer: null,
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		paymentNo: null,
		postingPeriod: null,
		arAccount: null,
		debitAccount: null,
		paymentTerm: null,
		paymentType: null,
		paymentMethod: null,
		genderCategory: null,
	};

	const { Panel } = Collapse;
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "paymentHeaderList", token, values, dispatch);

		})
	};

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Payment Header No": v.payment_header_no,
					"Payment Date": moment(v.payment_date).format("MM-DD-YYYY"),
					"Customer": v.customer_name,
					"Posting Period": v.period_name,
					"Debit Account": v.debit_account_name,
					"AR Account": v.credit_account_name,
					"Payment Amount": v.to_apply,
				};
			})
		}
	}

	useEffect(() => {
		dispatch(getAdminPaymentTypes(token))
	}, [])



	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])




	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Customer Payment List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => {
										history.push("/customer-payment/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="paymentNo" label="Payment NO#" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="customer" label="Customer Name" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="arAccount" label="A/R Account" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="debitAccount" label="Debit Account">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="paymentTerm" label="Payment Term" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										{/* <Form.Item name="paymentType" label="Payment Type">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item> */}
										<Form.Item name="paymentType" label="Payment Type">
											<Select
												showSearch
												allowClear
												size="small"
												className="styleInput"
												loading={adminPaymentType.loading}
												optionFilterProp="children"
											>
												{adminPaymentType?.payload?.data?.map((acc) => {
													return <Option key={acc.payment_type_id} value={acc.payment_type_id}>{acc.payment_type_name}</Option>;
												})}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="paymentMethod" label="Payment Method">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="genderCategory" label="Gender Category">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									{/* <Col xs={24} xl={8}>
										<Form.Item name="fromAmount" label="From Amount">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col> */}
								</Row>
								{/* <Row>
									<Col xs={24} xl={8}>
										<Form.Item name="toAmount" label="To Amount">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row> */}
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={payCustomerPaymentHeaderList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{payCustomerPaymentHeaderList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{
									payCustomerPaymentHeaderList?.payload?.data && (
										<CSVLink
											filename={"Customer-Payment.csv"}
											data={downloadExcelFileData(payCustomerPaymentHeaderList?.payload?.data) || []}
											onClick={() => {
											}}
										>
											Download Excel
										</CSVLink>
									)
								}
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									//   customerAllId={setCustomerAllId}
									//customerObj={setCustomerObj}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={payCustomerPaymentCols}
									source={payCustomerPaymentHeaderList?.payload?.data}
									load={payCustomerPaymentHeaderList.loading}
									paginate
								/>
								{/* <span className="records-text">
                      {dataLen === 0 ? <>No of Records:  <span className="fw-500">  0</span> </> : < > No of Records: <span className="fw-500"> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>}
                    </span> */}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
		// <>
		//     <div className="rangeModal">
		//         <Modal title="Select date Range" visible={isModalVisible} onOk={(value) => handleOk(value)} onCancel={handleCancel}>
		//             <RangePicker defaultValue={[moment("01-01-2022", dateFormat), moment("06-01-2022", dateFormat)]} format={dateFormat} onChange={onChange} />
		//         </Modal>
		//     </div>
		//     <div className="styleContainer">
		//         <div className="styleBody h-100">
		//             <PageHeader title={"Customer Payment"} />
		//             <div className="bodyStyle">
		//                 <div className="crudDiv">
		//                     <div className="mainProductContainer">
		//                         <>
		//                             {payCustomerPaymentHeaderList?.loading ? (
		//                                 <SkeletonLoad />
		//                             ) : (
		//                                 <div className="productTable pt-0">
		//                                     <span>
		//                                         <Button
		//                                             type="primary"
		//                                             onClick={() => {
		//                                                 history.push("/customer-payment/add?mode=add");
		//                                             }}
		//                                             className="iconBtn crudBTN mb-0 mbpx-0"
		//                                             shape="circle"
		//                                             paginate
		//                                             scrollx={"80rem"}

		//                                         >ADD</Button>
		//                                         <Button
		//                                             onClick={showModal}
		//                                             className="iconBtn crudBTN mb-0 mbpx-0"
		//                                             shape="circle"
		//                                             icon={<FilterOutlined />}
		//                                             paginate
		//                                             scrollx={"80rem"}
		//                                             type={"saleOrder"}
		//                                         />
		//                                         <Button
		//                                             className="iconBtn crudBTN mb-0 mbpx-0"
		//                                             shape="circle"
		//                                             icon={<FullscreenExitOutlined />}
		//                                             paginate
		//                                             scrollx={"80rem"}
		//                                             onClick={() => handleClose()}
		//                                             type={"saleOrder"}
		//                                         />
		//                                     </span>
		//                                     <Tables
		//                                         type={"saleOrder"}
		//                                         load={payCustomerPaymentHeaderList?.loading}
		//                                         col={payCustomerPaymentCols}
		//                                         scroll={570}
		//                                         perPage={100}
		//                                         source={payCustomerPaymentHeaderList?.payload ? payCustomerPaymentHeaderList?.payload?.data : []}
		//                                         paginate
		//                                         scrollx={"10rem"}
		//                                     />
		//                                 </div>
		//                             )}
		//                         </>
		//                     </div>
		//                 </div>
		//             </div>
		//         </div>
		//     </div>
		// </>
	);
}
